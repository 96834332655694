import React, {useEffect, useState} from 'react';
import EditReply from "./RouteComponents/EditReply";

import {Row, Col, Space, Steps, Button, Modal, Divider, Card} from 'antd'
import ShowRequest from "./RouteComponents/ShowRequest";
import {useHistory, useParams} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";
import {EditOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import axios from "axios";
import authHeader from "../../Services/auth-header";
import CreateReply from "./RouteComponents/CreateReply";

//const queryString = window.location.search;
//const urlParams = new URLSearchParams(queryString);
//var RequestValue = urlParams.get('id');




interface Params {
    id: string;
}


const RouteDetailsPage = () => {
    const {id} = useParams<Params>();
    //const RequestValue=id;
    console.log("RouteDetailsPage ReloadSuggestedContractor:")
    const {t} = useTranslation();
    
    const history= useHistory();
    const user = AuthService.getCurrentUser();
    //console.log("order-details - user: ", user)

    if(!user){
        history.push("/login");
    }

    const[reqNumber, setreqNumber] = useState<string>("");
    const[reqStatus, setreqStatus] = useState<number>(0);
    const[reqType, setreqType] = useState<number>(0);
    const[isOwnRequest, setisOwnRequest] = useState(false);

    
    const[showConfirmationStep, setShowConfirmationStep] = useState(true);
    

    useEffect(()=>{
        if(reqNumber===""){
            axios.get(AuthService.DATA_URL+'Request/' + id, {headers: authHeader()})
                .then((response) => {
                    //console.log("order-details - response: ", response)
                    setreqType(response.data.requestType)
                    if(response.data.externalRequestNumber!==null){
                        setreqNumber(response.data.externalRequestNumber)

                    }

                    if(response.data.requestStatus==20) setreqStatus(1)
                    if(response.data.requestStatus==30 && response.data.requestType!=0) setreqStatus(2)
                    if(response.data.requestStatus==40) setreqStatus(3)
                    if(response.data.requestStatus==50) setreqStatus(4)
                    if(response.data.createdByContractorId===user.contractorId)
                    {
                        setisOwnRequest(true)
                        setreqNumber(response.data.requestNumber)
                    }


                })            
        }


    },[id])

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOk = () => {
        setIsModalOpen(false);
        history.push("/routes/edit/:?id="+id);
    };

    
    

    return (
        
        <div>
            {/* 
            <Card style={{padding: "0px"}} className="EditRequestCard">
            */}
                <Row style={{height: "60px" ,padding : "15px"}}>
                <Col span={7}>
                            <h1 style={{marginBottom: "0px", marginTop : "-5px"}}>
                                {t("Naročilo")} № {reqNumber}

                                {//reqType===2 || reqType===1 || 
                                isOwnRequest  ?
                                    
                                    <Button style={{top: "-5px", left: "5px"}}
                                            onClick={() => {
                                                Modal.confirm({
                                                    title: t('Potrebno_je_potrdilo'),
                                                    okText: t("Nadaljevanje"),
                                                    cancelText: t("Nazaj"),
                                                    onOk: handleOk,
                                                    content: t("Če_ste_dodelili_izvajalca_bo_ta_ob_urejanju_naročila_ponastavljen"),
                                                    footer: (_, { OkBtn, CancelBtn }) => (
                                                        <>
                                                            <CancelBtn />
                                                            <OkBtn/>

                                                        </>
                                                    ),
                                                });
                                            }}
                                    >
                                        <EditOutlined />
                                    </Button>  
                                    :
                                    ""
                          
                                }
    


                            </h1>
                           
                      

                    
                </Col>
                    
                <Col span={17}>
                    

                        <Steps 
                               className="RequestStatuses"
                            size="small"
                            current={reqStatus}
                            items={[
                                {
                                    title: t('Novo'),
                                },
                                {
                                    title: t('Načrtovana'),
                                },
                                {
                                    title: t('Confirmed'),
                                },
                                {
                                    title: t('On_route'),
                                },
                                {
                                    title: t('Zaključena'),
                                },
                            ]}
                        />

                </Col>
            </Row>
            <Row style={{backgroundColor: "#f4f6f9"}}>
                
                <Col span={8}>
                   
                    <ShowRequest  RequestId={id ? id : undefined} ShowMode={"edit"}/>


                </Col>
                <Col span={15}>
                    {reqType===0 && !isOwnRequest ? 
                        <CreateReply RequestId={id ? id : undefined} />
                        :
                        <EditReply RequestId={id ? id : undefined}/>
                    }
                    
                </Col>
            </Row>
            {/* 
            </Card>
            */}
        </div>
    );
}

export default RouteDetailsPage;