import React, {useEffect, useState} from 'react';


import {Button, Card, Col, Row, Spin} from "antd";
import axios from "axios";
import * as AuthService from "../Services/auth-service";
import authHeader from "../Services/auth-header";
import {useHistory} from "react-router-dom";


const CheckOutPage = () => {


    const history = useHistory();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);


    const tokenValue = urlParams.get('token');
    const PayerIDValue = urlParams.get('PayerID');
    const[Loading, setisLoading] = useState<boolean>(true);


    if (tokenValue != null) {
        console.log("tokenValue: ", tokenValue);
        console.log("PayerIDValue: ", PayerIDValue);
        axios.get(AuthService.DATA_URL+'Settings/check-payement?tokenValue='+tokenValue, {headers: authHeader()})
            .then((response) => {
                console.log(response);
                
                var curr_user=AuthService.getCurrentUser();
                //console.log(curr_user);
                if(curr_user==null){
                    
                }
                else{
                    curr_user.paidTill=response.data.result;
                    localStorage.setItem("user", JSON.stringify(curr_user));
                    console.log("paidTill: ", response.data.result)
                    console.log("curr_user: ", curr_user);
                }
                history.push("/");
                window.location.reload();



            })

    }

    return (
        <div>
            <Row style={{height: "80vh", justifyContent: "center"}}>
                <Col style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                    width: "500px"

                }}>

                    <Card style={{marginTop: "20px", minWidth: "400px"}}>
                        {
                            Loading ? <div>
                                <Spin/> Проверка..
                            </div>
                                :
                                <div>
                                    проверка завершена
                                </div>
                        }

                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default CheckOutPage;
