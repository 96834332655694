import React from 'react';
import {Button, Card, Col, Form, Image, Input, List, message, Row, Select, Space, Tabs} from 'antd'
import axios from "axios";
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import authHeader from "../../../Services/auth-header";
import {useTranslation} from "react-i18next";
import RouteList from "../../myroutes/RouteComponents/list";
import DocList from "../../docs/DocComponents/docList";

export default function CreateTruck() {
    const {t} = useTranslation();


    //const [firstName, setFirstName] = useState('');
    //const [lastName, setLastName] = useState('');
    //const [checkbox, setCheckbox] = useState(false);
    const history= useHistory();
    //const postData = () => {
    //    console.log(firstName);
    //    console.log(lastName);
    //    console.log(checkbox);
    //}


    const user = AuthService.getCurrentUser();
    if(!user){
        history.push("/login");
    }
    const [form] = Form.useForm();


    const BackToList = () =>{
        history.push("/trucks");
    }
    
    const onFinish = (values: any) => {
        console.log(values);
        //console.log("values.rollingStockType: ",values.rollingStockType===undefined? "-1" : values.rollingStockType)
        //const res =  
        axios.get(AuthService.DATA_URL+'Contractor/get-ownCompany', {headers: authHeader()})
            .then((response) => {
            
               
                
                axios.post(AuthService.DATA_URL+'Truck/',
            {

                "regNumber": values.regNumber,
                "vin": values.vin,
                "brand": values.brand,
                "model": values.model,
                "truckType": values.truckType,
                "loadingType": values.loadingType===undefined? -1: values.loadingType,
                "rollingStockType": values.rollingStockType===undefined? -1 : values.rollingStockType,
                "contractorId" : response.data.id

            },{headers: authHeader()})
            .then(() =>{
                message.success(t("Done"));
                //console.log('Success:', values);
                history.push("/trucks");
            });
            
                 
            });
            
                 


    };

    const { TabPane } = Tabs;
    
    
    
    
    return (
        <div>
            <h1>{t("Dodajanje_vozila")}</h1>

            <Tabs defaultActiveKey="0" //activeKey={tabActiveKey}
                //onTabClick={theTabClick}
            >
                <TabPane tab={t("Osnovni_podatki")} key="0">

                <Form
                form={form}
                name="wrap"
                onFinish={onFinish}
                autoComplete="off"
                layout="horizontal"
                labelCol={{ flex: '200px' }}
                labelAlign="right"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
            >


                <Row>
                    <Col span={8}>

                        <Form.Item
                            name="regNumber"
                            label={t("Reg_številka")}

                            rules={[{ required: true, message: t("Obvezno_polje") },{ type: 'string', warningOnly: true }]}
                        >
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item
                            name="brand"
                            label={t("Brand")}
                            rules={[ { type: 'string', warningOnly: true }]}
                        >
                            <Input placeholder="input placeholder" />
                        </Form.Item>

                        <Form.Item
                            name="model"
                            label={t("Model")}
                            rules={[ { type: 'string', warningOnly: true }]}
                        >
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item
                            name="vin"
                            label="VIN"
                            rules={[{ type: 'string', warningOnly: true }]}
                        >
                            <Input placeholder="input placeholder" />
                        </Form.Item>

                    </Col>
                    <Col span={7} offset={1}>

                        <Form.Item
                            name="truckType"
                            label={t("Vrsta_transporta")}
                            rules={[{ required: true, message: t("Obvezno_polje") }]}
                            

                        >
                            <Select
                                defaultValue={form.getFieldsValue().truckType}
                                options={[
                                    { value: 0, label: t('Vlačilec') },
                                    { value: 1, label: t('Priklopnik') },
                                    { value: 2, label: t('Tovornjak') },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item
                            name="rollingStockType"
                            label={t("Vrsta_telesa")}
                            //rules={[ { type: 'number', warningOnly: true }]}
                        >
                            <Select
                                defaultValue={form.getFieldsValue().rollingStockType}
                                options={[
                                    { value: -1, label: "-" },
                                    { value: 0, label: t('Nagibno') },
                                    { value: 1, label: t('Odprto') },
                                    { value: 2, label: t('Hladnik') },
                                ]}
                            />
                        </Form.Item>

                        <Form.Item
                            name="loadingType"
                            label={t("Vrsta_nakladanja")}
                            //rules={[ { type: 'number', warningOnly: true }]}
                        >
                            <Select
                                defaultValue={form.getFieldsValue().loadingType}
                                options={[
                                    { value: -1, label: "-" },
                                    { value: 0, label: t('Nad') },
                                    { value: 1, label: t('Zadaj') },
                                    { value: 2, label: t('Strani') },
                                ]}
                            />
                        </Form.Item>


                    </Col>
                    <Col span={7} offset={1} className={"text-center"}>
                        <Image  className={"roundImage"} id={"truckTypeImage"}
                                width={200}
                                src= "/img/truckdefault3.png" 
                                preview={false}
                        />

                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item>
                            <Space>
                                <Button className={"orangeButton"} htmlType="submit">
                                    {t("Shraniti")}
                                </Button>
                                <Button className={"emptyButton"} onClick={BackToList} >
                                    {t("Ne_shranite_sprememb")}
                                </Button>

                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

                </TabPane>

                <TabPane tab={t("Zgodovina_opravljenih_letov")} key="1" disabled={true}>

                </TabPane>
                <TabPane tab={t("Ključni_kazalniki")} key="2" disabled={true}>
                    <Card>
                        <List
                            size="small"
                            header={<div>Header</div>}
                            footer={<div>Footer</div>}
                            bordered
                            //dataSource={ListData}
                            //renderItem={(item) => <List.Item>{item}</List.Item>}
                        />
                    </Card>
                </TabPane>
                <TabPane tab={t("Dokumenti")} key="3" disabled={true}>

                </TabPane>
            </Tabs>

        </div>
    )
}