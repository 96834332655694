import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Spin} from "antd/lib";

import {useTranslation} from "react-i18next";
import * as AuthService from "../../Services/auth-service";
import {Button, Card, Col, ConfigProvider, Row, Tabs} from "antd";
import Tab1 from "./Components/tab1";
import Tab2 from "./Components/tab2";
import Tab3 from "./Components/tab3";


const LP = () => {

    const history = useHistory()

    const user = AuthService.getCurrentUser();
    if(user) {
        history.push("/Dashboard");
    }
    
    const [tabActiveKey, settabActiveKey]=useState("1");
    
    
    
    

return <div style={{backgroundColor: "#fff", fontFamily: "Helvetica"}}>
    <ConfigProvider
        theme={{
            token: {
                // Seed Token
                colorPrimary: '#1c7884',
                colorLink: '#1c7884',
                colorWarning: "#fc7207",
                fontSize: 18,
            },
        }}>
<Row style={{padding: "20px", backgroundColor: "#fff", lineHeight: "30px"}}>
    <Col span={12} offset={2}>
        <img src={"/img/logo_min.png"} style={{width: "100px"}}/>
        
    </Col>
    <Col span={2}><a href={"#"} style={{fontWeight:"600"}}>О сервисе</a></Col>
    <Col span={2}><a href={"#"} style={{fontWeight:"600"}}>Стоимость</a></Col>
    <Col span={2}><a href={"#"} style={{fontWeight:"600"}}>Контакты</a></Col>
    <Col span={2}> <Button className={"emptyButton"} onClick={()=>{history.push("/login");window.location.reload();}}>Вход</Button></Col>
    <Col span={2}> </Col>
</Row>

<Row style={{backgroundColor: "#ecf3f4", minHeight: "300px", padding: "20px",}}>
                    <Col span={10} offset={2}>
                        <Row>
                            <Col span={24} style={{marginTop: "50px"}}>
                                <img src={"/img/logo_min.png"} style={{width: "200px"}}/></Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <h1 style={{color: "#000", marginBottom: 20}}>
                            <span style={{color: "#000"}}>
                                Приложение для финансового учета<br/> в Вашем предприятии
                            </span>
                                </h1></Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <h3 style={{color: "#000 !important", marginBottom: 40}}>
                            <span style={{color: "#777", fontWeight: 400}}>
                                Держите финансы под контролем. Увеличивайте рентабельность. Сокращайте издержки. Повышайте эффективность
                            </span>
                                </h3></Col>
                        </Row>
                        <Row style={{marginBottom: 40}}>
                            <Col span={24}>
                                <Button className={"lporangeButton"} style={{marginRight: "40px", height: 40, padding: "0px 20px"}}>Начать пользоваться</Button>


                                <Button className={"emptyButton"} style={{height: 40, padding: "0px 20px"}}   >Подробнее про сервис</Button>
                            </Col>
                        </Row>


                    </Col>
                    <Col span={10} style={{margin: "auto"}}>
                        <center>
                            <img src={"/img/lp_screen2.png"} style={{width: "700px", position: "absolute", top: -100, left:-80}}/>
                        </center></Col>
                    <Col span={2}> </Col>
                </Row>
                

        
        <Row style={{marginTop: "180px"}}>
            <Col span={20} offset={2} style={{backgroundColor: "#eeeff2", height: 80, borderRadius: 20}}>
                <Row>
                    <Col span={20} offset={2}>
                        <Row>
                            <Col span={4} style={{lineHeight: "80px", margin: "auto", textAlign: "center"}}>
                                <img alt={" "} src={"/img/sepa_logo.png"} style={{width: 100, marginTop: "27px"}} />
                            </Col>
                            <Col span={4} style={{lineHeight: "80px", margin: "auto", textAlign: "center"}}>
                                <img alt={" "} src={"/img/revolut_logo.png"} style={{width: 80}} />
                            </Col>
                            <Col span={4} style={{lineHeight: "80px", margin: "auto", textAlign: "center"}}>
                                <img alt={" "} src={"/img/paypal_logo.png"} style={{width: 80,  marginTop: "25px"}} />
                            </Col>
                            <Col span={4} style={{lineHeight: "80px", margin: "auto", textAlign: "center"}}>
                                <img alt={" "} src={"/img/amazon_logo.png"} style={{width: 100, marginTop: "30px"}} />
                            </Col>
                            <Col span={4} style={{lineHeight: "80px", margin: "auto", textAlign: "center"}}>
                                <img alt={" "} src={"/img/minimax_logo.png"} style={{width: 100, marginTop: "25px"}} />
                            </Col>
                            
                        </Row>
                    </Col>

                </Row>
            </Col>
        </Row>



        <Row style={{backgroundColor: "#fff", padding: "80px 0px"}}>
            <Col span={20} offset={2}>
                <Row>
                    <Col span={12} offset={6} style={{textAlign: "center"}}>
                        <h1 style={{color: "#007486 !important"}}>Держите руку на пульсе бизнеса: контролируйте и планируйте финансовые потоки</h1>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={12}>
                        <Card style={{marginBottom:"10px", borderColor: "#1c7884", color: "#1c7884"}}
                        onClick={()=>{settabActiveKey("1")}}
                        >
                            <b>Детальная информация по каждой транзакции</b><br/>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna aliqua. Ut enim ad minim
                        </Card>
                        <Card style={{marginBottom:"10px"}}
                              onClick={()=>{settabActiveKey("2")}}
                        >
                            <b>Детальная информация по каждой транзакции</b><br/>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna aliqua. Ut enim ad minim
                        </Card>
                        <Card style={{marginBottom:"10px"}}
                              onClick={()=>{console.log(tabActiveKey); settabActiveKey("3")}}
                        >
                            <b>Детальная информация по каждой транзакции</b><br/>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna aliqua. Ut enim ad minim
                        </Card>


                    </Col>
                    <Col span={12}>

                        <Tabs
                            defaultActiveKey={tabActiveKey}
                            activeKey={tabActiveKey}
                            tabBarStyle={{display: "none"}}
                            items={[
                                {
                                    label: '',
                                    key: '1',
                                    children:  <Tab1/>,
                                },
                                {
                                    label: '',
                                    key: '2',
                                    children:  <Tab2/>,
                                   
                                },
                                {
                                    label: '',
                                    key: '3',
                                    children:  <Tab3/>,
                                },
                            ]}
                        />
                        
                        
                        
                        
                       


                    </Col>
                </Row>   
            </Col>
        </Row>


        <Row style={{backgroundColor: "#ecf3f4", padding: "80px 0px"}}>
            <Col span={20} offset={2}>
                <Row>
                    <Col span={12} offset={6} style={{textAlign: "center"}}>
                        <h1 style={{color: "#007486 !important"}}>Держите руку на пульсе бизнеса: контролируйте и планируйте финансовые потоки</h1>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={12}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                        in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </Col>
                    <Col span={12}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                        in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </Col>
                </Row>
            </Col>
        </Row>


        <Row style={{backgroundColor: "#fff", padding: "80px 0px"}}>
            <Col span={20} offset={2}>
                <Row>
                    <Col span={12} offset={6} style={{textAlign: "center"}}>
                        <h1 style={{color: "#007486 !important"}}>Держите руку на пульсе бизнеса: контролируйте и планируйте финансовые потоки</h1>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={12}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                        in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </Col>
                    <Col span={12}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                        in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </Col>
                </Row>
            </Col>
        </Row>


        <Row style={{backgroundColor: "#ecf3f4", padding: "80px 0px"}}>
            <Col span={20} offset={2}>
                <Row>
                    <Col span={12} offset={6} style={{textAlign: "center"}}>
                        <h1 style={{color: "#007486 !important"}}>Держите руку на пульсе бизнеса: контролируйте и планируйте финансовые потоки</h1>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={12}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                        in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </Col>
                    <Col span={12}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                        in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </Col>
                </Row>
            </Col>
        </Row>

        <Row style={{backgroundColor: "#fff", padding: "80px 0px"}}>
            <Col span={20} offset={2}>
                <Row>
                    <Col span={12} offset={6} style={{textAlign: "center"}}>
                        <h1 style={{color: "#007486 !important"}}>Держите руку на пульсе бизнеса: контролируйте и планируйте финансовые потоки</h1>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={12}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                        in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </Col>
                    <Col span={12}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                        in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </Col>
                </Row>
            </Col>
        </Row>


        <Row style={{backgroundColor: "#ecf3f4", padding: "80px 0px"}}>
            <Col span={20} offset={2}>
                <Row>
                    <Col span={12} offset={6} style={{textAlign: "center"}}>
                        <h1 style={{color: "#007486 !important"}}>Держите руку на пульсе бизнеса: контролируйте и планируйте финансовые потоки</h1>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={12}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                        in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </Col>
                    <Col span={12}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                        in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </Col>
                </Row>
            </Col>
        </Row>

        <Row style={{backgroundColor: "#fff", padding: "80px 0px"}}>
            <Col span={20} offset={2}>
                <Row>
                    <Col span={12} offset={6} style={{textAlign: "center"}}>
                        <h1 style={{color: "#007486 !important"}}>Держите руку на пульсе бизнеса: контролируйте и планируйте финансовые потоки</h1>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={12}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                        in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </Col>
                    <Col span={12}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                        in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </Col>
                </Row>
            </Col>
        </Row>

        <Row style={{backgroundColor: "#027386", color: "#fff", fontSize: "16px", padding: "80px 0px"}}>
            <Col span={20} offset={2}>

                <Row gutter={20}>
                    <Col span={4} style={{fontSize: "14px"}}>
                        INBESI d.o.o.<br/>
                        DŠ SI 98356879<br/><br/>
                        Loke v Tuhinju, 11<br/>
                        1219 Laze
                    </Col>
                    <Col span={12}  style={{fontSize: "14px"}}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                        in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </Col>
                </Row>
            </Col>
        </Row>





    </ConfigProvider>

</div>

}
export default LP;