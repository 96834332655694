import React, {useRef, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import CreateDocPage from "./CreateDoc";
import {Button, Col, Form, Input, List, Row, Select} from "antd";
import DocPositionForm from "./PositionForm";
import {DeleteOutlined} from "@ant-design/icons";
import {DATA_URL} from "../../../Services/auth-service";
import * as RequestService from "../../../Services/Requests";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import DocPosition from "../../../types/DocPosition";
import {useTranslation} from "react-i18next";


interface PositionsListProps {
    DocId?: string;
    ReqId?: string;
    positions? : DocPosition[];
    forceReload?: boolean;
    isLoaded?: () => void;
    deleteFunc?: () => void;
    informParent? : (postions?: DocPosition[]) =>void;
    
}

const PositionsList = ({DocId, ReqId, positions, forceReload, deleteFunc, informParent}: PositionsListProps) => {

    const {t} = useTranslation();


    const history = useHistory();
    const user = AuthService.getCurrentUser();
    if (!user) {
        history.push("/login");
    }

    

    const [posNo, setposNo] = useState(1);
    const [positionsList, setpositionsList] = useState<any[]>([]);
    const [listLoading, setslistLoading] = useState(false);
    
    const handleAddedPosition = (values: any)=>{
        console.log("handleAddedPosition values: ", values );

        console.log("handleAddedPosition positionsList: ", positionsList );
        const index = positionsList.indexOf(positionsList.find(p=>p.id===values.id), 0);
        if(positionsList[index]){
            positionsList[index].naziv=values.naziv;
            positionsList[index].kolicina=values.kolicina;
            positionsList[index].cena=values.cena;
            positionsList[index].davek=values.davek;
            positionsList[index].productId=values.productId;
        }
        if(informParent){
            informParent(positionsList.map((p)=>({
              naziv : p.naziv,
              kolicina : p.kolicina,
              cena : p.cena,
              davek : p.davek,
              productId : p.productId  
            })));
        }
        //console.log("values: ", positionsList );
        ///addPosition();
    }
    
     
    const addPosition = (defaults? : any)=>{
        //console.log("adding defaults:", defaults);

        setslistLoading(true);
        
        var d=new Date();
        var secondsStart = d.getTime();
        setposNo(secondsStart);
        positionsList.push({
            id : defaults? defaults.keyStr : posNo,
            keyStr: defaults? defaults.keyStr : posNo,
            kolicina : defaults ? defaults.kolicina: 0,
            davek : defaults ? defaults.davek : 0,
            cena: defaults ? defaults.cena : 0,
            naziv: defaults ? defaults.naziv : "",
            title : "sdfsdf",
            productId : defaults ? defaults.productId : "",
            description: 
                <div>
                    <DocPositionForm keyStr={posNo} naziv={defaults? defaults.naziv : ""} 
                                     kolicina={defaults ? defaults.kolicina : 0} 
                                     davek={defaults ? defaults.davek : 0} 
                                     cena={defaults ? defaults.cena : 0}  
                                     productId={defaults ? defaults.productId : undefined}
                                     forceReload={true} deleteFunc={deletePos} addFunc={handleAddedPosition} />
                </div>
        });
        setpositionsList(positionsList);
        //console.log(positionsList);
        //setposNo(posNo+1);
        //console.log("after adding posId:", posNo);
        setslistLoading(false);
    }
    
    
    const renderItem = (item: any) =>{

        return(
            <List.Item key={"sdfsdf" + item.id.toString()}
                       style={{borderBottomWidth: 0, padding: "0px"}}
            >
                <List.Item.Meta
                    //bordered={false}
                    description={
                        <div>
                            <DocPositionForm keyStr={item.id} 
                                             naziv={item.naziv} 
                                             productId={item.productId}
                                             kolicina={item.kolicina} 
                                             davek={item.davek} 
                                             cena={item.cena}  
                                             forceReload={true}
                                             deleteFunc={deletePos} 
                                             addFunc={handleAddedPosition} />
                        </div>

                    }
                />
            </List.Item>

        )
    }


    const deletePos =(poskey : number) =>{

        var d=new Date();
        var secondsStart = d.getTime();
        setposNo(secondsStart);
      //  console.log("delete poskey:",poskey,  " posId: ", posNo);
        
        setslistLoading(true);
        
        const index = positionsList.indexOf(positionsList.find(p=>p.id===poskey), 0);
        //console.log("position to delete: ", positionsList[index]);
        
        if (index > -1) {
            positionsList.splice(index, 1);
        }
        //console.log("delete: ", poskey, "posId", posNo);
        setpositionsList(positionsList);
        //console.log(positionsList);
        var d=new Date();
        var secondsStart2 = d.getTime();
        setposNo(secondsStart2);

        if(informParent){
            informParent(positionsList.map((p)=>({
                naziv : p.naziv,
                kolicina : p.kolicina,
                cena : p.cena,
                davek : p.davek,
                productId : p.productId
            })));
        }

        setslistLoading(false);
    }


    const index = positionsList.indexOf(positionsList.find(p=>p.keyStr===ReqId), 0);
    
    useEffect(()=>{

        if(positions) {
            setpositionsList(positions);
        }
        
        if(ReqId && index <0) {
            /*
            console.log("ReqId: ", ReqId);
            positionsList.length=0;

            axios.get(AuthService.DATA_URL + `Request/` + ReqId, {headers: authHeader()})
                .then((response) => {
                  //  console.log("axios response.data: ", response.data)
                    addPosition({
                        keyStr: ReqId,
                        naziv: t("Prevoz_blaga_po_naročilu")+" " + response.data.requestNumber,
                        kolicina: 1,
                        davek: 0,
                        cena: response.data.price
                    });
                    if(informParent){
                        informParent(positionsList.map((p)=>({
                            naziv : p.naziv,
                            kolicina : p.kolicina,
                            cena : p.cena,
                            davek : p.davek
                        })));
                    }

                })


            console.log("positionsList: ", positionsList);
            
             */
        }
        else{
            if(positionsList.length===0){
                addPosition();
            }
        }

    }, [ReqId])
        
        
        
    

    //console.log("rendering posId: ", posNo);
    
    
    return(
        <div style={{width: "100%"}}>
            <Row  gutter={8}>
                <Col span={11} style={{marginBottom: "4px", paddingLeft: "8px" }}>{t("Naziv_storitva_blaga")}
                </Col>
                <Col span={3}>{t("Količina")}
                </Col>
                <Col span={3}>{t("Davek")}
                </Col>
                <Col span={4}>{t("Cena")}
                </Col>
            </Row>

            {listLoading ? "" :
            
                <div>
                    <List
                        style={{width: "100%", borderBottomWidth: "0"}}
                        //loading={listLoading}
                        bordered={false}
                        locale={{ emptyText: <span> </span> }}
                        dataSource={positionsList}
                        renderItem={(item: any, index) => (
                            renderItem(item)
                        )}
                    />
                    
                </div>
            
            }
            {/*
                positionsList.length>0 ?"" :
                <Button onClick={addPosition}>Dodajte vrstico</Button>
                */
            }
            <Button onClick={()=>addPosition(undefined)}>{t("Dodajte_vrstico")}</Button>




        </div>
        
    )

}

export default PositionsList;