import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import DopustList from "./DopustList";
import {Col, DatePicker, Form, Row, Select, Space, Input} from "antd";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import {DeleteOutlined} from "@ant-design/icons";
import RoutePoint from "../../../types/transporter-types";

interface DopustPositionProps {
    keyStr?: string;
    id? : string;
    fromDate? : string;
    toDate? : string;
    reason? : number;
    comment? : string;
    forceReload?: boolean;
    isLoaded?: () => void;
    deleteFunc?: (key: string) => void;
    addFunc?: (values: any) => void;

}

interface DopustPosition {
    keyStr?: string;
    id? : string;
    fromDate? : string;
    toDate? : string;
    reason? : number;
    comment? : string;
}


const dateToPoints =(today : Date) =>{
    const yyyy = today.getFullYear();
    let m = today.getMonth() + 1; // Months start at 0!
    let d = today.getDate();

    let mm = m.toString();
    let dd = d.toString();
    if (d < 10) dd = '0' + dd;
    if (m < 10) mm = '0' + mm;

    return dd + '.' + mm + '.' + yyyy;
}



const DopustPositionForm = ({keyStr, id, fromDate, toDate, reason, comment,  forceReload,  isLoaded,  deleteFunc, addFunc}: DopustPositionProps) => {

    
    
    console.log("reason",  reason)
    const {t} = useTranslation();

    const history = useHistory();
    const user = AuthService.getCurrentUser();
    if (!user) {
        history.push("/login");
        window.location.reload();

    }

    const [formPos] = Form.useForm();
    const { TextArea } = Input;
    const { RangePicker } = DatePicker;

    const [_dopust, set_dopust] = useState<DopustPosition>();
    
    const[initReason, setInitReason] = useState(0);

    const [listLoading, setslistLoading] = useState(false);



    useEffect(()=>{
        setslistLoading(true)
        set_dopust({
            keyStr : keyStr? keyStr : "",
            id : id,
            fromDate: fromDate ? fromDate : "",
            toDate: toDate,
            reason: reason,
            comment: comment});
        
        setInitReason(reason ? reason : 0);
        //console.log("sdfsdfsdfsfdfsdf: ", reason, " : ", initReason, ".")
        //formPos.setFieldsValue([{"reason" : reason}])
        setslistLoading(false)

    }, [])
    
    

    const deletePos=(key:string)=>{
        if(deleteFunc){
            deleteFunc(key);
        }
    }

    const handleDopustTypeChange = (value : any) =>{
        console.log("value: ", value);

        set_dopust((prev: any) => ({
            ...prev,
            reason : value,
        }));
        if(addFunc  && _dopust){
            addFunc({
                key : keyStr,
                id:id,
                fromDate: _dopust.fromDate,
                toDate: _dopust.toDate,
                reason: value,
                comment: _dopust.comment,
            });
        }
        console.log("_dopust: ", _dopust);
    }


    const handleDopustCommentChange = (value : any) =>{
        console.log("value: ", value);

        set_dopust((prev: any) => ({
            ...prev,
            comment : value.target.value,
        }));
        if(addFunc  && _dopust){
            addFunc({
                key : keyStr,
                id:id,
                fromDate: _dopust.fromDate,
                toDate: _dopust.toDate,
                reason: _dopust.reason,
                comment: value.target.value,
            });
        }
        console.log("_dopust: ", _dopust);

    }

    const handleDopustDatesChange = (value : any) =>{
        console.log("handleDopustDatesChange value: ", value);
        set_dopust((prev: any) => ({
            ...prev,
            fromDate : dateToPoints(new Date(value[0])),
            toDate : dateToPoints(new Date(value[1])),
        }));
        if(addFunc  && _dopust){
            addFunc({
                key : keyStr,
                id:id,
                fromDate: dateToPoints(new Date(value[0])),
                toDate: dateToPoints(new Date(value[1])),
                reason: _dopust.reason,
                comment: _dopust.comment,
            });
        }
        

    }

    if(listLoading){
        return (<div>...</div>)
    }
    console.log("_dopust: ", _dopust);
    return <div>

        <Form form={formPos} 
              name={keyStr? keyStr.toString() : "009"}
              id={keyStr? keyStr.toString() : "009"}
              layout={"inline"}
              initialValues={
                  {
                      //["thedate"]: PointDate ? dayjs(PointDate,  "DD.MM.YYYY") : undefined, //dayjs(dateToPoints(new Date(PointDate)), "DD.MM.YYYY") : undefined,
                      //["thetime"]: PointTime ? dayjs(PointTime,  "HH:mm") : undefined, //dayjs(timeToPoints(new Date(PointTime)), "HH:mm") : undefined,
                      ["comment"]: comment ? comment : '',
                      ["reason"] : reason ? reason : 0
                  }
              }
        >

            <Row style={{width: '100%'}}>
                <Col>
                    <Form.Item name="reason">
                        <Select placeholder={t('Reason')}
                            //defaultValue={form.getFieldsValue().truckType}
                            onChange={handleDopustTypeChange}

                            style={{ width: 120 }}
                            options={[
                                { value: 0, label: t('Dopust') },
                                { value: 1, label: t('Bolniški_dopust') },
                            ]}
                        />
                    </Form.Item>

                </Col>
                
        <Col span={14}>
            <Form.Item>
                <Space.Compact>
                    <Form.Item name="fromDate" style={{ marginInlineEnd:"0px"}}>
                        <RangePicker
                            defaultValue={[(fromDate ? dayjs(fromDate ? fromDate : undefined,  "DD.MM.YYYY") : null), toDate ? dayjs(toDate ? toDate : undefined,  "DD.MM.YYYY") : null]}
                            style={{width: "100%"}} format={"DD.MM.YYYY"} placeholder={[t("Od_datum"),t("Do_datum")]}
                                    onChange={handleDopustDatesChange}/>
                    </Form.Item>
                </Space.Compact>
            </Form.Item>
        </Col>


                <Col span={1} style={{paddingTop: "4px", marginLeft:"30px"}}>
                    <a onClick={()=>deletePos(keyStr ? keyStr : "0")} ><DeleteOutlined /></a>

                </Col>

            </Row>
            <Row style={{width: "85%", marginTop: "10px"}}>
                <Form.Item name="comment" style={{width: "100%"}}>
                <TextArea rows={2} style={{resize: "none"}} onChange={handleDopustCommentChange}>{comment}</TextArea>
                </Form.Item>
                </Row>
        
        
        
        
        </Form>


    </div>
}

export default DopustPositionForm;