import React, {useEffect, useState} from 'react';
import {Spin, Table, Tooltip} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {Link, useHistory} from "react-router-dom";
import axios, {Axios} from "axios";
import * as AuthService from "../../../Services/auth-service";
import authHeader from "../../../Services/auth-header";
import CompanySettingsPage from "../../settings/Components/CompanySettings";
import {useTranslation} from "react-i18next";
import Deal, {mapDealStatusColor, mapDealStatusName} from "../../../types/Deal";
import DealsListFilter from "../../../types/Deal";
import DealsFilter from "../../../types/DealsListFilter";
import {ServerdateToPoints} from "../../../utils/helpers";
import Product from "../../../types/Product";




interface dealsListProps{
    filter : DealsFilter
}

const ProductList= ({ filter} : dealsListProps) => {

    const {t} = useTranslation();

    const history = useHistory();
    const user = AuthService.getCurrentUser();
    if (!user) {
        AuthService.logout();
        history.push("/login");
        window.location.reload();

    }

    const [dealsList, setDealsList] = useState<Product[]|null>(null);

    const[pageloading, setpageloading] = useState<boolean>(false)

    //const [searchText, setSearchText] = useState("")






    useEffect(()=>{
        //console.log("list comp:", filter)
        if(dealsList===null){
            setpageloading(true)
            axios.get(AuthService.DATA_URL+'Products/get-products', {headers: authHeader()})
                .then((response)=>{
                    setDealsList(response.data);
                    //console.log(response.data)
                    setpageloading(false)
                })
                .catch(function (error) {
                    if(error.response.status===401){
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                })
        }

    });

    const columns: ColumnsType<Product> = [
        {
            title: t('title'),
            dataIndex: 'title',
            filteredValue :[filter? filter.searchText? filter.searchText : "" : ""],
            onFilter: (value, record) => {
                //console.log("value: ", value)
                return (
                    String(record.title).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.title).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.title).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.title).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.title).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.title).toLowerCase().includes(value.toString().toLowerCase())

                )
            },
            render: (text, record) => {
                return (
                    <>
                        {record.title}
                    </>

                )}
        },
        {
            title: t('Unit'),
            dataIndex: 'unit',
            render: (text, record) => {
                return (
                    <div>
                        {text} 
                    </div>
                )}
        },
        {
            title: t('CurrentStock'),
            dataIndex: 'unit',
            render: (text, record) => {
                return (
                    <div>
                        - 
                    </div>
                )}
        },
        {
            title: t('Cost'),
            dataIndex: 'purchasePrice',
            width: 150,
            align: 'right',
            sorter: (a:any, b:any) => a.purchasePrice - b.purchasePrice,
            render:(text, record) => <div>
                {
                    record.purchasePrice?
                        record.purchasePrice?.toFixed(2).toString()
                            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")+"€"
                        :"-"
                }
            </div>
        },

        {
            title: t('Price'),
            dataIndex: 'priceForClient',
            width: 150,
            align: 'right',
            sorter: (a:any, b:any) => a.priceForClient - b.priceForClient,
            render:(text, record) => <div>
                {
                    record.priceForClient? 
                    record.priceForClient?.toFixed(2).toString()
                        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")+"€"
                    :"-"
                }
            </div>
        },
        {
            title: t('Currency'),
            dataIndex: 'currency',
            filteredValue: [filter.requestStatus],
            onFilter: (value, record) => {
                //console.log(record.requestStatus, " : ",filter.requestStatus," : ", filter.hidefinished)
                return (
                    (record.title?.toString() === value.toString() || value.toString() === "100")
                    &&
                    ((!filter.hidefinished && record.title?.toString()!=="50") || (filter.hidefinished ))
                )},

            ellipsis: {
                showTitle: false,
            },
            render:(text, record) => 

                    <span style={{color: "#bbb"}}>
                        {record.currency}
                    </span>

        },

    ]



    return(
        <>
            <Spin spinning={pageloading}>

                <Table
                    locale={{
                        triggerDesc: t('Kliknite_za_razvrščanje_padajoče'),
                        triggerAsc: t('Kliknite_za_razvrščanje_naraščajoče'),
                        cancelSort: t('Prekliči_razvrščanje')
                    }}
                    columns={columns}
                    dataSource={dealsList? dealsList : undefined}
                    loading={pageloading}
                    pagination={false}
                    scroll={{ y: 'calc(65vh)' }}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => {history.push("/products/"+record.id)}, // click row
                        };
                    }}
                />
            </Spin>

        </>


    )
}

export default ProductList