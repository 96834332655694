import React from 'react';
import NewTruck from "./TruckComponents/Create";

const newTruckPage = () => {


    
    return (

        <div>
    <NewTruck/>
        </div>);
}

export default newTruckPage;