import React, {useEffect, useState} from 'react';
import {Spin, Table, Tooltip} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {Link, useHistory} from "react-router-dom";
import axios, {Axios} from "axios";
import * as AuthService from "../../../Services/auth-service";
import authHeader from "../../../Services/auth-header";
import CompanySettingsPage from "../../settings/Components/CompanySettings";
import {useTranslation} from "react-i18next";
import Deal, {mapDealStatusColor, mapDealStatusName} from "../../../types/Deal";
import DealsListFilter from "../../../types/Deal";
import DealsFilter from "../../../types/DealsListFilter";
import {ServerdateToPoints} from "../../../utils/helpers";


interface DealsListtProps{
    ContractorId?: string;
    DriverId? : string;
    RequestId?: string;
    TruckId? : string;
    ExpiryDate? : string;
    reload? : boolean;
    setHeight? : number|null;

}

interface dealsListProps{
 filter : DealsFilter 
}

const DealsList= ({ filter} : dealsListProps) => {

    const {t} = useTranslation();

    const history = useHistory();
    const user = AuthService.getCurrentUser();
    if (!user) {
        AuthService.logout();
        history.push("/login");
        window.location.reload();

    }
    
    const [dealsList, setDealsList] = useState<Deal[]|null>(null);
    
    const[pageloading, setpageloading] = useState<boolean>(false)
    
    //const [searchText, setSearchText] = useState("")






    useEffect(()=>{
        //console.log("list comp:", filter)
        if(dealsList===null){
            setpageloading(true)
            axios.get(AuthService.DATA_URL+'Deals/get-deals', {headers: authHeader()})
                .then((response)=>{
                    setDealsList(response.data);
                    //console.log(response.data)
                    setpageloading(false)
                })
                .catch(function (error) {
                    if(error.response.status===401){
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                })
        }
        
    });

    const columns: ColumnsType<Deal> = [
        {
            title: t('Date'),
            dataIndex: 'dealDate',
            render: (text, record) => {
                return (
                    <>
                        {record.dealDate? ServerdateToPoints(record.dealDate) : ""}
                    </>
                    
                )}
        },
        {
            title: t('Številka'),
            dataIndex: 'dealNumber',
            width: 150,
            filteredValue :[filter? filter.searchText? filter.searchText : "" : ""],
            onFilter: (value, record) => {
                //console.log("value: ", value)
                return (
                    String(record.dealNumber).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.client?.title).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.costPrice).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.employee?.name).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.employee?.surname).toLowerCase().includes(value.toString().toLowerCase())||   
                    String(record.dealDescription).toLowerCase().includes(value.toString().toLowerCase())   

                )
            },
            render: (text, record) => {
                return (
                    <div>
                        {text} <br/>
                        <span className={"dot"}
                              style={{backgroundColor: mapDealStatusColor(record.requestStatus), verticalAlign: "middle"}}> 
                        </span>
                        <span className={"greyLabel"}>{t(mapDealStatusName(record.requestStatus))}</span>

                    </div>
                )}
        },
        {
            title: t('Opis'),
            dataIndex: 'dealDescription',
            width: 310,
            filteredValue: [filter.requestStatus],
            onFilter: (value, record) => {
                //console.log(record.requestStatus, " : ",filter.requestStatus," : ", filter.hidefinished)
                return (
                    (record.requestStatus.toString() === value.toString() || value.toString() === "100")
                    &&
                    ((!filter.hidefinished && record.requestStatus.toString()!=="50") || (filter.hidefinished ))
                )},

                ellipsis: {
                showTitle: false,
            },
            render:(text, record) => <div>
                
                <Tooltip placement="topLeft" title={record.dealDescription}>
                    {record.title}<br/>
                    <span style={{color: "#bbb"}}>
                        {record.dealDescription}
                    </span>
                </Tooltip>
            </div>
        },
        {
            title: t('Cena'),
            dataIndex: 'priceForClient',
            width: 150,
            align: 'right',
            filteredValue: [filter.selectedDates ? filter.selectedDates : "" ],
            onFilter: (value, record) => {
               // console.log(filter.selectedDates);
                var fs=value.toString().split("-");
                var f=parseInt(fs[0]);
                var t=parseInt(fs[1]);
                var nd= record.dealDate? record.dealDate : "";//?.substring(3,5)+"/"+record.createdAt?.substring(0,2)+"/"+record.createdAt?.substring(6,10)
                
                var check = Date.parse(nd);
                //console.log(f, " : ", check, " : ", t)
                //console.log("f: ", f, "check: ", check, " t: ", t );

                return ((f<=check && check<=t )|| filter.selectedDates==="")
            },

            sorter: (a:any, b:any) => a.priceForClient - b.priceForClient,
            render:(text, record) => <div>
                {
                    record.priceForClient?.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")
                }€
            </div>
        },
        {
            title: t('Stranka'),
            dataIndex: 'Client',
            width: 300,
            filteredValue :[filter? filter.counterPartId? filter.counterPartId : "" : ""],
            onFilter: (value, record) => {
                //console.log("value: ", value)
                return (
                    (record.clientId===value|| record.subContractorId===value)||(filter.counterPartId===null||filter.counterPartId===undefined)
                )},
            render: (text, record) => {
                return (
                    <div>
                        {record.client?.title} </div>
                )}
        },
        {
            title: t('Employee'),
            dataIndex: 'Employee',
            render: (text, record) => {
                return (
                    <div>
                        {record.employeeId? record.employee?.name+" "+record.employee?.surname : ""} 
                    </div>
                )}
        },

    ]



    return(
        <>
            <Spin spinning={pageloading}>

            <Table
                locale={{
                    triggerDesc: t('Kliknite_za_razvrščanje_padajoče'),
                    triggerAsc: t('Kliknite_za_razvrščanje_naraščajoče'),
                    cancelSort: t('Prekliči_razvrščanje')
                }}
                columns={columns}
                dataSource={dealsList? dealsList : undefined}
                loading={pageloading}
                pagination={false}
                scroll={{ y: 'calc(65vh)' }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {history.push("/deals/"+record.id)}, // click row
                    };
                }}
            />
            </Spin>
        
        </>
        
        
    )
}

export default DealsList