import React, { useState, useEffect } from "react";
import {Card, Col, Divider, Flex, Progress, Row, Space} from 'antd';

import {
    useHistory
} from "react-router-dom";
import {DATA_URL, refreshUser} from "../../Services/auth-service";
import authHeader from "../../Services/auth-header";
import fetchauthHeader from "../../Services/fetch-auth-header";
import {CheckCircleOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";


const useProgress = (maxTimeInSeconds = 2) => {
    const [elapsedTime, setElapsedTime] = useState(0);
    const [progress, setProgress] = useState(0);
    const history = useHistory();

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (progress < 1) {
                setElapsedTime(t => t + 1);
            }
        }, 50);

        return () => {
            clearInterval(intervalId);

        };
    }, []);

    useEffect(() => {
        setProgress((elapsedTime / maxTimeInSeconds));
    }, [elapsedTime]);

    return progress;
};

const Return = () => {

    const {t} = useTranslation();
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');

    const history = useHistory();
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');

        fetch(DATA_URL+`ServicePayments/session-status?session_id=${sessionId}`,
            {
                method: "GET",
                headers: fetchauthHeader()
            })
            .then((res) => res.json())
            .then((data) => {
                console.log("stripe response: ", data)
                setStatus(data.status);
                setCustomerEmail(data.customer_email);
            });
    }, []);

    if (status === 'open') {
        history.push("/checkout")
        //return (
        //    <></>
        //<Navigate to="/checkout" />
        //)
    }



    const progress = useProgress();
    if (status === 'complete') {
        refreshUser();

        setTimeout(() => {
            history.push("/dashboard");
        }, 7000);








        return (
            <section id="success">
                <Card title={<span style={{color: "#0c5e9b", fontWeight: "400"}}>{t("Thank_you_for_your_purchase")}</span>} style={{width: "600px", margin: "0 auto"}}>
                    <Row>
                        <Col>
                            <Space.Compact>
                                <CheckCircleOutlined style={{color: "#52c41a", fontSize: "64px"}} />
                                <div>
                                    <Row gutter={20}>
                                        <Col span={24}>
                                            <h3 style={{marginLeft: "20px", color: "#0c5e9b"}}>{t("The_purchase_is_successful")}
                                                <br/>
                                                <span style={{fontSize:"14px", color: "#000", fontWeight: "400"}}>
                                            {t("A_confirmation_email_will_be_sent_to")} {customerEmail}.
                                        </span>

                                            </h3>
                                        </Col>
                                    </Row>

                                </div>

                            </Space.Compact>
                        </Col>

                    </Row>
                    <Row style={{margin: "20px 0px"}}>

                        <Col>
                            {t("We_will_redirect_you_to_your")} <a href={"/groups/0"} style={{color: "#0c5e9b",
                            borderBottom: "1px solid #0c5e9b", paddingBottom: "2px"}}>{t("group_page")}</a>.
                        </Col>
                    </Row>


                    <Progress percent={progress} trailColor={"#52c41a"} strokeColor={"#f5f5f5"} showInfo={false} />
                    <Row>
                        <Col span={24} style={{textAlign: "center", color: "#777", marginTop: "20px"}}>

                            {t("If_you_have_any_questions_please_contact_our_Support_Team")}
                        </Col>
                    </Row>


                </Card>

            </section>
        )

    }

    return (<>


    </>);
}


export default Return