import React, { useEffect, useState }  from 'react';
import {Button, Form, Input, message, Space, Col, Row, Image, Select, Tabs, List, Modal} from 'antd';

//import { useNavigate } from 'react-router-dom';

import {Card} from "antd";



import axios from 'axios';
import {useHistory, useParams} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";
import authHeader from "../../Services/auth-header";
import UploadDoc from "../docs/DocComponents/UploadDoc";
import DocList from "../docs/DocComponents/docList";
import RouteList from "../myroutes/RouteComponents/list";
import {useTranslation} from "react-i18next";
//const history= useHistory();
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
//const paramValue = urlParams.get('id');
//console.log(paramValue);

type truck ={
    id: string;
    regNumber: string;
    vin : string;
    model : string;
    brand: string;
    truckType: number;
    loadingType: number;
    rollingStockType: number;
};

type GetTrucksResponse = {
    data: truck;
};





interface Params {
    id: string;
}
const TrucksDetailsPage = () => {
    const {id} = useParams<Params>();
    const {t} = useTranslation();



    async function getTrucks() {



        try {
            // 👇️ const data: GetUsersResponse
            const { data } = await axios.get<GetTrucksResponse>(
                AuthService.DATA_URL+'Truck/'+id,
                {headers: authHeader()}
            );
            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log('error message: ', error.message);
                return error.message;
            } else {
                console.log('unexpected error: ', error);
                return 'An unexpected error occurred';
            }
        }
    }
    
    
    //const [APIData, setAPIData] = useState([]);
    const history= useHistory();


    /*
    useEffect(() => {
        axios.get(`https://60fbca4591156a0017b4c8a7.mockapi.io/fakeData`)
            .then((response) => {
                //console.log("inside")
      //          setAPIData(response.data);
            })
    }, []);


     */

    //const [imagesrc, Setimagecrs] = useState<string>();


    const [form] = Form.useForm();


    
    //Setimagecrs("/img/truckdefault3.png");
    function handleruckTypeChange() {
        //document.getElementById('truckTypeImage').attr('src',"/img/priklopdefault3.png")
        
        
        if(form.getFieldsValue().truckType===0){
            //Setimagecrs("/img/truckdefault3.png");
            //form.setFieldValue("truckType",0);
        }
        if(form.getFieldsValue().truckType===1){
            //Setimagecrs("/img/priklopdefault3.png");
            //form.setFieldValue("truckType",1);
        } //priklopdefault3.png tovordefault3.png
        if(form.getFieldsValue().truckType===2){
            //Setimagecrs("/img/tovordefault3.png");
            //form.setFieldValue("truckType",1);
        } //
        
        //console.log(`Selected: `+ form.getFieldsValue().truckType + imagesrc);
    }
    
    
    let mytruck;
    getTrucks().then( time=> {
        mytruck = JSON.parse(JSON.stringify(time, null, 4));

        console.log("mytruck: ", mytruck)
        form.setFieldsValue({
            regNumber: mytruck.regNumber,
            brand: mytruck.brand,
            model : mytruck.model,
            truckType : mytruck.truckType,
            rollingStockType : mytruck.rollingStockType,
            loadingType: mytruck.loadingType,
            vin : mytruck.vin,
        });
        
        //theTruck.push ({
        //    id: mytruck.id,
        //    regNumber: mytruck.regNumber, //`LJ NB ${(i+2)%10}${(i+13)% 10}${(i+28)%10}`,
        //    brand :  mytruck.brand ,//`SCANIA`, //`SCANIA ${i}`,
        //    model:  mytruck.model,
        //    vin : mytruck.vin,
        //    truckType: 0, // (time.truckType==0 ? 'Vozilo' : 'Priklopnik'),// `${tr.truckType}`
        //    rollingStockType : 0, //(time.rollingStockType==0 ? 'Nagibno' : 'Hladnik'),
        //    loadingType : 0,// (time.loadingType==0 ? 'Nad' : 'Zaday'),
        //});
    });

    //console.log(form.getFieldsValue());
    //console.log(form.getFieldsValue().brand);


    const onFinish = (values: any) => {
        //const res =  
        axios.get(AuthService.DATA_URL+'Contractor/get-ownCompany', {headers: authHeader()})
            .then((response) => {
                //console.log("response.data.id: ", response);
                axios.put(AuthService.DATA_URL+'Truck/'+id, 
            {
                "id": id, 
                "regNumber": values.regNumber, 
                "vin": values.vin, 
                "brand": values.brand, 
                "model": values.model, 
                "truckType": values.truckType, 
                "loadingType": values.loadingType, 
                "rollingStockType": values.rollingStockType,
                "contractorId" : response.data.id
        
            },
        {headers: authHeader()})
            .then(() =>{
                message.success('Submit success!');
                console.log('Success:', values)
                history.push("/trucks");
                //window.location.href="/trucks";
                }
            );
            });


       

        //history.push('/trucks');
    };

    const onFinishFailed = () => {
        message.error('Submit failed!');
    };


    const { TabPane } = Tabs;
/*
    const ListData = [
        'Racing car sprays burning fuel into crowd.',
        'Japanese princess to wed commoner.',
        'Australian walks 100km after outback crash.',
        'Man charged over missing wedding girl.',
       
    ];
*/
    const BackToList = () =>{
        history.push("/trucks");
    }


/*
    const[tabActiveKey, settabActiveKey] = useState<string>("1");
    const theTabClick =(d: string, e:any)=>{
        console.log(d);
        if(d==="1"){
            settabActiveKey("3")
        }
        else{
            settabActiveKey(d)
            
        }
    }
    
 */
    
    
    //// Documents Modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleModalCancel = () => {
        setIsModalOpen(false);
    };
    const showModal1 = () => {
        setIsModalOpen(true);

    };





    return (

        <div>
            <h1>{t("Podatki_o_vozilu")}</h1>

            <Tabs defaultActiveKey="0" //activeKey={tabActiveKey}
                //onTabClick={theTabClick}
            >
                <TabPane tab={t("Osnovni_podatki")} key="0">
            <Form
                form={form}
                name="wrap"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="horizontal"
                labelCol={{ flex: '200px' }}
                labelAlign="right"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
                //style={{ maxWidth: 600 }}
            >


                <Row>
                    <Col span={8}>

                        <Form.Item
                            name="regNumber"
                            label={t("Reg_številka")}
                            rules={[{ required: true },{ type: 'string', warningOnly: true }]}
                        >
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item
                            name="brand"
                            label={t("Znamka")}
                            rules={[ { type: 'string', warningOnly: true }]}
                        >
                            <Input placeholder="input placeholder" />
                        </Form.Item>

                        <Form.Item
                            name="model"
                            label={t("Model")}
                            rules={[ { type: 'string', warningOnly: true }]}
                        >
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item
                            name="vin"
                            label="VIN"
                            rules={[ { type: 'string', warningOnly: true }]}
                        >
                            <Input placeholder="input placeholder" />
                        </Form.Item>

                    </Col>
                    <Col span={8} offset={1}>

                        <Form.Item
                            name="truckType"
                            label={t("Vrsta_transporta")}
                            rules={[{ required: true }]}
                        >
                            <Select
                                //defaultValue={form.getFieldsValue().truckType}
                                onChange={handleruckTypeChange}
   
                                //style={{ width: 120 }}
                                options={[
                                    { value: 0, label: t('Vlačilec') },
                                    { value: 1, label: t('Priklopnik') },
                                    { value: 2, label: t('Tovornjak') },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item
                            name="rollingStockType"
                            label={t("Vrsta_telesa")}
                            rules={[ { type: 'number', warningOnly: true }]}
                        >
                            <Select
                               // defaultValue={form.getFieldsValue().rollingStockType}

                                //style={{ width: 120 }}
                                options={[
                                    { value: -1, label: "-" },
                                    { value: 0, label: t('Odprto') },
                                    { value: 1, label: t('Nagibno') },
                                    { value: 2, label: t('Hladnik') },
                                ]}
                            />
                        </Form.Item>

                        <Form.Item
                            name="loadingType"
                            label={t("Vrsta_nakladanja")}
                            rules={[ { type: 'number', warningOnly: true }]}
                        >
                            <Select
                                //defaultValue={form.getFieldsValue().loadingType}

                                //style={{ width: 120 }}
                                options={[
                                    { value: -1, label: "-" },
                                    { value: 0, label: t('Nad') },
                                    { value: 1, label: t('Zadaj') },
                                    { value: 2, label: t('Strani') },
                                ]}
                            />
                        </Form.Item>

                        
                    </Col>
                    <Col span={6} offset={1} className={"text-center"}>
                        <Image  className={"roundImage"} id={"truckTypeImage"}
                            width={200} 
                            src= "/img/truckdefault3.png" //priklopdefault3.png tovordefault3.png
                            //preview={{
                            //    src: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                            //}}
                            preview={false}
                        />
                        
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item>
                            <Space>
                                <Button className={"orangeButton"} htmlType="submit">
                                    {t("Shraniti")}
                                </Button>
                                <Button className={"emptyButton"} onClick={BackToList}>
                                    {t("Ne_shranite_sprememb")}
                                </Button>

                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
                
                
               


        
                

            </Form>

                </TabPane>

             <TabPane tab={t("Zgodovina_opravljenih_letov")} key="1">
                 <Card>
                     <RouteList TruckId={id ? id : undefined} />
                 </Card>
             </TabPane>
             <TabPane tab={t("Ključni_kazalniki")} key="2">
                 <Card>
                     <List
                         size="small"
                         header={<div>Header</div>}
                         footer={<div>Footer</div>}
                         bordered
                         //dataSource={ListData}
                         //renderItem={(item) => <List.Item>{item}</List.Item>}
                     />
                 </Card>
             </TabPane>
             <TabPane tab={t("Dokumenti")} key="3">
                 <Card>
        <Button onClick={showModal1} style={{
            border: "1px dashed #d9d9d9",
            borderTopLeftRadius: "5px",
            borderBottomLeftRadius: "5px",
            //borderRight: "0px",
            height: "40px",
            //width: "100%",
            fontSize: "14px",
            color: "#1449AE",
            marginBottom: "8px",
            padding: "8px 5px"}}>
            + {t("Prenesite_dokument")}
        </Button>
                     <DocList TruckId={  id ? id.toString() : undefined} />

                 </Card>
             </TabPane>
         </Tabs>


            <Modal open={isModalOpen}
                //onOk={handleModalOk}
                   title={t("Prenesite_dokument")}
                   centered
                   width={580}
                   onCancel={handleModalCancel}
                   //footer={[]}
            >

                <UploadDoc objectId={id} objectMode={2}/>
            </Modal>

        </div>
    );
};
export default TrucksDetailsPage;