import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, Input, List, message, Modal, Row, Space, Tabs, Switch, Tooltip, Dropdown} from "antd";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import {Link, useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import DocList from "../../docs/DocComponents/docList";
import UploadDoc from "../../docs/DocComponents/UploadDoc";
import RouteList from "../../myroutes/RouteComponents/list";
import {useTranslation} from "react-i18next";

import { ExclamationCircleFilled } from '@ant-design/icons';
import DealsList from "../../Deals/Components/DealsList";
import DealsFilter from "../../../types/DealsListFilter";
import TransactionsList from "../../transactions/TransactionsComponents/TransactionsList";
import TransactionsFilter from "../../../types/TransactionsListFilter";


interface EditContractorProps{
    paramValue?: string;
    afterCreate?: (data: any) => string;
}
export default function EditContractorComp({ paramValue, afterCreate} : EditContractorProps) {

    const history= useHistory();

    const {t} = useTranslation();



    const user = AuthService.getCurrentUser();
    if(!user){
        history.push("/login");
    }


    interface DataType {
        id : React.Key;
        title: string;
        davcnaStevilka: string;
        maticnaStevilka: string;
        adress1: string;
        adress2: string;
        iban : string;
        country: string;
        logoId : string;
        logo: any;
        zigId : string;
        zig: any;
        podpisId: string;
        podpis: any;
        swift:string;
    }



    const [form] = Form.useForm();

    const [company, setCompany] = useState<DataType>();
    const  [ownCompanyId, setownCompanyId] = useState<any>();

    const  [logoId, setlogoId] = useState<any>();
    const  [zigId, setzigId] = useState<any>();
    const  [podpisId, setpodpisId] = useState<any>();

    const[isClientVal, setisClientVal]=useState(false);
    const[isClientValDisabled, setisClientValDisabled]=useState(false);

    const[componentDisabled, setComponentDisabled]=useState(false);

    const [dealsFilter, setdealsFilter]=useState<DealsFilter>({
        searchText: "",
        dateFrom: "",
        dateTo: "",
        hidefinished:true,
        requestStatus: 100,
        selectedDates: "",
        counterPartId : paramValue
    })


    const [transactionFilter, settransactionFilter]=useState<TransactionsFilter>({
        searchText: "",
        dateFrom: "",
        dateTo: "",
        hidefinished:true,
        selectedDates: "",
        counterPartId : paramValue
    })


    useEffect(() => {

        axios.get(AuthService.DATA_URL+`Contractor/`+paramValue
            , { headers: authHeader() })
            .then((response) => {
                console.log("response.data: ", response.data)
                setCompany(response.data);
                setownCompanyId(response.data.id);
                setlogoId(response.data.logoId);
                setzigId(response.data.zigId);
                setpodpisId(response.data.podpisId)
                form.setFieldsValue({
                    title: response.data ? response.data.title : "",
                    davcnaStevilka: response.data ? response.data.davcnaStevilka : "",
                    maticnaStevilka: response.data ? response.data.maticnaStevilka : "",
                    adress1: response.data ? response.data.adress1 : "",
                    adress2: response.data ? response.data.adress2 : "",
                    iban: response.data ? response.data.iban : "",
                    swift: response.data ? response.data.swift : "",
                    contactPerson : response.data ? response.data.contactPerson : "",
                    tel: response.data ? response.data.tel : "",
                    fax: response.data ? response.data.fax : "",
                    isClient: response.data ? response.data.isClient : false,

                });
                setisClientVal(response.data ? response.data.isClient : false)


                if(response.data.createdByContractorId!==user.contractorId){
                    setComponentDisabled(true);
                    console.log("Идщсл ашудвы");
                }
                if(user.isTransporter){
                    setisClientValDisabled(true)
                }


            })
    }, [paramValue]);

    //settest((paramValue ? paramValue : "-"));



    const BackToList = () =>{

        if(afterCreate){
            afterCreate("");
        }
        else{
            history.push("/contractors");
        }
    }

    const onFinish =(values : any)=>{
        console.log("values: ", values)

        axios.put(AuthService.DATA_URL+'Contractor/'+paramValue,
            {

                "id": ownCompanyId,
                "title": values.title,
                "davcnaStevilka": values.davcnaStevilka,
                "maticnaStevilka": values.maticnaStevilka,
                "adress1": values.adress1,
                "adress2": values.adress2,
                "iban": values.iban,
                "swift": values.swift,
                "userid":"",
                "owneruserid":"",
                "logoid" : logoId,
                "podpisId": podpisId,
                "zigId":zigId,
                "tel":values.tel,
                "fax": values.fax,
                "contactPerson": values.contactPerson,
                "isClient": isClientVal//values.isClient//===true? "checked" : ""


            },{headers: authHeader()})
            .then((res) =>{
                console.log("after pit res: ", res)
                if(afterCreate){
                    afterCreate(res);
                }
                else{
                    history.push("/contractors");
                }
            }
            );



    }

    const { TabPane } = Tabs;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleModalCancel = () => {
        setreload(!reload);
        setIsModalOpen(false);
    };
    const showModal1 = () => {
        setIsModalOpen(true);

    };

    const [reload, setreload] = useState(false);

    const handleModalOk=()=>{
        setreload(!reload);
        setIsModalOpen(false);
    }




    const showval=(v: any)=>{
        console.log("v: ",v);
        console.log("FieldValue: ",form.getFieldValue("isClient"))
        setisClientVal(v);

    }


    const handleDelete=()=>{
        const { confirm } = Modal;
        confirm({
            title: t('Delete_warning'),
            icon: <ExclamationCircleFilled />,
            content: t("Delete_text"),
            okText: t('Yes'),
            okType: 'danger',
            cancelText: t('No'),
            onOk() {
                axios.delete(AuthService.DATA_URL+'Contractor/'+company?.id
                    ,{headers: authHeader()})
                    .then((res:any) =>{
                            console.log(res)
                            if(res.status.toString()==="204"){
                                Modal.error({
                                    title: t("Delete_error_title"),
                                    content: t("Contractor_delete_error_text"),
                                });
                            }
                            else{
                                message.success(t("Done"));
                                history.push("/contractors");
                            }
                        }
                    );


            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    if(company===undefined){
        return (<div> Loadng...</div>);
    }
    return (
        <div style={{width: "100%"}}>
            <h1>{t("Podatki_o_partnerju")}{company? ": "+company.title : ""}</h1>

                    <Form
                        form={form}
                        name="wrap"
                        onFinish={onFinish}
                        autoComplete="off"
                        layout="horizontal"
                        labelCol={{ flex: '120px' }}
                        labelAlign="right"
                        labelWrap
                        wrapperCol={{ flex: 1 }}
                        colon={false}
                        disabled={componentDisabled}

                    >


                        <Row>
                            <Col span={afterCreate? 24 : 8}>
                                <Form.Item
                                    name="isClient"
                                    label={""}
                                    valuePropName="checked"


                                >
                                    <Switch
                                        style={{float:"right"}}
                                        checkedChildren={t("Stranka")}
                                        unCheckedChildren={t("Izvajalec")}
                                        onChange={(e)=>showval(e)}
                                        checked={isClientVal}
                                        disabled={isClientValDisabled}
                                        //defaultChecked
                                    />
                                    <span  style={{float:"right", marginRight: "10px"}}>{t("Stranka_ali_izvajalec")}</span>
                                </Form.Item>

                            </Col>
                        </Row>

                        <Row>
                            <Col span={afterCreate? 24 : 8}>


                                <Form.Item
                                    name="title"
                                    label={t("Naziv")}
                                    rules={[{ required: true }, { type: 'string', warningOnly: true }]}
                                >
                                    <Input className={componentDisabled? "showformelem" : ""} placeholder="input placeholder" />
                                </Form.Item>
                                <Form.Item
                                    name="davcnaStevilka"
                                    label={t("Davčna_Številka")}
                                    rules={[{ type: 'string', warningOnly: true }]}
                                >
                                    <Input  className={componentDisabled? "showformelem" : ""} placeholder="input placeholder" />
                                </Form.Item>

                                <Form.Item
                                    name="maticnaStevilka"
                                    label={t("Matična_Številka")}
                                    rules={[{ type: 'string', warningOnly: true }]}
                                >
                                    <Input  className={componentDisabled? "showformelem" : ""} placeholder="input placeholder" />
                                </Form.Item>
                                <Form.Item
                                    name="contactPerson"
                                    label={t("Kontaktna_oseba")}
                                    rules={[{ type: 'string', warningOnly: true }]}
                                >
                                    <Input  className={componentDisabled? "showformelem" : ""} placeholder="input placeholder" />
                                </Form.Item>
                            </Col>
                            <Col span={afterCreate? 24 : 8}>
                                <Form.Item
                                    name="adress1"
                                    label={t("Naslov")+" (1)"}
                                    rules={[{ type: 'string', warningOnly: true }]}
                                >
                                    <Input  className={componentDisabled? "showformelem" : ""} placeholder="input placeholder" />
                                </Form.Item>
                                <Form.Item
                                    name="adress2"
                                    label={t("Naslov")+" (2)"}
                                    rules={[{ type: 'string', warningOnly: true }]}
                                >
                                    <Input  className={componentDisabled? "showformelem" : ""} placeholder="input placeholder" />
                                </Form.Item>
                                <Form.Item
                                    name="tel"
                                    label={t("Telefon")}
                                    rules={[{ type: 'string', warningOnly: true }]}
                                >
                                    <Input  className={componentDisabled? "showformelem" : ""} placeholder="input placeholder" />
                                </Form.Item>
                                <Form.Item
                                    name="fax"
                                    label={t("Faks")}
                                    rules={[{ type: 'string', warningOnly: true }]}
                                >
                                    <Input  className={componentDisabled? "showformelem" : ""} placeholder="input placeholder" />
                                </Form.Item>

                            </Col>
                            <Col span={afterCreate? 24 : 8}>
                                <Form.Item
                                    name="iban"
                                    label="IBAN"
                                >
                                    <Input  className={componentDisabled? "showformelem" : ""} placeholder="SI56123412341234123"  />
                                </Form.Item>
                                <Form.Item
                                    name="swift"
                                    label="SWIFT"
                                >
                                    <Input  className={componentDisabled? "showformelem" : ""} placeholder="LJAASI1A"  />
                                </Form.Item>

                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                {componentDisabled?
                                    <span>
                                        <br/>
                                        <Link className={"actionLink"} to={`/contractors`}>{t("BackToList")}</Link>

 
                                    </span>

                                    :
                                    <span>
                                        <Form.Item>
                                        <Space>
                                        <Button className={"orangeButton"} htmlType="submit">
                                    {t("Shraniti")}
                                        </Button>
                                        <Button className={"emptyButton"} onClick={BackToList} >
                                    {t("Ne_shranite_sprememb")}
                                        </Button>
                                            
                                            {afterCreate? 
                                            ""
                                            :
                                                <Dropdown menu={{'items': [{ key: '1',  label: <Button type="primary" danger>{t("Delete")}</Button> }], onClick: handleDelete }} >
                                                    <Button type="text">{t("Več")}..</Button>
                                                </Dropdown>
                                            }

                                            
                                        </Space>
                                        </Form.Item>
                                    </span>



                                }

                            </Col>
                        </Row>
                    </Form>




        </div>



    )}