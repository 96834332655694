import React, {useState, useEffect, useContext, useMemo} from "react";
import {Button, List, message, notification, Skeleton, Typography} from 'antd';

import authHeader from "../../../Services/auth-header";
import * as AuthService from "../../../Services/auth-service";
import {UserOutlined, InfoCircleOutlined, CheckCircleTwoTone} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

import {HubConnection, HubConnectionBuilder} from "@microsoft/signalr";
import {NOTIFICATION_URL} from "../../../Services/auth-service";
import {NotificationContext} from "../../../Components/Notifications/NotificationContext";
import {useConnection} from "../../../Components/Notifications/useConnection";


import { subscribe, unsubscribe } from "../../../Components/Notifications/NotificationContext";

interface DataType {
    contractorId : string,
    contractor : any,
    driverId : string,
    driver : any,
    fromDateEnd : string | null,
    fromDateStart : string | null,
    id : string,
    price : number,
    priklopnik : any,
    priklopnikId : string,
    requestId : string,
    toDateEnd : string | null,
    toDateStart : string | null,
    track : any,
    truckId : string,
    loading: boolean;
}


interface RequestProps {
    requestId: string | null,
    contractorId? : string,
    onContractorChoice: (data: any) => void;
   
}

const messages:string[] =[];

 export default function SuggestedContractor({requestId,contractorId, onContractorChoice} : RequestProps) {
     const DataUrl = AuthService.DATA_URL + 'reply/get-for-request?requestid=' + requestId;
     //console.log("SuggestedTransport contractorId:",contractorId);

     const user1 = AuthService.getCurrentUser()

     const [reloadNote, setReloadNote] = useState("")


     const {t} = useTranslation();

     const [initLoading, setInitLoading] = useState(true);
     const [loading, setLoading] = useState(false);
     const [data, setData] = useState<DataType[]>([]);
     const [list, setList] = useState<DataType[]>([]);



     const[rr, setRR]=useState("-")
     
     const newNotification=(msg: any)=>{
        // console.log("I see event!");
        // console.log(msg);
         setReloadNote(msg.detail.messageId);
         setRR("-")
     }
     
     useEffect(() => {
        
        if(rr==="-"){
            subscribe("newReply", (data: any) => newNotification(data));  
            fetch(DataUrl, {headers: authHeader()})
                .then((res) => res.json())
                .then((res) => {
                    //console.log("SuggestedContractor requestId: ",requestId)
                    //console.log("SuggestedContractor res", res);
                    setInitLoading(false);
                    setData(res);
                    setList(res);
                    //setData(res.map((driver: any) => ({ name: driver.name, surname: driver.surname, id : driver.id })));
                    //setList(res.map((driver: any) => ({ name: driver.name, surname: driver.surname, id : driver.id })));
                    //console.log(res)
                });
            setRR("--");
            
        }
        return () => {
            unsubscribe("newReply", (data: any) => newNotification(data));
            //unsubscribe("hideList", () => setReloadNote("true"));
        }
        
    }, [contractorId, reloadNote]);


    const ChooseContractor=(rn : any)=>{
        //console.log("choose driver", rn);
        onContractorChoice(rn);
    }




    
    return(

 
                <div style={{maxWidth: "330px", minWidth: "330px", height:"500px", overflow: "auto"}}>
                  
                    <List
                        className=""
                        loading={initLoading}
                        size="small"
                        itemLayout="horizontal"
                        //loadMore={loadMore}
                        dataSource={list}
                        renderItem={(item) =>
                        {

                            return(

                                <List.Item
                                    actions={[ ]}>
                                    <Skeleton avatar title={false} loading={item.loading} active>
                                        <div>
                                            <div>
                                                <div className="ContractorHintItem"  style={{cursor: "pointer"}} onClick={()=>ChooseContractor(item)}>
                                                    <b>{item.contractor.title}</b><br/>
                                                    {item.contractor.adress1 ?  <p style={{margin: "5px 0px"}}  >{item.contractor.adress1+" "+item.contractor.adress2}</p> : ""}
                                                    {item.contractor.davcnaStevilka ? <p style={{margin: "5px 0px"}} >{item.contractor.davcnaStevilka}</p> : ""}
                                                    <div className={"traktor_div"} style={{cursor: "pointer", margin: "5px 0px"}}
                                                        //     onClick={()=>ChooseVlacilec(item.vlacilec.id, 0)}
                                                    >
                                                        {item.track.truckType==2 ?
                                                            <img src={"/img/tovornjak.png"} alt={" "} />
                                                            :
                                                            <img src={"/img/traktor.png"} alt={" "} />}
                                                        {item.track.regNumber}
                                                    </div>
                                                    {item.priklopnik ?
                                                        <div  className={"priklopnik_div"} style={{cursor: "pointer"}}
                                                            //      onClick={()=>ChooseVlacilec(item.priklopnik.id, 1)}
                                                        >
                                                            <img src={"/img/priklopnik.png"} alt={" "} />
                                                            <div style={{clear: "right"}}>{item.priklopnik.regNumber}
                                                            </div>
                                                        </div> : ""

                                                    }
                                                    <p style={{margin: "5px 0px"}} ><UserOutlined/> {" "+ item.driver.name+ " "+ item.driver.surname}</p>
                                                    <p style={{margin: "5px 0px"}} >{t('Cena')+": "+item.price}</p>


                                                </div>
                                            </div>
                                        </div>
                                    </Skeleton>
                                </List.Item>
                            )
                        }}
                    />

                </div>

        
            
       
            
       

      
       
    );
}


