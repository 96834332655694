import React, {useContext, useEffect, useState} from 'react';
import {Avatar, Layout, Space, message, Button} from 'antd'
import {UserOutlined} from "@ant-design/icons";
import BreadCrumbsComp from "./BreadCrumbsComp";

import * as AuthService from "../Services/auth-service";

import {HubConnection, HubConnectionBuilder} from "@microsoft/signalr";
import {NOTIFICATION_URL} from "../Services/auth-service";
import authHeader from "../Services/auth-header";
import {NotificationContext} from "./Notifications/NotificationContext";
import {NotificationCentre} from "./Notifications/NotificationCenter";






const { Header } = Layout;
const messages:string[] =[];




const AppHeader = () => {
    const user1 = AuthService.getCurrentUser();
    //console.log("user1", user1);

   
    const [headerClass, setheaderClass] = useState("normalHeader");//#f4f6f9

    //console.log("user1: ", user1);

    //console.log("new Date(user1.paidTill): ", new Date(user1.paidTill));
    //console.log("new Date(): ", new Date());


    //const[ReloadSuggested, setReloadSuggested]=useState("-")


    

/*
    const[norifierConnection, setnorifierConnection] =useState<HubConnection|null>(null);
    const  joinNotifications = async (user: any, msg: string) => {
        try{
            const conn= new HubConnectionBuilder().withUrl(NOTIFICATION_URL,{headers: authHeader()}).build();
            await conn.on("ReceiveNotifiction", (msg)=>{
                if(!messages.find((t)=>t===msg.messageId)){
                    messages.push(msg.messageId);
                    if(msg.objectType==="hello"){
                        console.log("msg:", msg);
                    }
                    else{
                        message.success(msg.message);
                        console.log("msg.messageId: ", msg.messageId)
                        setreloadSuggested(msg.messageId.toString());
                    }
                }
            })
            await conn.start();
            const username= user.contractorId
            await conn.invoke("JoinChat", {username, msg});
            //norifierConnection=!norifierConnection;
            setnorifierConnection(conn);
            //return conn;
        }
        catch (e) {
            console.log("exception: ", e);
            //return null;
        }
    };
    
    
    
 */
    
    useEffect(()=>{
/*
        if(user1) {
            if (norifierConnection === null) {
                const ttt = joinNotifications(user1, "test");
            }
        }
        
        
 */
        if(new Date(user1.paidTill)< new Date()){
            console.log("user1: ", user1);
            setheaderClass("redHeader")
        }
    })




    return (
        
          
                <Header
                    className={headerClass}
                    style={{
                        backgroundColor: "#fff !important"

                       // margin: '6px 8px 0px 0px',

                    }}
                >
                    {/*  <NotificationCenter/>*/}
                    <Space style={{float: 'left', height: '64px'}} align="center">
                        <BreadCrumbsComp/>

                    </Space>
                    

                        
                    <Space style={{float: 'right', height: '64px'}} align="center">
                        {user1.contractorName}/{user1.firstName + " " + user1.lastName}
                        <Avatar icon={<UserOutlined/>}></Avatar>

                    </Space>
                    <Space style={{float: 'right', marginRight: "20px"}}>
                        <NotificationCentre/>
                    </Space>
                    {headerClass === "redHeader" ?
                        <div style={{marginRight: "100px", color: "red", display: "inline-block", float: "right"}}>
                            Вы пользуетесь бесплатной версией. &nbsp;&nbsp;
                            <a className="redHeadera" href="/settings?tab=3">Оформите подписку &rarr;</a>
                        </div> : ""}


                </Header>
            
       

        );
    };
export default AppHeader;