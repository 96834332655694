import React, {useState, useEffect} from "react";
import { List, Skeleton } from 'antd';

import authHeader from "../../../Services/auth-header";
import * as AuthService from "../../../Services/auth-service";

interface DataType {

    vlacilec:{
        id: string;
        regNumber: string;
        vin : string;
        model : string;
        brand: string;
        truckType: number;
        loadingType: number;
        rollingStockType: number;
        thumbnail?: string;
    },
    priklopnik :{
        id: string;
        regNumber: string;
        vin : string;
        model : string;
        brand: string;
        truckType: number;
        loadingType: number;
        rollingStockType: number;
        thumbnail?: string;
    }
    /*
    name: {
        title?: string;
        first?: string;
        last?: string;
    };
    email?: string;
    picture: {
        large?: string;
        medium?: string;
        thumbnail?: string;
    };
    nat?: string;
    
     */
    loading: boolean;
}


interface RequestProps {
    requestId: string | null,
    contractorId? : string,
    onVlacilecChoice: (data: any, type: number) => void;
}


export default function SuggestedTransport({requestId,contractorId, onVlacilecChoice} : RequestProps) {
    const DataUrl = AuthService.DATA_URL+'truck/get-for-request?requestid='+requestId+(contractorId ? "&ContractorId="+contractorId : "");
    //console.log("SuggestedTransport contractorId:",contractorId);

    const [initLoading, setInitLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<DataType[]>([]);
    const [list, setList] = useState<DataType[]>([]);

    useEffect(() => {
        fetch(DataUrl, {headers: authHeader()})
            .then((res) => res.json())
            .then((res) => {
               // console.log("res: ", res);

                setInitLoading(false);
                setData(res);
                setList(res);
            });
    }, [contractorId]);


    const ChooseVlacilec=(rn : string, tp: number)=>{
        console.log("choose vlacilec", rn, tp);
        onVlacilecChoice(rn, tp);
    }

    return(
    <div style={{maxWidth: "330px", minWidth: "330px", height:"500px", overflow: "auto"}}>
        
        

        <List
            className=""
            loading={initLoading}
            size="small"
            itemLayout="horizontal"
            //loadMore={loadMore}
            dataSource={list}
            renderItem={(item) => 
                {
                    if(item){
                        return(
                            <List.Item
                                actions={[ ]}>
                                <Skeleton avatar title={false} loading={item.loading} active>
                                    <div>
                                        <div>
                                            {item.vlacilec ?
                                                <div className={"traktor_div"} style={{cursor: "pointer"}} onClick={()=>ChooseVlacilec(item.vlacilec.id, 0)}>
                                                    {item.vlacilec.truckType==2 ?
                                                        <img src={"/img/tovornjak.png"} alt={" "} />
                                                        :
                                                        <img src={"/img/traktor.png"} alt={" "} />
                                                    }
                                                    {item.vlacilec.regNumber}
                                                </div>
                                            : 
                                                <div></div>}
                                            {item.priklopnik ?
                                                <div  className={"priklopnik_div"} style={{cursor: "pointer"}} onClick={()=>ChooseVlacilec(item.priklopnik.id, 1)}>
                                                    <img src={"/img/priklopnik.png"} alt={" "} />
                                                    <div style={{clear: "right"}}>{item.priklopnik.regNumber}
                                                    </div>
                                                </div>
                                            :
                                                <div></div>}
                                        </div>
                                    </div>
                                </Skeleton>
                            </List.Item>

                        )
                    }
                }}
        />
        
    </div>
);
}