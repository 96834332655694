import React, {useState, useCallback, useEffect} from "react";
import {
    Button,
    Divider,
    Form,
    Input,
    Steps,
    InputNumber,
    Row,
    Col,
    message,
    Select,
    Drawer,
    Radio,
    Checkbox, MenuProps, Dropdown, Space, Tooltip
} from 'antd'
import axios from "axios";
import {useHistory} from "react-router-dom";


import RoutePointStepForm from "./RoutePointStepForm";
//import axios from "axios/index";
import authHeader from "../../../Services/auth-header";
import * as AuthService from "../../../Services/auth-service";
import CreateContractor from "../../contractors/ContractorsComponents/create";
import PointsList from "./pointsList";
import DocPosition from "../../../types/DocPosition";
import {useTranslation} from "react-i18next";
import {DownOutlined, LockOutlined, UsergroupAddOutlined} from "@ant-design/icons";

interface request {
    // id: string;
     requestNumber: string;
     price : number;
     distance : number;
     loadingType: number;
     rollingStockType: number;
     requestStatus: number;
     RoutePoints: any[];
     
     clientId : string;
    receiverId : string;
    payerId : string;
    contractorId? : string | null;
    volume : number;
    weight : number;
    priceForClient? : number | null;
    priceToShowToSubcontractors? : number | null;


    CargoDescription : string;
    ClientName : string;
    requestType : number;
};

const routePoints :any[] =[];
type GUID = string & { isGuid: true};

const newReq: request =
    {
       // id:"",
        requestNumber : "---",
        price: 1200,
        distance: 0,
        loadingType: 0,
        rollingStockType: 0,
        requestStatus: 0,
        clientId: "",
        receiverId : "",
        payerId : "",
        contractorId :null,
        CargoDescription:"",
        ClientName:"",
        volume : 0,
        weight : 0,
        requestType : 0,

        priceForClient:0,
        priceToShowToSubcontractors:0,


        RoutePoints: []
    };
export default function CreateRequest() {
    const [data, setData] = useState({});
    const [step, setStep] = useState(0);
    const [stepItems, setstepItems] = useState<any[]>([]);

    const {t} = useTranslation();

    const history= useHistory();


    const user = AuthService.getCurrentUser();
    if(!user){
        AuthService.logout();
        history.push("/login");
        window.location.reload();

    }
    
    
    const blurNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        //newReq.requestNumber = e.target.value;
    }
    


    const [form] = Form.useForm();

    
    const ChangeClient = (value: string) => {
        newReq.clientId = value;//parseFloat(value);
        /*
        if(form.getFieldValue("Payer")===undefined){
            form.setFieldsValue({
                Payer: value
            });
        }
        
         */
        //if(PointsOfRoute.length >=1 && newReq.clientId && newReq.payerId && newReq.receiverId)
        //    setShowSaveButton('block');
        return value;
    }
    
     

    const ChangePrice = (value: string | null) => {
        newReq.price = parseFloat((value? value : "0"));
        //return value;
    }


    const ChangeClientPrice = (value: string | null) => {
        newReq.priceForClient = parseFloat((value? value : "0"));
        //return value;
    }

    const ChangeSubcontractorPrice = (value: string | null) => {
        newReq.priceToShowToSubcontractors = parseFloat((value? value : "0"));
        //return value;
    }
    
    
    
    const ChangeVolume = (value: string | null) => {
        newReq.volume =parseFloat((value? value : "0"));
        //return value;
    }
    const ChangeWeight = (value: string | null) => {
        newReq.weight = parseFloat((value? value : "0"));
        //return value;
    }
    
    const ChangeReceiver = (value: string) => {
//        console.log('Change:', value);
        newReq.receiverId = value;//parseFloat(value);
        //if(PointsOfRoute.length >=1 && newReq.clientId && newReq.payerId && newReq.receiverId)
        //    setShowSaveButton('block');
        return value;
        
        
    }

    
    
    
    const ChangePayer = (value: string) => {

        newReq.contractorId = value;//parseFloat(value);
        //newReq.payerId = value;//parseFloat(value);
        return value;


    }

    const CreateRequestHeader=(data: any, returnTo? : string ) =>{
        console.log("data: ", data);
        console.log("newReq: ", newReq);
        var newId="";
        if(user.isTransporter){
            newReq.requestType=1;
        }
        //setShowSaveButton('none');
        axios.post(AuthService.DATA_URL+'Request/',
            newReq,{headers: authHeader()})
            .then((response) =>{
                console.log(response.data);
                newId=response.data;
                //message.success('Naročilo je bilo shranjeno!');
                //newReqId = response.data;
                let idx: number =0;
                PointsOfRoute.forEach(point => {
                    //console.log("point.PointDate: ", point.PointDate);
                    //console.log("point.PointTime: ", point.PointTime)
                    //console.log("start: ", point.PointDate.substring(6,10)+"-"+point.PointDate.substring(3,5)+"-"+point.PointDate.substring(0,2)+"T"
                    //+(point.PointTime ? point.PointTime : "00:00")+":00.000Z")
                    axios.post(AuthService.DATA_URL+'RoutePoint/',
                        {
                            address: point.street ? point.street : null,
                            streetName: point.street ? point.street : null,
                            cityName: point.city  ? point.city : null,
                            countryName: point.country ? point.country : null,
                            lat: point.lat ? point.lat : 0,
                            lng: point.lng ? point.lng : 0,
                            cityId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                            countryId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                            dateStart: point.PointDate ? (point.PointDate.substring(6,10)+"-"+point.PointDate.substring(3,5)+"-"+point.PointDate.substring(0,2)+"T"
                                +(point.PointTime ? (point.PointTime.length>0 ?  point.PointTime : "00:00"): "00:00")+":00.000Z") : null,
                            dateEnd: point.PointDate ? (point.PointDate.substring(6,10)+"-"+point.PointDate.substring(3,5)+"-"+point.PointDate.substring(0,2)+"T"
                                +(point.PointTime ? (point.PointTime.length>0 ?  point.PointTime : "00:00") : "00:00")+":00.000Z") : null,
                            orderInRoute: idx,//point.step,
                            routePointType: point.PointType,
                            requestId: response.data as GUID,
                        },{headers: authHeader()}
                    )
                        .then((itog) =>{
                            //console.log(itog);
                        });
                    idx=idx+1;
                })
                
                if(returnTo===null || returnTo===undefined){
                    history.push("/routes");
                }
                else{
                    if(returnTo==="1"){
                        console.log(newId);
                        history.push("/routes/view/"+newId);
                        window.location.reload();
                    }
                    if(returnTo==="2"){
                        history.push("/routes");
                    }
                }
                   
                }
            );
        console.log(routePoints.length );
        console.log(newReq);
        }
   
    const [PointsOfRoute, setPointsOfRoutes] = useState<any[]>([]);

    const handleListChange=(posiitions? : any[]) => {
        setPointsOfRoutes(posiitions ? posiitions:[]);
        if(posiitions){
            setPointsOfRoutes(
            posiitions ? posiitions.map((tr, index) => ({
                key: Math.random().toString(),
                city: tr.city,
                country: tr.country,
                street: tr.street,
                lat: tr.lat,
                lng: tr.lng,
                PointDate: tr.PointDate,
                PointTime: tr.PointTime,
                PointType : tr.PointType
            }) ) : [])
        }
        console.log("posiitions: ", posiitions);
        console.log("PointsOfRoute: ", PointsOfRoute);
        
        console.log("PointsOfRoute.length: ", PointsOfRoute.length)
        if(PointsOfRoute.length >=1 )
            setShowSaveButton('block');
    }
    

    const [formH] = Form.useForm();



    const [ShowSaveButton, setShowSaveButton] = useState<string>("none");
    const[contractors, setcontractors] = useState<any[]>([]);


    const[ContractorOptions, setContractorOptions] = useState<any[]>([]);
    const[TransporterOptions, setTransporterOptions] = useState<any[]>([]);
    const[isPublicRequest, setisPublicRequest] = useState<boolean>(true);
    const[showPerformerSelect, setshowPerformerSelect] = useState("none");



    useEffect(() => {
        if (TransporterOptions.length === 0) {
            axios.get(AuthService.DATA_URL+'Contractor/get-transporters', {headers: authHeader()})
                .then((response) => {
                    console.log("TransporterOptions: ",response.data)
                    if (TransporterOptions.length === 0) {
                        JSON.parse(JSON.stringify(response.data, null, 4)).forEach((row: any, i: number) => {
                            TransporterOptions.push({
                                value: row.id,
                                label: row.title
                            });
                        });
                    }
                    setTransporterOptions(TransporterOptions);

                });
        }
    }, []);




    useEffect(() => {
        console.log("useffect for contractors");
        if(ContractorOptions.length===0){
            axios.get(AuthService.DATA_URL+'Contractor/get-clients', {headers: authHeader()})
                .then((response) => {
                    console.log("axios.get contractors")
                    setcontractors(JSON.parse(JSON.stringify(response.data, null, 4)));
                    setContractorOptions([]);
                    response.data.forEach((row:any) =>{
                        ContractorOptions.push({
                            value: row.id,
                            label: row.title,
                        });
                    });
                    setContractorOptions(ContractorOptions);
                })
                .catch(function (error) {
                    if(error.response.status===401){
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                })
            ;
        }
        if(newReq.requestNumber==="---"){
            axios.get(AuthService.DATA_URL+'Request/get-requestcount', {headers: authHeader()})
                .then((response)=>{
                   newReq.requestNumber="TN-"+(response.data+1).toString()+"."+(new Date()).getFullYear().toString();
                    formH.setFieldsValue({
                        requesrNumber: newReq.requestNumber
                    });

                })
                .catch(function (error) {
                    if(error.response.status===401){
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                })
            ;
        }
    });


    useEffect(() => {

            console.log("PointsOfRoute.length: ", PointsOfRoute.length)

            if(PointsOfRoute.length >=1 )
            {
                console.log("setShowSaveButton");
                setShowSaveButton('block');
            }
            else
            {
                setShowSaveButton('none');

            }
        },[PointsOfRoute, newReq]
        )

    const [openPayer, setopenPayer] = useState(false);
    const [openReceiver, setopenReceiver] = useState(false);
    const [openContractor, setopenContractor] = useState(false);

    const showPayerDrawer = () => {
        setopenPayer(true);
    };
    const showContractorDrawer = () => {
        setopenContractor(true);
    };
    const showReceiverDrawer = () => {
        setopenReceiver(true);
    };

    const onClose = () => {
        //один close на все дроеры
        setopenPayer(false);
        setopenContractor(false);
        setopenReceiver(false);
    };



    const setNewContractor = (value : any):string =>{
        setopenContractor(false);
        console.log(value);
        if(value!==""){
            
            setContractorOptions(prev => ([
                    ...prev,
                    {
                        value: value.data.id,
                        label : value.data.title
                    }]
            ));
            
             /*

            ContractorOptions.push({
                value: value.data.id,
                label: value.data.title
            });
            
              */
            form.setFieldsValue({
                Client: value.data.id
            });
            newReq.clientId= value.data.id

            /*
            if(form.getFieldValue("Payer")===undefined){
                form.setFieldsValue({
                    Payer: value.data.id
                });
                newReq.payerId= value.data.id
            }
            
             */
            console.log("ContractorOptions:::", ContractorOptions)


        }
        return "";
    }
    const setNewReceiver = (value : any):string =>{
        setopenReceiver(false);
        if(value!==""){
            setContractorOptions(prev => ([
                    ...prev,
                    {
                        value: value.data.id,
                        label : value.data.title
                    }]
            ));

            form.setFieldsValue({
                Receiver: value.data.id
            });
            newReq.receiverId= value.data.id
        }
        return "";
    }
    
    
    

    
    
    const setNewPlacnik = (value : any):string =>{
        setopenPayer(false);
        if(value!==""){
            setTransporterOptions(prev => ([
                    ...prev,
                    {
                        value: value.data.id,
                        label : value.data.title
                    }]
            ));

            form.setFieldsValue({
                Payer: value.data.id
            });
            newReq.contractorId = value.data.id
            newReq.requestType=1;
        }
        return "";
    }

    //описание груза
    const blurDescription = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {


        newReq.CargoDescription= e.target.value ===undefined ? "" : e.target.value
        /*
        setNewReq(prev => ({
            ...prev,
            cargoDescription: e.target.value ===undefined ? "" : e.target.value
        }));
        
         */
    }

    
    const changeRequestType=(e:any)=>{
        setisPublicRequest(e.target.checked)
        if(!e.target.checked){
            setshowPerformerSelect("block")
            newReq.requestType=2;
        }
        else{
            setshowPerformerSelect("none")
            newReq.requestType=0;
            
        }
        //console.log(e.target.checked)
        if(user.isTransporter){
            newReq.requestType=1;
        }
    }


    const checkRP=()=>{
        console.log("PointsOfRoute: ", PointsOfRoute);
    }





    const saveItems: MenuProps['items'] = [
        { key: '1',  label: <span>{t("save_and_choose_performer")}</span> },
        { key: '2',  label: <span>{t("save_and_quit")}</span> },
    ]
    const handleSaveOption: MenuProps['onClick'] = (e) => {
        console.log("statis change: ",e)
        CreateRequestHeader(null, e.key)

    }
    
    

    if(ContractorOptions.length===0){
        return (<div>Loading..</div>);
    }
    return (
        <div>
            
            <Row>
                <Col span={24}>
                    <Form
                        form={formH}
                        name={"RequestHeader"}
                        autoComplete="off"
                        labelAlign="right"
                        labelWrap
                        wrapperCol={{ flex: 1 }}
                        colon={false}
                        initialValues={{
                            ["requesrNumber"]: "TN-01.2023"
                        }}
                    >
                        <h1>{t("Dodajanje_naročila")} №
                            <Form.Item style={{marginBottom: "5px", marginLeft: "10px", display: "inline-block", verticalAlign: "middle" }}
                                       name="requesrNumber"
                                       label=""

                            >
                                <Input placeholder="" onChange={blurNumber}   />
                            </Form.Item>
                        </h1>
                    </Form>

                </Col>
            </Row>
            
            <Row>
                <Col span={8}>
                    <Divider orientation="left" orientationMargin="0">{t("Osnovni_podatki")}</Divider>
                    <Form
                        layout="horizontal"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        labelAlign="right"
                        name="header"
                        form={form}
                        //style={{ maxWidth: 600 }}
                    >
                                <Form.Item name="Client" label={t("Pošiljatelj")} >
                                    <Select options ={ContractorOptions}  onChange={ChangeClient} style={{maxWidth: "220px"}}
                                            dropdownRender={(menu) => (
                                                <>
                                                    {menu}
                                                    <Divider style={{ margin: '8px 0' }} />
                                                    <div style={{width: "100%", textAlign: "center"}}>
                                                        <a onClick={showContractorDrawer}>{t("Ustvarite_novo_podjetje")}</a>
                                                    </div>
                                                </>
                                            )}

                                    />
                                </Form.Item>
                                <Form.Item name="Receiver" label={t("Prejemnik")} >
                                    <Select options ={ContractorOptions}  onChange={ChangeReceiver} style={{maxWidth: "220px"}}
                                            dropdownRender={(menu) => (
                                                <>
                                                    {menu}
                                                    <Divider style={{ margin: '8px 0' }} />
                                                    <div style={{width: "100%", textAlign: "center"}}>
                                                        <a onClick={showReceiverDrawer}>{t("Ustvarite_novo_podjetje")}</a>
                                                    </div>
                                                </>
                                            )}

                                    />
                                </Form.Item>


                        {user.isOperator?
                            <>
                            <Form.Item name="RequestType" style={{width: "100%", marginLeft:"125px"}}>
                                <Checkbox checked={isPublicRequest} onClick={(e)=>{changeRequestType(e)}}>Открытый заказ</Checkbox>
                            </Form.Item>
                            <Form.Item name="Performer" label={t("Izvajalec")} style={{display: showPerformerSelect}} >
                                <Select options ={TransporterOptions} popupMatchSelectWidth={false}  onChange={ChangePayer} style={{maxWidth: "220px"}}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '5px 0' }} />
                                                <div >
                                                    <a style={{width: "100%", textAlign: "center"}} onClick={showPayerDrawer}>{t("Ustvarite_novo_podjetje")}</a>
                                                </div>
                                            </>
                                        )}

                                />
                            </Form.Item>
                            </>
                            : ""}


                        <Form.Item label={t("Cena")}>
                        <Space>
                            <Form.Item name="Price" label={t("Cena")} rules={[{ type: 'number', min: 0}]} noStyle >
                                <InputNumber onChange={ChangePrice} suffix="€" style={{/*maxWidth: "220px"*/}}/>
                            </Form.Item>

                            <Tooltip title=""><i style={{color: "#bbb"}}>{t("Cena_stranke")}</i></Tooltip>
                        </Space>
                        </Form.Item>
                        {
                            user.isOperator&&newReq.requestStatus===0 ?
                                <>
                                <Form.Item label={t("Cena")}>
                                    <Space>
                                <Form.Item name="PriceForSubcontractor" label={t("Cena_izvajalca")} rules={[{ type: 'number', min: 0}]} noStyle >
                                    <InputNumber onChange={ChangeSubcontractorPrice} suffix="€" style={{maxWidth: "220px"}}/>
                                </Form.Item>
                                        <Tooltip title=""><i style={{color: "#bbb"}}>{t("Cena_izvajalca")}</i></Tooltip>
                                    </Space>
                                </Form.Item>
                                </>
                                :
                                ""
                        }
                        
                    </Form>

                    <Divider orientation="left" orientationMargin="0">{t("Opis_tovora")}</Divider>
                    <Form
                        layout="horizontal"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        name="route"
                        //onFinish={onFinish}
                        style={{ maxWidth: 600 }}
                        //validateMessages={validateMessages}
                    >
                                <Form.Item name="weight" label={t("Teža")} rules={[{ type: 'number', min: 0 }]}>
                                    <InputNumber onChange={ChangeWeight} />
                                </Form.Item>
                                <Form.Item name="volume"  label={t("Volumen")} rules={[{ type: 'number', min: 0 }]}>
                                    <InputNumber onChange={ChangeVolume} />
                                </Form.Item>
                                <Form.Item name="description" label={t("Opis")}>
                                    <Input.TextArea  rows={3} autoSize={{ minRows: 3, maxRows: 3 }}  onChange={blurDescription} />
                                </Form.Item>
                    </Form>
                </Col>
                
                <Col span={12} offset={0}>
                    <Divider orientation="left" orientationMargin="0">{t("Itinerarij")}</Divider>
                    
                    <div>
                        
                        <PointsList informParent={handleListChange}  />
                    </div>

                    {/* 
                    <div>
                        <Steps
                            progressDot
                            current={step}
                            direction="vertical"
                            items={stepItems}
                        />
                    </div>*/}
                            
                </Col>
            </Row>







            <Dropdown menu={{'items': saveItems, onClick: handleSaveOption }} >
                <Button className={"orangeButton"}
                        style={{ marginTop:"25px", /*marginRight:"50px",*/ }}>
                    {t("Shranjevanje_naročila")}

                    <DownOutlined />
                </Button>
            </Dropdown>



            {/* 
            <Button className={"orangeButton"} onClick={CreateRequestHeader}
                    style={{ marginTop:"25px",  display: ShowSaveButton}}>
                {t("Shranjevanje_naročila")}
            </Button>

        */}



            <Drawer title={t("Ustvarite_novo_podjetje")} placement="right" onClose={onClose} open={openContractor}>
                <CreateContractor afterCreate={setNewContractor}  isClient={true}/>
            </Drawer>
            <Drawer title={t("Ustvarite_novo_podjetje")} placement="right" onClose={onClose} open={openReceiver}>
                <CreateContractor afterCreate={setNewReceiver} isClient={true}/>
            </Drawer>
            <Drawer title={t("Ustvarite_novo_podjetje")} placement="right" onClose={onClose} open={openPayer}>
                <CreateContractor afterCreate={setNewPlacnik} isClient={true}/>
            </Drawer>

        </div>
    )
}