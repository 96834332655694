
import React, {useRef, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";

import {
    Form,
    Input,
    Row,
    Col,
    Select,
    InputNumber,
    Spin,
    DatePicker,
    Checkbox,
    Button,
    message,
    Tabs,
    Space, Card, Divider
} from "antd";
import {DeleteOutlined, PlusOutlined, MinusOutlined,  PaperClipOutlined} from "@ant-design/icons";
import {Simulate} from "react-dom/test-utils";
//import submit = Simulate.submit;
import {useTranslation} from "react-i18next";
import {dateToPoints} from "../../../types/transporter-types";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import Transaction from "../../../types/Transaction";
import {
    dateForServer,
    dateForServerFromDate,
    mapDocTypeEnumToString, MoneyToString,
    ServerdateToPoints,
    ToMoney, translateFA
} from "../../../utils/helpers";
import {publish, subscribe, unsubscribe} from "../../../Components/Notifications/NotificationContext";
import dayjs from "dayjs";
import FloatLabel from "../../../utils/FloatLabel/FloatLabel";
import {tab} from "@testing-library/user-event/dist/tab";



interface TransactionProps {
    id?: string | null,
    incomeExpence? : number | null,
    reload? : boolean
}

const IncomeForm = ({id, incomeExpence, reload}: TransactionProps) => {

    
    
    const history = useHistory();
    const user = AuthService.getCurrentUser();
    if (!user) {
        history.push("/login");
    }

    const [formPos] = Form.useForm();
    const {t} = useTranslation();
    
    
    const [formLoading, setFormLoading] = useState<boolean>(false)



    const [ContractorOption,setContractorOption]=useState<{label:string, value: string, key?: string|null}[]>([])
    const [ArticleOption,setArticleOption]=useState<{label:string, value: string, key?: string|null}[]>([])
    const [RequestOption,setRequestOption]=useState<{label:string, value: string, key?: string|null}[]>([])
    const [DocOption,setDocOption]=useState<{label:string, value: string, key?: string|null, requestId? : string|null, contractorId? : string|null, positions? : any[]|null }[]>([])

    const [theTransaction, settheTransaction] = useState<Transaction>({
        id: "00000000-0000-0000-0000-000000000000", 
        incomeExpense:1})

    const [isPlanDisabled, setisPlanDisabled] =useState(false);
    
    
    
    const [dochelp, setdochelp]=useState("");
    
    const [tabKey, setTabKey]=useState('1');
    
    const [contractorhelp, setdcontractorhelp]=useState(<></>);
    
    
    const[docStatus, setdocStatus] = useState<"" | "warning" | "success" | "error" | "validating" | undefined>(undefined);
    
    
    
    const RegetDocsFor=(requestId: string|null)=>{
        
        var regetUrl='get-docsfor?requestId='+requestId;
        if(requestId===undefined||requestId===null){
            regetUrl='get-docs';
        }
        
        axios.get(AuthService.DATA_URL + 'Docs/'+regetUrl, {headers: authHeader()})
            .then((response)=>JSON.parse(JSON.stringify(response, null, 4)))
            .then((response) => {
                setDocOption(response.data.map((tr:any) => ({
                    label : t(mapDocTypeEnumToString(tr.documentType))+" "+tr.docNumber+" "+t("from")+" "+ServerdateToPoints(tr.documentDate),
                    value : tr.id,
                    key: tr.id,
                    requestId: tr.requestId,
                    contractorId:tr.contractorId,
                    positions: tr.docPositions

                }) ));
                if(theTransaction.docId!==null && theTransaction.docId!==undefined){
                    console.log(theTransaction.docId)
                    if(!response.data.some((d:any)=>d.id===theTransaction.docId)){
                        setdochelp("Документ и заказ не связаны")
                        setdocStatus("warning")
                    }
                }
            });

    }
    
    
    
    useEffect(()=>{
        setFormLoading(true)
        setTabKey('1')
        console.log("tabKey", tabKey)
        if(ContractorOption.length===0){
            axios.get(AuthService.DATA_URL + 'Contractor/', {headers: authHeader()})
                .then((response)=>JSON.parse(JSON.stringify(response, null, 4)))
                .then((response) => {
                    setContractorOption(response.data.map((tr:any) => ({
                        label : tr.title,
                        value : tr.id,
                        key: tr.id,
                    }) ))
                });
        }
        if(incomeExpence===1){
            //console.log("income: ", incomeExpence)
            axios.get(AuthService.DATA_URL + 'FinanceArticle/get-income-articles', {headers: authHeader()})
                .then((response)=>JSON.parse(JSON.stringify(response, null, 4)))
                .then((response) => {
                    setArticleOption(response.data.map((tr:any) => ({
                        label : <span> <span className="graydot" style={{backgroundColor: tr.color}}></span> {t(translateFA(tr.title))}</span>,
                        value : tr.id,
                        key: tr.id,
                    }) ))
                });
        }
        if(incomeExpence===-1){
            console.log("expence: ", incomeExpence)
            axios.get(AuthService.DATA_URL + 'FinanceArticle/get-expense-articles', {headers: authHeader()})
                .then((response)=>JSON.parse(JSON.stringify(response, null, 4)))
                .then((response) => {
                    console.log(response.data)
                    setArticleOption(response.data.map((tr:any) => ({
                        label : <span> <span className="graydot" style={{backgroundColor: tr.color}}></span> {t(translateFA(tr.title))}</span>,
                        value : tr.id,
                        key: tr.id,
                    }) ))
                });
        }
        if(DocOption.length===0){
            axios.get(AuthService.DATA_URL + 'Docs/get-docs', {headers: authHeader()})
                .then((response)=>JSON.parse(JSON.stringify(response, null, 4)))
                .then((response) => {
                    //console.log(response.data)
                    setDocOption(response.data.map((tr:any) => ({
                        label : t(mapDocTypeEnumToString(tr.documentType))+" "+tr.docNumber+" "+t("from")+" "+ServerdateToPoints(tr.documentDate),
                        value : tr.id,
                        key: tr.id,
                        requestId: tr.requestId,
                        contractorId:tr.contractorId,
                        positions: tr.docPositions
                    }) ))
                });
        }
        if(RequestOption.length===0){
            axios.get(AuthService.DATA_URL + 'Request/get-dto', {headers: authHeader()})
                .then((response)=>JSON.parse(JSON.stringify(response, null, 4)))
                .then((response) => {
                    //console.log("Requestoptions: ", response)
                    
                    setRequestOption(response.data.map((tr:any) => ({
                        label : tr.createdByContractorId===user.contractorId? tr.requestNumber : tr.externalRequestNumber,
                        value : tr.id,
                        key: tr.id
                    }) ))
                });
        }


        if(id!=="00000000-0000-0000-0000-000000000000"){
            //console.log("id: ", id)
          //  setTabKey("1")
            axios.get(AuthService.DATA_URL + 'Transaction/'+id, {headers: authHeader()})
                .then((t)=>{
                    settheTransaction(prev => ({
                        ...t.data,
                        transactionDate:ServerdateToPoints(t.data.transactionDate),
                        scenario2Amount : t.data.scenario2Amount===null ? t.data.amount :t.data.scenario2Amount,
                        scenario3Amount : t.data.scenario3Amount===null ? t.data.amount :t.data.scenario3Amount,
                        scenario2TransactionDate : t.data.scenario2TransactionDate===null ? ServerdateToPoints(t.data.transactionDate) : ServerdateToPoints(t.data.scenario2TransactionDate),
                        scenario3TransactionDate : t.data.scenario3TransactionDate===null ? ServerdateToPoints(t.data.transactionDate) :  ServerdateToPoints(t.data.scenario3TransactionDate),
                    }));
                
                    //console.log("t.data: ", t.data)
                    formPos.setFieldsValue({
                        amount:t.data.amount.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 "),
                        Doc: t.data.docId,
                        Request: t.data.requestId,
                        Contractor: t.data.counterPartId,
                        Article : t.data.financeArticleId,
                        title : t.data.description,
                        datump : dayjs(ServerdateToPoints(t.data.transactionDate),"DD.MM.YYYY" ),
                        isplan: t.data.isPlan,
                        scenario2Amount :  t.data.scenario2Amount,
                        scenario3Amount :  t.data.scenario3Amount,
                        scenario2TransactionDate : t.data.scenario2TransactionDate===null? dayjs(ServerdateToPoints(t.data.transactionDate),"DD.MM.YYYY" ) : dayjs(ServerdateToPoints(t.data.scenario2TransactionDate),"DD.MM.YYYY" ),
                        scenario3TransactionDate : t.data.scenario3TransactionDate==null ? dayjs(ServerdateToPoints(t.data.transactionDate),"DD.MM.YYYY" ) : dayjs(ServerdateToPoints(t.data.scenario3TransactionDate),"DD.MM.YYYY" ),

                    });

                    let date1 = new Date().getTime();
                    let date2 = new Date(t.data.transactionDate).getTime();
                    
                    
                    if(date1<date2){
                        settheTransaction(prev => ({
                            ...prev,
                            isPlan : true
                        }));
                        setisPlanDisabled(true)
                    }
                    else{
                        setisPlanDisabled(false)
                        
                    }
                    setFormLoading(false)
            })
        }
        else{
            settheTransaction({
                id: "00000000-0000-0000-0000-000000000000",
                incomeExpense:incomeExpence,
                transactionDate:dateToPoints(new Date())
            })
            formPos.setFieldsValue({
                amount:0,
                Contractor: null,
                Doc: null,
                Request: null,
                Article : null,
                title : "",
                datump : dayjs(dateToPoints(new Date()),"DD.MM.YYYY" ),
                isplan: false,

            });
            setFormLoading(false)


        }

        //formPos.setFieldsValue([{"title": transactProp?.description}])
       
    }, [id, incomeExpence, reload]);



    const onSelectPDate=(value: any)=>{
       
        settheTransaction(prev => ({
            ...prev,
            transactionDate : dateToPoints(new Date(value))
        }));
        if(new Date(value)>new Date()){
            settheTransaction(prev => ({
                ...prev,
                isPlan : true
            }));
            setisPlanDisabled(true)
        }
        else{
            setisPlanDisabled(false)
        }
    }
    
    const onSelectS2PDate=(value: any)=>{
        settheTransaction(prev => ({
            ...prev,
            scenario2TransactionDate : dateToPoints(new Date(value))
        }));  
    }

    const onSelectS3PDate=(value: any)=>{
        settheTransaction(prev => ({
            ...prev,
            scenario3TransactionDate : dateToPoints(new Date(value))
        }));
    }
    
    const onChangeAmount=(value: any)=>{
        settheTransaction(prev => ({
            ...prev,
            amount : value
        }));
    }
    
    const onChangeS2Amount=(value: any)=>{
        settheTransaction(prev => ({
            ...prev,
            scenario2Amount : value
        }));
    }

    const onChangeS3Amount=(value: any)=>{
        settheTransaction(prev => ({
            ...prev,
            scenario3Amount : value
        }));
    }
    
    
    const onChangeContractor=(value: any)=>{

        settheTransaction(prev => ({
            ...prev,
            counterPartId : value
        }));
    }
    const onChangeDoc=(value: any)=>{
        settheTransaction(prev => ({
            ...prev,
            docId : value
        }));
        setdochelp("");
        setdocStatus("");
        
        console.log(theTransaction)

        var docreq=DocOption.find((d:any)=>d.key===value);
        if(docreq!==null && docreq!==undefined) {
            if (theTransaction.requestId === null || theTransaction.requestId === undefined) {
                RegetDocsFor(docreq.requestId ? docreq.requestId : null);
                formPos.setFieldsValue({
                    Request: docreq.requestId
                });
                settheTransaction(prev => ({
                    ...prev,
                    requestId: docreq?.requestId
                }));
            }
            if (theTransaction.counterPartId === null || theTransaction.counterPartId === undefined) {
                formPos.setFieldsValue({
                    Contractor: docreq.contractorId
                });
                settheTransaction(prev => ({
                    ...prev,
                    counterPartId: docreq?.contractorId
                }));
            }
            if(theTransaction.amount===0||theTransaction.amount===undefined||theTransaction.amount===null){
                formPos.setFieldsValue({
                    amount: docreq.positions?.reduce((n, {cena}) => n + cena, 0)
                });
                settheTransaction(prev => ({
                    ...prev,
                    amount : docreq?.positions?.reduce((n, {cena}) => n + cena, 0)
                }));

            }

        }
        
        
    }
    const onChangeRequest=(value: any)=>{
        settheTransaction(prev => ({
            ...prev,
            requestId : value
        }));
        RegetDocsFor(value);
    }
    const onChangeArticle=(value: any)=>{
     //   console.log(value)
        settheTransaction(prev => ({
            ...prev,
            financeArticleId : value
        }));
    }
    const onChangeTitle=(value: any)=>{
        settheTransaction(prev => ({
            ...prev,
            description : value
        }));
    }
    const onChangeisPlan=(value: any)=>{
        settheTransaction(prev => ({
            ...prev,
            isPlan : value.target.checked
        }));
    }


    const requestFilterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());







    const CommitTransaction=()=>{

        console.log("theTransaction: ", theTransaction)
        var traDate = theTransaction.transactionDate?.trim();
        
        //console.log("theTransaction.transactionDate: '", traDate,"'")
        //console.log("theTransaction.transactionDate: '", theTransaction.transactionDate?.toString().replace(/^\s+|\s+$/gm,''),"'")
        //console.log("dateForServer(theTransaction.transactionDate) : ", dateForServer(theTransaction.transactionDate?.toString().replace(/^\s+|\s+$/gm,'')))
        
        
        
        const _transaction={
            id: theTransaction.id,
            amount : theTransaction.amount,
            description : theTransaction.description,
            incomeExpense: incomeExpence,
            financeArticleId: theTransaction.financeArticleId,
            docId : theTransaction.docId,
            requestId : theTransaction.requestId,
            truckId : theTransaction.truckId,
            driverId :  theTransaction.driverId,
            counterPartId : theTransaction.counterPartId,
            transactionDate : dateForServer(theTransaction.transactionDate),
            isPlan : theTransaction.isPlan,
            scenario2Amount : theTransaction.scenario2Amount==null ? theTransaction.amount : theTransaction.scenario2Amount,
            scenario3Amount : theTransaction.scenario3Amount==null ? theTransaction.amount : theTransaction.scenario3Amount,
            scenario2TransactionDate : theTransaction.scenario2TransactionDate===null ? dateForServer(theTransaction.transactionDate) : dateForServer(theTransaction.scenario2TransactionDate),
            scenario3TransactionDate : theTransaction.scenario3TransactionDate===null  ? dateForServer(theTransaction.transactionDate) : dateForServer(theTransaction.scenario3TransactionDate),
        }
        console.log("_transaction: ", _transaction)
        if(theTransaction.id==="00000000-0000-0000-0000-000000000000"){
            console.log("post")
            axios.post(AuthService.DATA_URL+'Transaction/'
                , _transaction, {headers: authHeader()}
            ).then((result=>{
                /*
                settheTransaction({
                    id: "00000000-0000-0000-0000-000000000000",
                    incomeExpense:1})
                    
                 */
                publish("onCloseTransactionModal", null)
            }))
        }
        else{
            //console.log("put")
            axios.put(AuthService.DATA_URL+'Transaction?id='+theTransaction.id
                , _transaction, {headers: authHeader()}
            ).then((result=>{
                console.log(result)
                if(result.data==="Saved"){
                    publish("onCloseTransactionModal", null)
                    /*
                    settheTransaction({
                        //id: "",
                        id: id,//"00000000-0000-0000-0000-000000000000",
                        incomeExpense:1});
                        
                     */
                }

            }))
        }
        //publish("onCloseTransactionModal", null)
    }
    
    
    /*
    if(theTransaction.id==="00000000-0000-0000-0000-000000000000" && id!=="00000000-0000-0000-0000-000000000000"){
        setFormLoading(true)
    }
    else{
        setFormLoading(false)
    }
    
     */

return(
    <div style={{width: "100%", height: "500px"}}>
        <Spin spinning={formLoading}>
        

  
            
            
            
        <Form
            form={formPos}
            id={"IncomeForm"}
            //name={keyStr.toString()}
            layout="horizontal"
            labelCol={{ flex: '100px' }}
            labelAlign="right"
            labelWrap
            //wrapperCol={{ flex: 1 }}
            onFinish={CommitTransaction}
            //onChange={onChangeForm}
            initialValues={
                {
                    ["vrsta"]:0,
                    ["Doc"] : theTransaction?.docId,
                    ["Contractor"]: theTransaction?.counterPartId? theTransaction?.counterPartId : "",
                    ["title"]:theTransaction?.description? theTransaction.description : "",
                    ["amount"]:theTransaction.amount,
                    //["datump"]:dayjs(theTransaction.transactionDate,  "DD.MM.YYYY")
                }
            }
        >
            
            <Tabs tabBarStyle={!isPlanDisabled ? {display: "none"} : {}}
                  onTabClick={(key: string)=>{setTabKey(key)}}
                  activeKey={tabKey}
            items={[
                {
                    label: 'Информация',
                    key: '1',
                    
                    children: <>
                    <FloatLabel  label={ incomeExpence===1? t("Income") : t("Expense")}>
                        <Form.Item name="amount" label="" style={{width: "100%", }}>
                            <InputNumber className={"myNumber"}

                                         style={{width: "100%", fontSize: "20px", textAlign: "right"}}
                                //prefix={incomeExpence===1? "+": "-"}
                                         //addonBefore={incomeExpence===1? <PlusOutlined />: <MinusOutlined />}
                                         decimalSeparator={"."}
                                //formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                                //parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}


                                //defaultValue={theTransaction.amount? theTransaction.amount : 0}
                                //defaultValue={transactProp?.amount?transactProp?.amount:0}
                                         onChange={(e)=>onChangeAmount(e)} />
                        </Form.Item>
                    </FloatLabel>
                        <Form.Item name="Request" label={t("Naročilo")} >
                            <Select
                                //defaultValue={transactProp?.counterPartId? transactProp?.counterPartId : ""}
                                allowClear={true}
                                showSearch
                                options={RequestOption}
                                filterOption={requestFilterOption}
                                onClear={()=>onChangeRequest(null)}
                                onChange={(e=>onChangeRequest(e))}
                            />
                        </Form.Item>

                        <Form.Item name="Doc" label={t("Dokument")} help={dochelp} validateStatus={docStatus}>
                            <Select
                                //defaultValue={transactProp?.counterPartId? transactProp?.counterPartId : ""}

                                allowClear={true}
                                options={DocOption}
                                onClear={()=>onChangeDoc(null)}
                                onChange={(e=>onChangeDoc(e))}
                            />
                        </Form.Item>
                        <Form.Item name="Contractor" label={t("Nasprotna_stranka")} help={contractorhelp}>
                            <Select
                                //defaultValue={transactProp?.counterPartId? transactProp?.counterPartId : ""}
                                allowClear={true}
                                onClear={()=>onChangeContractor(null)}
                                options={ContractorOption}
                                onChange={(e=>onChangeContractor(e))}
                            />
                        </Form.Item>
                        <Form.Item name="Article" label={t("Article")}>
                            <Select
                                options={ArticleOption}
                                onChange={(e)=>onChangeArticle(e)}
                            />
                        </Form.Item>
                        <Form.Item name="title" label={t("Description")} >
                            <Input style={{width: "100%"}}
                                   value= {theTransaction.description ? theTransaction.description : ""}
                                // defaultValue={transactProp?.description? transactProp.description : ""}
                                   onChange={(e)=>onChangeTitle(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item label={t("Date")} name="datump">
                            <DatePicker format={"DD.MM.YYYY"} placeholder={"datum"} onChange={(e)=>onSelectPDate(e)}/>
                        </Form.Item>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="" name="isplan" style={{marginLeft: "100px"}}>
                                    <Checkbox
                                        disabled={isPlanDisabled}
                                        checked={theTransaction.isPlan?theTransaction.isPlan:false }
                                        onChange={(e)=>onChangeisPlan(e)}>{t("Načrtovano")}</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col  span={12}>
                                <Button style={{float: "right"}}>
                                    <PaperClipOutlined /> {t("Attach_scan")}
                                </Button>

                            </Col>
                        </Row>
                    </>
                },
                {
                    label: t("Planner"),
                    key: '2',
                    children:  <>

                    <Form.Item style={{marginBottom: "-12px"}} >
                       <Row gutter={5} style={{marginBottom: "0px"}}>
                           <Col span={8} >
                               <FloatLabel  label={ incomeExpence===1? t("Income") : t("Expense")}>
                               <Form.Item  style={{ display: 'inline-block', width: '100%' }}>

                                   <InputNumber className={"myNumber"}
                                                disabled={true}
                                                variant={"borderless"}
                                                style={{width: "100%", 
                                                    backgroundColor: "#fff",
                                                    color: "#000",
                                                    fontSize: "20px",
                                                    borderColor: "#02BBD5",
                                                    textAlign: "right"}}
                                       //addonBefore={incomeExpence===1? <PlusOutlined />: <MinusOutlined />}
                                                decimalSeparator={"."}
                                                value={theTransaction.amount}
                                                //onChange={(e)=>onChangeS2Amount(e)} 
                                   />
                                   
                                   </Form.Item>
                               </FloatLabel>
                           </Col>
                           <Col span={8}>
                               <FloatLabel  label={t("Scenario")+" 2"}>
                                   <Form.Item name="scenario2Amount" style={{ display: 'inline-block', width: '100%' }}>
                                       <InputNumber className={"myNumber"}
                                                 style={{width: "100%", fontSize: "20px",
                                                     borderColor: "#02BBD5",

                                                     textAlign: "right"}}
                                                 //addonBefore={incomeExpence===1? <PlusOutlined />: <MinusOutlined />}
                                                 decimalSeparator={"."}
                                                 onChange={(e)=>onChangeS2Amount(e)} />
                                </Form.Item>
                            </FloatLabel>
                           </Col>
                           <Col span={8}>
                               <FloatLabel  label={t("Scenario")+" 3"}>
                                   <Form.Item name="scenario3Amount" style={{ display: 'inline-block', width: '100%' }}>
                                       <InputNumber className={"myNumber"}
                                             style={{width: "100%", fontSize: "20px",
                                                 borderColor: "#BC82B7",
                                                 textAlign: "right"}}
                                             //addonBefore={incomeExpence===1? <PlusOutlined />: <MinusOutlined />}
                                             decimalSeparator={"."}
                                             onChange={(e)=>onChangeS3Amount(e)} />
                                    </Form.Item>
                                </FloatLabel>
                           </Col>
                       </Row>
                    </Form.Item>
                        
                        
                        
                        
                        <Divider style={{marginTop: 0, marginBottom: 0}}/>
                        
                        <span style={{color: "#999"}} className={"greyzone"}>
                        <Form.Item name="Request" 
                                   label= {<label style={{ color: "#999" }}>{t("Naročilo")}</label>}>
                            <span style={{marginLeft: "12px", color: "#999"}}> 
                                {RequestOption.find((o:{label:string, value: string, key?: string|null})=>o.value===theTransaction.requestId)?.label}
                            </span>
                        </Form.Item>
                        <Form.Item name="Doc"
                                   label= {<label style={{ color: "#999" }}>{t("Dokument")}</label>}
                                   help={dochelp} validateStatus={docStatus}>
                            <span style={{marginLeft: "12px", color: "#999"}}> 
                                {DocOption.find((o:{label:string, value: string, key?: string|null})=>o.value===theTransaction.docId)?.label}
                            </span>
                        </Form.Item>
                        <Form.Item name="Contractor"
                                   label= {<label style={{ color: "#999" }}>{t("Nasprotna_stranka")}</label>}
                                   help={contractorhelp}>
                            <span style={{marginLeft: "12px", color: "#999"}}> 
                                {ContractorOption.find((o:{label:string, value: string, key?: string|null})=>o.value===theTransaction.counterPartId)?.label}
                            </span>
                        </Form.Item>
                        <Form.Item name="Article"
                                   label= {<label style={{ color: "#999" }}>{t("Article")}</label>}
                        >
                            <span style={{marginLeft: "12px", color: "#999"}}> 
                                {ArticleOption.find((o:{label:string, value: string, key?: string|null})=>o.value===theTransaction.financeArticleId)?.label}
                            </span>
                        </Form.Item>
                        <Form.Item name="title"
                                   label= {<label style={{ color: "#999" }}>{t("title")}</label>}
                        >
                            <span style={{marginLeft: "12px", color: "#999"}}>
                                {theTransaction.description ? theTransaction.description : ""}
                            </span>
                        </Form.Item>
                        </span>
                        <Divider style={{marginTop: -10, marginBottom: 10}}/>
                        
                        <Form.Item>
                            <Row gutter={5} style={{marginBottom: "0px"}}>
                                <Col span={8} >
                                    <Card className={"padding5 noborder"}><span style={{fontSize:"12px", color: "#999", border: "none"}}> {t("Date")} </span>
                                        <span style={{fontSize:"12px", color: "#999"}}> </span>
                                        <Form.Item>
                                            <span>{theTransaction.transactionDate}</span>
                                      </Form.Item>
                                    </Card>
                                        
                                      
                               
                                  
                                </Col>
                                <Col span={8} >
                                    <Card className={"padding5 scenario2"}> <span style={{fontSize:"12px", color: "#999", }}>{t("Scenario")+" 2"}</span>
                                        <Form.Item name="scenario2TransactionDate">
                                            <DatePicker format={"DD.MM.YYYY"} placeholder={"datum"} onChange={(e)=>onSelectS2PDate(e)}/>
                                        </Form.Item>
                                    </Card>
                                </Col>
                                <Col span={8} >
                                    <Card className={"padding5 scenario3"}> <span style={{fontSize:"12px", color: "#999"}}>{t("Scenario")+" 3"}</span>
                                        <Form.Item name="scenario3TransactionDate">
                                            <DatePicker format={"DD.MM.YYYY"} placeholder={"datum"} onChange={(e)=>onSelectS3PDate(e)}/>
                                        </Form.Item>
                                    </Card>
                                </Col>
                            </Row>
                        </Form.Item>

                    </>,
                },
               /* {
                    label: 'Сценарий 2',
                    key: '3',
                    children:  <></>,
                },
                
                */
            ]}
            >
                
            </Tabs>
            

            {/*
            <Form.Item label="" name="datump"  style={{marginLeft: "100px"}}>
                    <Checkbox>Учитывать в кредиторке</Checkbox>
            </Form.Item>
            */}


        </Form>

        </Spin>
        
    </div>
)}
export default IncomeForm;