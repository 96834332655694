import React from 'react';
import CreateRequest from "./RouteComponents/CreateRequest";
import {useHistory} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";

const RouteCreatePage = () => {
    const history= useHistory();
    const user = AuthService.getCurrentUser();
    if(!user){
        history.push("/login");
    }
    return (

        <div >
            <CreateRequest/>
        </div>);
}

export default RouteCreatePage;