import React, {useEffect, useState} from 'react';
import {Button, Table} from 'antd';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";
import DealsList from "./Components/DealsList";
import DetailsListFilter from "./Components/DetailsListFilter";
import DealsListFilter from "../../types/Deal";
import DealsFilter from "../../types/DealsListFilter";

const DealsPage = () => {
    const XLSX = require('xlsx');
    const {t} = useTranslation();
    const history= useHistory();

    ///

    const user = AuthService.getCurrentUser();
    if(!user){
        AuthService.logout();
        history.push("/login");
        window.location.reload();

    }
    
    const [pageFilter, setPageFilter]=useState<DealsFilter>({
        searchText: "",
        dateFrom: "",
        dateTo: "",
        hidefinished:false,
        requestStatus: 100,
        selectedDates: "",
    })
    
    const setCBFilterFunc=(filter: any)=>{
        setPageFilter(filter);
        //console.log("page comp:", filter)
    }
    
    return(
        
        <>
            <h1>{t("Narocila")}</h1>
            
            <DetailsListFilter setFilterFunc={setCBFilterFunc} /> 
            <Button onClick={()=>history.push("deals/0")}  className={"orangeButton"} style={{float: "right"}}>+ {t("Novo_naročilo")}</Button>
            
            <DealsList filter={pageFilter}  />
        </>
    )


}

export default DealsPage