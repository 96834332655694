import {Space} from "antd";
import React from "react";
import {CheckCircleFilled, EditOutlined} from "@ant-design/icons";



type Props = {
    data: any;
    onSuccess: (data: any) => void;
};
export default function RoutePointStepShow( { data, onSuccess }: Props) {

    //console.log(data);


    const dateText = data.dateText;
    const hourText = data.hourText;
    const streetText = data.streetText;//"";
    const cityText = data.cityText;
    const CountryText = data.CountryText;


    const unLockForm = () => {
        //const nextStep =  currentstep-1;
        //setComponentDisabled(false);
        //sethideLoadingButton("block");
        //sethideRoutePointSuffix("none") ;
        //setActiveStep(nextStep);
    }


    return (
        <Space>
                    <span className={"routePointEditSuffix"} style={{display: "block"}}>
                        <Space>
                        <div className="point start" title=""
                             style={{minWidth: "300px", maxWidth: "300px", lineHeight: "15px"}}>
                        <div className={"timeStart"} style={{color: "#000", marginTop: "0px"}}> 
                            <span>{dateText}<br/>
                                <span className={"routeTime"}>{hourText}</span>
                            </span>
                        </div>
                        <span style={{color: "#000"}}>{streetText}, {cityText} <br/>
                            <span className={"greyLabel"}>{CountryText}</span> 
                        </span><div className="truncate description_liDDR"></div>
                    </div>
                        </Space>
                        
                        <Space style={{display: "none"}}>
                            <a href={"#"}><EditOutlined onClick={unLockForm}
                                                        style={{verticalAlign: "middle", marginRight: "15px"}}/></a>
                                <span style={{color: "#1faa73", display: "inline-block"}}><CheckCircleFilled
                                    style={{verticalAlign: "middle"}}/> 
                                    <div style={{
                                        fontSize: "12px",
                                        display: "inline-block",
                                        marginLeft: "5px",
                                        verticalAlign: "middle"
                                    }}>Геокодирование успешно</div>
                                </span>                            
                        </Space>

                    </span>
        </Space>

    )
}
