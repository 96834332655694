import React, {useState, useEffect, useCallback} from "react";
import {
    Button,
    Form,
    Dropdown,
    Card,
    Select,
    Tabs,
    Divider,
    Drawer,
    Modal,
    Row,
    Col,
    
    MenuProps,
    Checkbox
} from 'antd'
import {CloseCircleOutlined, DownOutlined, FileTextOutlined} from '@ant-design/icons';



import axios from "axios/index";
import {useHistory} from "react-router-dom";
import SuggestedTransport from "./SuggestedTransport";
import authHeader from "../../../Services/auth-header";
import * as AuthService from "../../../Services/auth-service";
import CreateContractor from "../../contractors/ContractorsComponents/create";
import CreateDriver from "../../drivers/DriverComponents/create";
import DocList from "../../docs/DocComponents/docList";
import UploadDoc from "../../docs/DocComponents/UploadDoc";
import {useTranslation} from "react-i18next";
import {getCurrentUser} from "../../../Services/auth-service";
import FloatLabel from "../../../utils/FloatLabel/FloatLabel";
import SuggestedDrivers from "./SuggestedDrivers";
import SuggestedContractor from "./SuggestedContractor";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {subscribe, unsubscribe} from "../../../Components/Notifications/NotificationContext";


const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);



interface request {
    id: string;
    requestNumber: string;
    cargoDescription: string;
    price : number;
    clientId: string;
    receiverId: string;
    payerId : string;
    distance : number;
    loadingType: number;
    rollingStockType: number;
    requestStatus: number;
    truckId? : string | null;
    truck: any,
    priklopnik : any,
    requestType? : number | null,
    weight : number,
    volume : number,
    priklopnikId? : string | null;
    contractorId? : string;
    
    operatorId? : string | null;
    operator?: any|null;
    priceForClient? : number | null;
    priceToShowToSubcontractors? : number | null;
    
    
    driverId? :  string | null;
    driver : any;
    createdByContractorId : string | null;
    RoutePoints: any[];
}

interface EditReqwestProps {
    RequestId?: string;
    ShowMode? : string;
    
}




interface SuggestedContractor {
    contractorId : string,
    contractor : any,
    driverId : string,
    driver : any,
    fromDateEnd : string | null,
    fromDateStart : string | null,
    id : string,
    price : number,
    priklopnik : any,
    priklopnikId : string,
    requestId : string,
    toDateEnd : string | null,
    toDateStart : string | null,
    track : any,
    truckId : string,
    loading: boolean;
}

export default function EditReply({RequestId, ShowMode} :  EditReqwestProps) {

    const {t} = useTranslation();

    const history= useHistory();
    const user = AuthService.getCurrentUser();
    if(!user){
        AuthService.logout();
        history.push("/login");
        window.location.reload();

    }


    //if(RequestValue===null){
    //    RequestValue=RequestId ? RequestId: null;
   // }
    //RequestValue=RequestId ? RequestId: null;
    //console.log("RequestValue: ", RequestValue);
    //console.log("RequestId: ", RequestId);
    
    

    const[trucks, settrucks] = useState<any[]>([]);
    const[drivers, setdrivers] = useState<any[]>([]);
    const[request, setrequest] = useState<request>({
        id:"",
        requestNumber : "",
        price: 1200,
        distance: 0,
        loadingType: 0,
        rollingStockType: 0,
        requestStatus: 0,
        cargoDescription:"",
        clientId:"",
        receiverId:"",
        payerId : "",
        truckId :null,
        truck: null,
        priklopnik : null,
        requestType : null,
        weight : 0,
        volume : 0,
        priklopnikId :null,
        contractorId : "",
        driverId : null,
        driver : null,
        operatorId : null,
        operator: null,
        priceForClient : null,
        priceToShowToSubcontractors : null,
        createdByContractorId: "",
        RoutePoints: []
    });


    const[editRequestObj, seteditRequestObj] = useState<request>({
        id:"",
        requestNumber : "",
        price: 1200,
        distance: 0,
        loadingType: 0,
        rollingStockType: 0,
        requestStatus: 0,
        cargoDescription:"",
        clientId:"",
        receiverId : "",
        payerId : "",
        truckId :"",
        truck: null,
        priklopnik : null,
        requestType : null,
        weight : 0,
        volume: 0,
        priklopnikId :"",
        contractorId : "",
        driverId : "",
        driver : null,
        createdByContractorId: "",
        RoutePoints: []
    });



    //const ContractorOptions : any[]=[];

    const[pageloading, setPageloading] = useState<boolean>(true);
    
    
    const[ContractorOptions, setContractorOptions] = useState<any[]>([]);
    const[TruckOptions, setTruckOptions] = useState<any[]>([]);
    const[noTrucks, setNoTrucks] = useState(false);
    
    const[PriklopnikOptions, setPriklopnikOptions] = useState<any[]>([]);
    
    const[DriversOptions, setDriversOptions] = useState<any[]>([]);
    const[noDrivers, setNodrivers] = useState(false);

    const[tabTitle, settabTitle] = useState<string>("Namigi: Izvajalec");
    const[_RequestId, _setRequestId] = useState<string>("");

    
    const[initContractor, setinitContractor] = useState<string>("");

    
    const [availableTransport, setavailableTransport] = useState<any[]>([]);
    const [availableDriver, setavailableDriver] = useState<any[]>([]);
    
    const GetDriverOptions =(data: string) => {
        axios.get(AuthService.DATA_URL+'driver/get-for-contractor?ContractorId='+data, {headers: authHeader()})
            .then((response) => {
                //console.log(response);
                response.data.forEach((row: any, i: number) => {
                        if(!DriversOptions.find(o=>o.key===row.id))
                            DriversOptions.push({
                                key : row.id,
                                value: row.name + " " + row.surname,
                                label: row.name + " " + row.surname,
                            });
                });
                setDriversOptions(DriversOptions);
            });



            fetch(AuthService.DATA_URL + 'driver/get-for-request?requestid=' + RequestId + (data ? "&ContractorId=" + data : ""),
                {headers: authHeader()})
                .then((res) => res.json())
                .then((res) => {
                        //console.log("availableDrivers: ", res);
                        setavailableDriver(res);
                });


    }

    const GetTruckOptions = (data : string) =>{
        axios.get(AuthService.DATA_URL+'truck/get-for-contractor?ContractorId='+data, {headers: authHeader()})
            .then((response) => {
                response.data.forEach((row: any, i: number) => {
                    if (row.truckType === 0 || row.truckType === 2) {
                        if(!TruckOptions.find(o=>o.key===row.id))
                            TruckOptions.push({
                                key : row.id,
                                value: row.regNumber,
                                label: row.regNumber,
                                type : row.truckType
                            });
                        trucks.push({
                            id: row.id,
                            truck_type: row.truckType

                        })
                    } else {
                        if(!PriklopnikOptions.find(o=>o.key===row.id))
                            PriklopnikOptions.push({
                                key : row.id,
                                value: row.regNumber,
                                label: row.regNumber,
                            });

                    }
                });
                setTruckOptions(TruckOptions);
                setPriklopnikOptions(PriklopnikOptions);
                //setinitTruck(null);
                //form.setFieldsValue({"mainTruck":null})
            });

        fetch(AuthService.DATA_URL+'truck/get-for-request?requestid='+RequestId+(data ? "&ContractorId="+data : ""),
            {headers: authHeader()})
            .then((res) => res.json())
            .then((res) => {
                availableTransport.length=0;
                //console.log("availableTransport: ", res);
                setavailableTransport(res);
                




            });
    }


    const [form] = Form.useForm();
    
    useEffect(() => {
        if (ContractorOptions.length === 0) {
            axios.get(AuthService.DATA_URL+'Contractor/get-transporters', {headers: authHeader()})
                .then((response) => {
                    if (ContractorOptions.length === 0) {
                        JSON.parse(JSON.stringify(response.data, null, 4)).forEach((row: any, i: number) => {
                            ContractorOptions.push({
                                value: row.id,
                                label: row.title
                            });
                        });
                    }

                });
        }
    }, [RequestId]);
    

    /*
    useEffect(() => {
        if (DriversOptions.length === 0) {
            axios.get(AuthService.DATA_URL+'driver/', {headers: authHeader()})
                .then((response) => {
                    //console.log(response.data);
                    if(response.data.length===0){
                        DriversOptions.push({
                            value: "",
                            label: t("Ni_voznikov"),
                        });
                        setNodrivers(true);
                        //setdrivers(drivers);
                        
                    }
                    setdrivers(JSON.parse(JSON.stringify(response.data, null, 4)));
                    if (DriversOptions.length === 0) {
                        response.data.forEach((drow: any, i: number) => {
                            DriversOptions.push({
                                value: drow.id,
                                label: drow.name + " " + drow.surname,
                            });
                        });
                    }


                });
        }
        else{

        }
    }, [RequestId]);



     */


    const[blockedContractor, setblockedContractor] = useState(false);
    const[blockedVehicle, setblockedVehicle] = useState(false);
    const[blockedDriver, setblockedDriver] = useState(false);
    
    const[requestStatus, setRequestStatus]=useState(0)

    const[allowTransporterCancel, setallowTransporterCancel] = useState(false);


    const[initTruck, setinitTruck] = useState<string | null>(null);
    const[initPriklopnik, setinitPriklopnik] = useState<string>("");
    const[initDriver, setinitDriver] = useState<string>("");


    const[initContractorName, setinitContractorName] = useState<string>("");
    const[initTruckName, setinitTruckName] = useState<string>("");
    const[initTruckType, setinitTruckType] = useState<string>("");
    
    const[initPriklopnikName, setinitPriklopnikName] = useState<string>("");
    const[initDriverName, setinitDriverName] = useState<string>("");

    const[ownContractorId, setOwnContractorId] = useState<string>("");


    const newNotification=(msg: any)=>{
         console.log("I see event!");
        ShowContractorHints();
        // console.log(msg);
        //setReloadNote(msg.detail.messageId);
        //setRR("-")
    }
    
    useEffect(() => {
        //console.log(request)
        setPageloading(true);
        if(request.id===""){
            subscribe("newReply", (data: any) => newNotification(data));

            axios.get(AuthService.DATA_URL+'Request/' + RequestId, {headers: authHeader()})
                .then((response) => {
                    //console.log("response.data: ", response.data)
                    setrequest({
                        id: response.data.id,
                        requestNumber: response.data.requestNumber,
                        price: response.data.price,
                        distance: response.data.distance,
                        loadingType: response.data.loadingType,
                        rollingStockType: response.data.rollingStockType,
                        requestStatus: response.data.requestStatus,
                        clientId: response.data.clientId,
                        receiverId : response.data.receiverId,
                        payerId : response.data.payerId,
                        truckId: response.data.truckId,
                        truck : response.data.track,
                        priklopnik: response.data.priklopnik,
                        requestType: response.data.requestType,
                        priklopnikId: response.data.priklopnikId,
                        contractorId: user.isTansporter? user.contractorId :response.data.contractorId,
                        weight: response.data.weight,
                        volume: response.data.volume,
                        driverId: response.data.driverId,
                        driver : response.data.driver,
                        operatorId : response.data.operatorId,
                        priceForClient: response.data.priceForClient,
                        priceToShowToSubcontractors: response.data.priceToShowToSubcontractors,
                        operator: response.data.operator,
                        createdByContractorId : response.data.createdByContractorId,
                        cargoDescription: response.data.cargoDescription === undefined ? "" : response.data.cargoDescription,
                        RoutePoints: []
                    });
                    _setRequestId(RequestId ? RequestId : "");

                    if(response.data.subContractor){
                        setinitContractor(response.data.contractorId)
                        setinitContractorName(response.data.subContractor.title)
                        GetTruckOptions(response.data.contractorId);
                        GetDriverOptions(response.data.contractorId)
                    }
                    if(response.data.requestType===0) {

                    }
                    if(user.isTransporter && response.data.requestStatus <30){
                        setinitContractor(user.contractorId)
                        setblockedContractor(true);
                        setReqContractor(user.contractorId);
                    }

                    if(response.data.track) {
                        setinitTruck(response.data.track.regNumber);
                    }
                    if(response.data.priklopnik){
                        setinitPriklopnik(response.data.priklopnik.regNumber);
                    }
                    if(response.data.driver){
                        setinitDriver(response.data.driver.name + " " + response.data.driver.surname)
                    }

                    //console.log("response.data: ", response.data)

                    axios.get(AuthService.DATA_URL+'Contractor/get-ownInfo', {headers: authHeader()})
                        .then()
                        .then((oin) => {

                            setRequestStatus(response.data.requestStatus);
                            if(response.data.requestStatus>=30){
                                setblockedContractor(true);
                                setblockedVehicle(true);
                                setblockedDriver(true);
                            }
                            //console.log("OwnContractorId: ", oin);
                            setOwnContractorId(oin.data.contractorId);
                            if(response.data.userId!==oin.data.id){
                                setblockedContractor(true);
                                setallowTransporterCancel(true);
                            }
                            else{
                                if(response.data.requestStatus==20){
                                    setblockedContractor(true);
                                    setblockedVehicle(true);
                                    setblockedDriver(true);
                                    if(response.data.createdByContractorId ===user.contractorId){
                                        setblockedContractor(false);
                                        setblockedVehicle(false);
                                        setblockedDriver(false);
                                        ShowContractorHints();
                                    }

                                }

                            }
                        })

                })
                .catch(function (error) {
                    console.log("error: ", error);
                    if(error.response.status===401){
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                });
            setPageloading(false)
            
        }
        return () => {
            unsubscribe("newReply", (data: any) => newNotification(data));
            //unsubscribe("hideList", () => setReloadNote("true"));
        }
        },[RequestId]);


    
    
    const [returnTo, setreturnTo]=useState<string | undefined>("");
    const [needRacun, setneedRacun]=useState(false);
    const [needCMR, setneedCMR]=useState(false);
    const [needSauftrag, setneedSauftrag]=useState(false);


    const generateDocs=()=>{
        console.log(returnTo);
        console.log(needRacun);
        console.log(needCMR);
        console.log(needSauftrag);
        
        
    }

    const UdateRequestReply=(data: any, _returnTo? : string) =>{

        
        console.log("form-data:", data);
        if(request.contractorId!=ownContractorId){
            //с заказом работает закзчик
            if(request.requestStatus<20){
                //заказчик назначил исполнителя
                request.requestStatus=20
            }
            else{
                //заказ уже подтвержден исполнителем
                if(request.truckId && request.contractorId && request.driverId)
                {
                    request.requestStatus=1
                }           
            }
        }
        else{
            //это исполнитель назначаил машину и водителя
            if(request.truckId && request.contractorId && request.driverId){
                request.requestStatus=30
            }
            else{
                //по идее нельзя пускать без указанной машины и водителя
                //хорошо бы какой-нибудь модал тут поставить и не пускать идти дальше
                setIsStopModalOpen(true)
                return
            }
        }


        var urlString='Request/';
        //if(request.requestType===0){
        //    urlString='Reply/';
        //    console.log("request.requestType", request.requestType)
        //}
        console.log("request for put", request)

        if(request.truckId!=undefined && request.contractorId!=undefined && request.driverId!=undefined){
            //reply.requestStatus=30
        }
        else{
            //по идее нельзя пускать без указанной машины и водителя
            //хорошо бы какой-нибудь модал тут поставить и не пускать идти дальше
            setIsStopModalOpen(true)
            return
        }
        
        
        axios.put(AuthService.DATA_URL+urlString+RequestId,
            request, {headers: authHeader()})
            .then((response) =>{
                    console.log(response.data);


                    axios.get(AuthService.DATA_URL+'Docs/generate-docs-for-request?RequestId='+request.id, {headers: authHeader()})
                        .then((docresponse)=>{
                            console.log(docresponse)
                        })
                    
                    
                //setreturnTo(_returnTo)
                /*
                Modal.success({
                    title: 'Заказ сохранен',
                    okText:'Сгенерировать документы',
                    cancelText: 'Не генерировать',
                    width: '500px',
                    onOk: ()=>generateDocs(),
                    content: 
                    <>
                        <p>Сгенерировать документы?</p>

                            <Checkbox onChange={(e)=> {
                                setneedRacun(e.target.checked)
                            }}> Счет </Checkbox>
                            
                        <Checkbox checked={needSauftrag} onClick={(e:any)=> setneedSauftrag(e.target.checked)}/> Накладная<br/>
                        <Checkbox checked={needCMR} onClick={(e:any)=> setneedCMR(e.target.checked)}/> CRM<br/>
                        <Divider/>
                    </>,
                    footer: () => (
                        <>
                            <Button>fsdfsd</Button>
                            <Button>fsdfsd</Button>
                        </>
                    ),
                });
                    */
                    if(_returnTo===null){
                        history.push("/routes");
                    }
                    else{
                        if(_returnTo==="1"){

                            window.location.reload()
                        }
                        if(_returnTo==="2"){
                            history.push("/routes");
                        }
                    }
                    
                
                
                
                
                
                    if(request.requestStatus==1){
                        //history.push("/routes");
                    }
                    else{
                        //history.push("/routes/view/:?id="+RequestId);
                        //window.location.reload();

                    }
                }
            );
           
    }




    const[displaySuggestedTransport, setdisplaySuggestedTransport] = useState<string>("none");
    const[SuggestedTransportBtnClass, setSuggestedTransportBtnClass] = useState<string>("");

    const[displaySuggestedContractor, setdisplaySuggestedContractor] = useState<string>("none");
    const[SuggestedContractorBtnClass, setSuggestedContractorBtnClass] = useState<string>("");

    const[displaySuggestedDriver, setdisplaySuggestedDriver] = useState<string>("none");
    const[SuggestedDriverBtnClass, setSuggestedDriverBtnClass] = useState<string>("");
    
    const[hints, sethints] = useState<string>("none")

//console.log("rerender");

    const ShowTrucksHints = () =>{
        if(hints=="none"){
            sethints("block");
        }
        else{
           // sethints("none");
        }
        setdisplaySuggestedTransport("block")
        setSuggestedTransportBtnClass("orangeclicked");
        settabTitle(t("Namigi")+": "+t("Vozilo"));

        setSuggestedContractorBtnClass("");
        setSuggestedDriverBtnClass("");

        setdisplaySuggestedContractor("none");
        setdisplaySuggestedDriver("none");
        
    }
    
    
    const ShowContractorHints = () =>{
        console.log("ShowContractorHints", hints)
        if(hints=="none"){
            sethints("block");
        }
        else{
            //sethints("none");
        }

        setdisplaySuggestedContractor("block")
        setSuggestedContractorBtnClass("orangeclicked");
        settabTitle(t("Namigi")+": "+t("Izvajalec"));

        setSuggestedDriverBtnClass("");
        setSuggestedTransportBtnClass("");

        setdisplaySuggestedTransport("none");
        setdisplaySuggestedDriver("none");

    }

    const ShowDriverHints = () =>{
        if(hints=="none"){
            sethints("block");
        }
        else{
            //sethints("none");
        }
        setdisplaySuggestedDriver("block")
        setSuggestedDriverBtnClass("orangeclicked");
        settabTitle(t("Namigi")+": "+t("Voznik"));

        setSuggestedContractorBtnClass("");
        setSuggestedTransportBtnClass("");

        setdisplaySuggestedContractor("none");
        setdisplaySuggestedTransport("none");


    }

    const hideHints = () =>{
        sethints("none");
        setSuggestedContractorBtnClass("");
        setSuggestedTransportBtnClass("");
        setSuggestedDriverBtnClass("");

    }

    const { TabPane } = Tabs;


    const handleChooseVlacilec = useCallback(
        (data: any, type:number) => {
            console.log("handleChooseVlacilec: ", data, type)
            var mmm=TruckOptions.find(t=>t.key===data);
            if(type===0){
                form.setFieldsValue({
                    mainTruck: mmm.value
                })
                setrequest(prev => ({
                    ...prev,
                    truckId: mmm.key
                }));
                setstatusVlacilec("");
                setVlacilecHelp("");
            }
            if(type===1){
                mmm=PriklopnikOptions.find(t=>t.key===data);
                setrequest(prev => ({
                    ...prev,
                    priklopnikId: mmm.key
                }));
                form.setFieldsValue({
                    priklopnik: mmm.value
                })
                setstatusPriklopnik("");
                setPriklopnikHelp("");
            }
            if(type===2){
                form.setFieldsValue({
                    mainTruck: mmm.value
                })
                setrequest(prev => ({
                    ...prev,
                    truckId: mmm.key
                }));

                setrequest(prev => ({
                    ...prev,
                    priklopnikId: ""
                }));
                form.setFieldsValue({
                    priklopnik: null
                })
                setstatusVlacilec("");
                setVlacilecHelp("");
                setstatusPriklopnik("");
                setPriklopnikHelp("");
            }



        },[]);


    const handleChooseVoznik = useCallback(
        (data: any, type:number) => {
            
            //console.log("handleChooseVoznik: ", data, type)
            //console.log("handleChooseVoznik DriversOptions: ", DriversOptions)
            var mmm=DriversOptions.find(t=>t.key===data);
            console.log(mmm);
            form.setFieldsValue({
                driver: mmm.value
            })
            setrequest(prev => ({
                ...prev,
                driverId: mmm.key
            }));
           // setstatusDriver("");
           // setDriverHelp("");
            



        },[]);

    
    
    const [ShowContractorDetails, setShowContractorDetails] = useState(false);
    const [shownReply, setshownReply] = useState<SuggestedContractor>()
    
    
    
    const handleChooseContractor = useCallback(
        (data : SuggestedContractor) => {
            //some code todo
            setshownReply({
                contractorId :data.contractorId,
                contractor : data.contractor,
                driverId : data.driverId,
                driver : data.driver,
                fromDateEnd : "",
                fromDateStart : "",
                id : data.id,
                price : data.price,
                priklopnik : data.priklopnik,
                priklopnikId : data.priklopnikId,
                requestId : "",
                toDateEnd : "",
                toDateStart : "",
                track : data.track,
                truckId : data.truckId,
                loading: false
            });
            
            console.log("handleChooseContractor: ", data)
            console.log("shownReply: ", shownReply);
            setShowContractorDetails(true)
            
            
        }, []
    )


    const [statusPriklopnik, setstatusPriklopnik] = useState<any>("");
    const [statusVlacilec, setstatusVlacilec] = useState<any>("");
    const [statusDriver, setstatusDriver] = useState<any>("");

    const [PriklopnikHelp, setPriklopnikHelp] = useState<any>("");
    const [VlacilecHelp, setVlacilecHelp] = useState<any>("");
    const [DriverHelp, setDriverHelp] = useState<any>("");

//    help

    const setReqContractor =(data:any)=>{
  
        TruckOptions.length=0;
        PriklopnikOptions.length=0;
        DriversOptions.length=0;
        GetTruckOptions(data)
        GetDriverOptions(data)
        //console.log(initTruck);

        setinitTruck(null);
        form.setFieldsValue({
            mainTruck: null
        });
        form.setFieldsValue({
            priklopnik: null
        });
        form.setFieldsValue({
            driver: null
        });
        setrequest(prev => ({
            ...prev,
            contractorId: data
        }));
    }


    const setNewDriver = (value : any):string =>{
        setOpenDriver(false);
        if(value!==""){
            setDriversOptions(prev => ([
                    ...prev,
                    {
                        key : value.data.id,
                        value: value.data.name + " " + value.data.surname,
                        label : value.data.name + " " + value.data.surname
                    }]
            ));

            form.setFieldsValue({
                driver: value.value.data.name + " " + value.data.surname
            });
            setrequest(prev => ({
                ...prev,
                driverId: value.data.id
            }));

        }
        return "";
    }



    const setNewContractor = (value : any):string =>{
        setopenContractor(false);
        if(value!==""){
            setContractorOptions(prev => ([
                    ...prev,
                    {
                        value: value.data.id,
                        label : value.data.title
                    }]
            ));

            form.setFieldsValue({
                contractor: value.data.id
            });
            setrequest(prev => ({
                ...prev,
                contractorId: value.data.id
            }));
        }
        return "";
    }
    
    
    
    const setReqTruck =(data:any)=>{
        var mmm=TruckOptions.find(t=>t.value===data);
        //console.log(data, mmm);
        if(mmm){
            setrequest(prev => ({
                ...prev,
                truckId: mmm.key
            }));
            if(!availableTransport.find(t=>t.vlacilec!=null && t.vlacilec.id===mmm.key)){
                setstatusVlacilec("warning");
                var hr=t("Zaseden_v_teh_dneh")
                setVlacilecHelp(hr);
            }else{
                setstatusVlacilec("");
                setVlacilecHelp("");
            }
            sethints("none");
        }
        else{
            setrequest(prev => ({
                ...prev,
                truckId: null
            }));
        }
    }
    
    
    const setReqPriklopnik =(data:any)=> {
        var mmm=PriklopnikOptions.find(t=>t.value===data);
        console.log(data, mmm);


        console.log("Priklop availableTransport: ", availableTransport)
        if(mmm){
            setrequest(prev => ({
                ...prev,
                priklopnikId: mmm.key
            }));
            if(!availableTransport.find(t=>t.priklopnik!=null && t.priklopnik.id===mmm.key)){
                console.log("no priklops!")
                setstatusPriklopnik("warning");
                console.log("availableTransport: ", availableTransport);
                var hr=t("Zaseden_v_teh_dneh")
                setPriklopnikHelp(hr);
            }else{
                setstatusPriklopnik("");
                setPriklopnikHelp("");
            }
        }
        else{
            setrequest(prev => ({
                ...prev,
                priklopnikId: null
            }));
        }
        


        sethints("none");
    }

    const setReqDriver =(data:any)=> {

        var mmm=DriversOptions.find(t=>t.value===data);
        //console.log(data, mmm);
        if(mmm) {
            setrequest(prev => ({
                ...prev,
                driverId: mmm.key
            }));
            console.log("Priklop availableDrivers: ", availableDriver)

            if(!availableDriver.find(t=>t.id===mmm.key)){
                //console.log("no priklops!")
                setstatusDriver("warning");
                console.log("availableTransport: ", availableTransport);
                var hr=t("Zaseden_v_teh_dneh")
                setDriverHelp(hr);
            }else{
                setstatusDriver("");
                setDriverHelp("");
            }



            sethints("none");

        }
        else{
            setrequest(prev => ({
                ...prev,
                driverId: null
            }));
        }
        sethints("none");
    }



    const [openDriver, setOpenDriver] = useState(false);
    const [openContractor, setopenContractor] = useState(false);

    const showDriverDrawer = () => {
        setOpenDriver(true);
    };
    const showContractorDrawer = () => {
        setopenContractor(true);
    };

    const onClose = () => {
        //один close на все дроеры
        setOpenDriver(false);
        setopenContractor(false);
    };


    const cancelOffer_tr =()=>{

        //Отмена по желанию подрядчика
        console.log("RequestId: ", RequestId)
        axios.post(AuthService.DATA_URL+'Request/set-status?id='+RequestId+"&status=52",
            {},
            {headers: authHeader()})
            .then((response) =>{
                    console.log(response);
                    //history.push("/routes");
                    setblockedDriver(false);
                    setblockedVehicle(false);
                    setblockedContractor(false);
                    history.push("/routes");
                    window.location.reload();

                }
            );
    }



    const cancelOffer =()=>{

        //Отмена по желанию клиента
        console.log("RequestId: ", RequestId)
        axios.post(AuthService.DATA_URL+'Request/set-status?id='+RequestId+"&status=0",
            {},
             {headers: authHeader()})
            .then((response) =>{
                    console.log(response);
                    //history.push("/routes");
                setblockedDriver(false);
                setblockedVehicle(false);
                setblockedContractor(false);
                    history.push("/routes/view/:?id="+RequestId);
                window.location.reload();

                }
            );
    }
    
    




    const [isDocModalOpen, setIsDocModalOpen] = useState(false);

    const [isStopModalOpen, setIsStopModalOpen] = useState(false);


    const [isSauftragModalOpen, setIsSauftragModalOpen] = useState(false);


    

    const [reload, setreload] = useState(false);

    const handleModalCancel = () => {
        setIsDocModalOpen(false);
    };
    const handleDocModalCancel = () => {
        setreload(!reload);
        setIsDocModalOpen(false);
    };
    const showDocModal1 = () => {
        setIsDocModalOpen(true);

    };


    const handleDocModalOk=()=>{
        setreload(!reload);
        setIsDocModalOpen(false);
    }   
    
    
    
    

    if (ContractorOptions.length===0) return <div>Waiting for ContractorOptions data...</div>;
    //if (initContractor==="") return <div>Waiting for initContractor data...</div>;

    //if (TruckOptions.length===0 && !noTrucks) return <div>Waiting for TruckOptions data...</div>;

    //if (PriklopnikOptions.length===0 && !noTrucks) return <div>Waiting for PriklopnikOptions data...</div>;

    //if (DriversOptions.length===0 && !noDrivers ) return <div>Waiting for DriversOptions data...</div>;

    if (request.id==="") return <div>Waiting for request data...</div>;


    if(pageloading) return <div>LoadingPage</div>;



    
    
    const items2: MenuProps['items'] = [
        { key: '40',  label: <span>{t("On_route")}</span> },
        { key: '50',  label: <span>{t("Zaključena")}</span> },
    ]
    const handleLanguageClick: MenuProps['onClick'] = (e) => {
        console.log("statis change: ",e)

        
        axios.post(AuthService.DATA_URL+'Request/set-status?id='+RequestId+"&status="+e.key,
            {},
            {headers: authHeader()})
            .then((response) =>{
                    history.push("/routes/view/"+RequestId);
                    window.location.reload();

                }
            );
            
         
    }
    const menuProps = {
        items2,
        onClick: handleLanguageClick,
    };





    const saveItems: MenuProps['items'] = [
        { key: '1',  label: <span>{t("save_and_stay")}</span> },
        { key: '2',  label: <span>{t("save_and_quit")}</span> },
    ]
    const handleSaveOption: MenuProps['onClick'] = (e) => {
        console.log("statis change: ",e)
        UdateRequestReply(null, e.key)

    }
    const savemenuProps = {
        saveItems,
        onClick: handleSaveOption,
    };





    const truckTypeName =(tt:number)=>{
        switch (tt) {
            case 0: {
                return t('Vlačilec');
                break;
            }
            case 1: {
                return t('Priklopnik');
                break;
            }
            case 2: {
                return t('Tovornjak');
                break;
            }
        }
    }


    const rsTypeName =(tt:number)=>{
        switch (tt) {
            case 0: {
                return t('Odprto');
                break;
            }
            case 1: {
                return t('Nagibno');
                break;
            }
            case 2: {
                return t('Hladnik');
                break;
            }
        }
    }


    const loadingTypeName =(tt:number)=>{
        switch (tt) {
            case 0: {
                return t('Nad');
                break;
            }
            case 1: {
                return t('Zadaj');
                break;
            }
            case 2: {
                return t('Strani');
                break;
            }
        }
    }
    
    const convertBirthdate=(point : string)=>{
        
        return point.substring(8,10)+"."+point.substring(5,7)+"."+point.substring(0,4)
    }

    
    
    const setSelectedContractor=()=>{
        //
        
        setShowContractorDetails(false);

        //Подставим исполнителя
        if(!ContractorOptions.find(t=>t.id===shownReply?.contractor.id)){
        setContractorOptions(prev => ([
                ...prev,
                {
                    value: shownReply?.contractor.id,
                    label : shownReply?.contractor.title
                }]
        ));}
        form.setFieldsValue({
            contractor:shownReply?.contractor.id
        });
        setrequest(prev => ({
            ...prev,
            contractorId: shownReply?.contractor.id
        }));

        //Подставим основное ТС
        if(!TruckOptions.find(t=>t.id===shownReply?.track.id)){
            setTruckOptions(prev => ([
                    ...prev,
                    {
                        value: shownReply?.track.id,
                        label : shownReply?.track.regNumber
                    }]
            ));}
        form.setFieldsValue({
            mainTruck:shownReply?.track.id
        });
        setrequest(prev => ({
            ...prev,
            truckId: shownReply?.track.id
        }));


        //Подставим прицеп
        if(shownReply?.priklopnik){
            if(!PriklopnikOptions.find(t=>t.id===shownReply?.priklopnik.id)){
                setPriklopnikOptions(prev => ([
                        ...prev,
                        {
                            value: shownReply?.priklopnik.id,
                            label : shownReply?.priklopnik.regNumber
                        }]
                ));}
            form.setFieldsValue({
                priklopnik:shownReply?.priklopnik.id
            });
            setrequest(prev => ({
                ...prev,
                priklopnikId: shownReply?.priklopnik.id
            }));
        }

        //Подставим водителя
        if(!DriversOptions.find(t=>t.id===shownReply?.driver.id)){
            setDriversOptions(prev => ([
                    ...prev,
                    {
                        value: shownReply?.driver.id,
                        label : shownReply?.driver.name + " " + shownReply?.driver.surname     //shownReply?.driver.regNumber
                    }]
            ));}
        form.setFieldsValue({
            driver:shownReply?.driver.id
        });
        setrequest(prev => ({
            ...prev,
            driverId: shownReply?.driver.id
        }));
        
        //Подставим цену исполнителя
        setrequest(prev => ({
            ...prev,
            price: shownReply?.price===undefined ? 0 : shownReply?.price
        }));



        console.log(request);
    }




    //console.log("initContractorinitContractorinitContractorinitContractor: ", initContractor)
    //console.log("initContractorNameinitContractorNameinitContractorName: ", initContractorName)

    if(ShowMode==="show"){
     //   console.log("initDriver, initTruck, initPriklopnik:", initDriver, initTruck, initPriklopnik)
    }
    // @ts-ignore
    return (
        <div>
            {ShowMode==="show" ? 
                <div>
                    <Row>
                        <Col span={24} style={{marginBottom: "10px"}}>
                            <span style={{color: "#A4A6AC"}}> {t("Izvajalec")}:</span> <br/> {initContractorName? initContractorName : "-"}</Col>
                    </Row>
                    <Row>
                        <Col span={12} style={{marginBottom: "10px"}}>
                            <span style={{color: "#A4A6AC"}}> {t("Vozilo")}:</span> 
                            <br/>
                            {initTruckType==="0" ? <img src={"/img/traktor.png"} alt={" "} style={{marginRight: "10px", marginTop: "5px"}} /> : <img src={"/img/tovornjak.png"} alt={" "} style={{marginRight: "5px", width: "35px", marginTop: "5px"}} />}
                            {initTruck? initTruck : "-"}
                        </Col>
                        <Col span={12} style={{marginBottom: "10px"}}>
                            <span style={{color: "#A4A6AC"}}> {t("Priklopnik")}:</span>
                            <br/>
                            <img src={"/img/priklopnik.png"} alt={" "} style={{marginRight: "5px", width: "35px", marginTop: "5px"}} />
                            {initPriklopnik? initPriklopnik : "-"}
                        </Col>
                        
                        
                    </Row>
                    <Row>
                        <Col span={24} style={{marginBottom: "10px"}}>
                            <span style={{color: "#A4A6AC"}}> {t("Voznik")}:</span> <br/> {initDriver? initDriver : "-"}</Col>
                    </Row>
                
                    <a  className={"orangeA"} href={"routes/view/:?id="+request.id }>{t("Urejanje_tega_naročila")} &rarr;</a>

                </div> : 
                <div>
                    <Row> 
                        
                    <Col span={13}>
                        
                        <div style={{position: "relative", display:"flex", minHeight: "80vh", paddingTop: "10px", }}>
                                <Card style={{
                                    width : "100%",
                                  borderRadius: "0px 0px 8px 8px", marginTop: "-1px", borderTop: "1px solid #fff"}} >

                                   
                                <Form
                                    form={form}
                                    layout="vertical"
                                    onFinish={UdateRequestReply}
                                    labelAlign="right"
                                    labelWrap
                                    initialValues={
                                        {
                                            ["statusAfter"]: 0,
                                            ["contractor"]: initContractor,
                                            ["mainTruck"]: initTruck,
                                            ["priklopnik"]: initPriklopnik,
                                            ["driver"]: initDriver,
                                        }
                                    }
                                    
                                >
                                    <Divider orientation="left" orientationMargin="0" style={{marginTop: "0px"}}>{t("Izvajalec")}</Divider>
                                    
                                    <FloatLabel label={t("Izvajalec")}>
                                            <Form.Item name="contractor"
                                                       rules={[{ required: true }]}
                                            >
                                                <Select
                                                    style={{ display: "inline-table", height: "50px"}}

                                                    disabled ={blockedContractor}
                                                    //options={ContractorOptions.map((item) => ({ label: item.id, value: item.id }))} 
                                                    options ={ContractorOptions}
                                                    allowClear={true}
                                                    //value={initContractor}
                                                    defaultValue={initContractor}
                                                    //style={{width:"262px"}}
                                                    onClick={()=>ShowContractorHints()}
                                                    onChange={(e)=>setReqContractor(e)}
                                                    dropdownRender={(menu) => (
                                                        <>
                                                            {menu}
                                                            <Divider style={{ margin: '8px 0' }} />
                                                            <div style={{width: "100%", textAlign: "center"}}>
                                                                <a onClick={showContractorDrawer}>{t("Ustvarite_novo_podjetje")}</a>
                                                            </div>
                                                        </> 
                                                    )}
                                                    //defaultValue={form.getFieldsValue().truckType}
                                                /> 
                                            </Form.Item>
                                </FloatLabel>
                                    {/* 
                                            <Button  className={SuggestedContractorBtnClass}  onClick={()=>ShowContractorHints()}>?</Button>
                        */}
                    



                                    <Divider orientation="left" orientationMargin="0" style={{marginTop: "0px"}}>{t("Vozilo")}</Divider>

                                  
                                   
                                        <Row gutter={0} style={{ width: '100%'}}>
                                            <Col span={12}>
                                                <FloatLabel label={t("Vlačilec")} name="mainTruck">
                                                <Form.Item 
                                                name="mainTruck"
                                                help={VlacilecHelp}
                                                //rules={[{ required: true }]}
                                                style={{ display: 'inline-block', width: '100%' }}
                                            >
                                                

                                                <Select className={"bordered-left"}
                                                    style={{ display: "inline-table", height: "50px"}}
                                                    options={TruckOptions.map((truck) => ({ label: truck.label, value: truck.value }))}
                                                    onClick={()=>ShowTrucksHints()}
                                                    onChange={(e)=>setReqTruck(e)}
                                                        allowClear={true}
                                                    status={statusVlacilec} 
                                                        defaultValue={initTruck}
                                                        disabled={blockedVehicle}
                                                        

                                                    //defaultValue={form.getFieldsValue().truckType}
                                                />
                                            </Form.Item>
                                        </FloatLabel>
                                            </Col>
                                            <Col span={12}>
                                                <FloatLabel label={t("Priklopnik")}>
                                            <Form.Item
                                                name="priklopnik"
                                                rules={[{ required: false }]}
                                                help={PriklopnikHelp}
                                                style={{ display: 'inline-block', borderRight: "0px",  width: '100%'/*, marginLeft: '8px'*/ }}
                                            >
                                                <Select className={"bordered-right"}
                                                       // labelInValue={true}
                                                    style={{ display: "inline-table", height: "50px", borderRadius: "0px"}}
                                                    options={PriklopnikOptions.map((truck) => ({ label: truck.label, value: truck.value }))}
                                                    onClick={()=>ShowTrucksHints()}
                                                    onChange={(e)=>setReqPriklopnik(e)}
                                                        allowClear={true}
                                                    status={statusPriklopnik}
                                                        defaultValue={initPriklopnik}
                                                        disabled={blockedVehicle}


                                                    //disabled={disabledPriklopnik}
                                                    //defaultValue={form.getFieldsValue().truckType}
                                                />

                                            </Form.Item>
                                                </FloatLabel>
                                            </Col>
                                            {/* 
                                            <Col span={2}>
                                            <Button className={SuggestedTransportBtnClass}   onClick={()=>ShowTrucksHints()}>?</Button>
                                            </Col>
                                            */}
                                            </Row>
                                   

                                    <Divider orientation="left" orientationMargin="0" style={{marginTop: "0px"}}>{t("Voznik")}</Divider>
                                    <FloatLabel  label={t("Voznik")}>
                                            <Form.Item name="driver"
                                                       help={DriverHelp}

                                            >
                                                <Select
                                                    options={DriversOptions.map((truck) => ({ label: truck.label, value: truck.value }))}

                                                   // options ={DriversOptions}
                                                        defaultValue={initDriver}
                                                        status={statusDriver}
                                                        style={{ display: "inline-table", height: "50px", borderRadius: "0px"}}
                                                        onClick={()=>ShowDriverHints()}
                                                        onChange={(e)=>setReqDriver(e)}
                                                    allowClear={true}
                                                    disabled={blockedDriver}

                                                />
                                            </Form.Item>
                                    </FloatLabel>

                                    {requestStatus===20 && blockedVehicle ? 
                                        <div>
                                            {t("Waiting_confiramation_text")}
                                            <br/><br/>
                                            {t("Wish_change_contractor")}
                                            <Form.Item>
                                                <Button onClick={cancelOffer}
                                                        style={{ marginTop:"25px", /*marginRight:"50px",*/ }}>
                                                    {t("Cancel_offer")}
                                                </Button>

                                            </Form.Item>
                                        </div>: ""
                                    }
                                    {requestStatus===52 ?
                                        <div>
                                            <span style={{color: "#E3601D"}}>{t("Canceled_by_contractor_text")}</span>
                                            <br/><br/>
                                            {t("Wish_change_contractor")}
                                            <Form.Item>
                                                <Button onClick={cancelOffer}
                                                        style={{ marginTop:"25px", /*marginRight:"50px",*/ }}>
                                                    {t("Cancel_offer")}
                                                </Button>

                                            </Form.Item>
                                        </div>: ""
                                    }
                                    {requestStatus===30 || requestStatus===40 ? 
                                        <div>
                                        <span>{t("Izpolnjevanje_naročila_v_teku")}</span> 
                                        <br/><br/>
                                        <Form.Item>
                                            <Dropdown menu={{'items': items2, onClick:handleLanguageClick}} >
                                                <Button className={"orangeButton"} >
                                                    {t("Status_spremembe")}
                                                    <DownOutlined />
                                                </Button>
                                            </Dropdown>
                                            
                                        <Button className={"emptyButton"} style={{float: "right"}} onClick={()=>{history.push("/routes")}}>{t("Nazaj_na_seznam")}</Button>

                                        </Form.Item>
                                        </div> : ""
                                        

                                    }
                                    {requestStatus===50 ? 
                                        <div>{t("Zaključena")}
                                            <br/><br/>
                                            <Button className={"emptyButton"} style={{float: "right"}} onClick={()=>{history.push("/routes")}}>{t("Nazaj_na_seznam")}
                                            </Button>
                                        </div> : ""}
                                    
                                    {
                                        
                                        !blockedVehicle? 
                                           
                                            <div>

                                                <Form.Item>

                                                    <Dropdown menu={{'items': saveItems, onClick: handleSaveOption }} >
                                                        <Button className={"orangeButton"}
                                                                style={{ marginTop:"25px", /*marginRight:"50px",*/ }}>
                                                            {t("Shraniti")}

                                                            <DownOutlined />
                                                        </Button>
                                                    </Dropdown>





                                                    {/* 
                                                            <Button className={"orangeButton"}   htmlType="submit"
                                                                    style={{ marginTop:"25px", }}>
                                                                {t("Shraniti")}
                                                            </Button>
                                                */}





                                                    {allowTransporterCancel ?
                                                        <Button className={"emptyButton"} onClick={cancelOffer_tr}
                                                                style={{ marginTop:"25px", float: "right" /*marginRight:"50px",*/ }}>
                                                            {t("Cancel_offer_tr")}
                                                        </Button>
                                                        :""}

                                                </Form.Item>

                                            </div> :  ""
                                    }
                                            
                               

                                        </Form>
                                        
                                    
                                            
                                   
                            </Card>
                        </div>
                       

                    </Col>
                    <Col span={10}>
                        <Card style={{backgroundColor: "#f4f6f9", border: "none", }}>
                            <div style={{width: "320px",  display: hints}}>
                                <Tabs tabBarExtraContent={
                                    <a onClick={
                                        hideHints
                                        /*  ()=> {
                                          sethints("none")
                                      }*/}
                                    > <CloseCircleOutlined /> {t("Skrijte")}</a>
                                }>
                                    <TabPane tab={tabTitle} key="1">
                                        <div style={{display: displaySuggestedTransport}}> 
                                            <SuggestedTransport requestId={RequestId ? RequestId : null} contractorId={request.contractorId ? request.contractorId : undefined} onVlacilecChoice={handleChooseVlacilec}  />
                                            
                                        </div>
                                        <div style={{display: displaySuggestedContractor}}>
                                            <SuggestedContractor requestId={RequestId ? RequestId : null} contractorId={request.contractorId ? request.contractorId : undefined} onContractorChoice={handleChooseContractor}  />
                                            
                                        </div>
                                        <div style={{display: displaySuggestedDriver}}>
                                            
                                            <SuggestedDrivers requestId={RequestId ? RequestId : null} contractorId={request.contractorId ? request.contractorId : undefined} onVlacilecChoice={handleChooseVoznik}  />
                                            
                                        </div>

                                    </TabPane>
                                </Tabs>


                            </div>
                            <div style={{width: "320px",  display: (hints==="none"? "block":"none")}}>

                                <Divider orientation="left" orientationMargin="10" style={{marginTop: "0px"}}>{t("Dokumenti")}</Divider>

                                {/* 
                               
                                <Button onClick={()=>{setIsSauftragModalOpen(true)}} style={{
                                    border: "1px dashed #d9d9d9",
                                    borderTopLeftRadius: "5px",
                                    borderBottomLeftRadius: "5px",
                                    //borderRight: "0px",
                                    height: "40px",
                                    //width: "100%",
                                    fontSize: "14px",
                                    color: "#1449AE",
                                    marginTop: "10px",
                                    marginBottom: "8px",
                                    display: "block",
                                    padding: "8px 5px"}}>
                                    Sauftrag
                                </Button>

                                {
                                    request.truckId!==null ?
                                        <div
                                            style={{
                                                backgroundColor: "#fff",
                                                border: "1px dashed #d9d9d9",
                                                borderTopLeftRadius: "5px",
                                                borderBottomLeftRadius: "5px",
                                                //borderRight: "0px",
                                                height: "40px",
                                                //width: "100%",
                                                fontSize: "14px",
                                                color: "#1449AE",
                                                marginTop: "10px",
                                                marginBottom: "8px",
                                                display: "block",
                                                padding: "8px 5px"}}>
                                            <FileTextOutlined style={{color: "#1449AE", fontSize: "16px", padding: "0px 5px"}}/>

                                            <a href={"/?sauf="+RequestId} target={"_blank"}>Speditionsauftrag</a>
                                        </div>
                                        : ""
                                }

                                */}


                                
                                <DocList RequestId={  RequestId ? RequestId.toString() : undefined} reload={reload} shortNames={true} hideFileNames={true} />
                                
                                
                                <Button onClick={showDocModal1} style={{
                                    border: "1px dashed #d9d9d9",
                                    borderTopLeftRadius: "5px",
                                    borderBottomLeftRadius: "5px",
                                    //borderRight: "0px",
                                    height: "40px",
                                    //width: "100%",
                                    fontSize: "14px",
                                    color: "#1449AE",
                                    marginTop: "10px",
                                    marginBottom: "8px",
                                    padding: "8px 5px"}}>
                                    + {t("Prenesite_dokument")}
                                </Button>
                                <Modal open={isDocModalOpen}
                                       onOk={handleDocModalOk}
                                       title={t("Prenesite_dokument")}
                                       centered
                                       width={580}
                                       onCancel={handleDocModalCancel}
                                    //footer={[]}
                                >

                                    <UploadDoc objectId={RequestId ? RequestId.toString() : null} objectMode={1}/>
                                </Modal>


                            </div>







                        </Card>


                    </Col>
                    </Row>
                </div>
            }



            <Drawer title="Ustvarite novega voznika" placement="right" onClose={onClose} open={openDriver}>
               <CreateDriver afterCreate={setNewDriver} />
            </Drawer>
            <Drawer title="Ustvarite novo podjetje" placement="right" onClose={onClose} open={openContractor}>
                <CreateContractor afterCreate={setNewContractor}/>
            </Drawer>



            
            <Modal title={t("Ne_morem_shraniti")} open={isStopModalOpen}
                   footer={(_, { OkBtn }) => (
                       <>
                           <OkBtn />
                       </>
                   )}
                   onOk={()=>{setIsStopModalOpen(false)}}
                   onCancel={()=>{setIsStopModalOpen(false)}}>
                <p>{t("Navesti_je_treba_vozilo_in_voznika")}</p>
               
            </Modal>


            <Modal title={t("Izvajalec")}
                   okButtonProps={{ className: "orangeButton" }}
                   cancelButtonProps={{ className: "emptyButton", style : {float:"left"}}}
                   open={ShowContractorDetails}
                   okText={t('Select_this_offer')}
                   cancelText={t('Close_this_info')}
                   width={650}
                   footer={(_, { OkBtn, CancelBtn }) => (
                       <>
                           <CancelBtn/>
                           <OkBtn  />
                       </>
                   )}
                   onOk={()=>{setSelectedContractor()}}
                   onCancel={()=>{setShowContractorDetails(false)}}>

                {shownReply===undefined ?
                    <p></p>
                : 
                    <div>
                        <Divider/>
                     
                            <b>{ shownReply.contractor.title}</b><br/>
                            TAX ID: {shownReply.contractor.davcnaStevilka}<br/>
                            IBAN: {shownReply.contractor.iban}<br/>
                            SWIFT: {shownReply.contractor.swift}<br/>
                            {shownReply.contractor.adress1+" "+shownReply.contractor.adress2}<br/>
                     
                        <Divider/>
                        <p>
                            {shownReply.track.truckType==2 ?
                                <img src={"/img/tovornjak.png"} alt={" "} style={{height: "15px"}} />
                                :
                                <img src={"/img/traktor.png"} alt={" "} style={{height: "15px"}}/>}
                            { " "+ shownReply.track.regNumber +" "+ shownReply.track.brand+" "+ shownReply.track.model}
                        <br/>
                            <span style={{color: "#999"}}>{t("Vrsta_transporta")} : </span>
                            {truckTypeName(shownReply.track.truckType)} <span> | </span>

                            <span style={{color: "#999"}}>{t("Vrsta_telesa")} : </span>
                            {rsTypeName(shownReply.track.rollingStockType)} <span> | </span>

                            <span style={{color: "#999"}}>{t("Vrsta_nakladanja")} : </span>
                            {loadingTypeName(shownReply.track.loadingType)} 
                        
                        </p>
                        <div>
                            {shownReply.priklopnik ?
                                <div>
                                    <img src={"/img/priklopnik.png"} alt={" "}  style={{height: "15px"}} />
                                    {" "+ shownReply.priklopnik.regNumber+" "+ shownReply.priklopnik.brand+" "+ shownReply.priklopnik.model}
                                    <br/>
                                    <span style={{color: "#999"}}>{t("Vrsta_transporta")} : </span>
                                    {truckTypeName(shownReply.priklopnik.truckType)} <span> | </span>

                                    <span style={{color: "#999"}}>{t("Vrsta_telesa")} : </span>
                                    {rsTypeName(shownReply.priklopnik.rollingStockType)} <span> | </span>

                                    <span style={{color: "#999"}}>{t("Vrsta_nakladanja")} : </span>
                                    {loadingTypeName(shownReply.priklopnik.loadingType)}
                                </div> : ""

                            }

                        </div>
                        <Divider/>
                        <p>
                            <span style={{color: "#999"}}>{t("Voznik")}</span> :  {shownReply.driver.name+ " "+ shownReply.driver.surname} <br/>
                            <span style={{color: "#999"}}>{t("Datum_rojstva")}</span> :  {convertBirthdate(shownReply.driver.birthdate)}<br/>
                            <span style={{color: "#999"}}>{t("Vozniško_dovoljenje")}</span> :  {shownReply.driver.licenseNumber}
                        </p>
                        <Divider/>
                    </div>
                    
                    
                    
                    
                    
                    
                }
                <p></p>


            </Modal>






        </div>




    )
    
}