import React from "react";
import CreateContractor from "./ContractorsComponents/create";


export default function CreateContractorPage() {

    return(
        
        <div>
            <CreateContractor/>
        </div>
    )
}
