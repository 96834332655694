import i18n from "i18next";
import {initReactI18next} from "react-i18next";


i18n.use(initReactI18next).init({
    resources: {
        en:{
            translation: {
                Currency: "Currency",
                Unit: "Unit",
                CurrentStock: "Current stock level",
                Cost: "",
                Price: "",
                Products: "Products",
                Article_not_specified: "Article not specified",
                Upload: "Upload",
                Download: "Download",
                Schedule_payment_to_contractor: "Schedule payment to the contractor",
                Article: "Article",
                Description: "Description",
                Scheduled: "Scheduled",
                Attach_scan: "Attach_scan",
                Planner: "Planner",
                Statement: "Statement",
                Create_invoice: "Create invoice",
                Schedule_receipt: "Schedule payment",
                Goods_and_services_on_order: "Goods and services on order",
                Subscription_fee: "Subscription fee",
                Scenario : "Scenario",
                Created:"Order created",
                Modified:"Changes made",
                DocAdded:"Document added",
                IncomePlanned:"Income is planned",
                ExpencePlanned:"Payment is planned",
                IncomeHappened:"Income received",
                ExpenceHappened:"Payment made",
                StatusModified:"Status changed",
                Taxes: "Taxes",
                Profit_withdrawal : "Profit withdrawal",
                Narocila: "Orders",
                Contractor: "Contractor",
                Status: "Status",
                Employee: "Employee",
                Employees: "Employees",
                Deals: "Deals",
                Deal: "Deal",
                Accept: "Accept",
                Expenses_by_item: "Expenses by item",
                Date: "Date",
                Amount: "Amount",
                Cas_injection: "Cas_injection",
                Revenue: "Revenue",
                Rent: "Rent",
                Salary: "Salary",
                Payment_for_services: "Payment for services",
                Purchase_of_goods: "Purchase of goods",
                Other_expenses: "Other expenses",
                Account: "Account",
                Incomes: "Incomes",
                Expenses: "Expenses",
                Balance: "Balance",
                Income: "Income",
                Expense: "Expense",
                Show_charts: "Show charts",
                Reports: "Reports",
                Consider_planned: "Take into account planned operations",
                DDS_Rep: "Cash flow statement",
                DDS_report: "Cash flow statement",
                January: "January",
                February: "February",
                March: "March",
                April: "April",
                May: "May",
                June: "June",
                July: "July",
                August: "August",
                September: "September",
                October: "October",
                November: "November",
                December: "December",
                from: "from",
                Obvezno_polje: "Required field",
                Podatki_dokumenta: "Document data",
                Cena_stranke: "Price for client",
                Cena_izvajalca: "Price for performer",
                Delete: "Delete",
                Delete_warning: "Are you sure delete this partner?",
                Delete_text :"Once deleted, you will no longer be able to select this partner as a customer or performer for orders",
                Yes: "Yes",
                No: "No",
                Delete_error_title: "Can\'t delete",
                Contractor_delete_error_text: "First you need to delete all orders and documents that have been created for this partner.",
                Done: "Done!",
                Kontaktna_oseba:"Contact Person",
                Telefon:"Phone",
                Faks:"Fax",
                Stranka_ali_izvajalec:"Client or contractor?",
                Stranka:"Client",
                BackToList : "Back to list",
                Predogled: "Preview",
                Preneseno: "Uoloaded",
                Generirano: "Generated",
                Finances: "Finances",
                invite_message: "To register in the application, please enter the invitation code:",
                invite_mailto: "To receive an invitation code, please send a request to ",
                invite_error: "Alas, the code doesn't work",
                Davek_za_DDV: "VAT Amount",
                ID_za_DDV: "VAT ID",
                Hvala_za_zaupanje: "Thank you for your trust",
                Znesek_prosimo_plačajte_na: "Please pay the amount to",
                Ob_plačilu_navedite_referenco: "Please provide a reference when making payment",
                Skupaj : "Total",
                Dokument : 'Document',
                CMR:"CMR",
                Show_finished: "Show finished",
                save_and_stay: "Save and continue editing",
                save_and_quit: "Save and go to list",
                save_and_choose_performer : "Save and procced to performer selection", 
                here: "here",
                agree_to_treat1 : "I agree to FinTim communicating its products and offers to me by email. I can withdraw my consent at any time.",
                agree_to_treat2 : "I agree that FinTim collects, stores and uses my personal data (for the full use of the various services provided by the site). I can withdraw my consent at any time. The privacy policy is available ",
                Offer_is_sent:"Offer is sent",
                Offers_received: "Offers received",
                Close_this_info:"Close this info",
                Select_this_offer:"Select this offer",
                Obračunavanje: "Billing",
                Seznam_narocil: "My orders",
                Odprte_narocila : "Open order exchange",
                Seznam_voznikov: "List of drivers",
                Zadnje_mesto_razkladanja: "The last unloading point",
                Navesti_je_treba_vozilo_in_voznika: "Vehicle and driver must be specified!",
                Ne_morem_shraniti: "Can't save",
                Canceled_by_contractor_text: "The contractor refused to fulfill the order",
                Izpolnjevanje_naročila_v_teku : "Order fulfillment in progress",
                Status_spremembe: "Change status",
                Nazaj_na_seznam : "Back to list",
                Waiting_confiramation_text: "Waiting for confirmation from the contractor",
                Wish_change_contractor: "If you want to appoint another contractor, you can cancel the offer.",
                Cancel_offer_tr : "Reject the offer",
                Cancel_offer: "Cancel offer",
                Zaseden_v_teh_dneh:"Occupied these days",
                Namigi : "Hints",
                Ni_voznikov: "No drivers",
                Register: "Register",
                Log_in : "Login",
                Password : "Password",
                repeat_Password : "Repeat password",
                Company_name: "Company name",
                Predstavljam_prevozno_prevozniško_podjetje : "I represent transport/carrier company",
                Želim_prejemati_javne_ponudbe_za_prevoz : "I want to receive public offers for transportation",
                Predstavljam_pošiljatelja_špediterja: "I represent shipper/forwarder company",
                Želim_imeti_dostop_do_razpoložljivih_prevoznikov : "I want to have acces to available carriers",
                Please_input_your_Username : "Please input your Username!",
                Please_input_your_Email: "Please input a valid email",
                Please_input_your_Password : "Please input your Password!",
                Please_input_your_Companyname : "Please input your company name!",
                Remember_me : "Remember me",
                Forgot_password : "Forgot password",
                Or: "Or ",
                register_now: "register now!",
                Reason: "Reason",
                Dopust : 'Vacation',
                Bolniški_dopust: 'Sick leave',
                welcome : 'Welcome',
                Voznik : 'Driver',
                Povprečna_vrednost_naročila : 'Average order price',
                Število_naročil: 'Quantity of orders',
                Zasedenost_voznega_parka : 'Fleet workload' ,
                Zasedenost_voznikov : 'Drivers workload',
                Naročila_na_danes: 'Today\'s orders',
                Številka :'Number',
                Itinerarij : 'Route',
                Izvajalec : "Performer",
                Cena : 'Price',
                Vozniki : 'Drivers',
                Plačila : 'Payements',
                Poglejte_vse : 'See all',
                Vsi_vozniki_so_pripravljeni_za_delo : 'All drivers are occupied',
                Več : 'More',
                Načrtovano : 'Planned',
                Novo : 'New',
                Izberite : 'Choose',
                Domov : 'Home',
                Naročila_in_poti : 'Routes',
                Vozila : 'Fleet',
                Dokumenti : 'Documents',
                Stranke :' Partners',
                Nastavitve :'Settings',
                Odjava : 'Exit',
                Urejanje_voznika : 'Edit Driver',
                Dodajanje_naročila : 'Add new order',
                Načrtovanje_poti: 'Route planning',
                Urejanje_naročila : 'Edit order',
                Vsa : 'All',
                Nova: 'New',
                Načrtovana: 'Planning',
                Confirmed: 'Confirmed',
                On_route : 'In progress',
                Zaključena: 'Finished',
                Novo_naročilo : 'New order',
                Prenesite_v_Excel :'Download Excel',
                Kliknite_za_razvrščanje_padajoče : 'Click to sort ascending',
                Kliknite_za_razvrščanje_naraščajoče : 'Click to sort descending',
                Prekliči_razvrščanje : 'Cancel sorting',
                nedatirano: 'no date',
                Od_datum :'From date',
                Do_datum: 'To date',
                Išči: 'Search..',
                Osnovni_podatki:'General info',
                Pošiljatelj: 'Sender',
                Ustvarite_novo_podjetje: 'Add new company',
                Prejemnik : 'Receiver',
                Plačnik : 'Payer',
                Opis_tovora: 'Cargo description',
                Teža :'Weight',
                Volumen: 'Volume',
                Opis: 'Info',
                Nakladanje : 'Loading',
                Razkladanje : 'Unloading',
                Dodajanje_točke_poti :'Add route point',
                Shranjevanje_naročila : 'Save order',
                Vozilo : 'Vehicle',
                Dodajanje_novega_voznika:'Add a new employee',
                Shraniti :'Save',
                Skrijte : 'Hide',
                Prenesite_dokument: 'Upload document',
                Naročilo :'Order',
                Potrebno_je_potrdilo:'Confirmation is needed',
                Nadaljevanje : 'Continue',
                Nazaj : 'Back',
                Če_ste_dodelili_izvajalca_bo_ta_ob_urejanju_naročila_ponastavljen : 'If you have already assigned the perfomer, it will be unassigned',
                Dodajanje_vozila : 'Add new vehicle',
                Seznam_vozil : 'List of vehicles',
                Koledar : 'Calendar',
                Reg_številka: 'Reg. number',
                Znamka_Model : 'Brand/Model',
                Znamka : "Brand",
                Model : 'Model',
                Vrsta_transporta : 'Transport type',
                Vrsta_telesa : 'Rollingstock type',
                Vrsta_nakladanja : 'Loading type',
                Vlačilec :'Hauler',
                Priklopnik : 'Trailer',
                Tovornjak : 'Truck',
                Nagibno : 'Tented',
                Odprto :'Open',
                Hladnik : 'Refrigerated',
                Nad : 'Up',
                Zadaj :'Rear',
                Strani : 'Side',
                Vse_vrste_transporta : 'All transport types',
                Vse_tipe_telesa : 'All rollingstock types',
                Vse_vrste_nakladanja : 'All loading types',
                Po : 'Mon',
                To : 'Tue',
                Sr : 'Wed',
                Če : 'Thu',
                Pe : 'Fri',
                So : 'Sat',
                Ne : 'Sun',
                Urejanje_tega_naročila : 'Edit this order',
                Ne_shranite_sprememb : 'Don\'t save',
                Podatki_o_vozilu : 'Vehicle details',
                Zgodovina_opravljenih_letov : 'History of orders',
                Ključni_kazalniki : 'Key indicators',
                Datum_rojstva : 'Birth date',
                Vozniško_dovoljenje : 'License Number',
                Novi_voznik: 'Add a new employee',
                Ime : 'Name',
                Priimek : 'Surname',
                Vrsta_ddokumenta : 'Document type',
                Na_kaj_se_nanaša : 'Refers to',
                Ime_datoteke : 'Filename',
                Vrsta : 'Type',
                Novi_dokument : 'Add new document',
                Nov_dokument : 'New document',
                Račun : 'Invoice',
                Predračun : 'Estimate',
                Za_naročilo : 'For order',
                Nasprotna_stranka : 'Counterpart',
                Datum_dokumenta: 'Documents\'s date',
                Datum_plačila : 'Paymenet\'s date',
                Tiskanje : 'Print',
                Prevoz_blaga_po_naročilu : 'Goods and services on order',
                Naziv_storitva_blaga : 'Product or service',
                Količina : 'Quantity',
                Davek : 'Taxe',
                Dodajte_vrstico : 'Add position',
                Stranke_in_partnerji : 'Clients and partners',
                Naziv : 'Title',
                Davčna_Številka : 'Tax Id',
                Matična_Številka : 'Company Id',
                Naslov : 'Address',
                Nov_partner : 'New partner',
                Urejanje_podatkov_o_partnerju : 'Edit contractor\'s data',
                Podatki_o_partnerju : 'Contractor\'s data',
                Nastavitve_uporabnika : 'User\'s settings',
                Nastavitve_podjetja : 'Company settings',
                Prenesite_sliko : 'Upload image',
                Prenesete_lahko_slike_velikosti_do : 'Please upload image not greater than',
                E_pošta : 'Email',
                Login : 'Login',
                Staro_geslo : 'Old password',
                Novo_geslo: 'New password',
                Žig : 'Stamp',
                Podpis :'Signature',
                Prenesite_datoteko_ali_povlecite : 'Upload file or drag',
                in_spusti_na_označeno_območje : 'and drop it in this area',
                ne_več_kot : 'not greater than ',
                Tehnični_potni_list : 'Technical passport',
                Zavarovanje : 'Insurance',
                Drug_dokument : 'Another document',
                Pogodba : 'Agreemenent'

            }
        },
        si: {
            translation: {
                Currency: "Valuta",
                Unit: "Enota",
                CurrentStock: "Trenutna raven zalog",
                Cost: "Stroški",
                Price: "Cena",
                Products: "Izdelki",
                Article_not_specified: "Člen ni naveden",
                Upload: "Naložite",
                Download: "Prenesite",
                Schedule_payment_to_contractor: "Razporeditev plačila izvajalcu",
                Article: "Člen",
                Description: "Opis",
                Scheduled: "Načrtovana",
                Attach_scan: "Priložite skeniranje",
                Planner: "Načrtovalec",
                Statement: "Izpiski",
                Create_invoice: "Ustvari račun",
                Schedule_receipt: "Načrtovanje plačila",
                Goods_and_services_on_order: "Naročeno blago in storitve",
                Subscription_fee: "Naročnina",
                Scenario : "Scenarij",
                Created:"Ustvarjeno naročilo",
                Modified:"Izvedene spremembe",
                DocAdded:"Dodan dokument",
                IncomePlanned:"Načrtovan je prihodek",
                ExpencePlanned:"Načrtovano je plačilo",
                IncomeHappened:"Prejeti prihodki",
                ExpenceHappened:"Izvedeno plačilo",
                StatusModified:"Sprememba statusa",
                Taxes: "Davki",
                Profit_withdrawal : "Odvzem dobička",
                Narocila: "Naročila",
                Contractor: "Izvajalsko podjetje",
                Status: "Status",
                Employee: "Zaposleni",
                Employees: "Zaposleni",
                Deals: "Ponudbe",
                Deal: "Ponudba",
                Accept: "Sprejmite",
                Expenses_by_item: "Odhodki po postavkah",
                Date: "Datum",
                Amount: "Znesek",
                Cas_injection: "Denarna injekcija",
                Revenue: "Prihodki",
                Rent: "Najemnina",
                Salary: "Plače",
                Payment_for_services: "Plačilo za storitve",
                Purchase_of_goods: "Nakup blaga",
                Other_expenses: "Drugi stroški",
                Account: "Račun",
                Incomes: "Prihodki",
                Expenses: "Odhodki",
                Balance: "Saldo",
                Income: "Prihodek",
                Expense: "Odhodek",
                Show_charts: "Prikaži grafikone",
                Reports: "Poročila",
                Consider_planned: "Obračunavanje načrtovanih operacij",
                DDS_Rep: "Izkaz denarnih tokov",
                DDS_report: "Izkaz denarnih tokov",
                January: "Januar",
                February: "Februar",
                March: "Marec",
                April: "April",
                May: "Maj",
                June: "Junij",
                July: "Julij",
                August: "Avgust",
                September: "September",
                October: "Oktober",
                November: "November",
                December: "December",
                from: "od",
                Obvezno_polje: "Obvezno polje",
                Podatki_dokumenta: "Podatki o dokumentu",
                Cena_stranke: "Cena za stranko",
                Cena_izvajalca: "Cena za izvajalca",
                Delete: "Izbriši",
                Delete_warning: "Ste prepričani, da izbrišete tega partnerja?",
                Delete_text :"Ko ga izbrišete, tega partnerja ne boste mogli več izbrati kot stranko ali izvajalca naročil.",
                Yes: "Da",
                No: "Ne",
                Delete_error_title: "Ne morem izbrisati",
                Contractor_delete_error_text: "Najprej morate izbrisati vsa naročila in dokumente, ki so bili ustvarjeni za tega partnerja.",
                Done: "Opravljeno!",
                Kontaktna_oseba:"Kontaktna oseba",
                Telefon:"Telefon",
                Faks:"Faks",
                Stranka_ali_izvajalec:"Stranka ali izvajalec?",
                Stranka:"Stranka",
                BackToList : "Nazaj na seznam",
                Predogled: "Predogled",
                Preneseno: "Preneseno",
                Generirano: "Generirano",
                Finances: "Finance",
                invite_message: "Če se želite registrirati v aplikaciji, vnesite kodo vabila:",
                invite_mailto: "Če želite prejeti kodo vabila, pošljite zahtevek na naslov ",
                invite_error: "Žal se koda ne ujema",
                Davek_za_DDV: "Davek za DDV",
                ID_za_DDV: "ID za DDV",
                Hvala_za_zaupanje: "Hvala zazaupanje",
                Znesek_prosimo_plačajte_na: "Znesek prosimo plačajte na",
                Ob_plačilu_navedite_referenco: "Ob plačilu navedite referenco", 
                Skupaj : "Skupaj",
                Dokument : 'Dokument',
                CMR:"CMR",
                Show_finished: "Prikaži zaključeno",
                save_and_stay: "Shranite in nadaljujte z urejanjem",
                save_and_quit: "Shranite in pojdite na seznam",
                save_and_choose_performer : "Shranite in preidite na izbiro izvajalca",
                here : "tukaj",
                agree_to_treat1 : "Strinjam se, da mi FinTim sporoča svoje izdelke in ponudbe po elektronski pošti. Svoje soglasje lahko kadar koli prekličem.",
                agree_to_treat2 : "Strinjam se, da FinTim zbira, hrani in uporablja moje osebne podatke (za polno uporabo različnih storitev, ki jih zagotavlja spletno mesto). Svoje soglasje lahko kadar koli prekličem. Politika zasebnosti je na voljo ",
                Offer_is_sent:"Ponudba je poslana",
                Offers_received: "Prejete ponudbe",
                Close_this_info:"Zapri te informacije",
                Select_this_offer:"Izberite to ponudbo",
                Obračunavanje: "Obračunavanje",
                Seznam_narocil: "Moja naročila",
                Odprte_narocila : "Odprta borza naročil",
                Seznam_voznikov: "Seznam voznikov",
                Zadnje_mesto_razkladanja: "Zadnje mesto razkladanja",
                Navesti_je_treba_vozilo_in_voznika: "Navesti je treba vozilo in voznika!",
                Ne_morem_shraniti: "Ne morem shraniti",
                Canceled_by_contractor_text: "Izvajalec ni želel izpolniti naročila.",
                Izpolnjevanje_naročila_v_teku : "Izpolnjevanje naročila v teku",
                Status_spremembe: "Status spremembe",
                Nazaj_na_seznam : "Nazaj na seznam",
                Waiting_confiramation_text: "Čakamo na potrditev izvajalca",
                Wish_change_contractor: "Če želite izbrati drugega izvajalca, lahko prekličite svojo ponudbo.",
                Cancel_offer_tr : "Zavrnitev ponudbo",
                Cancel_offer: "Prekliči ponudbo",
                Zaseden_v_teh_dneh:"Zaseden v teh dneh",
                Namigi : "Namigi",
                Ni_voznikov: "Ni voznikov",
                Register: "Registracija",
                Log_in : "Prijava",
                Company_name: "Ime podjetja",
                Predstavljam_prevozno_prevozniško_podjetje : "Predstavljam prevozno/prevozniško podjetje",
                Želim_prejemati_javne_ponudbe_za_prevoz : "Želim prejemati javne ponudbe za prevoz",
                Predstavljam_pošiljatelja_špediterja: "Zastopam pošiljatelja/špediterja",
                Želim_imeti_dostop_do_razpoložljivih_prevoznikov : "Želim imeti dostop do razpoložljivih prevoznikov",
                Please_input_your_Username : "Vnesite svoje uporabniško ime!",
                Please_input_your_Email: "Prosimo, vnesite veljavno e-pošto",
                Please_input_your_Password : "Vnesite geslo!",
                Please_input_your_Companyname : "Prosimo, vnesite ime podjetja!",
                Remember_me : "Zapomni si me",
                Forgot_password : "Pozabljeno geslo",
                Or: "Ali ",
                register_now: "se zdaj registrirajte!",
                Password : "Geslo",
                repeat_Password : "Ponovite geslo",
                Reason: "Razlog",
                Dopust : 'Dopust',
                Bolniški_dopust: 'Bolniški dopust',
                welcome : 'Dobrodošli',
                Voznik: 'Voznik',
                Povprečna_vrednost_naročila : 'Povprečna vrednost naročila',
                Število_naročil : 'Število naročil',
                Zasedenost_voznega_parka : 'Zasedenost voznega parka',
                Zasedenost_voznikov : 'Zasedenost voznikov',
                Naročila_na_danes: 'Naročila na danes:',
                Številka :'Številka',
                Itinerarij : 'Itinerarij',
                Izvajalec : "Izvajalec",
                Cena : 'Cena',
                Vozniki : 'Vozniki',
                Plačila : 'Plačila',
                Poglejte_vse : 'Poglejte vse',
                Vsi_vozniki_so_pripravljeni_za_delo : 'Vsi vozniki so pripravljeni za delo',
                Več : 'Več',
                Načrtovano : 'Načrtovano',
                Novo : 'Novo',
                Izberite : 'Izberite',
                Domov : 'Domov',
                Naročila_in_poti : 'Poti',
                Vozila : 'Vozila',
                Dokumenti : 'Dokumenti',
                Stranke :' Stranke',
                Nastavitve :'Nastavitve',
                Odjava : 'Odjava',
                Urejanje_voznika : 'Urejanje voznika',
                Dodajanje_naročila : 'Dodajanje naročila',
                Načrtovanje_poti: 'Načrtovanje poti',
                Urejanje_naročila : 'Urejanje naročila',
                Vsa : 'Vsa',
                Nova: 'Nova',
                Načrtovana: 'Načrtovanje',
                Confirmed: 'Potrjeno',
                On_route : 'V teku',
                Zaključena: 'Zaključena',
                Novo_naročilo : 'Novo naročilo',
                Prenesite_v_Excel :'Prenesite v Excel',
                Kliknite_za_razvrščanje_padajoče : 'Kliknite za razvrščanje padajoče',
                Kliknite_za_razvrščanje_naraščajoče : 'Kliknite za razvrščanje naraščajoče',
                Prekliči_razvrščanje : 'Prekliči razvrščanje',
                nedatirano: 'nedatirano',
                Od_datum :'Od datuma',
                Do_datum: 'Do datuma',
                Išči: 'Išči..',
                Osnovni_podatki:'Osnovni podatki',
                Pošiljatelj: 'Pošiljatelj',
                Ustvarite_novo_podjetje: 'Ustvarite novo podjetje',
                Prejemnik : 'Prejemnik',
                Plačnik : 'Plačnik',
                Opis_tovora: 'Opis tovora',
                Teža :'Teža',
                Volumen: 'Volumen',
                Opis: 'Opis',
                Nakladanje : 'Nakladanje',
                Razkladanje : 'Razkladanje',
                Dodajanje_točke_poti :'Dodajanje točke poti',
                Shranjevanje_naročila : 'Shranjevanje naročila',
                Vozilo : 'Vozilo',
                Dodajanje_novega_voznika:'Dodajanje novega zaposlenega',
                Shraniti :'Shraniti',
                Skrijte : 'Skrijte',
                Prenesite_dokument: 'Prenesite dokument',
                Naročilo :'Naročilo',
                Potrebno_je_potrdilo:'Potrebno je potrdilo',
                Nadaljevanje : 'Nadaljevanje',
                Nazaj : 'Nazaj',
                Če_ste_dodelili_izvajalca_bo_ta_ob_urejanju_naročila_ponastavljen : 'Če ste dodelili izvajalca, bo ta ob urejanju naročila ponastavljen',
                Dodajanje_vozila : 'Dodajanje vozila',
                Seznam_vozil : 'Seznam vozil',
                Koledar : 'Koledar',
                Reg_številka: 'Reg. številka',
                Znamka_Model : 'Znamka/Model',
                Znamka : "Brand",
                Model : 'Model',
                Vrsta_transporta : 'Vrsta transporta',
                Vrsta_telesa : 'Vrsta telesa',
                Vrsta_nakladanja : 'Vrsta nakladanja',
                Vlačilec :'Vlačilec',
                Priklopnik : 'Priklopnik',
                Tovornjak : 'Tovornjak',
                Nagibno : 'Nagibno',
                Odprto :'Odprto',
                Hladnik : 'Hladnik',
                Nad : 'Nad',
                Zadaj :'Zadaj',
                Strani : 'Strani',
                Vse_vrste_transporta : 'Vse vrste transporta',
                Vse_tipe_telesa : 'Vse tipe telesa',
                Vse_vrste_nakladanja : 'Vse vrste nakladanja',
                Po : 'Po',
                To : 'To',
                Sr : 'Sr',
                Če : 'Če',
                Pe : 'Pe',
                So : 'So',
                Ne : 'Ne',
                Urejanje_tega_naročila : 'Urejanje tega naročila',
                Ne_shranite_sprememb : 'Ne shranite sprememb',
                Podatki_o_vozilu : 'Podatki o vozilu',
                Zgodovina_opravljenih_letov : 'Zgodovina naročil',
                Ključni_kazalniki : 'Ključni kazalniki',
                Datum_rojstva : 'Datum rojstva',
                Vozniško_dovoljenje : 'Vozniško dovoljenje',
                Novi_voznik: 'Dodajanje novega zaposlenega',
                Ime : 'Ime',
                Priimek : 'Priimek',
                Vrsta_ddokumenta : 'Vrsta ddokumenta',
                Na_kaj_se_nanaša : 'Na kaj se nanaša',
                Ime_datoteke : 'Ime datoteke',
                Vrsta : 'Vrsta',
                Novi_dokument : 'Novi dokument',
                Nov_dokument : 'Nov dokument',
                Račun : 'Račun',
                Predračun : 'Predračun',
                Za_naročilo : 'Za naročilo',
                Nasprotna_stranka : 'Nasprotna stranka',
                Datum_dokumenta: 'Datum dokumenta',
                Datum_plačila : 'Datum plačila',
                Tiskanje : 'Tiskanje',
                Prevoz_blaga_po_naročilu : 'Blago in storitve po naročilu',
                Naziv_storitva_blaga : 'Naziv storitva/blaga',
                Količina : 'Količina',
                Davek : 'Davek',
                Dodajte_vrstico : 'Dodajte vrstico',
                Stranke_in_partnerji : 'Stranke in partnerji',
                Naziv : 'Naziv',
                Davčna_Številka : 'Davčna Številka',
                Matična_Številka : 'Matična Številka',
                Naslov : 'Naslov',
                Nov_partner : 'Nov partner',
                Urejanje_podatkov_o_partnerju : 'Urejanje podatkov o partnerju',
                Podatki_o_partnerju : 'Podatki o partnerju',
                Nastavitve_uporabnika : 'Nastavitve uporabnika',
                Nastavitve_podjetja : 'Nastavitve podjetja',
                Prenesite_sliko : 'Prenesite sliko',
                Prenesete_lahko_slike_velikosti_do : 'Prenesete lahko slike velikosti do',
                E_pošta : 'E-pošta',
                Login : 'Login',
                Staro_geslo : 'Staro geslo',
                Novo_geslo: 'Novo geslo',
                Žig : 'Žig',
                Podpis :'Podpis',
                Prenesite_datoteko_ali_povlecite : 'Prenesite datoteko ali povlecite',
                in_spusti_na_označeno_območje : 'in spusti na označeno območje',
                ne_več_kot : 'ne več kot',
                Tehnični_potni_list : 'Tehnični potni list',
                Zavarovanje : 'Zavarovanje',
                Drug_dokument : 'Drug dokument',
                Pogodba : 'Pogodba'


















            }
        },

        fr: {
            translation: {
                Currency: "Monnaie",
                Unit: "Unité",
                CurrentStock: "Niveau de stock actuel",
                Cost: "Coût",
                Price: "Prix",
                Products: "Produits",
                Article_not_specified: "Article non spécifié",
                Upload: "Charger",
                Download: "Télécharger",
                Schedule_payment_to_contractor: "Planifier le paiement au contractant",
                Article: "Article",
                Description: "Description",
                Scheduled: "Planifié",
                Attach_scan: "Attacher un scan",
                Planner: "Planificateur",
                Statement: "Relevés",
                Create_invoice: "Créer une facture",
                Schedule_receipt: "Planifier le paiement",
                Goods_and_services_on_order: "Biens et services sur commande",
                Subscription_fee: "Naročnina",
                Scenario : "Scénario",
                Created:"Ordre créé",
                Modified:"Modifications apportées",
                DocAdded:"Document ajouté",
                IncomePlanned:"Revenu prévu",
                ExpencePlanned:"Paiement prévu",
                IncomeHappened:"Revenu reçu",
                ExpenceHappened:"Paiement effectué",
                StatusModified:"Statut modifié",
                Taxes: "Impôts",
                Profit_withdrawal : "Retrait des bénéfices",
                Narocila: "Ordres",
                Contractor: "Сontractant",
                Status: "Statut",
                Employee: "Employé",
                Employees: "Employés",
                Deals: "Offres",
                Deal: "Offre",
                Accept: "Accepter",
                Expenses_by_item: "Dépenses par article",
                Date: "Date",
                Amount: "Montant",
                Cas_injection: "Injection de liquidités",
                Revenue: "Revenus",
                Rent: "Loyers",
                Salary: "Salaires",
                Payment_for_services: "Paiement de services",
                Purchase_of_goods: "Achat de biens",
                Other_expenses: "Autres dépenses",
                Account: "Compte",
                Incomes: "Revenus",
                Expenses: "Dépenses",
                Balance: "Solde",
                Income: "Revenu",
                Expense: "Dépense",
                Show_charts: "Afficher les graphiques",
                Reports: "Rapports",
                Consider_planned: "Tenir compte des opérations planifiées",
                DDS_Rep: "Flux de trésorerie",
                DDS_report: "Tableau des flux de trésorerie",
                January: "Janvier",
                February: "Février",
                March: "Mars",
                April: "Avril",
                May: "Mai",
                June: "Juin",
                July: "Juillet",
                August: "Août",
                September: "Septembre",
                October: "Octobre",
                November: "Novembre",
                December: "Décembre",
                from: "du",
                Obvezno_polje: "Champ obligatoire",
                Podatki_dokumenta: "Données du document",
                Cena_stranke: "Prix pour le client",
                Cena_izvajalca: "Prix pour le contractant",
                Delete: "Supprimer",
                Delete_warning: "Êtes-vous sûr de supprimer ce partenaire ?",
                Delete_text :"Une fois supprimé, vous ne pourrez plus sélectionner ce partenaire en tant que client ou exécutant pour les commandes.",
                Yes: "Oui",
                No: "Non",
                Delete_error_title: "On ne peut supprimer",
                Contractor_delete_error_text: "Vous devez d'abord supprimer toutes les commandes et tous les documents qui ont été créés pour ce partenaire.",
                Done: "C\'est fait!",                
                Kontaktna_oseba:"Contact",
                Telefon:"Téléphone",
                Faks:"Fax",
                Stranka_ali_izvajalec:"Client ou exécutant?",
                Stranka:"Client",
                BackToList : "Retour à la liste",
                Predogled: "Aperçu",
                Preneseno: "Chargé",
                Generirano: "Généré",
                Finances: "Finances",
                invite_message: "Pour vous enregistrer dans l\'application, veuillez saisir le code d\'invitation:",
                invite_mailto: "Pour recevoir un code d\'invitation, veuillez envoyer une demande à ",
                invite_error: "Hélas, le code ne correspond pas",
                Davek_za_DDV: "TVA",
                ID_za_DDV: "ID TVA",
                Hvala_za_zaupanje: "Merci de votre confiance",
                Znesek_prosimo_plačajte_na: "Veuillez verser le montant à",
                Ob_plačilu_navedite_referenco: "Veuillez indiquer une référence lors du paiement",
                Skupaj : "Total",
                Dokument : 'Document',
                CMR:"CMR",
                Show_finished: "Afficher terminé",
                save_and_stay: "Sauvegarder et continuer l\'édition",
                save_and_quit: "Sauvegarder et retourner à la liste",
                save_and_choose_performer : "Enregistrer et procéder à la sélection de l\'executant",
                here : "ici",
                agree_to_treat1 : "J\'accepte que FinTim me communique ses produits et ses offres par courrier électronique. Je peux retirer mon consentement à tout moment.",
                agree_to_treat2 : "J\'accepte que FinTim collecte, stocke et utilise mes données personnelles (pour la pleine utilisation des différents services fournis par le site). Je peux retirer mon consentement à tout moment. La politique de confidentialité est disponible ",
                Offer_is_sent:"L\'offre est envoyée",
                Offers_received: "Offres reçues",
                Close_this_info:"Fermer cette info",
                Select_this_offer:"Choisir cette offre",
                Obračunavanje: "Facturation",
                Seznam_narocil: "Mes ordres",
                Odprte_narocila : "Bourse d\'ordres ouverte",
                Seznam_voznikov: "Liste des conducteurs",
                Zadnje_mesto_razkladanja: "Le dernier point de déchargement",
                Navesti_je_treba_vozilo_in_voznika: "Le véhicule et le conducteur doivent être spécifiés!",
                Ne_morem_shraniti: "Impossible de sauvegarder",
                Canceled_by_contractor_text: "Le contractant a refusé d'exécuter la commande",
                Izpolnjevanje_naročila_v_teku : "Exécution de la commande en cours",
                Status_spremembe: "Modifier le statut",
                Nazaj_na_seznam : "Retour à la liste",
                Waiting_confiramation_text: "Attendre la confirmation du contractant",
                Wish_change_contractor: "Si vous souhaitez désigner un autre contractant, vous pouvez annuler votre offre.",
                Cancel_offer_tr : "Rejeter l\'offre",
                Cancel_offer: "Annuler l\'offre",
                Zaseden_v_teh_dneh:"Occupé ces jours-ci",
                Namigi: "Conseils",
                Ni_voznikov: "Pas de conducteurs",
                Register: "S\'enregistrer",
                Log_in : "Se connecter",
                Company_name: "Nom d\'entreprise",
                Predstavljam_prevozno_prevozniško_podjetje : "Je représente une société de transport",
                Želim_prejemati_javne_ponudbe_za_prevoz : "Je souhaite recevoir des offres publiques de transport",
                Predstavljam_pošiljatelja_špediterja: "Je représente l'expéditeur/transitaire",
                Želim_imeti_dostop_do_razpoložljivih_prevoznikov : "Je veux avoir accès aux transporteurs disponibles",
                Please_input_your_Username : "Veuillez saisir votre nom d\'utilisateur!",
                Please_input_your_Email: "Veuillez saisir un e-mail valide",
                Please_input_your_Password : "Veuillez saisir votre mot de passe!",
                Please_input_your_Companyname : "Veuillez saisir nom d\'entreprise!",
                Remember_me : "Mémoriser mes identifiants",
                Forgot_password : "Mot de passe oublié",
                Or: "Ou ",
                register_now: "enregistrer maintenant!",
                Password : "Mot de passe",
                repeat_Password : "Répétez votre mot de passe",
                Reason: "Raison",
                Dopust : 'Vacances',
                Bolniški_dopust: 'Congé de maladie',
                welcome: 'Bienvenue',
                Voznik: 'Conducteur',
                Povprečna_vrednost_naročila: 'Prix moyen de la commande',
                Število_naročil: 'Quantité de commandes',
                Zasedenost_voznega_parka: 'Charge de travail de la flotte',
                Zasedenost_voznikov: 'Charge des conducteurs',
                Naročila_na_danes: 'Commandes du jour',
                Številka: 'Numéro',
                Itinerarij: 'Itinéraire',
                Izvajalec: "Exécutant",
                Cena: 'Prix',
                Vozniki: 'Conducteurs',
                Plačila: 'Paiements',
                Poglejte_vse: 'Voir tous',
                Vsi_vozniki_so_pripravljeni_za_delo: 'Tous les conducteurs sont occupés',
                Več: 'Plus d\'info',
                Načrtovano: 'Planifié',
                Novo: 'Nouveau',
                Izberite: 'Choisir',
                Domov: 'Accueil',
                Naročila_in_poti: 'Routes',
                Vozila: 'La flotte',
                Dokumenti: 'Documents',
                Stranke: ' Partenaires',
                Nastavitve: 'Paramètres',
                Odjava: 'Sortie',
                Urejanje_voznika: 'Modifier le conducteur',
                Dodajanje_naročila: 'Ajouter un nouvel ordre',
                Načrtovanje_poti: 'Planification des itinéraires',
                Urejanje_naročila: 'Editer l\'ordre',
                Vsa: 'Tous',
                Nova: 'Nouveau',
                Načrtovana: 'Planification',
                Confirmed: "Confirmé",
                On_route : 'En cours',
                Zaključena: 'Terminé',
                Novo_naročilo: 'Nouvel ordre',
                Prenesite_v_Excel: 'Télécharger Excel',
                Kliknite_za_razvrščanje_padajoče: 'Cliquez pour trier par ordre croissant',
                Kliknite_za_razvrščanje_naraščajoče: 'Cliquez pour trier par ordre décroissant',
                Prekliči_razvrščanje: 'Annuler le tri',
                nedatirano: 'sans date',
                Od_datum: 'Date de début',
                Do_datum: 'Jusqu\'à',
                Išči: 'Recherche..',
                Osnovni_podatki: 'Informations générales',
                Pošiljatelj: 'Expéditeur',
                Ustvarite_novo_podjetje: 'Ajouter une nouvelle entreprise',
                Prejemnik: 'Récepteur',
                Plačnik: 'Payeur',
                Opis_tovora: 'Description du fret',
                Teža: 'Poids',
                Volumen: 'Volume',
                Opis: 'Info',
                Nakladanje: 'Chargement',
                Razkladanje: 'Déchargement',
                Dodajanje_točke_poti: 'Ajouter un point d\'itinéraire',
                Shranjevanje_naročila: 'Enregistrer la commande',
                Vozilo: 'Véhicule',
                Dodajanje_novega_voznika: 'Ajouter un nouvel employé',
                Shraniti: 'Enregistrer',
                Skrijte: 'Cacher',
                Prenesite_dokument: 'Charger un document',
                Naročilo: 'Commande',
                Potrebno_je_potrdilo: 'La confirmation est nécessaire',
                Nadaljevanje: 'Poursuivre',
                Nazaj: 'Retour',
                Če_ste_dodelili_izvajalca_bo_ta_ob_urejanju_naročila_ponastavljen: 'Si vous avez déjà affecté le perfomer, il sera désaffecté.',
                Dodajanje_vozila: 'Ajouter un nouveau véhicule',
                Seznam_vozil: 'Liste des véhicules',
                Koledar: 'Calendrier',
                Reg_številka: 'Numéro d\'imm.',
                Znamka_Model: 'Marque/Modèle',
                Znamka: "Marque",
                Model: 'Modèle',
                Vrsta_transporta: 'Type de transport',
                Vrsta_telesa: 'Type de corps',
                Vrsta_nakladanja: 'Type de chargement',
                Vlačilec: 'Tracteur',
                Priklopnik: 'Bande-annonce',
                Tovornjak: 'Camion',
                Nagibno: 'Tente',
                Odprto: 'Ouvert',
                Hladnik: 'Réfrigéré',
                Nad: 'Haut',
                Zadaj: 'Arrière',
                Strani: 'Côté',
                Vse_vrste_transporta: 'Tous les types de transport',
                Vse_tipe_telesa: 'Tous les types de corps',
                Vse_vrste_nakladanja: 'Tous les types de chargement',
                Po: 'Lun',
                To: 'Mar',
                Sr: 'Mer',
                Če: 'Jeu',
                Pe: 'Ven',
                So: 'Sam',
                Ne: 'Dim',
                Urejanje_tega_naročila: 'Modifier cet ordre',
                Ne_shranite_sprememb: 'Ne pas enregistrer',
                Podatki_o_vozilu: 'Détails du véhicule',
                Zgodovina_opravljenih_letov: 'Historique des ordres',
                Ključni_kazalniki: 'Indicateurs clés',
                Datum_rojstva: 'Date de naissance',
                Vozniško_dovoljenje: 'Numéro de licence',
                Novi_voznik: 'Ajouter un nouvel employé',
                Ime: 'Prenom',
                Priimek: 'Nom',
                Vrsta_ddokumenta: 'Type de document',
                Na_kaj_se_nanaša: 'Se rapporte à',
                Ime_datoteke: 'Nom de fichier',
                Vrsta: 'Type',
                Novi_dokument: 'Ajouter un nouveau document',
                Nov_dokument: 'Nouveau document',
                Račun: 'Facture',
                Predračun: 'Devis',
                Za_naročilo: 'Pour la commande',
                Nasprotna_stranka: 'Contrepartie',
                Datum_dokumenta: 'Date du document',
                Datum_plačila: 'Date du paiement',
                Tiskanje: 'Imprimer',
                Prevoz_blaga_po_naročilu: 'Biens et services sur commande',
                Naziv_storitva_blaga: 'Produit ou service',
                Količina: 'Quantité',
                Davek: 'Taxe',
                Dodajte_vrstico: 'Ajouter une position',
                Stranke_in_partnerji: 'Clients et partenaires',
                Naziv: 'Titre',
                Davčna_Številka: 'Numéro d\'identification fiscal',
                Matična_Številka: 'Identité de l\'entreprise',
                Naslov: 'Adresse',
                Nov_partner: 'Nouveau partenaire',
                Urejanje_podatkov_o_partnerju: 'Modifier les données du contractant',
                Podatki_o_partnerju: 'Données du contractant',
                Nastavitve_uporabnika: 'Paramètres de l\'utilisateur',
                Nastavitve_podjetja: 'Paramètres de l\'entreprise',
                Prenesite_sliko: 'Charger l\'image',
                Prenesete_lahko_slike_velikosti_do: 'Veuillez télécharger une image dont la taille ne dépasse pas',
                E_pošta: 'Email',
                Login: 'Login',
                Staro_geslo: 'Mot de passe précédent',
                Novo_geslo: 'Nouveau mot de passe',
                Žig: 'Tampon',
                Podpis: 'Signature',
                Prenesite_datoteko_ali_povlecite: 'Télécharger un fichier ou faire glisser',
                in_spusti_na_označeno_območje: 'et le déposer dans cette zone',
                ne_več_kot: 'ne dépasse pas ',
                Tehnični_potni_list: 'Passeport technique',
                Zavarovanje: 'Assurance',
                Drug_dokument: 'Autre document',
                Pogodba: 'Contrat'


            }
        },
        
        de : {
            translation: {
                Currency: "Währung",
                Unit: "Einheit",
                CurrentStock: "Aktueller Lagerbestand",
                Cost: "Kosten",
                Price: "Preis",
                Products: "Produkte",
                Article_not_specified: "Artikel nicht angegeben",
                Upload: "Hochladen",
                Download: "Herunterladen",
                Schedule_payment_to_contractor: "Zeitplan für die Zahlung an den Auftragnehmer",
                Article: "Artikel",
                Description: "Beschreibung",
                Scheduled: "Geplant",
                Attach_scan: "Scan anhängen",
                Planner: "Planer",
                Statement: "Auszüge",
                Create_invoice: "Rechnung erstellen",
                Schedule_receipt: "Zahlung einplanen",
                Goods_and_services_on_order: "Waren und Dienstleistungen auf Bestellung",
                Scenario : "Szenario",
                Created:"Auftrag erstellt",
                Modified:"Änderungen vorgenommen",
                DocAdded:"Dokument hinzugefügt",
                IncomePlanned:"Einkommen ist geplant",
                ExpencePlanned:"Zahlung ist geplant",
                IncomeHappened:"Einkommen erhalten",
                ExpenceHappened:"Zahlung erfolgt",
                StatusModified:"Status geändert",
                Taxes: "Steuern",
                Profit_withdrawal : "Gewinnentnahme",
                Narocila: "Aufträge",
                Contractor: "Auftragnehmer",
                Status: "Status",
                Employee: "Mitarbeiter",
                Employees: "Mitarbeiter",
                Deals: "Angebote",
                Deal: "Angebot",
                Accept: "Akzeptieren",
                Expenses_by_item: "Ausgaben nach Posten",
                Date: "Date",
                Amount: "Betrag",
                Cas_injection: "Finanzspritze",
                Revenue: "Einnahmen",
                Rent: "Miete",
                Salary: "Gehälter",
                Payment_for_services: "Zahlung für Dienstleistungen",
                Purchase_of_goods: "Kauf von Waren",
                Other_expenses: "Sonstige Ausgaben",
                Account: "Konto",
                Incomes: "Einkünfte",
                Expenses: "Ausgaben",
                Balance: "Saldo",
                Income: "Einnahmen",
                Expense: "Ausgaben",
                Show_charts: "Diagramme anzeigen",
                Reports: "Berichte",
                Consider_planned: "Zur Berücksichtigung geplanter Operationen",
                DDS_Rep: "Kapitalflussrechnung",
                DDS_report: "Kapitalflussrechnung",
                January: "Januar",
                February: "Februar",
                March: "März",
                April: "April",
                May: "Mai",
                June: "Juni",
                July: "Juli",
                August: "August",
                September: "September",
                October: "Oktober",
                November: "November",
                December: "Dezember",
                from: "ab",
                Obvezno_polje: "Pflichtfeld",
                Podatki_dokumenta: "Dokumentdaten",
                Cena_stranke: "Preis für den Auftraggeber",
                Cena_izvajalca: "Preis für den Auftragnehmer",
                Delete: "Löschen",
                Delete_warning: "Sind Sie sicher, dass Sie diesen Partner löschen möchten?",
                Delete_text :"Nach dem Löschen können Sie diesen Partner nicht mehr als Kunden oder Ausführenden für Aufträge auswählen.",
                Yes: "Ja",
                No: "Nein",
                Delete_error_title: "Wir können nicht löschen",
                Contractor_delete_error_text: "Zuerst müssen Sie alle Aufträge und Dokumente löschen, die für diesen Partner erstellt wurden.",
                Done: "Erledigt!",
                Kontaktna_oseba:"Kontaktperson",
                Telefon:"Telefon",
                Faks:"Fax",
                Stranka_ali_izvajalec:"Auftraggeber oder darsteller?",
                Stranka:"Auftraggeber",
                BackToList : "Zurück zur Liste",
                Predogled: "Vorschau",
                Preneseno: "Geladen",
                Generirano: "Erzeugt",
                Finances: "Finanzen",
                invite_message: "Um sich in der Anwendung zu registrieren, geben Sie bitte den Einladungscode ein:",
                invite_mailto: "Um einen Einladungscode zu erhalten, senden Sie bitte eine Anfrage an ",
                invite_error: "Leider stimmt der Code nicht überein",
                Davek_za_DDV: "MwSt.",
                ID_za_DDV: "UST-ID",
                Hvala_za_zaupanje: "Vielen Dank für Ihr Vertrauen",
                Znesek_prosimo_plačajte_na: "Bitte überweisen Sie den Betrag an",
                Ob_plačilu_navedite_referenco: "Bitte geben Sie bei der Überweisung eine Referenz an",
                Skupaj : "Gesamt",
                Dokument : 'Dokument',
                CMR:"CMR",
                Show_finished: "Vollständig anzeigen",
                save_and_stay: "Speichern und weiter bearbeiten",
                save_and_quit: "Speichern und zur Liste gehen",
                save_and_choose_performer : "Speichern und zur Interpretenauswahl gehen",
                here : "hier",
                agree_to_treat1 : "Ich bin damit einverstanden, dass FinTim mir seine Produkte und Angebote per E-Mail mitteilt. Ich kann meine Zustimmung jederzeit widerrufen.",
                agree_to_treat2 : "Ich bin damit einverstanden, dass FinTim meine persönlichen Daten erhebt, speichert und nutzt (für die vollständige Nutzung der verschiedenen Dienste, die auf der Seite angeboten werden). Ich kann mein Einverständnis jederzeit widerrufen. Die Datenschutzrichtlinie ist abrufbar unter ",
                Offer_is_sent:"Angebot wird gesendet",
                Offers_received: "Erhaltene Angebote",
                Close_this_info:"Diese Info schließen",
                Select_this_offer:"Dieses Angebot wählen",
                Obračunavanje: "Rechnungsstellung",
                Seznam_narocil: "Meine Aufträge",
                Odprte_narocila : "Auftragsbörse öffnen",
                Seznam_voznikov: "Liste der Fahrer",
                Zadnje_mesto_razkladanja: "Die letzte Entladestelle",
                Navesti_je_treba_vozilo_in_voznika: "Fahrzeug und Fahrer müssen angegeben werden!",
                Ne_morem_shraniti: "Kann nicht speichern",
                Canceled_by_contractor_text: "Der Auftragnehmer weigerte sich, den Auftrag zu erfüllen",
                Izpolnjevanje_naročila_v_teku : "Auftragserfüllung in Bearbeitung",
                Status_spremembe: "Status ändern",
                Nazaj_na_seznam : "Zurück zur Liste",
                Waiting_confiramation_text: "Warten auf die Bestätigung des Auftragnehmers",
                Wish_change_contractor: "Wenn Sie einen anderen Auftragnehmer beauftragen möchten, können Sie Ihr Angebot zurückziehen.",
                Cancel_offer_tr : "Ablehnung des Vorschlags",
                Cancel_offer: "Angebot stornieren",
                Zaseden_v_teh_dneh:"Viel zu tun heutzutage",
                Namigi: "Tipps",
                Ni_voznikov: "Keine Fahrer",
                Register: "Registrieren",
                Log_in : "Anmeldung",
                Company_name: "Firmennamen eine",
                Predstavljam_prevozno_prevozniško_podjetje : "Ich vertrete ein Transport-/Fuhrunternehmen",
                Želim_prejemati_javne_ponudbe_za_prevoz : "Ich möchte öffentliche Angebote für den Transport erhalten",
                Predstavljam_pošiljatelja_špediterja: "Ich vertrete den Verlader/Spediteur",
                Želim_imeti_dostop_do_razpoložljivih_prevoznikov : "Ich möchte Zugang zu den verfügbaren Trägern haben",
                Please_input_your_Username : "Bitte geben Sie Ihren Benutzernamen ein!",
                Please_input_your_Email: "Bitte geben Sie eine gültige E-Mail ein",
                Please_input_your_Password : "Bitte geben Sie Ihr Passwort ein!",
                Please_input_your_Companyname : "Bitte geben Sie Ihren Firmennamen ein!",
                Remember_me : "Erinnern Sie sich an mich",
                Forgot_password : "Passwort vergessen",
                Or: "Oder ",
                register_now: "jetzt registrieren!",
                Password : "Passwort",
                repeat_Password : "Wiederholen Sie Ihr Passwort",
                Reason: "Vernunft",
                Dopust : 'Urlaub',
                Bolniški_dopust: 'Krankenstand',
                welcome : 'Willkommen',
                Voznik : 'Treiber',
                Povprečna_vrednost_naročila : 'Auftragspreis',
                Število_naročil: 'Anzahl der Aufträge',
                Zasedenost_voznega_parka : 'Auslastung der Flotte' ,
                Zasedenost_voznikov : 'Arbeitsbelastung der Fahrer',
                Naročila_na_danes: 'Heutige Aufträge',
                Številka :'Nummer',
                Itinerarij : 'Route',
                Izvajalec : "Darsteller",
                Cena : 'Preis',
                Vozniki : 'Treiber',
                Plačila : 'Zahlungen',
                Poglejte_vse : 'Alle sehen',
                Vsi_vozniki_so_pripravljeni_za_delo : 'Alle Fahrer sind besetzt',
                Več : 'Mehr',
                Načrtovano : 'Geplant',
                Novo : 'Neu',
                Izberite : 'Wählen Sie',
                Domov : 'Startseite',
                Naročila_in_poti : 'Routen',
                Vozila : 'Flotte',
                Dokumenti : 'Dokumente',
                Stranke :' Partner',
                Nastavitve :'Einstellungen',
                Odjava : 'Ausfahrt',
                Urejanje_voznika : 'Treiber bearbeiten',
                Dodajanje_naročila : 'Neuen Auftrag hinzufügen',
                Načrtovanje_poti: 'Routenplanung',
                Urejanje_naročila : 'Bestellung bearbeiten',
                Vsa : 'Alle',
                Nova: 'Neu',
                Načrtovana: 'Planung',
                Confirmed: "Bestätigt",
                On_route : 'In Arbeit',
                Zaključena: 'Fertige',
                Novo_naročilo : 'Neue Bestellung',
                Prenesite_v_Excel :'Excel herunterladen',
                Kliknite_za_razvrščanje_padajoče : 'Klicken Sie auf, um in aufsteigender Reihenfolge zu sortieren',
                Kliknite_za_razvrščanje_naraščajoče : 'Klicken Sie auf , um absteigend zu sortieren',
                Prekliči_razvrščanje : 'Sortierung abbrechen',
                nedatirano: 'kein Datum',
                Od_datum :'Vom Datum',
                Do_datum: 'Bis datum',
                Išči: 'Suche..',
                Osnovni_podatki:'Allgemeine Informationen',
                Pošiljatelj: 'Sender',
                Ustvarite_novo_podjetje: 'Neues Unternehmen hinzufügen',
                Prejemnik : 'Empfänger',
                Plačnik : 'Zahler',
                Opis_tovora: 'Beschreibung der Ladung',
                Teža :'Gewicht',
                Volumen: 'Band',
                Opis: 'Infos',
                Nakladanje : 'Laden',
                Razkladanje : 'Entladen',
                Dodajanje_točke_poti :'Einen Wegpunkt hinzufügen',
                Shranjevanje_naročila : 'Bestellung speichern',
                Vozilo : 'Fahrzeug',
                Dodajanje_novega_voznika:'Einen neuen Mitarbeiter hinzufügen',
                Shraniti :'Speichern Sie',
                Skrijte : 'Ausblenden',
                Prenesite_dokument: 'Dokument hochladen',
                Naročilo :'Bestellung',
                Potrebno_je_potrdilo:'Bestätigung ist erforderlich',
                Nadaljevanje : 'Fortsetzung folgt',
                Nazaj : 'Zurück',
                Če_ste_dodelili_izvajalca_bo_ta_ob_urejanju_naročila_ponastavljen : 'Wenn Sie bereits einen Testamentsvollstrecker zugewiesen haben, wird dieser gelöscht.',
                Dodajanje_vozila : 'Ein neues Fahrzeug hinzufügen',
                Seznam_vozil : 'Liste der Fahrzeuge',
                Koledar : 'Kalender',
                Reg_številka: 'Registrierungsnummer',
                Znamka_Model : 'Marke/Modell',
                Znamka : "Marke",
                Model : 'Modell',
                Vrsta_transporta : 'Art der Beförderung',
                Vrsta_telesa : 'Typ des rollenden Materials',
                Vrsta_nakladanja : 'Art der Verladung',
                Vlačilec :'Hauler',
                Priklopnik : 'Anhänger',
                Tovornjak : 'Lkw',
                Nagibno : 'Zeltaufbau',
                Odprto :'Öffnen Sie',
                Hladnik : 'Gekühlt',
                Nad : 'Nach oben',
                Zadaj :'Rückseite',
                Strani : 'Seite',
                Vse_vrste_transporta : 'Alle Transportarten',
                Vse_tipe_telesa : 'Alle Arten von rollendem Material',
                Vse_vrste_nakladanja : 'Alle Arten der Verladung',
                Po : 'Mo',
                To : 'Di',
                Sr : 'Mi',
                Če : 'Do',
                Pe : 'Fr',
                So : 'Sa',
                Ne : 'So',
                Urejanje_tega_naročila : 'Diesen Auftrag bearbeiten',
                Ne_shranite_sprememb : 'Nicht speichern',
                Podatki_o_vozilu : 'Fahrzeuginformationen',
                Zgodovina_opravljenih_letov : 'Geschichte der Aufträge',
                Ključni_kazalniki : 'Schlüsselindikatoren',
                Datum_rojstva : 'Datum der Geburt',
                Vozniško_dovoljenje : 'Zulassungsnummer',
                Novi_voznik: 'Neuen Mitarbeiter hinzufügen',
                Ime : 'Name',
                Priimek : 'Nachname',
                Vrsta_ddokumenta : 'Art des Dokuments',
                Na_kaj_se_nanaša : 'Bezieht sich auf',
                Ime_datoteke : 'Dateiname',
                Vrsta : 'Typ',
                Novi_dokument : 'Ein neues Dokument hinzufügen',
                Nov_dokument : 'Neues Dokument',
                Račun : 'Rechnung',
                Predračun : 'Schätzung',
                Za_naročilo : 'Zum Bestellen',
                Nasprotna_stranka : 'Gegenstück',
                Datum_dokumenta: 'Datum der Dokumente',
                Datum_plačila : 'Datum des Zahlungseingangs',
                Tiskanje : 'Drucken',
                Prevoz_blaga_po_naročilu : 'Waren und Dienstleistungen im Auftrag',
                Naziv_storitva_blaga : 'Produkt oder Dienstleistung',
                Količina : 'Menge',
                Davek : 'Taxe',
                Dodajte_vrstico : 'Position hinzufügen',
                Stranke_in_partnerji : 'Kunden und Partner',
                Naziv : 'Titel',
                Davčna_Številka : 'Steuernummer',
                Matična_Številka : 'Firma Id',
                Naslov : 'Adresse',
                Nov_partner : 'Neue Partnerinr',
                Urejanje_podatkov_o_partnerju : 'Daten des Auftragnehmers bearbeiten',
                Podatki_o_partnerju : 'Daten des Auftragnehmers',
                Nastavitve_uporabnika : 'Benutzereinstellungen',
                Nastavitve_podjetja : 'Einstellungen des Unternehmens',
                Prenesite_sliko : 'Bild hochladen',
                Prenesete_lahko_slike_velikosti_do : 'Bitte laden Sie ein Bild hoch, das nicht größer ist als',
                E_pošta : 'E-Mail',
                Login : 'Anmeldung',
                Staro_geslo : 'Altes Passwort',
                Novo_geslo: 'Neues Passwort',
                Žig : 'Stempel',
                Podpis :'Unterschrift',
                Prenesite_datoteko_ali_povlecite : 'Laden Sie die Datei herunter oder ziehen Sie sie per Drag & Drop',
                in_spusti_na_označeno_območje : 'und legen Sie sie in diesem Bereich ab',
                ne_več_kot : 'nicht mehr',
                Tehnični_potni_list : 'Technischer Reisepass',
                Zavarovanje : 'Versicherung',
                Drug_dokument : 'Ein weiteres Dokument',
                Pogodba : 'Vereinbarung'
            }
        },
        ru : {
            translation: {
                Currency: "Валюта",
                Unit: "Единица",
                CurrentStock: "Текущий уровень запасов",
                Cost: "Себестоимость",
                Price: "Цена",
                Products: "Товары",
                Article_not_specified: "Статья не указана",
                Upload: "Загрузить",
                Download: "Скачать",
                Schedule_payment_to_contractor: "Запланировать выплату подрядчику",
                Article: "Статья",
                Description: "Описание",
                Scheduled: "Плановая",
                Attach_scan: "Прикрепить скан",
                Planner: "Планировщик",
                Statement: "Выписки",
                Create_invoice: "Создать счет",
                Schedule_receipt: "Запланировать оплату",
                Goods_and_services_on_order: "Товары и услуги по заказу",
                Scenario : "Сценарий",
                Created:"Заказ создан",
                Modified:"Внесены измененения",
                DocAdded:"Добавлен документ",
                IncomePlanned:"Запланировано поступление",
                ExpencePlanned:"Запланирована выплата",
                IncomeHappened:"Поступили средства",
                ExpenceHappened:"Выплата произведена",
                StatusModified:"Изменен статус",
                Taxes: "Налоги",
                Profit_withdrawal : "Вывод прибыли",
                Narocila: "Заказы",
                Contractor: "Подрядчик",
                Status: "Статус",
                Employee: "Сотрудник",
                Employees: "Сотрудники",
                Deals: "Сделки",
                Deal: "Сделка",
                Accept: "Принять",
                Expenses_by_item: "Расходы по статьям",
                Date: "Дата",
                Amount: "Сумма",
                Cas_injection: "Ввод денег",
                Revenue: "Выручка",
                Rent: "Аренда",
                Salary: "Зарплата",
                Payment_for_services: "Оплата услуг",
                Purchase_of_goods: "Покупка товаров",
                Other_expenses: "Прочие расходы",
                Account: "Счет",
                Incomes: "Поступления",
                Expenses: "Списания",
                Balance: "Сальдо",
                Income: "Поступление",
                Expense: "Списание",
                Show_charts: "Показать графики",
                Reports: "Отчеты",
                Consider_planned: "Учитывать плановые операции",
                DDS_Rep: "Отчет ДДС",
                DDS_report: "Отчет о движении денежных средств",
                January: "Январь",
                February: "Февраль",
                March: "Март",
                April: "Апрель",
                May: "Май",
                June: "Июнь",
                July: "Июль",
                August: "Август",
                September: "Сентябрь",
                October: "Октябрь",
                November: "Ноябрь",
                December: "Декабрь",
                from: "от",
                Obvezno_polje: "Обязательное поле",
                Podatki_dokumenta: "Данные документа",
                Cena_stranke: "Цена клиента",
                Cena_izvajalca: "Цена исполнителя",
                Delete: "Удалить",
                Delete_warning: "Вы уверены, что хотите удалить этого партнера?",
                Delete_text :"После удаления вы больше не сможете выбрать этого партнера в качестве заказчика или исполнителя заказов",
                Yes: "Да",
                No: "Нет",
                Delete_error_title: "Не получается удалить",
                Contractor_delete_error_text: "Сначала вам нужно удалить все заказы и документы, которые были созданы для этого партнера.",
                Done: "Готово!",
                Kontaktna_oseba:"Контактное лицо",
                Telefon:"Телефон",
                Faks:"Факс",
                Stranka_ali_izvajalec:"Клиент или исполнитель?",
                Stranka:"Клиент",
                BackToList : "Назад к списку",
                Predogled: "Предпросмотр",
                Preneseno: "Загружен",
                Generirano: "Сгенерирован",
                Finances: "Деньги",
                invite_message: "Для регистрации в приложении, пожалуйста, ведите код приглашения:",
                invite_mailto: "Чтобы получить код приглашения, отправьте запрос на ",
                invite_error: "Увы, код не подходит",
                Davek_za_DDV: "НДС",
                ID_za_DDV: "ИНН",
                Hvala_za_zaupanje: "Благодарим вас за доверие",
                Znesek_prosimo_plačajte_na: "Пожалуйста, перечислите сумму на",
                Ob_plačilu_navedite_referenco: "Пожалуйста, укажите ссылку при оплате",
                Skupaj : "Всего",
                Dokument : 'Документ',
                CMR:"CMR",
                Show_finished: "Показывать завершенные",
                save_and_stay: "Сохранить и продолжить редактирование",
                save_and_quit: "Сохранить и вернуться к списку",
                save_and_choose_performer : "Сохранить и перейти к выбору исполнителя",
                here : "здесь",
                agree_to_treat1 : "Я согласен с тем, что FinTim сообщает мне о своих продуктах и предложениях по электронной почте. Я могу отозвать свое согласие в любое время.",
                agree_to_treat2 : "Я согласен с тем, что FinTim собираем, хранит, использует мои личные данные (для полноценного пользования различными сервисами предоставляемыми сайтом). Я могу отозвать свое согласие в любое время. С политикой конфиденциальности можно ознакомиться ",
                Offer_is_sent:"Предложение отправлено",
                Offers_received: "Поступившие предложения",
                Close_this_info:"Закрыть эту информацию",
                Select_this_offer:"Выбрать это предложение",
                Obračunavanje: "Оплаты",
                Seznam_narocil: "Мои заказы",
                Odprte_narocila : "Биржа открытых заказов",
                Seznam_voznikov: "Список водителей",
                Zadnje_mesto_razkladanja: "Последнее место разгрузки",
                Navesti_je_treba_vozilo_in_voznika: "Необходимо указать транспортное средство и водителя!",
                Ne_morem_shraniti: "Не получается сохранить",
                Canceled_by_contractor_text: "Подрядчик отказался от выполнения заказа",
                Izpolnjevanje_naročila_v_teku : "Идет выполнение заказа",
                Status_spremembe: "Изменить статус",
                Nazaj_na_seznam : "Вернуться к списку",
                Waiting_confiramation_text: "Ожидаем подтверждения от исполнителя.",
                Wish_change_contractor: "Если Вы хотите назначить другого исполнителя, то можете отозвать предложение.",
                Cancel_offer_tr : "Отклонить предложение",
                Cancel_offer: "Отозвать предложение",
                Zaseden_v_teh_dneh:"Занят в эти дни",
                Namigi : "Подсказки",
                Ni_voznikov: "Нет водителей",
                Register: "Регистрация",
                Log_in : "Логин",
                Password : "Пароль",
                repeat_Password : "Повторите пароль",
                Company_name: "Название компании",
                Predstavljam_prevozno_prevozniško_podjetje : "Я представляю транспортную компанию/перевозчика",
                Želim_prejemati_javne_ponudbe_za_prevoz : "Я хочу получать публичные предложения по перевозкам",
                Predstavljam_pošiljatelja_špediterja: "Я представляю компанию грузоотправителя/экспедитораy",
                Želim_imeti_dostop_do_razpoložljivih_prevoznikov : "Я хочу иметь доступ к доступным перевозчикам",
                Please_input_your_Username : "Пожалуйста, введите ваше имя пользователя!",
                Please_input_your_Email: "Пожалуйста, введите действительный адрес электронной почты!",
                Please_input_your_Password : "Пожалуйста, введите ваш пароль!",
                Please_input_your_Companyname : "Пожалуйста, введите название вашей компании!",
                Remember_me : "Запомнить меня",
                Forgot_password : "Забыли пароль?",
                Or: "Или ",
                register_now: "зарегистрируйтесь сейчас!",
                Reason: "Причина",
                Dopust : 'Отпуск',
                Bolniški_dopust: 'Больничный',
                welcome : 'Добро пожаловать',
                Voznik : 'Сотрудники',
                Povprečna_vrednost_naročila : 'Средняя цена заказа',
                Število_naročil: 'Количество заказов',
                Zasedenost_voznega_parka : 'Загруженность автопарка' ,
                Zasedenost_voznikov : 'Загруженность сотруников',
                Naročila_na_danes: 'Заказы на сегодня',
                Številka :'Номер',
                Itinerarij : 'Маршрут',
                Izvajalec : "Исполнитель",
                Cena : 'Цена',
                Vozniki : 'Сотрудники',
                Plačila : 'Выплаты',
                Poglejte_vse : 'Посмотреть все',
                Vsi_vozniki_so_pripravljeni_za_delo : 'Все водители заняты',
                Več : 'Больше',
                Načrtovano : 'Запланировано',
                Novo : 'Новые',
                Izberite : 'Выбрать',
                Domov : 'Главная',
                Naročila_in_poti : 'Рейсы',
                Vozila : 'Автопарк',
                Dokumenti : 'Документы',
                Stranke :' Партнеры',
                Nastavitve :'Настройки',
                Odjava : 'Выход',
                Urejanje_voznika : 'Редактировать водителя',
                Dodajanje_naročila : 'Добавить новый заказ',
                Načrtovanje_poti: 'Планирование маршрута',
                Urejanje_naročila : 'Редактировать заказ',
                Vsa : 'Все',
                Nova: 'Новые',
                Načrtovana: 'Планирование',
                Confirmed: "Подтверждено",
                On_route : 'В процессе',
                Zaključena: 'Завершено',
                Novo_naročilo : 'Новый заказ',
                Prenesite_v_Excel :'Скачать Excel',
                Kliknite_za_razvrščanje_padajoče : 'Нажмите для сортировки по возрастанию',
                Kliknite_za_razvrščanje_naraščajoče : 'Нажмите для сортировки по убыванию',
                Prekliči_razvrščanje : 'Отменить сортировку',
                nedatirano: 'без даты',
                Od_datum :'От даты',
                Do_datum: 'До даты',
                Išči: 'Поиск..',
                Osnovni_podatki:'Общая информация',
                Pošiljatelj: 'Отправитель',
                Ustvarite_novo_podjetje: 'Добавить новую компанию',
                Prejemnik : 'Получатель',
                Plačnik : 'Плательщик',
                Opis_tovora: 'Описание груза',
                Teža :'Вес',
                Volumen: 'Объем',
                Opis: 'Информация',
                Nakladanje : 'Погрузка',
                Razkladanje : 'Разгрузка',
                Dodajanje_točke_poti :'Добавить точку маршрута',
                Shranjevanje_naročila : 'Сохранить заказ',
                Vozilo : 'Транспортное средство',
                Dodajanje_novega_voznika:'Добавить нового сотрудника',
                Shraniti :'Сохранить',
                Skrijte : 'Скрыть',
                Prenesite_dokument: 'Загрузить документ',
                Naročilo :'Заказ',
                Potrebno_je_potrdilo:'Необходимо подтверждение',
                Nadaljevanje : 'Продолжить',
                Nazaj : 'Назад',
                Če_ste_dodelili_izvajalca_bo_ta_ob_urejanju_naročila_ponastavljen : 'Если вы уже назначили исполнителя, то нужно будет назначить его снова',
                Dodajanje_vozila : 'Добавить новое транспортное средство',
                Seznam_vozil : 'Список транспортных средств',
                Koledar : 'Календарь',
                Reg_številka: 'Рег. номер',
                Znamka_Model : 'Марка/модель',
                Znamka : "Марка",
                Model : 'Модель',
                Vrsta_transporta : 'Тип транспорта',
                Vrsta_telesa : 'Тип кузова',
                Vrsta_nakladanja : 'Тип погрузки',
                Vlačilec :'Тягач',
                Priklopnik : 'Прицеп',
                Tovornjak : 'Грузовик',
                Nagibno : 'Тентованный',
                Odprto :'Открытый',
                Hladnik : 'Рефрижератор',
                Nad : 'Верхняя',
                Zadaj :'Задняя',
                Strani : 'Боковая',
                Vse_vrste_transporta : 'Все типы транспорта',
                Vse_tipe_telesa : 'Все типы кузова',
                Vse_vrste_nakladanja : 'Все типы погрузки',
                Po : 'ПН',
                To : 'ВТ',
                Sr : 'СР',
                Če : 'ЧТ',
                Pe : 'ПТ',
                So : 'СБ',
                Ne : 'ВС',
                Urejanje_tega_naročila : 'Отредактируйте этот заказ',
                Ne_shranite_sprememb : 'Не сохранять изменения',
                Podatki_o_vozilu : 'Подробная информация о ТС',
                Zgodovina_opravljenih_letov : 'История заказов',
                Ključni_kazalniki : 'Ключевые показатели',
                Datum_rojstva : 'Дата рождения',
                Vozniško_dovoljenje : 'Водительское удостоверение',
                Novi_voznik: 'Добавить нового сотрудника',
                Ime : 'Имя',
                Priimek : 'Фамилия',
                Vrsta_ddokumenta : 'Тип документа',
                Na_kaj_se_nanaša : 'Относится к',
                Ime_datoteke : 'Имя файла',
                Vrsta : 'Тип',
                Novi_dokument : 'Добавить новый документ',
                Nov_dokument : 'Новый документ',
                Račun : 'Счет',
                Predračun : 'Счет',
                Za_naročilo : 'По заказу',
                Nasprotna_stranka : 'Контрагент',
                Datum_dokumenta: 'Дата документа',
                Datum_plačila : 'Дата платежа',
                Tiskanje : 'Печать',
                Prevoz_blaga_po_naročilu : 'Товары и услуги по заказу ',
                Naziv_storitva_blaga : 'Товар или услуга',
                Količina : 'Количество',
                Davek : 'НДС',
                Dodajte_vrstico : 'Добавить позицию',
                Stranke_in_partnerji : 'Клиенты и партнеры',
                Naziv : 'Название',
                Davčna_Številka : 'ИНН',
                Matična_Številka : 'Id компании',
                Naslov : 'Адрес',
                Nov_partner : 'Новый партнер',
                Urejanje_podatkov_o_partnerju : 'Редактирование данных о партнере',
                Podatki_o_partnerju : 'Данные о партнере',
                Nastavitve_uporabnika : 'Настройки пользователя',
                Nastavitve_podjetja : 'Настройки компании',
                Prenesite_sliko : 'Загрузить изображение',
                Prenesete_lahko_slike_velikosti_do : 'Пожалуйста, загрузите изображене не больше ',
                E_pošta : 'Электронная почта',
                Login : 'Логин',
                Staro_geslo : 'Старый пароль',
                Novo_geslo: 'Новый пароль',
                Žig : 'Печать',
                Podpis :'Подпсиь',
                Prenesite_datoteko_ali_povlecite : 'Загрузите файл или перетащите ',
                in_spusti_na_označeno_območje : 'его в эту область',
                ne_več_kot : 'не более, чем ',
                Tehnični_potni_list : 'Тех. паспорт',
                Zavarovanje : 'Страховка',
                Drug_dokument : 'Другой документ',
                Pogodba : 'Договор'
            }
        },
        it:{
            translation: {
                Currency: "Valuta",
                Unit: "Unità",
                CurrentStock: "Livello attuale delle scorte",
                Cost: "Costo",
                Price: "Prezzo",
                Products: "Prodotti",
                Article_not_specified: "Articolo non specificato",
                Upload: "Carica",
                Download: "Scarica",
                Schedule_payment_to_contractor: "Programmare il pagamento al contraente",
                Article: "Articolo",
                Description: "Descrizione",
                Scheduled: "Programmato",
                Attach_scan: "Allegare scansione",
                Planner: "Pianificatore",
                Statement: "Dichiarazioni",
                Create_invoice: "Creare la fattura",
                Schedule_receipt: "Pianificare il pagamento",
                Goods_and_services_on_order: "Beni e servizi su ordinazione",
                Scenario : "Scenario",
                Created:"Ordine creato",
                Modified:"Modifiche apportate",
                DocAdded:"Aggiunta di un documento",
                IncomePlanned:"Il reddito è pianificato",
                ExpencePlanned:"Il pagamento è pianificato",
                IncomeHappened:"Entrate ricevute",
                ExpenceHappened:"Pagamento effettuato",
                StatusModified:"Stato modificato",
                Taxes: "Tasse",
                Profit_withdrawal : "Prelievo di utili",
                Narocila: "Ordini",
                Contractor: "Contraente",
                Status: "Stato",
                Employee: "Dipendente",
                Employees: "I dipendenti",
                Deals: "Offerte",
                Deal: "Offerte",
                Accept: "Accettare",
                Expenses_by_item: "Spese per voce",
                Date: "Data",
                Amount: "Importo",
                Cas_injection: "Iniezione di liquidità",
                Revenue: "Entrate",
                Rent: "Affitto",
                Salary: "Salario",
                Payment_for_services: "Pagamento di servizi",
                Purchase_of_goods: "Acquisto di beni",
                Other_expenses: "Altre spese",
                Account: "Conto",
                Incomes: "Entrate",
                Expenses: "Spese",
                Balance: "Saldo",
                Income: "Entrate",
                Expense: "Spese",
                Show_charts: "Mostra grafici",
                Reports: "Rapporti",
                Consider_planned: "Per tenere conto delle operazioni pianificate",
                DDS_Rep: "Rendiconto finanziario",
                DDS_report: "Rendiconto finanziario",
                January: "Gennaio",
                February: "Febbraio",
                March: "Marzo",
                April: "Aprile",
                May: "Maggio",
                June: "Giugno",
                July: "Luglio",
                August: "Agosto",
                September: "Settembre",
                October: "Ottobre",
                November: "Novembre",
                December: "Dicembre",
                from: "dal",
                Obvezno_polje: "Campo obbligatorio",
                Podatki_dokumenta: "Dati del documento",
                Cena_stranke: "Prezzo per il cliente",
                Cena_izvajalca: "Prezzo per l\'appaltatore",
                Delete: "Cancellare",
                Delete_warning: "Sei sicuro di voler eliminare questo partner?",
                Delete_text :"Una volta eliminato, non sarà più possibile selezionare questo partner come cliente o esecutore per gli ordini.",
                Yes: "Sì",
                No: "No",
                Delete_error_title: "Non possiamo eliminare",
                Contractor_delete_error_text: "Per prima cosa è necessario eliminare tutti gli ordini e i documenti creati per questo partner.",
                Done: "Fatto!",
                Kontaktna_oseba:"Persona di contatto",
                Telefon:"Telefono",
                Faks:"Fax",
                Stranka_ali_izvajalec:"Cliente o esecutore?",
                Stranka:"Cliente",
                BackToList : "Torna all\'elenco",
                Predogled: "Anteprima",
                Preneseno: "Caricato",
                Generirano: "Generato",
                Finances: "Finanze",
                invite_message: "Per registrarsi nell\'applicazione, inserire il codice di invito:",
                invite_mailto: "Per ricevere il codice di invito, inviare una richiesta a ",
                invite_error: "Ahimè, il codice non corrisponde",
                Davek_za_DDV: "IVA",
                ID_za_DDV: "ID IVA",
                Hvala_za_zaupanje: "Grazie per la vostra fiducia",
                Znesek_prosimo_plačajte_na: "Si prega di pagare l'importo a",
                Ob_plačilu_navedite_referenco: "Si prega di fornire un riferimento al momento del pagamento",
                Skupaj : "Totale",
                Dokument : 'Documento',
                CMR:"CMR",
                Show_finished: "Mostra completata",
                save_and_stay: "Salva e continua a modificare",
                save_and_quit: "Salvare e passare all\'elenco",
                save_and_choose_performer : "Salva e passa alla selezione degli esecutori",
                here : "qui",
                agree_to_treat1 : "Accetto che FinTim mi comunichi i suoi prodotti e le sue offerte via e-mail. Posso ritirare il mio consenso in qualsiasi momento.",
                agree_to_treat2 : "Accetto che FinTim raccolga, memorizzi e utilizzi i miei dati personali (per l\'utilizzo completo dei vari servizi forniti dal sito). Posso ritirare il mio consenso in qualsiasi momento. L\'informativa sulla privacy è disponibile ",
                Offer_is_sent:"L\'offerta viene inviata",
                Offers_received: "Offerte ricevute",
                Close_this_info:"Chiudi questa informazione",
                Select_this_offer:"Scegli questa offerta",
                Obračunavanje: "Fatturazione",
                Seznam_narocil: "I miei ordini",
                Odprte_narocila : "Scambio ordini aperto",
                Seznam_voznikov: "Elenco dei conducenti",
                Zadnje_mesto_razkladanja: "L\'ultimo punto di scarico",
                Navesti_je_treba_vozilo_in_voznika: "Il veicolo e il conducente devono essere specificati!",
                Ne_morem_shraniti: "Non è possibile salvare",
                Canceled_by_contractor_text: "L\'appaltatore si è rifiutato di eseguire l\'ordine",
                Izpolnjevanje_naročila_v_teku : "Evasione dell\'ordine in corso",
                Status_spremembe: "Cambia stato",
                Nazaj_na_seznam : "Torna all\'elenco",
                Waiting_confiramation_text: "In attesa di conferma da parte dell\'appaltatore",
                Wish_change_contractor: "Se si desidera nominare un altro contraente, è possibile annullare l\'offerta.",
                Cancel_offer_tr : "Rifiuta l\'offerta",
                Cancel_offer: "Annullare l\'offerta",
                Zaseden_v_teh_dneh:"Occupato in questi giorni",
                Namigi : "Suggerimenti",
                Ni_voznikov: "Nessun autista",
                Register: "Registrazione",
                Log_in : "Accesso",
                Password : "Password di accesso",
                repeat_Password : "Ripeti la password",
                Company_name: "Nome della società",
                Predstavljam_prevozno_prevozniško_podjetje : "Rappresento un\'azienda di trasporto/vettore",
                Želim_prejemati_javne_ponudbe_za_prevoz : "Desidero ricevere offerte pubbliche di trasporto",
                Predstavljam_pošiljatelja_špediterja: "Rappresento una società di spedizionieri/spedizionieri",
                Želim_imeti_dostop_do_razpoložljivih_prevoznikov : "Voglio avere accesso ai vettori disponibili",
                Please_input_your_Username : "Inserisci il tuo nome utente!",
                Please_input_your_Email: "Inserire un'email valida",
                Please_input_your_Password : "Inserisci la tua password!",
                Please_input_your_Companyname : "Inserisci il nome della tua azienda!",
                Remember_me : "Ricordati di me",
                Forgot_password : "Dimenticato la password",
                Or: "Oppure ",
                register_now: "registrati ora!",
                Reason: "Motivo",
                Dopust : 'Ferie',
                Bolniški_dopust: 'Congedo per malattia',
                welcome : 'Benvenuto',
                Voznik : 'Autista',
                Povprečna_vrednost_naročila : 'Prezzo medio dell\'ordine',
                Število_naročil: 'Quantità di ordini',
                Zasedenost_voznega_parka : 'Carico di lavoro della flotta' ,
                Zasedenost_voznikov : 'Carico di lavoro dei conducenti',
                Naročila_na_danes: 'Offerte odierne',
                Številka :'Numero',
                Itinerarij : 'Percorso',
                Izvajalec : "Esecutore",
                Cena : 'Prezzo',
                Vozniki : 'Autisti',
                Plačila : 'Pagamenti',
                Poglejte_vse : 'Vedi tutti',
                Vsi_vozniki_so_pripravljeni_za_delo : 'Tutti gli autisti sono occupati',
                Več : 'Di più',
                Načrtovano : 'Pianificato',
                Novo : 'Nuovo',
                Izberite : 'Scegliere',
                Domov : 'Casa',
                Naročila_in_poti : 'Percorsi',
                Vozila : 'Flotta',
                Dokumenti : 'Documenti',
                Stranke :' Partner',
                Nastavitve :'Impostazioni',
                Odjava : 'Esci',
                Urejanje_voznika : 'Modifica autista',
                Dodajanje_naročila : 'Aggiungi un nuovo ordine',
                Načrtovanje_poti: 'Pianificazione del percorso',
                Urejanje_naročila : 'Modifica ordine',
                Vsa : 'Tutti',
                Nova: 'Nuovo',
                Načrtovana: 'Pianificazione',
                Confirmed: 'Confermato',
                On_route : 'In corso',
                Zaključena: 'Finito',
                Novo_naročilo : 'Nuovo ordine',
                Prenesite_v_Excel :'Scarica Excel',
                Kliknite_za_razvrščanje_padajoče : 'Clicca per ordinare in ordine crescente',
                Kliknite_za_razvrščanje_naraščajoče : 'Fare clic per ordinare in modo decrescente',
                Prekliči_razvrščanje : 'Annulla l\'ordinamento',
                nedatirano: 'Nessuna data',
                Od_datum :'Dalla data',
                Do_datum: 'Alla data',
                Išči: 'Ricerca..',
                Osnovni_podatki:'Informazioni generali',
                Pošiljatelj: 'Mittente',
                Ustvarite_novo_podjetje: 'Aggiungi una nuova azienda',
                Prejemnik : 'Ricevitore',
                Plačnik : 'Pagatore',
                Opis_tovora: 'Descrizione del carico',
                Teža :'Peso',
                Volumen: 'Volume',
                Opis: 'Info',
                Nakladanje : 'Carico',
                Razkladanje : 'Scarico',
                Dodajanje_točke_poti :'Aggiungi punto di percorso',
                Shranjevanje_naročila : 'Salva ordine',
                Vozilo : 'Veicolo',
                Dodajanje_novega_voznika:'Aggiungere un nuovo dipendente',
                Shraniti :'Salva',
                Skrijte : 'Nascondere',
                Prenesite_dokument: 'Carica documento',
                Naročilo :'Ordine',
                Potrebno_je_potrdilo:'È necessaria una conferma',
                Nadaljevanje : 'Continua',
                Nazaj : 'Indietro',
                Če_ste_dodelili_izvajalca_bo_ta_ob_urejanju_naročila_ponastavljen : 'Se il perfomer è già stato assegnato, verrà disassegnato',
                Dodajanje_vozila : 'Aggiungi un nuovo veicolo',
                Seznam_vozil : 'Elenco dei veicoli',
                Koledar : 'Calendario',
                Reg_številka: 'Numero di imm.',
                Znamka_Model : 'BMarca/Modello',
                Znamka : "Marca",
                Model : 'Modello',
                Vrsta_transporta : 'Tipo di trasporto',
                Vrsta_telesa : 'Tipo di materiale rotabile',
                Vrsta_nakladanja : 'Tipo di carico',
                Vlačilec :'Trasportatore',
                Priklopnik : 'Rimorchio',
                Tovornjak : 'Camion',
                Nagibno : 'Tendato',
                Odprto :'Aperto',
                Hladnik : 'Refrigerato',
                Nad : 'Su',
                Zadaj :'Posteriore',
                Strani : 'Laterale',
                Vse_vrste_transporta : 'Tutti i tipi di trasporto',
                Vse_tipe_telesa : 'Tutti i tipi di materiale rotabile',
                Vse_vrste_nakladanja : 'Tutti i tipi di carico',
                Po : 'Lun',
                To : 'Mar',
                Sr : 'Mer',
                Če : 'Gio',
                Pe : 'Ven',
                So : 'Sab',
                Ne : 'Dom',
                Urejanje_tega_naročila : 'Modifica questo ordine',
                Ne_shranite_sprememb : 'Non salvare',
                Podatki_o_vozilu : 'Dettagli del veicolo',
                Zgodovina_opravljenih_letov : 'Storia degli ordini',
                Ključni_kazalniki : 'Indicatori chiave',
                Datum_rojstva : 'Data di nascita',
                Vozniško_dovoljenje : 'Numero di patente',
                Novi_voznik: 'Aggiungere un nuovo dipendente',
                Ime : 'Nome',
                Priimek : 'Cognome',
                Vrsta_ddokumenta : 'Tipo di documento',
                Na_kaj_se_nanaša : 'Si riferisce a',
                Ime_datoteke : 'Nome del file',
                Vrsta : 'Tipo',
                Novi_dokument : 'Aggiungi nuovo documento',
                Nov_dokument : 'Nuovo documento',
                Račun : 'Fattura',
                Predračun : 'Preventivo',
                Za_naročilo : 'Per ordine',
                Nasprotna_stranka : 'Controparte',
                Datum_dokumenta: 'Data del documento',
                Datum_plačila : 'Data del pagamento',
                Tiskanje : 'Stampa',
                Prevoz_blaga_po_naročilu : 'Beni e servizi su ordinazione',
                Naziv_storitva_blaga : 'Prodotto o servizio',
                Količina : 'Quantità',
                Davek : 'Tassa',
                Dodajte_vrstico : 'Aggiungi posizione',
                Stranke_in_partnerji : 'Clienti e partner',
                Naziv : 'Titolo',
                Davčna_Številka : 'Codice fiscale',
                Matična_Številka : 'Codice azienda',
                Naslov : 'Indirizzo',
                Nov_partner : 'Nuovo partner',
                Urejanje_podatkov_o_partnerju : 'Modifica dei dati del contraente',
                Podatki_o_partnerju : 'Dati del contraente',
                Nastavitve_uporabnika : 'Impostazioni dell\'utente',
                Nastavitve_podjetja : 'Impostazioni dell\'azienda',
                Prenesite_sliko : 'Carica immagine',
                Prenesete_lahko_slike_velikosti_do : 'Si prega di caricare un\'immagine non superiore a',
                E_pošta : 'Email',
                Login : 'Accesso',
                Staro_geslo : 'Vecchia password',
                Novo_geslo: 'Nuova password',
                Žig : 'Timbro',
                Podpis :'Firma',
                Prenesite_datoteko_ali_povlecite : 'Caricare il file o trascinare',
                in_spusti_na_označeno_območje : 'in quest\'area',
                ne_več_kot : 'non superiore a ',
                Tehnični_potni_list : 'Passaporto tecnico',
                Zavarovanje : 'Assicurazione',
                Drug_dokument : 'Altro documento',
                Pogodba : 'Accordo'

            }
        },
        uk:{
            translation: {
                Currency: "Валюта",
                Unit: "Одиниця виміру",
                CurrentStock: "Поточний рівень запасів",
                Cost: "Собівартість",
                Price: "Ціна",
                Products: "Товари",
                Article_not_specified: "Стаття не вказана",
                Upload: "Завантажити",
                Download: "Скачати",
                Schedule_payment_to_contractor: "Складіть графік виплат підряднику",
                Article: "Стаття",
                Description: "Опис",
                Scheduled: "Планова",
                Attach_scan: "Прикріпити скан",
                Planner: "Планувальник",
                Statement: "Виписки",
                Create_invoice: "Створити рахунок",
                Schedule_receipt: "Запланувати оплату",
                Goods_and_services_on_order: "Товари та послуги під замовлення",
                Scenario : "Сценарій",
                Created:"Створено замовлення",
                Modified:"Внесено зміни",
                DocAdded:"Додано документ",
                IncomePlanned:"Заплановано надходження",
                ExpencePlanned:"Планується платіж",
                IncomeHappened:"Дохід отримано",
                ExpenceHappened:"Виплату здійснено",
                StatusModified:"Статус змінено",
                Taxes: "Податки",
                Profit_withdrawal : "Виведення прибутку",
                Narocila: "Замовлення",
                Contractor: "Підрядник",
                Status: "Статус",
                Employee: "Співробітник",
                Employees: "Співробітники",
                Deals: "Угоди",
                Deal: "Угода",
                Accept: "Прийняти",
                Expenses_by_item: "Витрати за статтями",
                Date: "Дата",
                Amount: "Сума",
                Cas_injection: "Введення грошей",
                Revenue: "Виручка",
                Rent: "Оренда",
                Salary: "Зарплата",
                Payment_for_services: "Оплата послуг",
                Purchase_of_goods: "Купівля товарів",
                Other_expenses: "Інші витрати",
                Account: "Рахунок",
                Incomes: "Доходи",
                Expenses: "Витрати",
                Balance: "Баланс",
                Income: "Дохід",
                Expense: "Витрата",
                Show_charts: "Показати графіки",
                Reports: "Звіти",
                Consider_planned: "Враховувати планові операції",
                DDS_Rep: "Звіт РГК",
                DDS_report: "Звіт про рух грошових коштів",
                January: "Січень",
                February: "Лютий",
                March: "Березень",
                April: "Квітень",
                May: "Травень",
                June: "Червень",
                July: "Липень",
                August: "Серпень",
                September: "Вересень",
                October: "Жовтень",
                November: "Листопад",
                December: "Грудень",
                from: "з",
                Obvezno_polje: "Обов'язкове поле",
                Podatki_dokumenta: "Дані документа",
                Cena_stranke: "Ціна для клієнта",
                Cena_izvajalca: "Ціна для виконавця",
                Delete: "Видалити",
                Delete_warning: "Ви дійсно хочете видалити цього партнера?",
                Delete_text :"Після видалення ви більше не зможете обирати цього партнера як замовника або виконавця для замовлень",
                Yes: "Так",
                No: "Ні",
                Delete_error_title: "Ми не можемо видалити",
                Contractor_delete_error_text: "Спочатку вам потрібно видалити всі замовлення і документи, які були створені для цього партнера.",
                Done: "Готово!",
                Kontaktna_oseba:"Контактна особа",
                Telefon:"Телефон",
                Faks:"Факс",
                Stranka_ali_izvajalec:"Клієнт чи виконавець?",
                Stranka:"Клієнт",
                BackToList : "Назад к списку",
                Predogled: "Перегляд",
                Preneseno: "Завантажено",
                Generirano: "Згенеровано",
                Finances: "Фінанси",
                invite_message: "Для реєстрації в додатку, будь ласка, ведіть код запрошення:",
                invite_mailto: "Щоб отримати код запрошення, надішліть запит на ",
                invite_error: "На жаль, код не підходить",
                Davek_za_DDV: "ПДВ",
                ID_za_DDV: "IНП ПДВ",
                Hvala_za_zaupanje: "Дякуємо за довіру",
                Znesek_prosimo_plačajte_na: "Будь ласка, перерахуйте суму на рахунок",
                Ob_plačilu_navedite_referenco: "При здійсненні платежу, будь ласка, вкажіть реквізити",
                Skupaj : "Всього",
                Dokument : 'Документ',
                CMR:"CMR",
                Show_finished: "Показувати завершені",
                save_and_stay: "Зберегти та продовжити редагування",
                save_and_quit: "Зберегти і перейти до списку",
                save_and_choose_performer : "Зберегти та перейти до вибору виконавця",
                here : "тут",
                agree_to_treat1 : "Я згоден з тим, що FinTim повідомляє мені про свої продукти та пропозиції електронною поштою. Я можу відкликати свою згоду в будь-який час.",
                agree_to_treat2 : "Я згоден з тим, що FinTim збирає, зберігає, використовує мої особисті дані (для повноцінного користування різними сервісами, що надаються сайтом). Я можу відкликати свою згоду в будь-який час. З політикою конфіденційності можна ознайомитися",
                Offer_is_sent:"Пропозиція відправлена",
                Offers_received: "Отримані пропозиції",
                Close_this_info:"Закрити цю інформацію",
                Select_this_offer:"Виберіть цю пропозицію",
                Obračunavanje: "Білінг",
                Seznam_narocil: "Мої замовлення",
                Odprte_narocila : "Біржа відкритих замовлень",
                Seznam_voznikov: "Список водіїв",
                Zadnje_mesto_razkladanja: "Останнє місце розвантаження",
                Navesti_je_treba_vozilo_in_voznika: "Необхідно вказати транспортний засіб і водія!",
                Ne_morem_shraniti: "Не виходить зберегти",
                Canceled_by_contractor_text: "Підрядник відмовився від виконання замовлення",
                Izpolnjevanje_naročila_v_teku : "Триває виконання замовлення",
                Status_spremembe: "Змінити статус",
                Nazaj_na_seznam : "Повернутися до списку",
                Waiting_confiramation_text: "Очікуємо підтвердження від виконавця.",
                Wish_change_contractor: "Якщо Ви хочете призначити іншого виконавця, то можете відкликати пропозицію.",
                Cancel_offer_tr : "Відхилити пропозицію",
                Cancel_offer: "Відкликати пропозицію",
                Zaseden_v_teh_dneh: "Зайнятий у ці дні",
                Namigi : "Підказки",
                Ni_voznikov: "Немає водіїв",
                Register: "Реєстрація",
                Log_in : "Логін",
                Password : "Пароль",
                repeat_Password : "Повторіть пароль",
                Company_name: "Назва компанії",
                Predstavljam_prevozno_prevozniško_podjetje : "Я представляю транспортну компанію/перевізника",
                Želim_prejemati_javne_ponudbe_za_prevoz : "Я хочу отримувати публічні пропозиції щодо перевезень",
                Predstavljam_pošiljatelja_špediterja: "Я представляю компанію вантажовідправника/експедитора",
                Želim_imeti_dostop_do_razpoložljivih_prevoznikov : "Я хочу мати доступ до доступних перевізників",
                Please_input_your_Username : "Будь ласка, введіть ваше ім'я користувача!",
                Please_input_your_Email: "Будь ласка, введіть дійсну адресу електронної пошти!",
                Please_input_your_Password : "Будь ласка, введіть ваш пароль!",
                Please_input_your_Companyname : "Будь ласка, введіть назву вашої компанії!",
                Remember_me : "Запам'ятати мене",
                Forgot_password : "Забули пароль?",
                Or: "Або ",
                register_now: "зареєструйтеся зараз!",
                Reason: "Причина",
                Dopust : "Відпустка",
                Bolniški_dopust: "Лікарняний",
                welcome : "Ласкаво просимо",
                Voznik : "Водій",
                Povprečna_vrednost_naročila : "Середня ціна замовлення",
                Število_naročil: "Кількість замовлень",
                Zasedenost_voznega_parka : "Завантаженість автопарку",
                Zasedenost_voznikov : "Завантаженість водіїв",
                Naročila_na_danes: "Замовлення на сьогодні",
                Številka : "Номер",
                Itinerarij : "Маршрут",
                Izvajalec : "Виконавець",
                Cena : "Ціна",
                Vozniki : "Водії",
                Plačila : "Виплати",
                Poglejte_vse : "Переглянути всі",
                Vsi_vozniki_so_pripravljeni_za_delo : "Усі водії зайняті",
                Več : "Більше",
                Načrtovano : "Заплановано",
                Novo : "Нові",
                Izberite : "Обрати",
                Domov : "Головна",
                Naročila_in_poti : "Рейси",
                Vozila : "Автопарк",
                Dokumenti : "Документи",
                Stranke : "Партнери",
                Nastavitve : "Налаштування",
                Odjava : "Вихід",
                Urejanje_voznika : "Редагувати водія",
                Dodajanje_naročila : "Додати нове замовлення",
                Načrtovanje_poti: "Планування маршруту",
                Urejanje_naročila : "Редагувати замовлення",
                Vsa : "Усі",
                Nova: "Нові",
                Načrtovana: "Планування",
                Confirmed: "Підтверджено",
                On_route : "У процесі",
                Zaključena: "Завершено",
                Novo_naročilo : "Нове замовлення",
                Prenesite_v_Excel : "Завантажити Excel",
                Kliknite_za_razvrščanje_padajoče : "Натисніть для сортування за зростанням",
                Kliknite_za_razvrščanje_naraščajoče : "Натисніть для сортування за спаданням",
                Prekliči_razvrščanje : "Скасувати сортування",
                nedatirano: "без дати",
                Od_datum : "Від дати",
                Do_datum: "До дати",
                Išči: "Пошук...",
                Osnovni_podatki: "Загальна інформація",
                Pošiljatelj: "Відправник",
                Ustvarite_novo_podjetje: "Додати нову компанію",
                Prejemnik : "Одержувач",
                Plačnik : "Платник",
                Opis_tovora: "Опис вантажу",
                Teža : "Вага",
                Volumen: "Обсяг",
                Opis: "Інформація",
                Nakladanje : "Навантаження",
                Razkladanje : "Розвантаження",
                Dodajanje_točke_poti : "Додати точку маршруту",
                Shranjevanje_naročila : "Зберегти замовлення",
                Vozilo : "Транспортний засіб",
                Dodajanje_novega_voznika: "Додати нового співробітника",
                Shraniti : "Зберегти",
                Skrijte : "Приховати",
                Prenesite_dokument: "Завантажити документ",
                Naročilo : "Замовлення",
                Potrebno_je_potrdilo: "Необхідне підтвердження",
                Nadaljevanje : "Продовжити",
                Nazaj : "Назад",
                Če_ste_dodelili_izvajalca_bo_ta_ob_urejanju_naročila_ponastavljen : "Якщо ви вже призначили виконавця, то потрібно буде призначити його знову",
                Dodajanje_vozila : "Додасть новий транспортний засіб",
                Seznam_vozil : "Список транспортних засобів",
                Koledar : "Календар",
                Reg_številka: "Рег. номер",
                Znamka_Model : "Марка/модель",
                Znamka : "Марка",
                Model : "Модель",
                Vrsta_transporta : "Тип транспорту",
                Vrsta_telesa : "Тип кузова",
                Vrsta_nakladanja : "Тип навантаження",
                Vlačilec : "Тягач",
                Priklopnik : "Причіп",
                Tovornjak : "Вантажівка",
                Nagibno : "Тентований",
                Odprto : "Відкритий",
                Hladnik : "Рефрижератор",
                Nad : "Верхня",
                Zadaj : "Задня",
                Strani : "Бічна",
                Vse_vrste_transporta : "Усі типи транспорту",
                Vse_tipe_telesa : "Усі типи кузова",
                Vse_vrste_nakladanja : "Усі типи навантаження",
                Po : "ПН",
                To : "ВТ",
                Sr : "СР",
                Če : "ЧТ",
                Pe : "ПТ",
                So : "СБ",
                Ne : "ВС",
                Urejanje_tega_naročila : "Відредагуйте це замовлення",
                Ne_shranite_sprememb : "Не зберігати зміни",
                Podatki_o_vozilu : "Детальна інформація про ТС",
                Zgodovina_opravljenih_letov : "Історія замовлень",
                Ključni_kazalniki : "Ключові показники",
                Datum_rojstva : "Дата народження",
                Vozniško_dovoljenje : "Посвідчення водія",
                Novi_voznik: "Додати нового співробітника",
                Ime : "Ім'я",
                Priimek : "Прізвище",
                Vrsta_ddokumenta : "Тип документа",
                Na_kaj_se_nanaša : "Належить до",
                Ime_datoteke : "Ім'я файлу",
                Vrsta : "Тип",
                Novi_dokument : "Додати новий документ",
                Nov_dokument : "Новий документ",
                Račun : "Рахунок-фактура",
                Predračun : "Рахунок",
                Za_naročilo : "За замовленням",
                Nasprotna_stranka : "Контрагент",
                Datum_dokumenta: "Дата документа",
                Datum_plačila : "Дата платежу",
                Tiskanje : "Друк",
                Prevoz_blaga_po_naročilu : "Товари та послуги на замовлення",
                Naziv_storitva_blaga : "Товар або послуга",
                Količina : "Кількість",
                Davek : "ПДВ",
                Dodajte_vrstico : "Додати позицію",
                Stranke_in_partnerji : "Клієнти та партнери",
                Naziv : "Назва",
                Davčna_Številka : "ІПН",
                Matična_Številka : "Id компанії",
                Naslov : "Адреса",
                Nov_partner : "Новий партнер",
                Urejanje_podatkov_o_partnerju : "Редагування даних про партнера",
                Podatki_o_partnerju : "Дані про партнера",
                Nastavitve_uporabnika : "Налаштування користувача",
                Nastavitve_podjetja : "Налаштування компанії",
                Prenesite_sliko : "Завантажити зображення",
                Prenesete_lahko_slike_velikosti_do : "Будь ласка, завантажте зображення не більше",
                E_pošta : "Електронна пошта",
                Login : "Логін",
                Staro_geslo : "Старий пароль",
                Novo_geslo: "Новий пароль",
                Žig : "Друк",
                Podpis : "Підпсіь",
                Prenesite_datoteko_ali_povlecite : "Завантажте файл або перетягніть його",
                in_spusti_na_označeno_območje : "його в цю область",
                ne_več_kot : "не більше, ніж",
                Tehnični_potni_list : "Тех. паспорт",
                Zavarovanje : "Страховка",
                Drug_dokument : "Інший документ",
                Pogodba : "Договір",
            }
        },

    },
    lng: 'en',
    debug: true,
})

export default i18n;