import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {Button, Col, Divider, Dropdown, Form, Input, InputNumber, MenuProps, Row, Select, Spin, Switch} from "antd";
import React, {useEffect, useState} from 'react';
import Deal from "../../../types/Deal";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import Product from "../../../types/Product";
import {DownOutlined} from "@ant-design/icons";
import {dateForServerFromDate, ServerdateToPoints} from "../../../utils/helpers";
import dayjs from "dayjs";
import {dateToPoints} from "../../../types/transporter-types";
import ProductSelect from "../../docs/DocComponents/ProductSelect";


interface createParams {
    createId? : string;
    aftercreate? : (data: any) => void;
}

interface Params {
    id: string;
}
export default function ProductDetailPage({createId, aftercreate}: createParams) {
    const {t} = useTranslation();
    const {id} = useParams<Params>()
    
  

    const history = useHistory();

    const user = AuthService.getCurrentUser();
    if (!user) {
        history.push("/login");
    }
    const [form] = Form.useForm();


    const [isLoading, setisLoading] = useState(false);
    const[isProductVal, setisProductVal]=useState( false);






    const [theProduct, setTheProduct] = useState<Product>({
        id: id === "0" ? "00000000-0000-0000-0000-000000000000" : createId!==undefined ? "00000000-0000-0000-0000-000000000000" : id,
    });



    useEffect(()=> {
        if (id !== "0" && isLoading && createId===undefined) {
            setisLoading(true)
            axios.get(AuthService.DATA_URL + 'Products/get-product?id=' + id, 
                {headers: authHeader()})
                .then((response) => {
                    setTheProduct(response.data);
                    form.setFieldsValue({
                        title: response.data ? response.data.title : "",
                        description: response.data ? response.data.description : "",
                        productType: response.data ? response.data.productType : "",
                        unit: response.data ? response.data.unit : "",
                        vatRate: response.data ? response.data.vatRate : "",
                        currency: response.data ? response.data.currency : "",
                        country: response.data ? response.data.country : "",
                        priceForClient : response.data ? response.data.priceForClient : "",
                        purchasePrice: response.data ? response.data.purchasePrice : "",

                    });
                    
                    
                    setisLoading(false)
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                })
        }
        
    });




    const showval=(v: any)=>{
        console.log("v: ",v);
        console.log("FieldValue: ",form.getFieldValue("isClient"))
        setisProductVal(v);

    }

    const saveItems: MenuProps['items'] = [
        { key: "1",  label: <span>{t("save_and_stay")}</span> },
        { key: "2",  label: <span>{t("save_and_quit")}</span> },
    ]
    const handleSaveOption: MenuProps['onClick'] = (e) => {
        console.log("statis change: ",e)
        onFinish(null, e.key)

    }

    const OnChange=(values: any)=>{
        //console.log("On change form: ", theProduct);
        //console.log(form.getFieldValue("planPaydate"))
        setTheProduct(prev => ({
            ...prev,
            title: form.getFieldValue("title"),
            description: form.getFieldValue("description"),
            productType: form.getFieldValue("productType"),
            unit: form.getFieldValue("unit"),
            vatRate: form.getFieldValue("vatRate"),
            currency: form.getFieldValue("currency"),
            country: form.getFieldValue("country"),
            priceForClient :form.getFieldValue("priceForClient"),
            purchasePrice :form.getFieldValue("purchasePrice"),
        }));

    }



    const onFinish = (values: any, goto?: string|undefined) => {
        setisLoading(true);
        
        console.log("theProduct: ", theProduct)
        if(theProduct.id==="00000000-0000-0000-0000-000000000000"){
            axios.post(AuthService.DATA_URL+'Products/',
                theProduct,{headers: authHeader()})
                //.then((response)=> json(response))
                .then((response) =>{
                    console.log(response)
                    if(aftercreate){
                        aftercreate(response.data)
                    }
                    else{
                        if(parseInt(goto? goto: "2")===1){
                            history.push("/products/"+response.data)
                        }
                        else{
                            history.push("/products")
                        }
                    }
                    
                    setisLoading(false);
                })
        }else{
            axios.put(AuthService.DATA_URL+'Products/'+id,
                theProduct,{headers: authHeader()})
                .then()
                .then((response) =>{
                    if(parseInt(goto? goto: "2")===1){
                        history.push("/products/"+theProduct.id)
                    }
                    else{
                        history.push("/products")
                    }
                    setisLoading(false);
                })

        }
        console.log(theProduct);
    }
    
    const goBack=()=>{
        if(aftercreate)
        {
            aftercreate(null);
        }
        else{
            history.push("/products");
        }
    }
    


    return( <Spin spinning={isLoading}>
        
        
        <h1>Новый товар/услуга</h1>

        <Form
            form={form}
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 12 }}
            onFinish={onFinish}
            onChange={OnChange}
            style={{ maxWidth: "600" }}
            initialValues={{
                ["productType"]:theProduct.productType,
                ["title"]: theProduct.title,
                ["unit"]: theProduct.unit,
                ["vatRate"]: theProduct.vatRate,
                ["currency"]: theProduct.currency,
                ["priceForClient"] : theProduct.priceForClient,
                ["purchasePrice"] : theProduct.purchasePrice

        }}


        >
            <Row>
                <Col span={aftercreate? 24 : 8}>
                    <Form.Item
                        name="productType"
                        label={"Service or good?"}
                        valuePropName="checked"
                    >
                        <Switch
                            style={{float:"right"}}
                            checkedChildren={<span style={{backgroundColor: "#1b7884 !important"}}>{t("Products")}</span>}
                            unCheckedChildren={<span style={{backgroundColor: "#1c6ef3 !important"}}>{t("Products")}</span>}
                            onChange={(e)=>showval(e)}
                            checked={true}
                           
                
                            //defaultChecked
                        />
                    </Form.Item>

                    <Form.Item
                        name="title"
                        label={t("Naziv")}
                        rules={[{ required: true }, { type: 'string', warningOnly: true }]}
                    >
                        <Input placeholder="input placeholder" />
                    </Form.Item>
                    <Form.Item name="unit" label="Ед. изм.">
                        <Select
                            options={[
                                { label: "шт", value: "PCE" },
                                { label: "час", value: "HUR" },
                                { label: "килограмм", value: "KGM" },
                                { label: "метр", value: "MTR" },
                                { label: <span>м<sup>3</sup></span>, value: "MTQ" }
                            ]}
                            onChange={(e)=>OnChange(e)}
                            //onChange={(e) => ShowContractorDoc(e)}
                        />
                    </Form.Item>



                    <Form.Item name="vatRate" label="НДС">
                        <Select
                            options={
                            [
                                { label: "22%", value: 2200 },
                                { label: "9.5%", value: 950 }, 
                                { label: "8%", value: 800 },
                                { label: "5%", value: 500 },
                                { label: "Exempted 0%", value: 0 },
                                { label: "Non-taxable 0%", value: 1 },
                            ]}
                            //onChange={SavePosition}
                            //onChange={(e) => ShowContractorDoc(e)}
                        />
                    </Form.Item>
                    <Form.Item name="currency" label="Валюта">
                        <Select
                            options={[{ label: "EUR", value: "EUR" },{ label: "USD", value: "USD" }, ] }
                            onChange={(e)=>OnChange(e)}
                        />
                    </Form.Item>
                    <Form.Item name="purchasePrice"   label={t("Cost")}  >
                        <InputNumber value={theProduct.purchasePrice}
                                     onChange={(e)=>OnChange(e)}
                                     suffix="€" />
                    </Form.Item>
                    <Form.Item name="priceForClient"   label={t("Price")}  >
                        <InputNumber value={theProduct.priceForClient}
                                     onChange={(e)=>OnChange(e)}
                                     suffix="€" />
                    </Form.Item>
                    <Divider/>
                    <Button className={"emptyButton"} onClick={()=>{ goBack()}} style={{}}>
                        {t("Ne_shranite_sprememb")}
                    </Button>


                    {
                        aftercreate?
                            <Button className={"orangeButton"}
                                    onClick={()=> {
                                        onFinish(null, "3")
                                    }}
                                    style={{ float:"right",/*marginTop:"25px", marginRight:"50px",*/ }}>
                                {t("Shraniti")}
                            </Button>
                            
                            :
                            <Dropdown menu={{'items': saveItems, onClick: handleSaveOption }} >
                                <Button className={"orangeButton"}
                                        onClick={()=> {
                                            onFinish(null, "2")
                                        }}
                                        style={{ float:"right",/*marginTop:"25px", marginRight:"50px",*/ }}>
                                    {t("Shraniti")}

                                    <DownOutlined />
                                </Button>
                            </Dropdown>
                            
                            
                    }
                    



                </Col>
                
                
                
                <Col>
             
                </Col>
            </Row>


    </Form>
    </Spin>)
}