import React, {useEffect, useState} from 'react';
import {Button, Table} from 'antd';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";
import DealsFilter from "../../types/DealsListFilter";
import ProductListFilter from "./Components/ProductListFilter";
import ProductList from "./Components/ProductList";

const ProductsPage = () => {
    const XLSX = require('xlsx');
    const {t} = useTranslation();
    const history= useHistory();

    ///

    const user = AuthService.getCurrentUser();
    if(!user){
        AuthService.logout();
        history.push("/login");
        window.location.reload();

    }

    const [pageFilter, setPageFilter]=useState<DealsFilter>({
        searchText: "",
        dateFrom: "",
        dateTo: "",
        hidefinished:false,
        requestStatus: 100,
        selectedDates: "",
    })

    const setCBFilterFunc=(filter: any)=>{
        setPageFilter(filter);
        //console.log("page comp:", filter)
    }

    return(

        <>
            <h1>{t("Products")}</h1>

            <ProductListFilter setFilterFunc={setCBFilterFunc} />
            <Button onClick={()=>history.push("products/0")}  className={"orangeButton"} style={{float: "right"}}>+ {t("Novo_naročilo")}</Button>

            <ProductList filter={pageFilter}  />
        </>
    )


}

export default ProductsPage