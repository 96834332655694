import React, {useEffect, useState} from 'react';

import {Button, Space, Table, Input, Tooltip, Modal, Badge, Collapse, Select, Row, Col, List, Popover} from 'antd';
import {DownOutlined, SafetyCertificateOutlined, SearchOutlined} from '@ant-design/icons';

import type { ColumnsType } from 'antd/es/table';
import {Link, useHistory} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";
import axios from "axios/index";
import authHeader from "../../Services/auth-header";
import {CollapsibleType} from "antd/es/collapse/CollapsePanel";
import {useTranslation} from "react-i18next";
import ShowDoc from "./DocComponents/ShowDoc";
import {ServerdateToPoints, ToMoney} from "../../utils/helpers";
import Doc from "../../types/DocPosition";



interface DataType {
    key: string;
    id: string;
    docNumber : string;
    fileName: string;
    request: any;
    vrequest:string;
    truck: any;
    vtruck : string;
    driver: any;
    vdriver : string;
    routePoint : any;
    requestReply : any;
    documentOrigin : number;
    documentType : number;
}



const takeFileName =(text : string) =>{
    if(text!=null){
        const splitted = text.split('_');
        return splitted[3];
    }
    return "";
}






const clearAll = () => {
//    setSortedInfo({});
};









export  const DocsPage = () => {

    const {t} = useTranslation();

    const history= useHistory();
    const user = AuthService.getCurrentUser();
    if(!user){
        history.push("/login");
    }
    
    
    
    const mapEnumToString=(i : number)=>{
        switch ( i ) {
            case 0:
                return "Drugačen dokument"
                break;
            case 10:
                return t("Račun")
                break;
            case 11:
                return t("CMR")
                break;
            case 20:
                return "Truck foto"
                break;
            case 21:
                return "Truck license"
                break;
            case 22:
                return "Truck insuarence"
                break;
            case 30:
                return "Driver foto"
                break;
            case 31:
                return "Driver license"
                break;
            case 32:
                return "Driver insuarence"
                break;
            default:
                return "Drugačen dokument"
                break;
        }
    }


    const mapObjectTitle = (record : any)=>{

        if(record.truck){
            return (record.truck.brand+' '+ record.truck.model+' '+ record.truck.regNumber)
        }
        if(record.driver){
            return (record.driver.name+' '+ record.driver.surname)
        }
        if(record.request){
            return (t('Naročilo')+' '+record.requestNumber)
            //return (t('Naročilo')+' '+record.vrequest)
        }

        return ("");
    }


    const [searchText, setSearchText] = useState("")
    const [showFilterBadge, setshowFilterBadge] = useState(false)

    const [selectedType, setselectedType] = useState(100)


    const columns: ColumnsType<Doc> = [
        {
            key: 1,
            title: t('Vrsta_ddokumenta'),
            dataIndex: 'id',
            width: 200,
            filteredValue :[searchText],
            onFilter: (value, record) => {
                return (
                    String(record.counterPart? record.counterPart.title : "" ).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.request? record.request.requestNumber : "").toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.docNumber).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.request? record.request.title : "").toLowerCase().includes(value.toString().toLowerCase())
                )
            },

            //sorter: (a, b) => a.name.length - b.name.length,
            //sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
            
            
            render : (text, record)  =>
                <div>
                    
                    <Popover placement="topLeft"
                             content={
                        <div>
                            {record.docPositions?
                                <List
                                    dataSource={record.docPositions}
                                    renderItem={(item) =>
                                    {
                                        console.log(item.naziv)
                                        return (
                                            <List.Item>
                                                {item.naziv+": "+item.kolicina+" x "+ToMoney(item.cena? item.cena : 0)}
                                            </List.Item>
                                        )                                        
                                    }
}
                                />
                                : ""}
                        </div>
                    }
                    >
                    <Link className={"actionLink"} to={`/docs/${record.id}`}>
                        <span>{mapEnumToString(record.documentType? record.documentType : 0)+" "+record.docNumber}</span>
                    </Link><br/>
                    <span style={{fontSize: "13px", color: "#999" }}>{t("from")+" "+ServerdateToPoints(record.documentDate)}</span>
                    </Popover>

                </div>
 
//        <a href={"docs/createDoc?id="+record.id }></a>
        },
        {
            key: 2,
            title: t('Naročilo'),
            dataIndex: 'request',
            width: 330,
            filteredValue :[selectedType],
            onFilter: (value, record) => {
                //console.log(record)
                return (record.documentType ? record.documentType : 0).toString()===value.toString() || (value.toString()==="100")
            },

            render : (text, record)  =>
                <div style={{marginLeft:"0px", /*width: "120px"*/}}>
                    {
                        record.request?<span>
                            <SafetyCertificateOutlined/>
                            <span style={{marginLeft:"18px"}}>{t("Naročilo")+" "}
                                {record.request.requestNumber}<br/>
                                 <Tooltip placement="topLeft" title={record.request.cargoDescription}>
                                     <span style={{color: "#999"}}>{record.request.title}<br/></span>
                                </Tooltip> 
                                                
                                
                                
                                
                                    </span>
                        </span>
                            :"-"
                    }
                    
                    
                    
             
                </div>

        },
        {
            title: t('Nasprotna_stranka'),
            dataIndex: 'documentOrigin',
            width: 150,
            render : (text, record)  => <span>{record.counterPart?  record.counterPart.title : "-"}</span>
        },
        {
            title: t('Amount'),
            dataIndex: 'documentOrigin',
            width: 150,
            align: "right",
            render : (text, record)  =>
                <span>
                    {   record.docPositions?
                        ToMoney(record.docPositions.reduce((sum, current) => sum + (current? (current.cena? current.cena : 0)*(current.kolicina? current.kolicina : 0) : 0), 0)) 
                        :
                        "-"
                    }

                    <br/> 
                    {
                        <span style={{color: "#999"}}>
                        {record.transactions ?
                            ToMoney(record.transactions.reduce((sum, current) => sum + (current.amount ? current.amount : 0), 0))

                            : ToMoney(0)}
                        </span>
                    }
                
                
                </span>
        },
        {
            title: ' ',
            key: 'operation',
            fixed: 'right',
            width: 200,
            render: (text, record) =>
                <div>
                    <Button onClick={()=>showPreview(record.id ? record.id : "", record.documentOrigin ? record.documentOrigin : 0)}>{t("Predogled")}</Button>
                </div>
        },
    ];

    //const data: DataType[] = [];

    
    const [isModalOpen, setisModalOpen] = useState(false);
    const [isPreviewOpen, setisPreviewOpen] = useState(false);
    
    const[_requestId, set_requestId]=useState();
    const[rerender, setrerender]=useState(false);
    const[_documentId, set_documentId]=useState();

    const[_documentType, set_documentType]=useState();
    
    

    
    
    const  showPreview=(id : string, origin: number)=>{

        //axios.get(AuthService.DATA_URL+`Docs/get-doc?id=`+id, {headers: authHeader()})
        //setisModalOpen(true)
        
        //setisPreviewOpen(true);
        if(origin===1){
            fetch(AuthService.DATA_URL+`Docs/get-doc?id=`+id, {headers: authHeader()})
                .then((response) => response.blob())
                .then((blob) => {
                    console.log(blob);
                    const imageUrl = URL.createObjectURL(blob);
                    const imageElement = document.createElement("img");
                    imageElement.src = imageUrl;
                    const container = document.getElementById("imgContainer");
                    // @ts-ignore
                    container.innerHTML='';
                    // @ts-ignore
                    container.appendChild(imageElement);
                });
            setisModalOpen(true)
        }
        if(origin===0){
            axios.get(AuthService.DATA_URL+'docs/get-doc-dto?id='+id, {headers: authHeader()})
                .then((response)=>{
                    console.log(response.data);
                    set_documentType(response.data.documentType);
                    set_requestId(response.data.requestId);
                    setrerender(!rerender)
                    set_documentId(response.data.id);
                    setisPreviewOpen(true);
                    
                })
        }
            


    }
    
    const handleModalCancel=()=>{
        setisModalOpen(false);
    }
    
    const [APIdata, setAPIData] = useState<Doc[]>([]);
    const [isLoading, setisLoading] = useState(true);


    useEffect(() => {
        if(data.length===0){
            axios.get(AuthService.DATA_URL+`Docs/get-docs`, {headers: authHeader()})
                .then((response) => {
                    console.log("response.data: ", response.data)

                    setAPIData(response.data);
                    setisLoading(false);
                })
                .catch((r)=>{
                    console.log(r);
                    console.log(r.response);
                    //if(r.response.status ===401){
                    //    history.push("/login");
                   // }
                    // history.push()
                })
        }
    }, []);
    
    
    const data = APIdata.map((tr, index) => ({
        key: tr.id,
        docNumber : tr.docNumber===null? "" : " №"+tr.docNumber,
        id: tr.id,
        fileName: tr.fileName,
        request: tr.request,
        vrequest:tr.request ? ( tr.request.createdByContractorId===user.contractorId ? tr.request.requestNumber : tr.request.externalRequestNumber ): "",
        truck: tr.truck,
        vtruck: mapObjectTitle(tr), //.truck ? tr.truck.regNumber : "",
        driver: tr.driver,
        vdriver: tr.driver ? tr.driver.name +" "+tr.driver.surname : "",
        routePoint : tr.routePoint,
        requestReply : tr.requestReply,
        documentOrigin : tr.documentOrigin,
        documentType : tr.documentType 

    }) )







    const newDriver = () => {
        history.push("docs/createdoc");
    };
    const [filterShow, setfilterShow] = useState('disabled' as CollapsibleType)
    const [filterKey, setfilterKey] = useState(["0"])



    const ShowFilters=()=>{
        console.log("filterShow: ", filterShow);
        if(filterShow.toString()==="disabled"){
            setfilterShow("head" as CollapsibleType);
            setfilterKey(["1"]);
            //setfilterShow("disabled" as CollapsibleType);
        }
        else{
            setfilterKey(["0"]);
            setfilterShow("disabled" as CollapsibleType);
        }
    }






    const [modal1Open, setModal1Open] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);



    return (
        <div>
            <h1>{t("Dokumenti")}</h1>



            {/* 
            <Space style={{ marginBottom: 0 }}>
                <Badge color={"orange"} dot={showFilterBadge}>
                    <Button className={"emptyB"} onClick={ShowFilters}>
                        <Space>
                            Filtri
                            <DownOutlined />
                        </Space>
                    </Button>
                </Badge>
            </Space>*/}
            
            <Row>
                <Col span={24}>
                    <Space style={{float:'right'}}>
                        <Input.Search className={"emptyB"}
                                      placeholder={t("Išči")}
                                      onSearch={(value)=>{
                                          setSearchText(value)
                                      }}
                                      onChange={(e)=>{
                                          setSearchText(e.target.value)
                                      }}

                            //suffix={
                            //    <Tooltip title="Extra information">
                            //        <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                            //    </Tooltip>
                            //}
                        />

                        <Button onClick={newDriver}  className={"orangeButton"}>+ {t("Novi_dokument")}</Button>
                        <Button onClick={clearAll}  className={"emptyButton"}>{t("Prenesite_v_Excel")}</Button>

                    </Space>
                </Col>
            </Row>
          
           
            <Collapse
                ghost
                collapsible={filterShow}
                activeKey={filterKey}
                items={
                    [{
                        key: "1",
                        label: "",
                        children: <div>
                            <Select
                                defaultValue={selectedType}
                                bordered={false}
                                dropdownMatchSelectWidth={false}
                                options={[
                                    { value: 100, label:'Vse vrste dokumentov' },
                                    { value: 0, label: 'Drugačen dokument' },
                                    { value: 10, label: 'Racun' },
                                    { value: 11, label: 'CMR' },
                                    { value: 20, label: 'Truck foto' },
                                    { value: 21, label: 'Truck license' },
                                    { value: 22, label: 'Truck insuarence' },
                                    { value: 30, label: 'Driver foto' },
                                    { value: 31, label: 'Driver license' },
                                    { value: 32, label: 'Driver insuarence' },
                                ]}
                                onChange={(v: number) => {
                                    setshowFilterBadge(true);
                                    if (v === 100) {
                                        if(selectedType===100)
                                            setshowFilterBadge(false);
                                    }
                                    setselectedType(v)
                                }}
                            />

                        </div>,
                        showArrow: false,
                    }]
                }
            />


            <div style={{background: '#fff'}} id={"driverstab"}>

                <Table
                    locale={{
                        triggerDesc: 'Нажмите, чтобы отсортировать по убыванию',
                        triggerAsc: 'Нажмите, чтобы отсортировать по возрастанию',
                        cancelSort: 'Отменить сортировку'
                    }}
                    columns={columns}
                    dataSource={APIdata}
                    loading={isLoading}
                    scroll={{ y: 'calc(63vh)' }}
                    pagination={false}
                    //pagination={{ pageSize: 7 }} size="small"  scroll={{ y: 'calc(60vh - 4em)' }}
                   /*
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => {history.push("/docs/"+record.id)}, // click row
                        };
                    }}
                    
                    */
                />




            </div>

             
                <Modal open={isModalOpen}
                   
                       title={t("Predogled")}
                       centered
                       footer={(_, { CancelBtn }) => (
                           <>
                               <CancelBtn />
                           </>
                       )}
                       onCancel={()=>setisModalOpen(false)}> 
                    <div id={"imgContainer"}>
                    <img id={"photo"} src={""} />
                </div>
                </Modal>


            <Modal open={isPreviewOpen}

                   title={t("Predogled")}
                   centered
                   footer={(_, { CancelBtn }) => (
                       <>
                           <CancelBtn />
                       </>
                   )}
                   onCancel={()=>setisPreviewOpen(false)}>
                <ShowDoc  ratio={0.48}
                          showmode="preview"
                          docId={_documentId}
                          forceReload={rerender}
                          RequestId={_requestId ? _requestId : undefined}
                         documentType={_documentType ? _documentType : undefined} />

            </Modal>











        </div>
    );
};
export default DocsPage;