import React, {useEffect, useState} from 'react';


const  tab2 =() =>{

    return(
        <>
            <img src={"/img/lp_screen5.png"} style={{width: "500px", marginTop: "-50px", marginBottom: "20px"}}/>
            <br/>
            <p style={{textAlign: "right"}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </p>

        </>)
}

export default tab2