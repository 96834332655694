import React, {useEffect, useState} from 'react';
import {Badge, Button, Checkbox, DatePicker, Input, Select, Space, Table, Tooltip} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {Link, useHistory} from "react-router-dom";
import axios, {Axios} from "axios";
import * as AuthService from "../../../Services/auth-service";
import authHeader from "../../../Services/auth-header";
import CompanySettingsPage from "../../settings/Components/CompanySettings";
import {useTranslation} from "react-i18next";
import Deal, {mapDealStatusColor, mapDealStatusName} from "../../../types/Deal";
import dayjs from "dayjs";
import DealsFilter from "../../../types/DealsListFilter";




interface FilterProps {
    setFilterFunc?: (values: any) => void;
}

 const DetailsListFilter=({setFilterFunc}: FilterProps)=>{

     const {t} = useTranslation();
     
     
     const [showFilterBadge, setshowFilterBadge] = useState(false)
     const [showDatesBadge, setshowDatesBadge] = useState(false)
     //const[hideFinished, sethideFinished]=useState(false)
     const[filterStartDate, setfilterStartDate]=useState<string|null>(null)
     const[filterEndDate, setfilterEndDate]=useState<string|null>(null)
     const [selectedDates, setsselectedDates] = useState<string>("")

     //const [selectedStatus, setselectedStatus] = useState(100)
     //const [searchText, setSearchText] = useState("")



     

     const { RangePicker } = DatePicker;



     const[_filter, setFilter] = useState<DealsFilter>({
         searchText: "",
         dateFrom: "",
         dateTo: "",
         hidefinished: false,
         requestStatus:100,
         selectedDates: ""
     })



     const setSelectedStatusF = (v : number)=> {
         localStorage.setItem("selectedStatus", v.toString());
         //setselectedStatus(v);
         setFilter(prev => ({
             ...prev,
             requestStatus:v
         }));
         
       
     }

     const setselectedDatesF =(v:any)=>{
         if(v!=="" && v!==null){
             localStorage.setItem("selectedDates", JSON.stringify(v))//Date.parse(v[0]).toString()+"-"+Date.parse(v[1]).toString());
             setsselectedDates(Date.parse(v[0]).toString()+"-"+Date.parse(v[1]).toString())
             setFilter(prev => ({
                 ...prev,
                 dateFrom:dayjs(Date.parse(v[0])).format("DD.MM.YYYY"),
                 dateTo: dayjs(Date.parse(v[1])).format("DD.MM.YYYY"),
                 selectedDates: Date.parse(v[0]).toString()+"-"+Date.parse(v[1]).toString()
             }));
         }
         if(v==="" || v===null){
             localStorage.removeItem("selectedDates");
         }
         


     }


     const showhideFinished=(e :any)=>{
         if(e.target.checked ) {
             setFilter(prev => ({
                 ...prev,
                 hidefinished:true
             }));
         }
         else{
             setFilter(prev => ({
                 ...prev,
                 hidefinished:false
             }));
         }
        

     }


     useEffect(()=>{
         if(setFilterFunc){setFilterFunc(_filter)}
         
     }, [_filter])


     useEffect(()=>{
         if(localStorage.getItem("selectedStatus")!==null){
             const filter_status: number=Number(localStorage.getItem("selectedStatus"));
             if(filter_status!==100){
                 console.log("filter_status: ", filter_status)
                 setSelectedStatusF(filter_status);
                 setshowFilterBadge(true);
             }
             
             const dates_status: any=localStorage.getItem("selectedDates");
             if(dates_status!=="" && dates_status!==null){
                 //console.log("dates_status: ", dates_status)
                 setselectedDatesF(JSON.parse(dates_status));
                 setshowDatesBadge(true);

             }
             if(dates_status==="" && dates_status===null){
                 setsselectedDates("");
                 setshowDatesBadge(false);
                 setFilter(prev => ({
                     ...prev,
                     dateFrom:"",
                     dateTo: "",
                     selectedDates: ""
                 }));
             }




         }
     },[])
     


     return (
        <div  style={{marginBottom: "20px", display: "inline-block"}}>
            <Space>
        <div>
            
            <Badge color={"#1c7884"} dot={showFilterBadge}>
                <Select
                    defaultValue={_filter?.requestStatus}
                    value={_filter?.requestStatus}
                    labelInValue={false}
                    bordered={false}
                    popupMatchSelectWidth={false}
                    options={[
                        { value: 100, label: t('Vsa') },
                        { value: 0, label: t('Nova') },
                        { value: 20, label: t('Načrtovana') },
                        { value: 1, label: t('Načrtovano') },
                        { value: 30, label: t('Confirmed') },
                        { value: 40, label: t('On_route') },
                        { value: 50, label: t('Zaključena') }
                    ]}
                    onChange={(v:number)=>{
                        setshowFilterBadge(true);
                        if(v===100){
                            setshowFilterBadge(false);
                        }
                        setSelectedStatusF(v)
                        }}
                />
            </Badge>
            <Badge color={"#1c7884"} dot={showDatesBadge}>
                <RangePicker variant="borderless"
                             style={{width: "220px", borderBottom: "1px solid #999", borderRadius: "0"}}
                             placeholder={[t("Od_datum"),t("Do_datum")]}
                             format={'DD.MM.YYYY'}
                             defaultValue={[dayjs(_filter?.dateFrom,'DD.MM.YYYY'), dayjs(_filter?.dateTo,'DD.MM.YYYY')]}

                             value={[_filter?.dateFrom? dayjs(_filter?.dateFrom, 'DD.MM.YYYY') : null, _filter?.dateTo? dayjs(_filter?.dateTo, 'DD.MM.YYYY'):null]}

                             onChange={(v:any)=>{
                                 // console.log("v: ", v)
                                 //console.log("selectedDates Date.parse: ", Date.parse(v[0]).toString()+"-"+Date.parse(v[1]).toString())
                                 if(v){
                                     setsselectedDates(Date.parse(v[0]).toString()+"-"+Date.parse(v[1]).toString())
                                     setshowDatesBadge(true)
                                 }else{
                                     setsselectedDates("");
                                     setFilter(prev => ({
                                         ...prev,
                                         dateFrom:"",
                                         dateTo: "",
                                         selectedDates: ""
                                     }));
                                     
                                     
                                     setshowDatesBadge(false)
                                     setfilterStartDate(null);
                                     setfilterEndDate(null);
                                     //if(selectedStatus===100){
                                     //    setshowFilterBadge(false)
                                     //}
                                 }
                                 setselectedDatesF(v)

                             }} />
            </Badge>

            <Checkbox style={{marginLeft: "40px"}} 
                      checked={_filter.hidefinished}
                      onClick={(e)=>{showhideFinished(e) }}>{t('Show_finished')}</Checkbox>


        </div>

         <Input.Search className={"emptyB"}
                       placeholder={t("Išči")}
                       onSearch={(value)=>{
                           setFilter(prev => ({
                               ...prev,
                               searchText: value
                           }));
                       }}
                       onChange={(e)=>{
                           setFilter(prev => ({
                               ...prev,
                               searchText: e.target.value
                           }));
                           
                       }}

             //suffix={
             //    <Tooltip title="Extra information">
             //        <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
             //    </Tooltip>
             //}
         />

         {/* 
         <Button onClick={GoToDetails}  className={"orangeButton"}>+ {t("Novo_naročilo")}</Button>
         <Button  className={"emptyButton"}
                  onClick={DowmloadExcel}

         >{t("Prenesite_v_Excel")}</Button>
*/}
     </Space>
        
        </div>
    )
}

export default DetailsListFilter
         