import React from 'react';
import EditRequest from "./RouteComponents/EditRequest";
import {useHistory} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";

const RouteEditPage = () => {
    const history= useHistory();
    const user = AuthService.getCurrentUser();
    if(!user){
        history.push("/login");
    }
    return (

        <div>
            <EditRequest/>
        </div>);
}

export default RouteEditPage;