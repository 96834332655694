import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {Button, Row, Spin} from "antd";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import ServicePayment from "../../../types/ServicePayment";
import {ServerdateToPoints, ToMoney} from "../../../utils/helpers";


interface billingProps{
    result? : string | null
}
const BillingPage = ({result}: billingProps) => {
    const {t} = useTranslation();

    const history = useHistory();
    const user = AuthService.getCurrentUser();


    //console.log(user);
    if (!user) {
        history.push("/login");
    }
    
    const[payLink, setpayLink] = useState(null)

    const[payRevLink, setpayRevLink] = useState<string|null>(null)
    
    const [calculation, setCalculation] =useState<ServicePayment|null>(null)

    
    useEffect(()=>{
            if(calculation===null){
                //console.log("ping server")
                axios.get(AuthService.DATA_URL+'ServicePayments/get-crm-revolut-paylink', {headers: authHeader()})
                    .then((response) => {
                        console.log("response.data: ", response.data);
                        setCalculation(response.data.pendingPayement);
                        setpayRevLink(response.data.pendingPayement.checkOutUrl);
                    })
            }
    })
    
     
    
    return(
        <div>
            <Row style={{paddingTop: "10px"}}>
                {calculation===null ?

                    <Spin />

                    :

                    <div>
                        Абонентская плата за период с
                        {ServerdateToPoints(calculation.payForPeriodFrom)} - {ServerdateToPoints(calculation.payForPeriodTo)} : {calculation.amount? ToMoney(calculation?.amount/100) : 0}
                        <br/>

                        <Button  className="orangeButton" onClick={()=>{history.push("/checkout")}}>
                            Перейти к оплате
                        </Button>


                    </div>





                }

            </Row>
          
            <Row style={{paddingTop: "10px"}}>

                    <div>
                        Абонентская плата 20 евро
                        <br/>
                        
                            <Button  className="orangeButton" onClick={()=>{history.push("/checkout")}}>
                                Перейти к оплате  
                            </Button>
                           
     
                    </div>
                

            </Row>
        </div>
    )
}

export default BillingPage;