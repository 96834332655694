import React, {useEffect, useState} from 'react';
import {Button, Col, Row, Space, Spin, Table, Tooltip} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {Link, useHistory} from "react-router-dom";
import axios, {Axios} from "axios";
import * as AuthService from "../../../Services/auth-service";
import authHeader from "../../../Services/auth-header";
import CompanySettingsPage from "../../settings/Components/CompanySettings";
import {useTranslation} from "react-i18next";
import Deal, {mapDealStatusColor, mapDealStatusName} from "../../../types/Deal";
import DealsListFilter from "../../../types/Deal";
import DealsFilter from "../../../types/DealsListFilter";
import {
    getArticleITypeClass,
    mapDocTypeEnumToString,
    MoneyToString,
    ServerdateToPoints,
    translateFA
} from "../../../utils/helpers";
import DealsList from "../../Deals/Components/DealsList";
import TransactionsFilter from "../../../types/TransactionsListFilter";
import Transaction from "../../../types/Transaction";
import {FileTextOutlined, SafetyCertificateOutlined} from "@ant-design/icons";
import CommonListFilter from "../../../types/CommonListFilter";


interface DealsListtProps{
    ContractorId?: string;
    DriverId? : string;
    RequestId?: string;
    TruckId? : string;
    ExpiryDate? : string;
    reload? : boolean;
    setHeight? : number|null;

}

interface transctionsListProps{
    filter : TransactionsFilter,
    commonFilter? : CommonListFilter | null;
    mode? : string|null|undefined
}

const TransactionsList= ({ filter, mode, commonFilter} : transctionsListProps) => {

    const {t} = useTranslation();

    const history = useHistory();
    const user = AuthService.getCurrentUser();
    if (!user) {
        AuthService.logout();
        history.push("/login");
        window.location.reload();

    }


    const mapMonthNumberToString=(i: number)=>{
        switch ( i ) {
            case 1: return t("January");
            case 2:return t("February")
            case 3:return t("March")
            case 4: return t("April");
            case 5:return t("May")
            case 6:return t("June")
            case 7: return t("July");
            case 8:return t("August")
            case 9:return t("September")
            case 10: return t("October");
            case 11:return t("November")
            case 12:return t("Декабрь")
            default:return "Месяц"

        }
    }



    const [transactions, setTransactions]=useState<Transaction[] | null>(null);
    const [anyTransactions, setanyTransactions]=useState("");

    const[choosedMonth, setChoosedMonth] = useState<number>(localStorage.getItem("selectedMonth") ? Number(localStorage.getItem("selectedMonth")) : (new Date().getMonth() + 1))//((new Date().getMonth() + 1))
    const[choosedYear, setchoosedYear] = useState<number>(localStorage.getItem("selectedYear") ? Number(localStorage.getItem("selectedYear")) : new Date().getFullYear())//((new Date().getFullYear() ));
    const[choosedMonthName, setMonthName]=useState<string>(mapMonthNumberToString((localStorage.getItem("selectedMonth") ? Number(localStorage.getItem("selectedMonth")) : (new Date().getMonth() + 1))))

    const [rowTransctionId, setRowTransctionId]=useState<string>("00000000-0000-0000-0000-000000000000");
    const[totalIncome, settotalIncome]=useState(0);
    const[totalExpense, settotalExpense]=useState(0);
    const[totalSaldo, settotalSaldo]=useState(0);



    const getTransactions=()=>{

        //console.log("localStorage: ",localStorage.getItem("selectedMonth"), "month: ", month);
        
            if(transactions===null){
                axios.get(AuthService.DATA_URL + `Transaction`
                    , {headers: authHeader(),
                    params:{filter: filter},
                    })
                    .then((response) => {

                        console.log("list response.data: ->'", anyTransactions,"'<-",  response.data)
                        setTransactions(response.data)
                        setanyTransactions("1");
                        let prihod = 0;
                        let rashod = 0;
                        let saldo = 0;
                        response.data.forEach((pos: Transaction) => {
                            if (pos !== null) {
                                if (pos.incomeExpense !== null && pos.incomeExpense !== undefined) {
                                    if (pos.incomeExpense > 0) {
                                        prihod = prihod + (pos.amount ? pos.amount : 0)
                                    } else {
                                        rashod = rashod + (pos.amount ? pos.amount : 0)
                                    }
                                }
                            }
                        })
                        saldo = prihod - rashod;
                        settotalIncome(prihod);
                        settotalExpense(rashod);
                        settotalSaldo(saldo)
                        setisLoading(false)

                    })
                    .catch(function (error) {
                        if (error.response.status === 401) {
                            AuthService.logout();
                            history.push("/login");
                            window.location.reload();

                        }
                    });
            }               
            

    }




    const [isLoading, setisLoading]=useState(false)

    useEffect(()=>{
        if(transactions===null){
            //setanyTransactions("--");
            getTransactions()

        }

        return () => {
            //unsubscribe("onCloseTransactionModal", (data: any) => IndexOnCloseModal());
        }


    }, )






    const onRowClick=(record: any)=>{
        
    }
    const accept=(transactionid:string | null | undefined)=>{
        
    }

    const columns: ColumnsType<Transaction> = [
        {
            title: t("Date"),
            dataIndex: 'transactionDate',
            width: 110,
            filteredValue :[filter? filter.searchText? filter.searchText : "" : ""],
            onCell: (record, rowIndex) => {
                return {
                    onClick: (event) => {onRowClick(record)}, // click row
                };
            },
            onFilter: (value, record) => {
                return (
                    //record.financeArticle!==null ? String(t(translateFA(record.financeArticle.title))).toLowerCase().includes(value.toString().toLowerCase()):[1].includes(1)||
                    String(t(mapDocTypeEnumToString(record.doc?.documentType))).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.doc?.docNumber).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.request?.requestNumber).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.description).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.request?.externalRequestNumber).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.counterPart?.title).toLowerCase().includes(value.toString().toLowerCase())
                )
            },
            render: (text, record) => {
                return (
                    <div className={record.isPlan
                    || (record.transactionDate&&new Date(record.transactionDate.valueOf())>new Date())? "isPlan": ""}>
                        {ServerdateToPoints(text)}

                    </div>
                )
            }
        },
        {
            title: t("Amount"),
            dataIndex: 'amount',
            width: 160,
            filteredValue :[filter? filter.counterPartId? filter.counterPartId : "" : ""],
            onFilter: (value, record) => {
                //console.log(filter.counterPartId, value, record.counterPartId)
                return (
                    (record.counterPartId ===value|| record.counterPartId===value)||(filter.counterPartId===null||filter.counterPartId===undefined)
                )},

            onCell: (record, rowIndex) => {
                return {
                    onClick: (event) => {onRowClick(record)}, // click row
                };
            },
            render: (text, record) =>
                <span style={{float: "right"}} className={record.isPlan || (record.transactionDate&&new Date(record.transactionDate.valueOf())>new Date())? "isPlan": ""}>{
                    record.incomeExpense? record.incomeExpense>0?
                        <span style={{color: "green"}}>+ {text.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}€</span>
                        :
                        <span style={{color: "#7918e6"}}>- {text.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}€</span>  :""     }</span>

        },
        {
            title: t("Opis"),//t('Itinerarij'),
            dataIndex: 'description',
            width: 530,
            filteredValue :[filter? filter.requestId? filter.requestId : "" : ""],
            onFilter: (value, record) => {
                //console.log("filteredValue value: ", value, record.requestId)
                return (
                    (record.requestId ===value|| record.requestId===value)||(filter.requestId===null||filter.requestId===undefined)
                )},

            onCell: (record, rowIndex) => {
                return {
                    onClick: (event) => {onRowClick(record)}, // click row
                };
            },
            render: (text, record)=>
                <span className={record.isPlan || (record.transactionDate&&new Date(record.transactionDate.valueOf())>new Date())? "isPlan": ""}>
                        {text}
                    <br/>
                        <div style={{color:"#666", fontSize: "13px", verticalAlign: "top"}}>
                            {record.financeArticle?
                                <div style={{width: "190px", display: "inline-block", verticalAlign: "top"}}><span className="graydot" style={{backgroundColor: record.financeArticle.color}}/>{t(translateFA(record.financeArticle.title))}</div>
                                :
                                <div style={{width: "190px", display: "inline-block"}} ><span className={getArticleITypeClass(0)}/>Статья не указана</div>
                            }
                            <div style={{ display: "inline-block",  verticalAlign: "top"}}>
                            {record.request?
                                <div style={{marginLeft:"10px", /*width: "120px"*/}}>
                                    <SafetyCertificateOutlined/>
                                    <span style={{marginLeft:"18px"}}>{t("Naročilo")+" "}
                                        {record.request.createdByContractorId===user.contractorId? record.request.requestNumber : record.request.externalRequestNumber}
                                    </span>
                                </div>
                                : <span></span>}
                                {record.doc?
                                    <span  style={{marginLeft:"10px"}}>   
                                    <FileTextOutlined/>
                                    <span style={{marginLeft:"18px"}}>
                                        {t(mapDocTypeEnumToString(record.doc.documentType))+" "+record.doc.docNumber+" "+t("from")+" "+ServerdateToPoints(record.doc.documentDate) }
                                    </span>
                                 </span>
                                    : <span></span>

                                }
                            </div>
                        </div>
                    
                    </span>,
        },
        {
            title: t("Nasprotna_stranka"),//t('Itinerarij'),
            dataIndex: 'counterpart.title',
            width: 210,
            onCell: (record, rowIndex) => {
                return {
                    onClick: (event) => {onRowClick(record)}, // click row
                };
            },

            render: (text, record) =>
                <span className={record.isPlan || (record.transactionDate&&new Date(record.transactionDate.valueOf())>new Date())? "isPlan": ""}>{record.counterPart?  record.counterPart.title : ""}</span>,

        },
        {
            title: ' ',
            key: 'operation',
            fixed: 'right',
            //width: 70,

            render: (text, record) =>
                <span>
                        {record.isPlan &&record.transactionDate&&new Date(record.transactionDate.valueOf())<new Date() ?
                            <Button style={{zIndex:5000}} onClick={()=>accept(record.id)}>{t("Accept")}</Button>
                            : ""
                        }
                    </span>,
        }
    ];


    const reducedColumns: ColumnsType<Transaction> = [
        {
            title: t("Opis"),
            dataIndex: 'transactionDate',
            width: 410,
            filteredValue :[filter? filter.searchText? filter.searchText : "" : ""],
            onCell: (record, rowIndex) => {
                return {
                    onClick: (event) => {onRowClick(record)}, // click row
                };
            },
            onFilter: (value, record) => {
                return (
                    //record.financeArticle!==null ? String(t(translateFA(record.financeArticle.title))).toLowerCase().includes(value.toString().toLowerCase()):[1].includes(1)||
                    String(t(mapDocTypeEnumToString(record.doc?.documentType))).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.doc?.docNumber).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.request?.requestNumber).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.description).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.request?.externalRequestNumber).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.counterPart?.title).toLowerCase().includes(value.toString().toLowerCase())
                )
            },
            render: (text, record) => {
                return (
                    <div className={record.isPlan
                    || (record.transactionDate&&new Date(record.transactionDate.valueOf())>new Date())? "isPlan": ""}>
                        <div>
                            <Row gutter={10}>
                                <Col span={8}>
                                    {ServerdateToPoints(text)}
                                </Col>
                                <Col span={8} style={{textAlign: "right"}}>
                                   {record.incomeExpense? record.incomeExpense>0?
                                       <span style={{color: "green"}}>+ {record.amount?.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}€</span>
                                       :
                                       <span style={{color: "#7918e6"}}>- {record.amount?.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}€</span>  :""     
                                   }
                                </Col>
                                <Col span={8}>
                                    {record.financeArticle?
                                        <div style={{width: "190px", display: "inline-block", verticalAlign: "top"}}><span className="graydot" style={{backgroundColor: record.financeArticle.color}}/>{t(translateFA(record.financeArticle.title))}</div>
                                        :
                                        <div style={{width: "190px", display: "inline-block"}} ><span className={getArticleITypeClass(0)}/>{t("Article_not_specified")}</div>
                                    }
                                </Col>
                            </Row> 
                            <Row gutter={5}>
                                <Col span={24}>
                                    {record.description}
                                </Col>
                            </Row>
                           
                            
                        </div>
                        
                        
                       

                    </div>
                )
            }
        },
        {
            dataIndex: "id",
            width: 0,
            filteredValue :[filter? filter.requestId? filter.requestId : "" : ""],
            onFilter: (value, record) => {
                return (
                    (record.requestId ===value|| record.requestId===value)||(filter.requestId===null||filter.requestId===undefined)
                )},
            render: (text, record) => {
                return ("")}
        },
        {
            dataIndex: "id",
            width: 0,
            filteredValue :[filter? filter.counterPartId? filter.counterPartId : "" : ""],
            onFilter: (value, record) => {
                return (
                    (record.counterPartId ===value|| record.counterPartId===value)||(filter.counterPartId===null||filter.counterPartId===undefined)
                )},
            render: (text, record) => {
                return ("")}
        },
    ];



    if(transactions===null){
        return <Spin/>
    }

    return (
        <>
            <Table
                locale={{
                    triggerDesc: t('Kliknite_za_razvrščanje_padajoče'),
                    triggerAsc: t('Kliknite_za_razvrščanje_naraščajoče'),
                    cancelSort: t('Prekliči_razvrščanje')
                }}
                columns={mode==="show"?  reducedColumns : columns}
                dataSource={transactions}
                loading={isLoading}
                pagination={false}
                scroll={{ y: 'calc(35vh)' }}
                summary={(pageData) => {
                    let totalIncome = 0;
                    let totalExpense = 0;
                    pageData.forEach(({ amount, incomeExpense }) => {
                        if((incomeExpense? incomeExpense:0)>0){totalIncome = totalIncome+(amount? amount : 0);}
                        if((incomeExpense?incomeExpense:0)<0){totalExpense = totalExpense+(amount? amount : 0);}

                    });
                    settotalIncome(totalIncome);
                    settotalExpense(totalExpense);
                    settotalSaldo(totalIncome-totalExpense)
                    return (
                        <Table.Summary fixed>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>
                                    <div style={{width: "100px"}}>
                                        {t("Incomes")}: <br/>
                                        {t("Expenses")}: <br/>
                                        {t("Balance")}:
                                    </div>


                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>
                                    <div style={{width: "130px"}}>

                                        <span style={{color: "green", float: "right"}}>{totalIncome.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}€</span> <br/>
                                        <span style={{color: "#7918e6", float: "right"}}>- {totalExpense.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}€</span> <br/>
                                        {totalSaldo>0?
                                            <span style={{color: "green",float: "right"}}>{totalSaldo.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}€</span>
                                            :
                                            <span style={{color: "#7918e6",float: "right"}}>{totalSaldo.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}€</span>
                                        }
                                    </div>

                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )}}


            />

        </>
        
        
        
    )
}
export default TransactionsList