import React, { useEffect, useState }  from 'react';
import {Table, Card, ConfigProvider, Space, Button, Drawer} from 'antd';

import { DoubleLeftOutlined, DoubleRightOutlined} from '@ant-design/icons';


import type { ColumnsType } from 'antd/es/table';
import axios from "axios/index";
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import authHeader from "../../../Services/auth-header";
import CreateDriver from "../../drivers/DriverComponents/create";
import EditReply from "../../myroutes/RouteComponents/EditReply";
import ShowRequest from "../../myroutes/RouteComponents/ShowRequest";
import {useTranslation} from "react-i18next";




export default function CalendarTrucks() {

    const {t} = useTranslation();

    const history= useHistory();
    const user = AuthService.getCurrentUser();
    if(!user){
        history.push("/login");
    }
//    const [form] = Form.useForm();
    
    
    type truck = {
        id: string;
        regNumber: string;
        trackDays : any[];
        truckType : number;


    };
    type GetTrucksResponse = {
        data: truck[];
    };

    interface DataType {
        key: React.Key;
        id: string;
        regNumber: string;
        truckType : number;
        
        trackDays : any[];
        rqNumber: string;
        mon : string;
        tue : string;
        wed : string;
        ths : string;
        fri : string;
        sat : string;
        sun : string;
        monReqId : string;
        tueReqId : string;
        wedReqId : string;
        thsReqId : string;
        friReqId : string;
        satReqId : string;
        sunReqId : string;
    }




    const dateToPoints =(today : Date) =>{
        const yyyy = today.getFullYear();
        let m = today.getMonth() + 1; // Months start at 0!
        let d = today.getDate();
        
        let mm = m.toString();
        let dd = d.toString();
        if (d < 10) dd = '0' + dd;
        if (m < 10) mm = '0' + mm;
        
        return dd + '.' + mm + '.' + yyyy;
    }

    const stringToPoints =(today : string) =>{
        var ddddd=today.substring(8,10)+"."+today.substring(5,7)+"."+today.substring(0,4);
        return ddddd;
    }


    //var initweek=1

    const[initweek, setinitweek ]= useState<number>(1);



    const PrevWeek =() => {
        var prevFirst=initweek
        setinitweek(prevFirst-7);
    }

    const NextWeek =() => {
        var prevFirst=initweek
        setinitweek(prevFirst+7);
    }


    const myweek=Array.from(Array(7).keys()).map((idx) => {
        const d = new Date(); 
        d.setDate(d.getDate() - d.getDay() + idx+initweek); 
        return d; }
    );
    
    //console.log("myweek: ", myweek)


    const columns: ColumnsType<DataType> = [
        {
            title: t('Reg_številka'),
            dataIndex: 'regNumber',
            //width: 100,
            //sorter: (a, b) => a.regNumber.length - b.regNumber.length,
            //sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
            render: (text, record) => {
                if(record.truckType===0){
                    return(
                        <div>
                            <div><img src={"/img/traktor.png"} alt={" "} style={{marginRight: "10px"}} /> {text}</div>
                        </div>
                    )
                }
                else{
                    if(record.truckType===1){
                        return(
                            <div>
                                <div><img src={"/img/priklopnik.png"} alt={" "} style={{marginRight: "5px", width: "35px"}} />{text}</div>
                            </div>
                        )
                    }else{
                        return(
                            <div>
                                <div><img src={"/img/tovornjak.png"} alt={" "} style={{marginRight: "5px", width: "35px"}} />{text}</div>
                            </div>
                        )
                        
                    }
                }
            }
            //ellipsis: true,
        },
        {
            title: t('Po')+' '+dateToPoints(myweek[0]),
            //width: 100,
            dataIndex: 'mon',
            render: (text, record) =>{
                //console.log("record_record:", record);
                if(text.length>0){
                    return(<div onClick={()=>{showDrawer(record.monReqId, text)}} style={{backgroundColor: "#E3601D", padding: "5px", color: "#fff", height: "32px", cursor:"pointer"}}>{text}</div>);
                }
            }
        },
        {
            title: t('To')+' '+dateToPoints(myweek[1]),
            dataIndex: 'tue',
            //width: 100,
            render: (text, record) =>{
                //console.log("record_record:", record);
                if(text!==""){
                    if(record.mon===text){
                        return(<div onClick={()=>{showDrawer(record.tueReqId, text)}} style={{backgroundColor: "#E3601D", padding: "5px", color: "#E3601D", height: "32px", cursor:"pointer"}}>
                            {text}
                        </div>);

                    }
                    else {
                        return (<div onClick={()=>{showDrawer(record.tueReqId, text)}} style={{backgroundColor: "#E3601D", padding: "5px", color: "#fff", height: "32px", marginLeft: "1px", cursor:"pointer"}}>
                            {text}
                        </div>);

                    }
                }

            }
          
        },
        {
            title: t('Sr')+' '+dateToPoints(myweek[2]),
            dataIndex: 'wed',
            //width: 100,
            render: (text, record) =>{
                if(text!==""){
                    if(record.tue===text){
                        return(<div onClick={()=>{showDrawer(record.wedReqId, text)}} style={{backgroundColor: "#E3601D", padding: "5px", color: "#E3601D", height: "32px", cursor:"pointer"}}>
                            {text}
                        </div>);

                    }
                    else {
                        return (<div onClick={()=>{showDrawer(record.wedReqId, text)}} style={{backgroundColor: "#E3601D", padding: "5px", color: "#fff", height: "32px", marginLeft: "1px", cursor:"pointer"}}>
                            {text}
                        </div>);

                    }
                }
            }
        },
        {
            title: t('Če')+' '+dateToPoints(myweek[3]),
            dataIndex: 'ths',
            //width: 100,
            render: (text, record) =>{
                if(text!=="") {

                    if (record.wed === text) {
                        return (
                            <div onClick={()=>{showDrawer(record.thsReqId, text)}} style={{backgroundColor: "#E3601D", padding: "5px", color: "#E3601D", height: "32px", cursor:"pointer"}}>
                                {text}
                            </div>);

                    } else {
                        return (<div onClick={()=>{showDrawer(record.thsReqId, text)}} style={{
                            backgroundColor: "#E3601D",
                            padding: "5px",
                            color: "#fff",
                            height: "32px",
                            marginLeft: "1px",
                            cursor:"pointer"
                        }}>
                            {text}
                        </div>);

                    }
                }
            }
        },
        {
            title: t('Pe')+' '+dateToPoints(myweek[4]),
            dataIndex: 'fri',
            //width: 100,
            render: (text, record) =>{
                if(text!=="") {
                    if (record.ths === text) {
                        return (
                            <div onClick={()=>{showDrawer(record.friReqId, text)}} style={{backgroundColor: "#E3601D", padding: "5px", color: "#E3601D", height: "32px", cursor:"pointer"}}>
                                {text}
                            </div>);

                    } else {
                        return (<div onClick={()=>{showDrawer(record.friReqId, text)}} style={{
                            backgroundColor: "#E3601D",
                            padding: "5px",
                            color: "#fff",
                            height: "32px",
                            marginLeft: "1px",
                            cursor:"pointer"
                        }}>
                            {text}
                        </div>);

                    }
                }
            }
        },
        {
            title: t('So')+' '+dateToPoints(myweek[5]),
            dataIndex: 'sat',
            render: (text, record) => {
                if (text !== "") {
                    if (record.fri === text) {
                        return (
                            <div onClick={()=>{showDrawer(record.satReqId, text)}} style={{backgroundColor: "#E3601D", padding: "5px", color: "#E3601D", height: "32px", cursor:"pointer"}}>
                                {text}
                            </div>);

                    } else {
                        return (<div onClick={()=>{showDrawer(record.satReqId, text)}} style={{
                            backgroundColor: "#E3601D",
                            padding: "5px",
                            color: "#fff",
                            height: "32px",
                            marginLeft: "1px",
                            cursor:"pointer"
                        }}>
                            {text}
                        </div>);

                    }
                }
            }
        },
        {
            title: t('Ne')+' '+dateToPoints(myweek[6]),
            //key: 'operation',
            fixed: 'right',
            dataIndex: 'sun',
            //width: 100,
            render: (text, record) =>{
                if(text!=="") {
                    if (record.sat === text) {
                        return (
                            <div onClick={()=>{showDrawer(record.sunReqId, text)}} style={{backgroundColor: "#E3601D", padding: "5px", color: "#E3601D", height: "32px", cursor:"pointer"}}>
                                {text}
                            </div>);

                    } else {
                        return (<div onClick={()=>{showDrawer(record.sunReqId, text)}} style={{
                            backgroundColor: "#E3601D",
                            padding: "5px",
                            color: "#fff",
                            height: "32px",
                            marginLeft: "1px",
                            cursor:"pointer"
                        }} >
                            {text}
                        </div>);

                    }
                }
            }
        },
    ];

    //const TableData: DataType[] = [];
    const TableData: DataType[] = [];
    var mytruck: truck[];
    const [isLoading, setisLoading] = useState(true);
    const [CalendarData, setCalendarData] = useState([]);
    
    
    
    
    
    

    useEffect(() => {
        axios.get(AuthService.DATA_URL+`Truck`, { headers: authHeader() })
            .then((response) => {
                //console.log(response.data)
          //      setAPIData(response.data);
            });

        axios.get(AuthService.DATA_URL+`Truck/get-calendar`, { headers: authHeader() })
            .then((response) => {
                console.log("CalendarData: ",response.data)
                setCalendarData(response.data);
                setisLoading(false);
            })
            .catch(function (error) {
                if(error.response.status===401){
                    AuthService.logout();
                    history.push("/login");
                    window.location.reload();

                }
            });





    }, []);

    mytruck= JSON.parse(JSON.stringify(CalendarData, null, 4));

    //console.log("mytruck: ", mytruck)
    //var _firstday = new Date(curr.setDate(first));




    mytruck.forEach((tr, i)=> {
        //console.log("tr: ", tr.trackDays,  dateToPoints(myweek[0]), tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[0])));
        tr.trackDays.forEach((v:any)=>{
           // console.log(stringToPoints(v.daysDate));
        })
        
        
        
        TableData.push({
            key: i,
            id: tr.id,
            regNumber: tr.regNumber,
            trackDays : tr.trackDays,
            rqNumber : "tr",
            truckType : tr.truckType,
            mon : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[0])) === undefined ? "" : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[0])).requestNumber, //.length === 0 ? "-": tr.trackDays[0].requestNumber ,
            tue : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[1])) === undefined ? "" : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[1])).requestNumber, //.length === 0 ? "-": tr.trackDays[0].requestNumber ,
            wed : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[2])) === undefined ? "" : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[2])).requestNumber, //.length === 0 ? "-": tr.trackDays[0].requestNumber ,
            ths : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[3])) === undefined ? "" : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[3])).requestNumber, //.length === 0 ? "-": tr.trackDays[0].requestNumber ,
            fri : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[4])) === undefined ? "" : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[4])).requestNumber, //.length === 0 ? "-": tr.trackDays[0].requestNumber ,
            sat : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[5])) === undefined ? "" : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[5])).requestNumber, //.length === 0 ? "-": tr.trackDays[0].requestNumber ,
            sun : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[6])) === undefined ? "" : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[6])).requestNumber, //.length === 0 ? "-": tr.trackDays[0].requestNumber ,
            monReqId : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[0])) === undefined ? "" : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[0])).requestId, //.length === 0 ? "-": tr.trackDays[0].requestNumber ,
            tueReqId : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[1])) === undefined ? "" : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[1])).requestId, //.length === 0 ? "-": tr.trackDays[0].requestNumber ,
            wedReqId : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[2])) === undefined ? "" : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[2])).requestId, //.length === 0 ? "-": tr.trackDays[0].requestNumber ,
            thsReqId : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[3])) === undefined ? "" : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[3])).requestId, //.length === 0 ? "-": tr.trackDays[0].requestNumber ,
            friReqId : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[4])) === undefined ? "" : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[4])).requestId, //.length === 0 ? "-": tr.trackDays[0].requestNumber ,
            satReqId : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[5])) === undefined ? "" : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[5])).requestId, //.length === 0 ? "-": tr.trackDays[0].requestNumber ,
            sunReqId : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[6])) === undefined ? "" : tr.trackDays.find(d=>stringToPoints(d.daysDate)===dateToPoints(myweek[6])).requestId, //.length === 0 ? "-": tr.trackDays[0].requestNumber ,
        });
    });

    //console.log("TableData:", TableData);



    const [openReq, setNewReq] = useState(false);
    const [ReqId, setReqId] = useState("");
    const [ReqNumber, setReqNumber] = useState("");
    
    const showDrawer=(reqId : string, reqNumber : string)=>{
        //console.log("reqId_reqId:", reqId);
        setReqId(reqId);
        setReqNumber(t("Naročilo")+' '+reqNumber);
        //console.log("RRRRRReqId:", ReqId);
        setNewReq(true);
    }
    
    const onClose = () => {
        //один close на все дроеры
        setReqId("");
        //console.log("Close RRRRRReqId:", ReqId);
        setNewReq(false);
    };


    //PrevWeek();
    return (
        <div>
            <Card>
                
                <Space>
                    <Button onClick={PrevWeek}>
                        <DoubleLeftOutlined />
                    </Button>
                    
                    {dateToPoints(myweek[0])} - {dateToPoints(myweek[6])}
                    <Button onClick={NextWeek}>
                        <DoubleRightOutlined />
                    </Button>
                </Space>

                
                <div style={{marginTop: "10px"}}>
                    
                
            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            cellPaddingInline: 0,
                            cellPaddingInlineMD : 0,
                            cellPaddingInlineSM : 0,
                            cellPaddingBlockSM : 4,
                            //headerBg: "#000",
                            /* here is your component tokens */
                        },
                    },
                }}
            >
            <Table
                locale={{
                    triggerDesc: t("Kliknite_za_razvrščanje_padajoče"),
                    triggerAsc: t("Kliknite_za_razvrščanje_naraščajoče"),
                    cancelSort: t("Prekliči_razvrščanje")
                }}
                columns={columns}
                loading={isLoading}
                size={"small"}
                dataSource={TableData}
                pagination={false}
                scroll={{ y: 'calc(60vh - 4em)' }}
                
                //pagination={{ pageSize: 50 }} size="small"  scroll={{ y: 'calc(60vh - 4em)' }}
            />
            </ConfigProvider>
                </div>
            </Card>


            <Drawer title={ReqNumber} placement="right" onClose={onClose} open={openReq}>

                <ShowRequest  RequestId={ReqId} ShowMode={"show"}/>
                <EditReply RequestId={ReqId} ShowMode={"show"} />
            </Drawer>

        </div>
    )}
