import React from "react";
import EditContractor from "./ContractorsComponents/edit";
import {useHistory} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";


export default function EditContractorPage(props: any) {

    const {id} = props.match.params;
    const history= useHistory();

    const user = AuthService.getCurrentUser();
    if(!user){
        history.push("/login");
    }
    
    //взяв параметры из адресной строки

    return(

        <div>
            <EditContractor paramValue={(id ? id : "")}  />
        </div>
    )
}