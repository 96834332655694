import React, {useState,  useEffect} from "react";
import {Button, Divider, Form,  Steps,  Row, Col, Modal} from 'antd'
import {useHistory} from "react-router-dom";

import {ExclamationCircleOutlined, EditOutlined,} from '@ant-design/icons';

import axios from "axios/index";
import RoutePointStepShow from "./RoutePointStepShow";
import authHeader from "../../../Services/auth-header";
import * as AuthService from "../../../Services/auth-service";
import {useTranslation} from "react-i18next";
interface request {
    // id: string;
    requestNumber: string;
    price : number;
    distance : number;
    loadingType: number;
    rollingStockType: number;
    requestStatus: number;
    priceForClient? : number | null;
    priceToShowToSubcontractors? : number | null;

    operatorId?: string | null;
    operator? :any|null; 
    RoutePoints: any[];
};

type GUID = string & { isGuid: true};
type requestDTO ={
    id: string;
    requestNumber: string;
    price : number;
    distance : number;
    loadingType: number;
    rollingStockType: number;
    requestStatus: number;
    priceForClient? : number | null;
    operatorId?: string | null;
    operator? :any|null;

    priceToShowToSubcontractors? : number | null;

    routePoints: any[];
};


type RequestDTO={
    id: string;
    requestNumber: string;
    price : number;
    distance : number;
    loadingType: number;
    rollingStockType: number;
    requestStatus: number;
    priceForClient? : number | null;
    operatorId?: string | null;
    operator? :any|null;

    priceToShowToSubcontractors? : number | null;

    routePoints: any[];
}


type RequestDTOType = {
    data: requestDTO;
};


const newReq: request =
    {
        // id:"",
        requestNumber : "TN-01.2023",
        price: 1200,
        distance: 0,
        loadingType: 0,
        rollingStockType: 0,
        priceForClient:0,
        priceToShowToSubcontractors:0,

        requestStatus: 0,
        RoutePoints: [


        ]
    };

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);



const dummy = () => {
    //const nextStep =  currentstep-1;
    //setComponentDisabled(false);
    //sethideLoadingButton("block");
    //sethideRoutePointSuffix("none") ;
    //setActiveStep(nextStep);
}

interface ShowReqwestProps {
    RequestId?: string;
    ShowMode? : string;
}
export default function ShowRequest({RequestId, ShowMode} : ShowReqwestProps) {

    console.log("ShowRequest");
    
    const {t} = useTranslation();

    const history= useHistory();
    const user = AuthService.getCurrentUser();
    if(!user){
        AuthService.logout();
        history.push("/login");
        window.location.reload();

    }



    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleOk = () => {
        setIsModalOpen(false);
        history.push("/routes/edit/:?id="+RequestId);
    };
    
    
    /*
    const GoToDetails = () => {
        history.push("/editroute/editroute?id="+paramValue);
    };
    
     */
    
    
    const[editRequestObj, seteditRequestObj] = useState({});
    const[PointsObj, seteditPointsObj] = useState<any[]>([]);


    const [stepItems, setstepItems] = useState<any[]>([]);
    useEffect(() => {
        if(RequestId===""){
            stepItems.length=0
        }
        else{
            //console.log("stepItems.length : ",stepItems.length, "RequestId="+RequestId);
            axios.get(AuthService.DATA_URL+'Request/'+RequestId, {headers: authHeader()})
                .then((response) => {
                    seteditRequestObj(response.data);
                    seteditPointsObj(response.data.routePoints);
                    if(stepItems.length===0){
                        //console.log("PointsObj: ", PointsObj)
                        response.data.routePoints.forEach((ttt:any)=> {
                            stepItems.push(
                                {
                                    key :0,
                                    title : "-"+ ttt.routePointType.toString() +"-" + ttt.routePointType ? ( ttt.routePointType ===0 ? t("Nakladanje") : (ttt.routePointType ===1 ? t("Razkladanje") : "Delo na terenu") ) : "",
                                    subTitle: <RoutePointStepShow onSuccess={dummy}
                                                                  data={{
                                                                      dateText :  ttt.dateStart ? ttt.dateStart.substring(8,10)+"."+ttt.dateStart.substring(5,7)+"."+ttt.dateStart.substring(0,4) : t("nedatirano"),
                                                                      hourText : ttt.dateStart ? ttt.dateStart.substring(11,16) : "",
                                                                      streetText : ttt.streetName,
                                                                      cityText : ttt.cityName,
                                                                      CountryText : ttt.countryName,
                                                                      PointType : ttt.routePointType

                                                                  }
                                                                  }/>
                                }
                            );
                        });
                    }
                });
        }

    }, [RequestId]);


    //var myrequests: RequestDTO[];
    let editRequest;
    editRequest= JSON.parse(JSON.stringify(editRequestObj, null, 4));

    let rPoints;


    const [form] = Form.useForm();
    if (!editRequest.requestNumber) return <div>Waiting for data...</div>;

    //console.log("ShowMode: ", ShowMode)

    return (
    <div>
        <div style={{width: "360px",padding: "10px", paddingTop: "20px"}}>

            {/* 
            {ShowMode==="edit" ? 
                <div>
                <h1>
                    {t("Naročilo")} № {editRequest.requestNumber}

                <Button style={{top: "-5px", left: "5px"}}
                onClick={() => {
                Modal.confirm({
                title: t('Potrebno_je_potrdilo'),
                okText: t("Nadaljevanje"),
                cancelText: t("Nazaj"),
                onOk: handleOk,
                content: t("Če_ste_dodelili_izvajalca_bo_ta_ob_urejanju_naročila_ponastavljen"),
                footer: (_, { OkBtn, CancelBtn }) => (
                <>
                <CancelBtn />
                <OkBtn/>

                </>
                ),
            });
            }}
                >
                <EditOutlined />
                </Button>


                </h1>
                    <Divider orientation="left" orientationMargin="0">{t("Osnovni_podatki")}</Divider>
                </div>: ""

            }
            */}


            <Divider orientation="left" orientationMargin="0">{t("Osnovni_podatki")}</Divider>
            <Row>
                <Col span={24} style={{marginBottom: "10px"}}>
                    <span style={{color: "#A4A6AC"}}> {t("Pošiljatelj")}:</span> <br/> {editRequest.client? editRequest.client.title : "-"}</Col>
            </Row>
            <Row>
                <Col span={24} style={{marginBottom: "10px"}}>
                    <span style={{color: "#A4A6AC"}}> {t("Prejemnik")}:</span> <br/> {editRequest.receiver ? editRequest.receiver.title : "-"}</Col>
            </Row>
            <Row>
                <Col span={12}>
                    <span style={{color: "#A4A6AC"}}> {t("Plačnik")}:</span> <br/> 
                    {
                        editRequest.operator&&user.isTransporter ? editRequest.operator.title : 
                        editRequest.payer? editRequest.payer.title : editRequest.client? editRequest.client.title : ""}
                </Col>
                <Col span={12}><span  style={{float: "right"}}><span style={{color: "#A4A6AC"}}>{t("Cena")}: 
                </span> 
                    <br/>
                    {user.isOperator&&editRequest.operatorId===user.contractorId ?
                        <span>
                            {/* Показываем цену оператору, который смотрит открытый заказ*/}
                            {editRequest.priceForClient.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")} €<br/></span> : ""}
                            {/* И ниже показываем оператору цену, которую он установил для перевозчиков*/}
                            {editRequest.priceToShowToSubcontractors?
                                    editRequest.requestStatus<30?
                                        /* Если заказ еще не согласован, то продолжаем показывать общую цену для перевозчиков*/
                                        editRequest.priceToShowToSubcontractors.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")
                                        /* Если заказ согласован, то покажем цену, которая была обновлена при согласовании*/
                                        : editRequest.price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")
                                    :
                                /*Оператор не установил цену для перевозчиков при создании заказа*/
                                editRequest.price? editRequest.price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ") :""
                            } €
                </span> 
                </Col>
            </Row>
            <Divider orientation="left" orientationMargin="0">{t("Opis_tovora")}</Divider>
            <Row>
                <Col span={8}>
                    <span style={{color: "#A4A6AC"}}>{t("Teža")}: </span> {editRequest.weight} kg
                </Col>

                    <Col span={8}>
                        <div style={{textAlign: "center"}}>{editRequest.weight} plt.</div>

                    </Col>
                <Col span={8}>  <span style={{float: "right"}}><span style={{color: "#A4A6AC"}}>{t("Volumen")}: </span> {editRequest.volume} m<sup>3</sup> </span>  </Col>
            </Row>
            <p>
                {editRequest.cargoDescription}
            </p>
            <Divider orientation="left" orientationMargin="0">{t("Itinerarij")}</Divider>
            <Steps
                progressDot
                current={4}
                direction="vertical"
                items={stepItems}
            />
    </div>
    </div>
        
        
        
        
    )
}