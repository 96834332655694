import React, {useEffect, useState} from 'react';
import {
    Button,
    Card, Checkbox,
    Col, DatePicker, Divider, Drawer,
    Form,
    Image,
    Input,
    InputNumber,
    List,
    message,
    Row,
    Select,
    Space,
    Spin,
    Steps,
    Tabs, Tooltip, ConfigProvider, StepsProps, Popover, SelectProps, Dropdown, MenuProps, 
} from 'antd';
import {DownOutlined, LoadingOutlined, SmileOutlined, SolutionOutlined, UserOutlined} from '@ant-design/icons';

import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import authHeader from "../../../Services/auth-header";
import {useTranslation} from "react-i18next";
import RouteList from "../../myroutes/RouteComponents/list";
import DocList from "../../docs/DocComponents/docList";
import Deal, {mapDealStatusColor} from "../../../types/Deal";
import {dateForServer, dateForServerFromDate, mapRequestStatusToStep, ServerdateToPoints} from "../../../utils/helpers";
import dayjs from "dayjs";
import CreateContractor from "../../contractors/ContractorsComponents/create";
import {dateToPoints} from "../../../types/transporter-types";
import DocPositionForm from "../../docs/DocComponents/PositionForm";
import Doc from "../../../types/DocPosition";
import TransactionsList from "../../transactions/TransactionsComponents/TransactionsList";
import DealsFilter from "../../../types/DealsListFilter";
import TransactionsFilter from "../../../types/TransactionsListFilter";
import {json} from "stream/consumers";
import DealLogsTimeLine from "./DealLogsTimeLine";



interface Params {
    id: string;
}
export default function DealsDetailPage() {
    const {t} = useTranslation();
    const {id} = useParams<Params>();
    
    const history = useHistory();

    const user = AuthService.getCurrentUser();
    if (!user) {
        history.push("/login");
    }
    const [form] = Form.useForm();
    
    
    
    const[isLoading, setisLoading]=useState(true);
    const[isPageLoading, setisPageLoading]=useState(true);
    
    
    const [theDeal, setTheDeal] = useState<Deal>({
        id: id==="0" ? "00000000-0000-0000-0000-000000000000" : id,
        requestStatus:0,
    });
    const[ContractorOptions, setContractorOptions] = useState<any[]|null>(null);
    const[DriversOptions, setDriversOptions] = useState<any[]|null>(null);

    const[SubContractorOptions, setSubContractorOptions] = useState<any[]|null>(null);


    const [DriverHelp, setDriverHelp] = useState<any>("");
    const [statusDriver, setstatusDriver] = useState<any>("");
    
    const[planDocShow, setPlanDocShow] = useState("none")
    const[nextDocNumber, setnextDocNumber] = useState("")
    
    const[planIncomeShow, setplanIncomeShow] = useState("none");

    const[ExpenseCheckShow, setExpenseCheckShow]=useState("none");
    const[planExpenseShow, setplanExpenseShow]=useState("none");


    const [transactionFilter, settransactionFilter]=useState<TransactionsFilter>({
        searchText: "",
        dateFrom: "",
        dateTo: "",
        hidefinished:true,
        selectedDates: "",
        requestId: theDeal.id,
    })
    
    


    useEffect(()=>{
        if(id!=="0"&& isLoading){
            axios.get(AuthService.DATA_URL+'Deals/get-deal?id='+id, {headers: authHeader()})
                .then((response)=>{
                    setTheDeal(response.data);
                    //console.log(response.data)
                    setisLoading(false)
                })
                .catch(function (error) {
                    if(error.response.status===401){
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                })
        }
        else{
            if(theDeal.dealNumber==="" || theDeal.dealNumber===undefined)
            axios.get(AuthService.DATA_URL+'Deals/get-next-deal-number', {headers: authHeader()})
                .then((response)=>{
                    setTheDeal(prev => ({
                        ...prev,
                        dealNumber : response.data,
                        dealDate :dateForServerFromDate(dayjs(dateToPoints( new Date()), "DD.MM.YYYY"))
                    }));
                    console.log(response.data)
                })
            setisLoading(false)
        }
        if(ContractorOptions===null){
            setisLoading(true)
            axios.get(AuthService.DATA_URL+'Contractor/get-clients', {headers: authHeader()})
                .then((response) => {
                    setContractorOptions([]);
                    setContractorOptions(
                        response.data.map((row:any) =>(
                            {
                                value : row.id,
                                label : row.title
                            }                     
                        ))
                    );
                })
                .catch(function (error) {
                    if(error.response.status===401){
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                });
        }
        if(SubContractorOptions===null){
            setisLoading(true)
            axios.get(AuthService.DATA_URL+'Contractor/get-transporters', {headers: authHeader()})
                .then((response) => {
                    setSubContractorOptions([]);
                    setSubContractorOptions(
                        response.data.map((row:any) =>(
                            {
                                value : row.id,
                                label : row.title
                            }
                        ))
                    );
                })
                .catch(function (error) {
                    console.log(error)
                    setSubContractorOptions([])
                    if(error.response.status===401){
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                });
        }
        if(DriversOptions===null){
            setisLoading(true)
            axios.get(AuthService.DATA_URL+'driver/get-for-contractor?ContractorId='+user.contractorId, {headers: authHeader()})
                .then((response) => {
                    setDriversOptions(
                        response.data.map((row:any) =>(
                            {
                                value : row.id,
                                label : row.name+ " "+row.surname
                            }
                        ))
                    );
                })
                .catch(function (error) {
                    if(error.response.status===401){
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                });
        }
    })
    
    

    const PlanDocClick = (e: any)=>{
        ////
        
        console.log(theDeal.dealDate)
        
        ////
        if(e.target.checked){
            setPlanDocShow("block");
            axios.get(AuthService.DATA_URL + 'docs/get-next-number?docType=10', {headers: authHeader()})
                .then((response) => {
                    setnextDocNumber(response.data)
                });
            if(theDeal.dealDate){
                form.setFieldValue("planDocDate",
                    theDeal.dealDate ? dayjs(ServerdateToPoints(theDeal.dealDate), "DD.MM.YYYY") : dayjs(dateToPoints( new Date()), "DD.MM.YYYY")
                )
                var result = new Date(theDeal.dealDate);
                console.log("result: ", result)
                result.setDate(result.getDate() + 7);
                console.log("resul+7t: ", result)
                form.setFieldValue("planPaydate",
                     dayjs(dateToPoints(result), "DD.MM.YYYY"))

            }
        }
        else{setPlanDocShow("none");}


    }
    
    const PlanIncomeClick=(e:any)=>{
        if(e.target.checked)
        {
            setplanIncomeShow("block");
            form.setFieldValue("planIncomeAmount",theDeal.priceForClient)
            if(theDeal.planPaydate){
                form.setFieldValue("planIncomeDate",
                    dayjs(ServerdateToPoints(theDeal.planPaydate), "DD.MM.YYYY")
                )
            }
            
            
        }
        else{setplanIncomeShow("none");}
    }
    
    
    const PlanExpenseClick=(e: any)=>{
        //console.log(form.getFieldValue("planExpense"))
        if(e.target.checked){setplanExpenseShow("block");}
        else{setplanExpenseShow("none");} 
    }

    const BackToList = () => {
        history.push("/deals");
    }



    const [openContractor, setopenContractor] = useState(false);
    const[openSubContractor, setopenSubContractor] = useState(false);
    
    
    
    const showContractorDrawer = () => {
        setopenContractor(true);
    };

    const setNewContractor = (value : any):string =>{
        setopenContractor(false);
        if(value!==""){
            setContractorOptions(prev => ([
                    ...prev===null? [] : prev,
                    {
                        value: value.data.id,
                        label : value.data.title
                    }]
            ));
            form.setFieldsValue({
                Client: value.data.id
            });

            setTheDeal(prev => ({
                ...prev,
                clientId: value.data.id,
            }));
        }
        return "";
    }

    const setSubNewContractor = (value : any):string =>{
        setopenSubContractor(false);
        if(value!==""){
            setSubContractorOptions(prev => ([
                    ...prev===null? [] : prev,
                    {
                        value: value.data.id,
                        label : value.data.title
                    }]
            ));
            form.setFieldsValue({
                Subcontractor: value.data.id
            });

            setTheDeal(prev => ({
                ...prev,
                subContractorId: value.data.id,
            }));
        }
        return "";
    }
    
    
    
    
    const OnChangeDrive=(e: any)=>{
        setstatusDriver("");
        setDriverHelp("")

        console.log("jn change driver", e);
        setTheDeal(prev => ({
            ...prev,
            employeeId: form.getFieldValue("driver"),
        }));
        if(theDeal.dealDate){
            axios.get(AuthService.DATA_URL
                +"driver/checkEmployee?EmployeeId="+e+"&StartDate="+theDeal.dealDate+"",
                {headers: authHeader()})
                .then((response) => {
                    console.log(response.data)
                    if(response.data>0){
                        setstatusDriver("warning");
                        setDriverHelp(t("Zaseden_v_teh_dneh"))
                    }
                    else{
                        setstatusDriver("");
                        setDriverHelp("")
                    }
                });
        }
    }

    const OnChangeDealDate=(e: any)=>{
        setstatusDriver("");
        setDriverHelp("")

        console.log("jn change date", e);
        setTheDeal(prev => ({
            ...prev,
            dealDate: (form.getFieldValue("dealDate")===undefined)||form.getFieldValue("dealDate")===null ? null :dateForServerFromDate(form.getFieldValue("dealDate")),
        }));
        if(theDeal.employeeId
        &&(form.getFieldValue("dealDate")!==undefined)||form.getFieldValue("dealDate")!==null) {
            axios.get(AuthService.DATA_URL
                +"driver/checkEmployee?EmployeeId="+theDeal.employeeId+"&StartDate="+dateForServerFromDate(e)+"",
                {headers: authHeader()})
                .then((response) => {
                    console.log(response.data)
                    if(response.data>0){
                        setstatusDriver("warning");
                        setDriverHelp(t("Zaseden_v_teh_dneh"))
                    }
                    else{
                        setstatusDriver("");
                        setDriverHelp("")
                    }
                });
        }
    }
    
    
    
    
    const OnChange=(values: any)=>{
        console.log("On change form");
       console.log(form.getFieldValue("planPaydate"))
        setTheDeal(prev => ({
            ...prev,
            dealDescription:form.getFieldValue("dealDescription"),
            dealNumber : form.getFieldValue("dealNumber"),
            priceForClient:form.getFieldValue("priceForClient"),
            employeeId: form.getFieldValue("driver"),
            clientId: form.getFieldValue("Client"),
            requestStatus: form.getFieldValue("dealStatus"),
            subContractorId: form.getFieldValue("Subcontractor"),
            dealDate: (form.getFieldValue("dealDate")===undefined)||form.getFieldValue("dealDate")===null ? null :dateForServerFromDate(form.getFieldValue("dealDate")),
            priceToShowToSubcontractors : form.getFieldValue("expenseAmount"),
            costPrice : form.getFieldValue("expenseAmount"),
            planPaydate: (form.getFieldValue("planPaydate")===undefined)||form.getFieldValue("planPaydate")===null ? null : dateForServerFromDate(form.getFieldValue("planPaydate")),
            planDocDate:  (form.getFieldValue("planDocDate")===undefined||form.getFieldValue("planDocDate")===null) ? null : dateForServerFromDate(form.getFieldValue("planDocDate")),
            planExpenceAmount: form.getFieldValue("planExpenceAmount"),
            planIncomeAmount: form.getFieldValue("planIncomeAmount"),
            planIncomeDate:  (form.getFieldValue("planIncomeDate")===undefined || form.getFieldValue("planIncomeDate")===null)? null : dateForServerFromDate(form.getFieldValue("planIncomeDate")),
            planExpenceDate: (form.getFieldValue("planExpenceDate")===undefined || form.getFieldValue("planExpenceDate")===null) ? null : dateForServerFromDate(form.getFieldValue("planExpenceDate")),
            title : form.getFieldValue("Title"),
            planDocNumber : nextDocNumber,
            
        }));
        if(form.getFieldValue("Subcontractor")!==user.contractorId){
            setExpenseCheckShow("inline-block")
        }
        else{
            setExpenseCheckShow("none")
        }
    }
    
    
    const onFinish = (values: any, goto?: string|undefined) => {
        console.log("goto: ", goto);
        setisPageLoading(true);
        if(theDeal.id==="00000000-0000-0000-0000-000000000000"){
            axios.post(AuthService.DATA_URL+'Deals/',
                theDeal,{headers: authHeader()})
                //.then((response)=> json(response))
                .then((response) =>{
                    console.log(response)
                    if(parseInt(goto? goto: "2")===1){
                        history.push("/deals/"+response.data)
                        window.location.reload()
                    }
                    else{
                        history.push("/deals")
                    }
                    setisPageLoading(false);
                })
        }else{
            console.log("goto: ", goto);
            axios.put(AuthService.DATA_URL+'Deals?id='+id,
                theDeal,{headers: authHeader()})
                .then()
                .then((response) =>{
                    if(parseInt(goto? goto: "2")===1){
                        history.push("/deals/"+theDeal.id)
                    }
                    else{
                        history.push("/deals")
                    }
                    setisPageLoading(false);
                })
            
        }
        console.log(theDeal);
    }
    
    
    useEffect(()=>{
        //console.log(ContractorOptions?.length, DriversOptions?.length, isLoading)
        if(ContractorOptions!==null && DriversOptions!==null && SubContractorOptions!==null && !isLoading){
            setisPageLoading(false)
           
        }
    },[ContractorOptions, DriversOptions, isLoading])
    
    
   if(isPageLoading){
        return(
            <Spin style={{verticalAlign: "middle"}}>
            <div style={{height: "100vh"}}> </div>
            </Spin>
            
            
            )
    }
    
     


    const customDot: StepsProps['progressDot'] = (dot, stat:{ status: any, index: number }) => {
        //console.log("stat: ", stat)
        if(stat.status==="process"){
            return <><span className={"ant-steps-icon-dot"} style={{backgroundColor: mapDealStatusColor(theDeal.requestStatus)}}> </span></>
        }
        else{
           return  <>{dot}</> 
        }
    }

    type LabelRender = SelectProps['labelRender'];
    const labelRender: LabelRender = (props) => {
        const { label, value } = props;

        if (label) {
            return(
            <span> 
            <span className={"dot"} style={{backgroundColor: mapDealStatusColor(Number(value)), verticalAlign: "middle"}}></span>{label}
        </span>);
        }
        return (<span> 
            <span className={"dot"} style={{backgroundColor: mapDealStatusColor(Number(value)), verticalAlign: "middle"}}></span>{label}
        </span>);
    };

    const saveItems: MenuProps['items'] = [
        { key: "1",  label: <span>{t("save_and_stay")}</span> },
        { key: "2",  label: <span>{t("save_and_quit")}</span> },
    ]
    const handleSaveOption: MenuProps['onClick'] = (e) => {
        console.log("statis change: ",e)
        onFinish(null, e.key)

    }

    const { TabPane } = Tabs;
    
    return(
        
        <>
            
            
            <Form
                form={form}
                name="wrap"
                onFinish={onFinish}
                autoComplete="off"
                layout="horizontal"
                labelCol={{ flex: '150px' }}
                labelAlign="right"
                labelWrap
                onChange={(e)=>OnChange(e)}
                wrapperCol={{ flex: 1 }}
                colon={false}
                initialValues={{
                    ["dealNumber"]:theDeal.dealNumber,
                    ["dealDescription"]: theDeal.dealDescription,
                    ["priceForClient"]: theDeal.priceForClient,
                    ["Client"]: theDeal.clientId,
                    ["driver"]: theDeal.employeeId,
                    ["dealStatus"]:theDeal.requestStatus,
                    ["Subcontractor"] : theDeal.subContractorId ? theDeal.subContractorId : user.contractorId,
                    ["dealDate"]: theDeal.dealDate ? dayjs(ServerdateToPoints(theDeal.dealDate), "DD.MM.YYYY") : dayjs(dateToPoints( new Date()), "DD.MM.YYYY"),
                    ["Title"] : theDeal.title

                }}
            >

                <Row gutter={10}>
                        <Col span={8}>
                            <Space style={{width: "100%"}}>
                                <h1 style={{marginBottom: "0px", marginTop : "0px", width: "140px"}}>{t("Naročilo")} №</h1>
                            <Form.Item name={"dealNumber"} noStyle
                            >
                                <Input value={theDeal.dealNumber ? theDeal.dealNumber: ""} style={{width: "100%"}} />
                            </Form.Item>
                            </Space>
                        </Col>
                        <Col span={16}>

                            
                            <Steps
                                style={{marginTop: "5px"}}
                                className="dealsstep"
                                //type="inline"
                                size="small"
                                progressDot={customDot}
                                current={mapRequestStatusToStep(theDeal.requestStatus)}
                                items={[
                                    {
                                        title: t('Novo'),
                                        status: theDeal.requestStatus===0 ? 'process' : "finish",
                                    },
                                    {
                                        title: t('Načrtovana'),
                                        status: theDeal.requestStatus===20 || theDeal.requestStatus===1 ? 'process'
                                            : theDeal.requestStatus<1 ?'wait' : "finish",
                                    },
                                    {
                                        title: t('Confirmed'),
                                        status: theDeal.requestStatus===30 ? 'process'
                                            : theDeal.requestStatus<30 ?'wait' : "finish",
                                    },
                                    {
                                        title: t('On_route'),
                                        status: theDeal.requestStatus===40 ? 'process'
                                            : theDeal.requestStatus<40 ?'wait' : "finish",
                                    },
                                    {
                                        title: t('Zaključena'),
                                        status: theDeal.requestStatus===50 ? 'process'
                                            : theDeal.requestStatus<50 ?'wait' : "finish",
                                    },
                                ]}
                            />

                        </Col>
                        <Divider/>
                    </Row>

                <Tabs defaultActiveKey="0" //activeKey={tabActiveKey}
                    //onTabClick={theTabClick}
                >
                    
                    
                    <TabPane tab={t("Osnovni_podatki")} key="0">
                        <Row gutter={1} style={{marginTop: "0px"}}>
                            <Col span={10}>

                                <Form.Item name="Client" label={t("Stranka")} >
                                    <Select options ={ContractorOptions? ContractorOptions : []}
                                            onChange={(e)=>OnChange(e)}
                                            dropdownRender={(menu) => (
                                                <>
                                                    {menu}
                                                    <Divider style={{ margin: '8px 0' }} />
                                                    <div style={{width: "100%", textAlign: "center"}}>
                                                        <a onClick={showContractorDrawer}>{t("Ustvarite_novo_podjetje")}</a>
                                                    </div>
                                                </>
                                            )}

                                    />
                                </Form.Item>

                                <Space>
                                    <Form.Item name="priceForClient"   label={t("Cena_stranke")} style={{width: "280px"}} >
                                        <InputNumber value={theDeal.priceForClient}  suffix="€" style={{minWidth: "120px"}}/>
                                    </Form.Item>

                                </Space>



                                <Form.Item name={"Title"} label={t("Naziv")}>
                                    <Input
                                        value={theDeal.title? theDeal.title : ""}/>
                                </Form.Item>



                                <Form.Item name={"dealDescription"} label={t("Opis")}>
                                    <Input.TextArea
                                        rows={3} autoSize={{ minRows: 3, maxRows: 3 }}
                                        value={theDeal.dealDescription? theDeal.dealDescription : ""}/>
                                </Form.Item>



                                <Form.Item name="driver" label={t("Izvajalec")} help={DriverHelp}>
                                    <Select
                                        options={DriversOptions ? DriversOptions : []}
                                        status={statusDriver}
                                        onChange={(e)=>OnChangeDrive(e)}
                                        allowClear={true}
                                    />
                                </Form.Item>
                                <Form.Item name={"dealStatus"} label={t("Status")}>
                                    <Select

                                        //value={theDeal.requestStatus}
                                        onChange={(e)=>OnChange(e)}
                                        options={[
                                            { value: 0, label: <div style={{verticalAlign: "middle"}}><span className={"dot"} style={{backgroundColor: mapDealStatusColor(Number(0))}}></span> {t('Nova')}</div> },
                                            { value: 20, label: <div style={{verticalAlign: "middle"}}><span className={"dot"} style={{backgroundColor: mapDealStatusColor(Number(20)), verticalAlign: "middle"}}></span> {t('Načrtovana')}</div>  },
                                            { value: 30, label: <div style={{verticalAlign: "middle"}}><span className={"dot"} style={{backgroundColor: mapDealStatusColor(Number(30)), verticalAlign: "middle"}}></span> {t('Confirmed')}</div>  },
                                            { value: 40, label: <div  style={{verticalAlign: "middle"}}><span className={"dot"} style={{backgroundColor: mapDealStatusColor(Number(40)), verticalAlign: "middle"}}></span> {t('On_route')}</div>  },
                                            { value: 50, label: <div  style={{verticalAlign: "middle"}}><span className={"dot"} style={{backgroundColor: mapDealStatusColor(Number(50)), verticalAlign: "middle"}}></span> {t('Zaključena')}</div>  }
                                        ]}
                                    />
                                </Form.Item>
                                <Form.Item label={t("Date")} name="dealDate">
                                    <DatePicker style={{width: "120px"}} format={"DD.MM.YYYY"} placeholder={"datum"}
                                                value={dayjs(theDeal.dealDate, "DD.MM.YYYY")}
                                                onChange={(e)=>OnChangeDealDate(e)}
                                    />

                                </Form.Item>
                                <Divider/>
                                <Form.Item name="Subcontractor" label={t("Contractor")} >
                                    <Select options ={SubContractorOptions? SubContractorOptions : []}
                                            onChange={(e)=>OnChange(e)}
                                            dropdownRender={(menu) => (
                                                <>
                                                    {menu}
                                                    <Divider style={{ margin: '8px 0' }} />
                                                    <div style={{width: "100%", textAlign: "center"}}>
                                                        <a onClick={()=>setopenSubContractor(true)}>{t("Ustvarite_novo_podjetje")}</a>
                                                    </div>
                                                </>
                                            )}

                                    />
                                </Form.Item>
                                <Divider/>
                                <Button className={"emptyButton"} onClick={BackToList} style={{}}>
                                    {t("Ne_shranite_sprememb")}
                                </Button>

                                <Dropdown menu={{'items': saveItems, onClick: handleSaveOption }} >
                                    <Button className={"orangeButton"}
                                            style={{ float:"right",/*marginTop:"25px", marginRight:"50px",*/ }}>
                                        {t("Shraniti")}

                                        <DownOutlined />
                                    </Button>
                                </Dropdown>





                            </Col>



                            <Col span={12} offset={2}>
                                <div style={{display: id==="0"? "block": "none"}}>
                                    <Divider  orientation="left" orientationMargin="0">{t("Dokumenti")}</Divider>
                                    <Form.Item name="planDoc"  style={{ marginBottom: "5px" }} >
                                        <Checkbox
                                            onChange={(ev)=>{
                                                PlanDocClick(ev)
                                            }}
                                        >{t("Create_invoice")}</Checkbox>
                                    </Form.Item>
                                    <div style={{display: planDocShow}} >
                                        <Form.Item name={"planDocDate"} label={<span>№ {nextDocNumber} {t("from")}</span>}
                                                   style={{ marginBottom: "5px" }}>
                                            <DatePicker style={{width: "120px"}} format={"DD.MM.YYYY"} placeholder={"datum"}
                                                        onChange={(e)=>OnChange(e)}
                                            />
                                        </Form.Item>

                                        <Form.Item name={"planPaydate"}   label={<span style={{marginLeft: "10px"}}>{t("Datum_plačila")}</span>}>
                                            <DatePicker style={{width: "120px"}}
                                                        format={"DD.MM.YYYY"} placeholder={"datum"}
                                                        onChange={(e)=>OnChange(e)}
                                            />
                                        </Form.Item>
                                    </div>

                                    <Divider orientation="left" orientationMargin="0">{t("Finances")}</Divider>
                                    <Form.Item name="planIncome" style={{ marginBottom: "5px" }} >
                                        <Checkbox
                                            onChange={(ev)=>{
                                                PlanIncomeClick(ev)
                                            }}
                                        >{t("Schedule_receipt")}</Checkbox>
                                    </Form.Item>
                                    <div style={{display: planIncomeShow}}>
                                        <Form.Item name={"planIncomeAmount"} label={<span>{t("Amount")}</span>}
                                                   style={{ marginBottom: "5px" }}>
                                            <InputNumber value={theDeal.priceForClient}  suffix="€" style={{width: "120px"}}/>
                                        </Form.Item>
                                        <Form.Item name={"planIncomeDate"}   label={t("Datum_plačila")}>
                                            <DatePicker style={{width: "120px"}}
                                                        format={"DD.MM.YYYY"} placeholder={"datum"}
                                                        onChange={(e)=>OnChange(e)}
                                            />
                                        </Form.Item>
                                    </div>
                                    <Form.Item name="planExpense" style={{ marginBottom: "5px", display: ExpenseCheckShow }}  >
                                        <Checkbox
                                            onChange={(ev)=>{
                                                PlanExpenseClick(ev)
                                            }}
                                        >{t("Schedule_payment_to_contractor")}</Checkbox>
                                    </Form.Item>
                                    <div style={{display: planExpenseShow}}>
                                        <Form.Item name={"planExpenceAmount"} label={<span>{t("Amount")}</span>}
                                                   style={{ marginBottom: "5px" }}>
                                            <InputNumber value={theDeal.priceForClient}  suffix="€" style={{width: "120px"}}/>
                                        </Form.Item>
                                        <Form.Item name={"planExpenceDate"}   label={t("Datum_plačila")}>
                                            <DatePicker style={{width: "120px"}}
                                                        format={"DD.MM.YYYY"} placeholder={"datum"}
                                                        onChange={(e)=>OnChange(e)}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>





                                
                                <div style={{display: id==="0"? "none": "block"}}>
                            
                                    <DealLogsTimeLine  id={id? id : ""}/>

                                </div>

                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab={id === "0" ?"" : t("Dokumenti")} key="2"
                             style={{
                                 height:  "calc(75vh - 4em)",//"300px",
                                 overflow: "scroll",
                                
                             
                             }}
                    >
                        <DocList RequestId={id}/>
                    </TabPane>
                    <TabPane tab={id === "0" ?"" : t("Finances")} key="33"
                             style={{
                                 height:  "calc(75vh - 4em)",//"300px",
                                 overflow: "scroll",
                                
                             }}

                    >
                        <TransactionsList filter={transactionFilter} commonFilter={{requestId: theDeal.id}} />
                    </TabPane>
                </Tabs>
                
                
              
                    

               


            </Form>




            <Drawer title={t("Ustvarite_novo_podjetje")} placement="right" onClose={()=>setopenContractor(false)} open={openContractor}>
                <CreateContractor afterCreate={setNewContractor}  isClient={true}/>
            </Drawer>
            <Drawer title={t("Ustvarite_novo_podjetje")} placement="right" onClose={()=>setopenSubContractor(false)} open={openSubContractor}>
                <CreateContractor afterCreate={setSubNewContractor}  isClient={false}/>
            </Drawer>

        </>
       
        
        
    )
}