import React, { useEffect, useState }  from 'react';
import {Button, Space, Table, Input, Tooltip, Badge, Collapse, Select} from 'antd';
import { DownOutlined,  SearchOutlined } from '@ant-design/icons';

import type { ColumnsType } from 'antd/es/table';
import axios from "axios/index";

import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";

import authHeader from "../../../Services/auth-header";
import {CollapsibleType} from "antd/es/collapse/CollapsePanel";
import {useTranslation} from "react-i18next";
import { Link } from 'react-router-dom';





export default function ListTrucks() {

    const {t} = useTranslation();




    interface DataType {
        key: React.Key;
        id : string;
        regNumber: string;
        brand: string;
        model: string;
        truckType: number;
        vtruckType : string
        rollingStockType: number;
        vrollingStockType: string;
        loadingType: number;
        vloadingType: string;
        age: number;
        address: string;
        location : string;
        locationDate : string;
    }

    const [searchText, setSearchText] = useState("")
    const [showFilterBadge, setshowFilterBadge] = useState(false)

    const [selectedType, setselectedType] = useState(100)
    const [selectedRolling, setselectedRolling] = useState(100)
    const [selectedLoading, setselectedLoading] = useState(100)




    const stringToPoints =(today : string) =>{
        var ddddd=today.substring(8,10)+"."+today.substring(5,7)+"."+today.substring(0,4);
        return ddddd;
    }



    const columns: ColumnsType<DataType> = [
        {
            title: t('Reg_številka'),
            dataIndex: 'regNumber',
            //width: 100,
            //sorter: (a, b) => a.regNumber.length - b.regNumber.length,
            //sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
            ellipsis: true,
            filteredValue :[searchText],
            onFilter: (value, record) => {
                return (
                    String(record.regNumber).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.brand).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.model).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.vtruckType ).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.vrollingStockType).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.vloadingType).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.location).toLowerCase().includes(value.toString().toLowerCase())

                )
            },
            render: (text, record) => {
                if(record.truckType===0){
                    return(
                        <div>
                            <div><img src={"/img/traktor.png"} alt={" "} style={{marginRight: "10px"}} /> {text}</div>
                        </div>
                    )
                }
                else{
                    if(record.truckType===1){
                        return(
                            <div>
                                <div><img src={"/img/priklopnik.png"} alt={" "} style={{marginRight: "5px", width: "35px"}} />{text}</div>
                            </div>
                        )
                    }else{
                        return(
                            <div>
                                <div><img src={"/img/tovornjak.png"} alt={" "} style={{marginRight: "5px", width: "35px"}} />{text}</div>
                            </div>
                        )

                    }
                }
            }

        },
        {
            title: t('Znamka_Model'),
            dataIndex: 'brand',
            width: 120,
            render:(text, record)=>{
                return(<div>
                    {record.brand}<br/>
                    <span style={{fontSize:"10px"}}>{record.model}</span>
                </div>)
            }
        },
        {
            title: t('Vrsta_transporta'),
            dataIndex: 'vtruckType',
            //width: 120,
            filteredValue :[selectedType],
            onFilter: (value, record:any) => {
                //console.log("selectedType: ", selectedType, " | selectedLoading: ", selectedLoading)
                return (record.truckType.toString()===selectedType.toString() || (selectedType.toString()==="100")) &&
                    (record.rollingStockType.toString()===selectedRolling.toString() || (selectedRolling.toString()==="100"))&&
                    (record.loadingType.toString()===selectedLoading.toString() || (selectedLoading.toString()==="100"))


            },
            render:(text, record)=>{
                return(<div>
                    {record.vtruckType}<br/>
                    <span style={{fontSize:"10px"}}>{record.vrollingStockType} / {record.vloadingType}</span>
                </div>)
            }
        },
        {
            title: t("Zadnje_mesto_razkladanja"),
            dataIndex: 'location',
            render: (text, record) => {
                return(<div>
                    {record.location}<br/>
                    <span style={{fontSize:"10px"}}>{record.locationDate ? stringToPoints(record.locationDate): ""}</span>
                </div>)
            }
        },
        {
            title: ' ',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (text, record) =>
                <Link className={"actionLink"} to={`/trucks/${record.id}`}>{t("Več")} &rarr;</Link>
                //<a className={"actionLink"} href={"trucks/truck?id="+record.id}>{t("Več")} &rarr;</a>,
//            <Link to="../trucks/"+record.key >Детали &rarr;</Link>, 
// <a className={"actionLink"} Link to={"5"}>Детали &rarr;</a>,
        },
    ];

    const history= useHistory();

    //const TableData: DataType[] = [];
    const [APIData, setAPIData] = useState<DataType[]>([]);
    //const [LAPIData, setLAPIData] = useState<LDataType[]>([]);

    
    
    
    
   // const [TableData, setTableData] = useState<DataType[]>([]);
    const [isLoading, setisLoading] = useState(true);


    useEffect(() => {
        axios.get(AuthService.DATA_URL+`Truck`, { headers: authHeader() })
            .then((response) => {
                //setAPIData(response.data);
                //setisLoading(false);
                })
            .catch(function (error) {
                if(error.response.status===401){
                    AuthService.logout();
                    history.push("/login");
                    window.location.reload();

                }
            });

        axios.get(AuthService.DATA_URL+`Truck/get-with-location`, { headers: authHeader() })
            .then((response) => {
                console.log("get-with-location response: ", response.data);
                setAPIData(response.data);
                setisLoading(false);
            })
        ;


                //setTableData(TableData);
                //console.log("TableData: ", TableData);

    }, []);
    
    const TableData:DataType[] =[];

    APIData.forEach((tr, i)=> {
    // console.log(tr);
    TableData.push({
        key: i,
        id: tr.id,
        regNumber: tr.regNumber, //`LJ NB ${(i+2)%10}${(i+13)% 10}${(i+28)%10}`,
        brand: tr.brand,//`SCANIA`, //`SCANIA ${i}`,
        model: tr.model,
        vtruckType : (tr.truckType === 0 ? t('Vlačilec') : (tr.truckType === 1 ? t('Priklopnik') : t('Tovornjak'))),
        truckType : tr.truckType,
        rollingStockType: tr.rollingStockType,
        vrollingStockType: (tr.rollingStockType === 0 ? t('Nagibno') : tr.rollingStockType === 1 ? t('Odprto') : t('Hladnik')),
        loadingType : tr.loadingType,
        vloadingType: (tr.loadingType === 0 ? t('Nad') : t('Zadaj')),
        age: i,
        address: ``,
        location : tr.location,
        locationDate : tr.locationDate
    });
});



        const clearAll = () => {
        history.push("/newtruck");
        //window.location.href="/newtruck";
    };


    const [filterShow, setfilterShow] = useState('disabled' as CollapsibleType)
    const [filterKey, setfilterKey] = useState(["0"])



    const ShowFilters=()=>{
        console.log("filterShow: ", filterShow);
        if(filterShow.toString()==="disabled"){
            setfilterShow("head" as CollapsibleType);
            setfilterKey(["1"]);
            //setfilterShow("disabled" as CollapsibleType);
        }
        else{
            setfilterKey(["0"]);
            setfilterShow("disabled" as CollapsibleType);
        }
    }


    if(!TableData){
        return (<div>Loading..</div>)
        
    }

    return (
        <div>
            <div style={{marginBottom: "20px"}}>
            
            {/* 
            <Space style={{ marginBottom: 0 }}>
                <Badge color={"orange"} dot={showFilterBadge}>
                    <Button className={"emptyB"} onClick={ShowFilters}>
                        <Space>
                            Filtri
                            <DownOutlined />
                        </Space>
                    </Button>
                </Badge>

            </Space>
            */}
            <Space>
                <div style={{paddingLeft: "275px"}}>
                    <Select style={{minWidth: "170px", textAlign: "right"}}
                            defaultValue={selectedType}
                            bordered={false}
                            dropdownMatchSelectWidth={false}
                            options={[
                                { value: 100, label:t('Vse_vrste_transporta') },
                                { value: 0, label: t('Vlačilec') },
                                { value: 1, label: t('Priklopnik') },
                                { value: 2, label: t('Tovornjak') },
                            ]}
                            onChange={(v: number) => {
                                setshowFilterBadge(true);
                                if (v === 100) {
                                    if(selectedLoading===100 && selectedRolling ===100)
                                        setshowFilterBadge(false);
                                }
                                setselectedType(v)
                            }}
                    />
                    <Select style={{ minWidth: "140px", textAlign: "center"}}
                            defaultValue={selectedRolling}
                            bordered={false}
                        //dropdownMatchSelectWidth={false}
                            options={[
                                { value: 100, label: t('Vse_tipe_telesa') },
                                { value: 0, label: t('Nagibno') },
                                { value: 1, label: t('Odprto') },
                                { value: 2, label: t('Hladnik') },
                            ]}
                            onChange={(v: number) => {
                                setshowFilterBadge(true);
                                if (v === 100) {
                                    if(selectedType===100 && selectedLoading===100)
                                        setshowFilterBadge(false);
                                }
                                setselectedRolling(v)
                            }}
                    />
                    <Select
                        defaultValue={selectedLoading}
                        bordered={false}
                        dropdownMatchSelectWidth={false}
                        options={[
                            { value: 100, label: t('Vse_vrste_nakladanja') },
                            { value: 0, label: t('Nad') },
                            { value: 1, label: t('Zadaj') },
                            { value: 2, label: t('Strani') },
                        ]}
                        onChange={(v: number) => {
                            setshowFilterBadge(true);
                            if (v === 100) {
                                if(selectedType===100 && selectedRolling===100)
                                    setshowFilterBadge(false);
                            }
                            setselectedLoading(v)
                        }}
                    />

                </div>
            </Space>
            <Space style={{float:'right'}}>
                <Input.Search className={"emptyB"}
                       placeholder={t("Išči")}
                       onSearch={(value)=>{
                           setSearchText(value)
                       }}
                       onChange={(e)=>{
                           setSearchText(e.target.value)
                       }}

                />

                <Button onClick={clearAll}  className={"emptyButton"}>{t("Prenesite_v_Excel")}</Button>

            </Space>
            </div>


            <div style={{background: '#fff'}} id={"driverstab"}>

                <Table
                    locale={{
                        triggerDesc: t("Kliknite_za_razvrščanje_naraščajoče"),
                        triggerAsc: t("Kliknite_za_razvrščanje_padajoče"),
                        cancelSort: t("Prekliči_razvrščanje")
                    }}
                    columns={columns}
                    loading={isLoading}

                    dataSource={TableData}
                    pagination={false}
                    scroll={{ y: 'calc(53vh)' }}

                    //pagination={{ pageSize: 50 }} size="small"  scroll={{ y: 'calc(60vh - 4em)' }}
                />




            </div>
        </div>

    );
}
