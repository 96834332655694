import React, {useCallback, useState, useEffect, useContext} from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {
    BrowserRouter as Router,
    Route, useHistory
} from "react-router-dom";
import {DATA_URL} from "../../Services/auth-service";
import axios from "axios";
import authHeader from "../../Services/auth-header";
import * as AuthService from "../../Services/auth-service";
import fetchauthHeader from "../../Services/fetch-auth-header";
import {subscribe, unsubscribe} from "../../Components/Notifications/NotificationContext";
import {message} from "antd";

const stripePromise = loadStripe("pk_test_YYr21W9dlUm5tdKjXUqnnX9h");


interface CheckOutProps{
    calculation: number
}

const CheckoutForm = ({calculation}: CheckOutProps) => {

    const [secret, setSecret] = useState<string|null>(null);
    //const l_calculation = localStorage.getItem("calculation");

    useEffect(()=>{
        subscribe("paymentFailed", (data: any) => message.error("Ошибка"));

        if(secret===null){

            //console.log("calculation: ", l_calculation)
            axios.post(DATA_URL+"ServicePayments/create-checkout-session", {}, {headers:authHeader()})
                .then((res: any)=>{
                    setSecret(res);
                    console.log(res)
                })
        }
        return () => {
            unsubscribe("paymentFailed", (data: any) => message.error("Ошибка"));
        }

    })


    const fetchClientSecret = useCallback(() => {
        // Create a Checkout Session
        // @ts-ignore

        return fetch(DATA_URL+"ServicePayments/create-checkout-session", {
            method: "POST",
            headers: fetchauthHeader(),
            body: JSON.parse(JSON.stringify(localStorage.getItem("calculation")))//JSON.stringify(l_calculation)
        })
            .then((res) => res.json())
            .then((data) => data.clientSecret);
    }, []);

    const options = {fetchClientSecret};

    return(<div style={{maxHeight: '90vh', overflow: "scroll"}}>
        <div id="checkout">
            <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}
            >
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>

    </div>)

}



export default CheckoutForm;


//export default