import React, {useRef, useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
//import RacunPage from "../../Racun";
import {toCanvas} from "html-to-image";
import {
    Button,
    Col,
    Space,
    Row,
    Select,
    Form,
    List,
    Checkbox,
    DatePicker,
    Divider,
    Spin,
    MenuProps,
    Dropdown, Drawer
} from "antd";
import ShowDoc from "./ShowDoc";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import * as RequestService from "../../../Services/Requests";
import dayjs from 'dayjs';
import DocPositionForm from "./PositionForm";
import PositionForm from "./PositionForm";
import PositionsList from "./positionsList";
import DocPosition from "../../../types/DocPosition";
import {useTranslation} from "react-i18next";
import {dateToPoints} from "../../../types/transporter-types";
import {DownOutlined, PrinterOutlined} from '@ant-design/icons';
import Doc from "../../../types/DocPosition";
import {dateForServer, mapDocTypeEnumToString, ServerdateToPoints} from "../../../utils/helpers";
import Input from "antd/es/input/Input";
import i18n from "i18next";
import CreateContractor from "../../contractors/ContractorsComponents/create";
import EditContractorComp from "../../contractors/ContractorsComponents/EditComponent";
import ProductSelect from "./ProductSelect";



interface RacunProps {
    id2? : string|null;
    ContractorId?: string;
    forceReload?: boolean;
    isLoaded: () => void;
}
interface Params {
    id: string;
}
const CreateDocPage = ({id2, ContractorId, forceReload}: RacunProps) => {


    const {id} = useParams<Params>();
    const {t} = useTranslation();
    
    const history = useHistory();
    const user = AuthService.getCurrentUser();
    if (!user) {
        history.push("/login");
    }
    
    
    
    const routeList = RequestService.routeList();

    const [routListOptions, setroutListOptions] = useState<any[]>([]);
    const [routeContractors, setrouteContractors] = useState<any[]>([]);
    const [langIcon, setlangIcon] = useState("🇬🇧");
    const [docLang, setdoclang] = useState("en");


    const [openContractor, setopenContractor] = useState(false);




    const [theDoc, setDoc] = useState<Doc>({
        id:"",
        key: "",
        requestId:"",
        request:null,
        contractorId:"",
        counterPartId: "",
        contractor:null,
        documentDate:"",
        payementDate:"",
        createdByContractorId:"",
        expiryDate:"",
        createdAt:"",
        documentType:10,
        documentOrigin:0,
        requestReplyId:"",
        requestReply:null,
        routePointId:"",
        routePoint:null,
        driverId:"",
        driver:null,
        truckId:"",
        truck:null,
        userId:"",
        docLanguage:"",
        docPositions:[]
    })



    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    
    useEffect(()=>{
        if(theDoc.id===""){
            
            if(theDoc.docNumber===null || theDoc.docNumber===undefined) {
                console.log("theDoc.docNumber", theDoc.docNumber)
                axios.get(AuthService.DATA_URL + 'docs/get-next-number?docType=10', {headers: authHeader()})
                    .then((response) => {
                        
                        setDoc(prev => ({
                            ...prev,
                            docNumber : response.data
                        }));
                    })
                    .catch(function (error) {
                        if(error.response.status===401){
                            AuthService.logout();
                            history.push("/login");
                            window.location.reload();

                        }
                    })
                ;
            }
            
            if(id!=="createdoc"){
                axios.get(AuthService.DATA_URL+'docs/get-doc-dto?id='+id, {headers: authHeader()})
                    .then((response)=>{

                        console.log("dateToPoints: ",ServerdateToPoints(response.data.documentDate))
                        console.log("ServerdateToPoints: ",dayjs(ServerdateToPoints(response.data.documentDate), "DD.MM.YYYY"))
                        if(!ContractorOption.find(s=>s.value===response.data.contractorId)){

                            const Contractor = routeContractors.find(s=>s.contractorId ===response.data.contractorId);
                        }

                       
                        switch(response.data.docLanguage) {
                            case "en": {
                                setdoclang("English");
                                setlangIcon("en")
                                break;
                            }
                            case "si": {
                                setdoclang("Slovenščina");
                                setlangIcon("si")
                                break;
                            }
                            case "fr": {
                                setdoclang("Français");
                                setlangIcon("fr")
                                break;
                            }
                            case "de": {
                                setdoclang("Deutsch");
                                setlangIcon("de")
                                break;
                            }
                            case "ru": {
                                setdoclang("Русский");
                                setlangIcon("ru")
                                break;
                            }
                            case "it": {
                                setdoclang("Italiano");
                                setlangIcon("it")
                                break;
                            }
                            case "uk": {
                                setdoclang("Українська");
                                setlangIcon("uk")
                                break;
                            }


                            default: {
                                setdoclang("English");
                                setlangIcon("en")
                                break;
                            }
                        }


                         
                        console.log("response.data.payementDate: ", response.data.payementDate);
                        setDoc(prev => ({
                            ...response.data,
                            docLanguage : response.data.docLanguage ===null? "en" : response.data.docLanguage,
                            documentDate : ServerdateToPoints(response.data.documentDate),
                            payementDate:ServerdateToPoints(response.data.payementDate? response.data.payementDate : undefined)
                        }));
                    })
            }
        }
    })

    const [formV] = Form.useForm();
    const [formH] = Form.useForm();


    const onSelectDDate=(value: any)=>{

        setDoc(prev => ({
            ...prev,
            documentDate : dateToPoints(new Date(value))
        }));
    }

    const onSelectPDate=(value: any)=>{

        setDoc(prev => ({
            ...prev,
            payementDate : dateToPoints(new Date(value))
        }));

    }
    
    const onSelectDocumentType=(value: any)=>{
        
        axios.get(AuthService.DATA_URL + 'docs/get-next-number?docType='+value, {headers: authHeader()})
            .then((response) => {
                console.log(response.data)
                setDoc(prev => ({
                    ...prev,
                    documentType : value,
                    docNumber: response.data
                }));
                formV.setFieldValue("docNumber", response.data)
                setrerender(!rerender);
            });

       
    }



    useEffect(()=>{
        if(routListOptions.length===0){
            routeList.then(
                ((value: any)=>{
                        if(value.length>0){
                            routListOptions.length=0;
                            routeContractors.length=0
                            value.forEach((row:any)=>{
                                routListOptions.push(
                                    {
                                        label: row.createdByContractorId===user.contractorId?  row.requestNumber : row.externalRequestNumber,
                                        value: row.id,
                                        price : row.price
                                    }
                                );
                                
                                routeContractors.push(
                                    {
                                        requestId : row.id,
                                        contractorId : row.payerId ? row.payerId : row.clientId,
                                        title: row.payerId ? row.payer.title : row.client? row.client.title : "-"
                                    }
                                );
                            })
                            setroutListOptions(routListOptions)
                        }
                    }
                ));
        }

        var ddd= new Date();

        var  date= new Date();
        setDoc(prev => ({
            ...prev,
            documentDate : dateToPoints(date)
        }));
        date.setDate(date.getDate() + 7);
        setDoc(prev => ({
            ...prev,
            payementDate : dateToPoints(date)
        }));


    },[])
    
    const [connectToRequest, setconnectToRequest] = useState(false);
    

    const [rerender, setrerender] = useState(true);
    const [apiData, setapiData] = useState<any[]>([]);
    const [contractorToEdit, setcontractorToEdit]=useState("");
    
    const [drawerTitle, setdrawerTitle]=useState("Ustvarite_novo_podjetje");


    const [ContractorOption,setContractorOption]=useState<{label:string, value: string, key?: string|null, davcnastevilka?: string|null }[]>([])

    const setNewContractor = (value : any):string =>{
        console.log(value)
        setopenContractor(false);
        if(value!==""){
            setContractorOption(prev => ([
                    ...prev===null? [] : prev,
                    {
                        value: value.data.id,
                        label : value.data.title,
                        key: value.data.id,
                        davcnastevilka: value.data.davcnaStevilka
                    }]
            ));
            if(value.data.davcnaStevilka===""){
                console.log("no ds")
            }
            else{
                formH.setFieldsValue({
                    contractorSelect: value.data.id
                });

                setDoc(prev => ({
                    ...prev,
                    contractorId : value.data.id,
                    counterPartId : value.data.id
                }));
            }

        }
        return "";
    }
    
    
    
    

    useEffect(()=>{
        if(apiData.length===0){
            axios.get(AuthService.DATA_URL + 'Contractor/', {headers: authHeader()})
                .then((response)=>JSON.parse(JSON.stringify(response, null, 4)))
                .then((response) => {
                    setapiData(response.data);
                })
                .catch(function (error) {
                    if(error.response.status===401){
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                });
        }

    });
    
    useEffect(()=>{
        if(ContractorOption.length===0){
            if(apiData.length>0){
                setContractorOption(apiData.map((tr, index) => ({
                    label : tr.title,
                    value : tr.id,
                    key: tr.id,
                    davcnastevilka: tr.davcnaStevilka
                }) ))
            }
        }
    }, [apiData])







    const ShowContractorDoc = (e: any) => {
        
        var cnt=ContractorOption.find(o=>o.value===e);
        console.log("cnt: ", cnt)
        if(cnt?.davcnastevilka===null || cnt?.davcnastevilka===''){
            formH.setFieldsValue({
                contractorSelect:""
            });
            setcontractorToEdit(e)
            setdrawerTitle("No DDV!!!")
            setopenContractor(true);
        }else{
            setDoc(prev => ({
                ...prev,
                contractorId : e,
                counterPartId: e
            }));
        }
        
        
        
        //setContId(e);
    }


    const setDocNumber=(e:any)=>{
        //console.log(e);
        setDoc(prev => ({
            ...prev,
            docNumber : e.target.value
        }));
        setrerender(!rerender);
    }
    
    
    const refreshRequestId = (e: any) =>{
        setDoc(prev => ({
            ...prev,
            requestId : e
        }));

        var d=new Date();
        var secondsStart = d.getTime();
        const reqq = routListOptions.find(s=>s.value===e);

        setDoc(prev => ({
            ...prev,
            docPositions : [{
                id:secondsStart.toString(),
                naziv: t("Prevoz_blaga_po_naročilu")+" " + reqq.label,
                kolicina: 1,
                davek: 0,
                cena: reqq.price//response.data.price

            }]
        }));
        const Contractor = routeContractors.find(s=>s.requestId===e);
        console.log("found contractor: ",Contractor)
        if(!ContractorOption.find(s=>s.value===Contractor.contractorId)){
            setContractorOption(prev => ([
                    ...prev,
                    {
                        label : Contractor.title,
                        value: Contractor.contractorId,
                        key:Contractor.contractorId
                    }]
            ));

        }

        formH.setFieldsValue({
            ["contractorSelect"]: Contractor.contractorId
        });
        ShowContractorDoc(Contractor.contractorId);
        setDoc(prev => ({
            ...prev,
            contractorId : Contractor.contractorId,
            counterPartId: Contractor.contractorId
        }));
    }
    
    const printDoc=()=>{
        const printContent = document.getElementById("htmlContainer");
        const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
        // @ts-ignore
        WindowPrt.document.write(printContent? printContent.innerHTML : "no content");
        // @ts-ignore
        console.log(WindowPrt.document.readyState)

        // @ts-ignore
        if(WindowPrt.document.readyState==="complete"){
            // @ts-ignore
            WindowPrt.focus();
            // @ts-ignore
            WindowPrt.print();
            // @ts-ignore
            WindowPrt.document.close();
            // @ts-ignore
            WindowPrt.close();
        }
        
        
        

        
        
        


    }


    const checkCO=()=>{
        console.log("theDoc: ", theDoc)
    }
    

    const handleListChange=(posiitions? : DocPosition[]) => {
        console.log("Create Doc handleListChange posiitions: ", posiitions)
        if(posiitions!==undefined){
            setDoc(prev => ({
                ...prev,
                docPositions : posiitions
        }));
        }
        setrerender(!rerender);
    }
    
    
    
    //console.log(ContractorOption)
    if(ContractorOption.length===0){
        return <Spin/>
    }
    
    
    
    
    const SaveDocument=()=>{

        const newDoc: Doc={
            id:"00000000-0000-0000-0000-000000000000",
            requestId : theDoc.requestId,
            contractorId: theDoc.contractorId,
            counterPartId: theDoc.counterPartId,
            documentType: theDoc.documentType,
            documentOrigin: 0,
            docNumber: theDoc.docNumber,
            documentDate : dateForServer(theDoc.documentDate),
            payementDate : dateForServer(theDoc.payementDate),
            docPositions: [],
            docLanguage: theDoc.docLanguage
        }
        var newdocId="";
        if(theDoc.id===""){
            console.log("newDoc: ", newDoc)
            axios.post(AuthService.DATA_URL+'Docs/',
                newDoc, {headers: authHeader()})
                .then((response) =>{
                        //console.log(response.data);
                        //console.log(theDoc.docPositions);
                        newdocId=response.data;
                        //console.log("newdocId: ", newdocId);
                        axios.delete(AuthService.DATA_URL+'DocPoistion/delete-for-doc?id='+newdocId,
                            {headers: authHeader()})
                            .then((response) =>{
                                var idx: number=0;
                                console.log(theDoc.docPositions)
                                theDoc.docPositions?.forEach((pos:DocPosition)=>{
                                    pos.id="00000000-0000-0000-0000-000000000000";
                                    pos.docId=newdocId;
                                    pos.orderInDoc=idx;
                                    axios.post(AuthService.DATA_URL+'DocPoistion/',
                                        pos, {headers: authHeader()})
                                        .then((response) =>{})
                                    idx=idx+1;
                                })
                            });
                    history.push("/docs");
                    }
                );
        }
        else{
            console.log("theDoc.id: ", theDoc.id);
            console.log("newDoc: ", newDoc)
            axios.put(AuthService.DATA_URL+'Docs/'+theDoc.id,
                newDoc, {headers: authHeader()})
                .then((response) =>{
                        //console.log(response.data);
                        //console.log(theDoc.docPositions);
                        newdocId=response.data;
                        axios.delete(AuthService.DATA_URL+'DocPoistion/delete-for-doc?id='+theDoc.id,
                            {headers: authHeader()})
                            .then((response) =>{
                                var idx: number=0;
                                theDoc.docPositions?.forEach((pos:DocPosition)=>{
                                    pos.id="00000000-0000-0000-0000-000000000000";
                                    pos.docId=theDoc.id;
                                    pos.orderInDoc=idx;
                          //          console.log("pos: ", pos)
                                    axios.post(AuthService.DATA_URL+'DocPoistion/',
                                        pos, {headers: authHeader()})
                                        .then((response) =>{})
                                    idx=idx+1;
                                })
                                //history.push("/docs");
                                //window.location.reload()
                            });

                    }
                );       
        }
        
        //console.log(newDoc)
        
        
    }



    const requestFilterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    
    if(id!=="createdoc" && theDoc.id===""){
        return <Spin/>
    }



    const items: MenuProps['items'] = [
        { key: 'en', icon: <img src="/img/lang/en.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; English</span> },
        { key: 'si', icon: <img src="/img/lang/si.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Slovenščina</span> },
        { key: 'fr', icon: <img src="/img/lang/fr.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Français</span> },
        { key: 'de', icon: <img src="/img/lang/de.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Deutsch</span>},
        { key: 'ru', icon: <img src="/img/lang/ru.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Русский</span> },
        { key: 'it', icon: <img src="/img/lang/it.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Italiano</span> },
        { key: 'uk', icon: <img src="/img/lang/uk.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Українська</span> },
    ]

    const handleLanguageClick: MenuProps['onClick'] = (e) => {


        switch(e.key) {
            case "en": {
                setdoclang("English");
                setlangIcon("en")
                break;
            }
            case "si": {
                setdoclang("Slovenščina");
                setlangIcon("si")
                break;
            }
            case "fr": {
                setdoclang("Français");
                setlangIcon("fr")
                break;
            }
            case "de": {
                setdoclang("Deutsch");
                setlangIcon("de")
                break;
            }
            case "ru": {
                setdoclang("Русский");
                setlangIcon("ru")
                break;
            }
            case "it": {
                setdoclang("Italiano");
                setlangIcon("it")
                break;
            }
            case "uk": {
                setdoclang("Українська");
                setlangIcon("uk")
                break;
            }


            default: {
                setdoclang("English");
                setlangIcon("en")
                break;
            }
        }
        
        
        
        
        //setdoclang(e.key)
        setDoc(prev => ({
            ...prev,
            docLanguage : e.key
        }));
    };

    const menuProps = {
        items,
        onClick: handleLanguageClick,
    };
    
    
    

    
    return (
        <div style={{maxWidth: "100%", overflowX: "hidden"}}>
            <Row>
                <Col span={14}>
                    <h1>{theDoc.id===""?
                        t("Nov_dokument")
                        : t(mapDocTypeEnumToString(theDoc.documentType? theDoc.documentType: -1))+" № "+theDoc.docNumber
                    }



                    </h1>
                    <Form
                        form={formV}
                        layout="vertical"
                        //labelCol={{ flex: '100px' }}
                        labelAlign="right"
                        labelWrap
                        //wrapperCol={{ flex: 1 }}
                        initialValues={
                            {
                                ["vrsta"]:theDoc.documentType,
                                ["docNumber"]:theDoc.docNumber
                            }
                        }
                    >
                        
                        <Row gutter={8}>
                            <Col span={5}>
                                <Form.Item label={t("Vrsta")} name="vrsta">
                                <Select
                                    options={[{ label: t("Račun"), value: 10 },{ label: t("CMR"), value: 11 }] }
                                   
                                    value={theDoc.documentType}
                                    //style={{marginLeft: "20px", }}
                                    
                                    onChange={(e) => onSelectDocumentType(e)}
                                    //defaultValue={form.getFieldsValue().truckType}
                                />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name={"docNumber"} label={t("Številka")} >
                                    <Input type="text" value={theDoc.docNumber? theDoc.docNumber : ""}
                                    autoComplete={"off"}
                                           onChange={(e)=>{setDocNumber(e)}}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item>                                     
                                    <Checkbox style={{marginBottom:"8px"}}
                                    onChange={(ev)=>{
//                                  //      console.log(ev.target.checked)
                                        if(!ev.target.checked){
                                            setDoc(prev => ({
                                                ...prev,
                                                requestId : null
                                            }));
                                            //setRequestId(null);
                                        }
                                        setconnectToRequest(!connectToRequest);
                                    }}
                                    >{t("Za_naročilo")}:</Checkbox>

                                    
                                    <Select disabled={!connectToRequest}
                                    options={(routListOptions.length===0 ? [{}] : routListOptions)}
                                            showSearch
                                            filterOption={requestFilterOption}
                                    value={theDoc.requestId}
                                    onChange={(e) => {
                                        refreshRequestId(e)
                                    }}
                                />
                                </Form.Item>
                            </Col>
                            
                        </Row>
                    </Form>
                    <Form
                        form={formH}
                        layout="horizontal"
                        //labelCol={{ flex: '100px' }}
                        labelAlign="right"
                        labelWrap
                        //wrapperCol={{ flex: 1 }}
                        initialValues={
                            {
                                ["contractorSelect"]: theDoc.counterPartId,
                                ["datumd"] : dayjs(theDoc.documentDate, "DD.MM.YYYY"),//theDoc.documentDate,
                                ["datump"]: theDoc.payementDate ? dayjs(theDoc.payementDate, "DD.MM.YYYY") : "",//theDoc.payementDate
                            }
                        }
                    >



                    <Row gutter={8}>
                            <Col span={22}>
                                <Form.Item label={t("Nasprotna_stranka")}>
                                        <Form.Item name="contractorSelect">
                                            <Select
                                                //value={ContId}
                                                labelInValue={false}
                                                options={ContractorOption}
                                                value={theDoc.counterPartId}
                                                //options={[ContractorOption.map((t)=>({label:t.label, value:t.value}))]}
                                                style={{width: "100%"}}
                                                onChange={(e) => ShowContractorDoc(e)}
                                            />
                                        </Form.Item>
                                </Form.Item>
                            </Col>
                        <Col>
                            
                        </Col>
                            
                        </Row>
                    <Row gutter={8}>
                            <Col span={11}>
                                <Form.Item label={t("Datum_dokumenta")} name="datumd">
                                    <DatePicker style={{width: "120px"}} format={"DD.MM.YYYY"} placeholder={"datum"}
                                                //defaultValue={dayjs(ServerdateToPoints(theDoc.documentDate), "DD.MM.YYYY")}
                                                value={dayjs(theDoc.documentDate, "DD.MM.YYYY")}        
                                        //defaultValue="01.01.2023" //{(new Date().toUTCString())}
                                        onChange={(e)=>onSelectDDate(e)}
                                    />

                                </Form.Item>
                            </Col>
                            <Col span={11}>

                                {theDoc.documentType===10 ?
                                    <Form.Item label={t("Datum_plačila")} name="datump" style={{float: "right"}}>
                                        <DatePicker style={{width: "120px"}} format={"DD.MM.YYYY"} placeholder={"datum"}
                                            //defaultValue={theDoc.payementDate}
                                                    onChange={(e)=>onSelectPDate(e)}
                                        />
                                    </Form.Item>
                                    
                                    : ""}
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col span={23}>
                                <Divider />
                            </Col>
                        </Row>
                        
                    </Form>
                    
                    <Row gutter={8} id={"PositionsList"}>
                        <div style={{maxHeight: "240px", width: "100%", overflowY: "scroll", marginLeft: "5px"}}>
                            
                        <PositionsList ReqId={theDoc.requestId ? theDoc.requestId : undefined}
                                       positions={theDoc.docPositions ? theDoc.docPositions : undefined}
                                       informParent={handleListChange} />
                        </div>
                    </Row>

                    <Row>
                        <Col span={23}>
                            <Divider/>
                            <Button className="orangeButton" onClick={SaveDocument}>{t('Shraniti')}</Button>

                            <Button onClick={printDoc} style={{ marginLeft: "30px"}}><PrinterOutlined/> {t("Tiskanje")}</Button>

                            <div style={{display: "inline-block", float: "right"}}>
                                <Form.Item  label={"Язык документа"}>
                                    <Dropdown menu={menuProps}>
                                        <Button>
                                            <Space>
                                                <img src={"/img/lang/"+langIcon+".svg"}  style={{width:"12px", height: "12px"}}/>
                                                {docLang}

                                                <DownOutlined />
                                            </Space>
                                        </Button>
                                    </Dropdown>
                                </Form.Item>
                            </div>
                            

                        </Col>
                    </Row>


                </Col>
                <Col span={10}>
                    <div className="shadow"
                         style={{backgroundColor: "#fff", padding: "20px",
                             boxShadow: "0 4px 16px 0 rgba(0, 0, 0, 0.08)",
                             border: "1px solid #e6e6e6",
                         }}
                    >
                    
                    <ShowDoc ContractorId={theDoc.contractorId? theDoc.contractorId : ""} forceReload={rerender} ratio={0.48} 
                             RequestId={theDoc.requestId ? theDoc.requestId : undefined}
                             documentDate={theDoc.documentDate? theDoc.documentDate : ""}
                             payementDate={theDoc.payementDate ? theDoc.payementDate: ""}
                             documentNumber={theDoc.docNumber ? theDoc.docNumber : ""}
                             documentType = {theDoc.documentType ? theDoc.documentType : 10}
                             lang={theDoc.docLanguage? theDoc.docLanguage : "en"}
                             
                             positions={theDoc.docPositions ? theDoc.docPositions : undefined}
                    
                    />
                    </div>
               
                </Col>


            </Row>

            {/* 
            <Drawer title={t("Ustvarite_novo_podjetje")} placement="right" onClose={()=>setopenContractor(false)} open={openContractor}>
                <CreateContractor afterCreate={setNewContractor}  isClient={true}/>
            </Drawer>
            */}

            <Drawer title={t("Ustvarite_novo_podjetje")} placement="right" onClose={()=>setopenContractor(false)} open={openContractor}>
                <EditContractorComp paramValue={contractorToEdit? contractorToEdit : ""} afterCreate={setNewContractor}/>
            </Drawer>

            
            
            
            
        </div>

    );
};

export default CreateDocPage;