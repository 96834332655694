import React, {useEffect, useState} from 'react';

import {Button, List, Skeleton, Tooltip, Space, Modal, message} from 'antd';
import { FileTextOutlined, DeleteOutlined, FileImageOutlined } from '@ant-design/icons';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import axios from "axios/index";
import authHeader from "../../../Services/auth-header";
import ShowDoc from "./ShowDoc";
import {useTranslation} from "react-i18next";
import {ServerdateToPoints} from "../../../utils/helpers";



interface DataType {
    id: string;
    fileName: string;
    docNumber : string;
    request: any;
    vrequest:string;
    truck: any;
    vtruck : string;
    driver: any;
    vdriver : string;
    routePoint : any;
    requestReply : any;
    documentOrigin : number;
    documentType : number;
    loading : boolean;
    documentDate? : string | null,



}



const takeFileName =(text : string) =>{
    if(text!=null) {
        const splitted = text.split('_');
        return splitted[3];
    }
    return ""
}

/*
const mapObjectTitle = (record : any)=>{

    if(record.truck){
        return (record.truck.brand+' '+ record.truck.model+' '+ record.truck.regNumber)
    }
    if(record.driver){
        return (record.driver.name+' '+ record.driver.surname)
    }

    return ("");
}

 */

const mapEnumToString=(i : number)=>{
    switch ( i ) {
        case 0:
            return "Drugačen dokument"
        case 10:
            return "Račun"
        case 11:
            return "CMR"
        case 20:
            return "Truck foto"
        case 21:
            return "Truck license"
        case 22:
            return "Truck insuarence"
        case 30:
            return "Driver foto"
        case 31:
            return "Driver license"
        case 32:
            return "Driver insuarence"
        default:
            return "Drugačen dokument"
    }
}











interface DocListProps{
    ContractorId?: string;
    DriverId? : string;
    RequestId?: string;
    TruckId? : string;
    DocType? : number;
    ExpiryDate? : string;
    reload? : boolean;
    hideFileNames? : boolean;
    setHeight? : number|null;
    shortNames? : boolean | null;

}

const DocList = ({ ContractorId, DriverId, RequestId, TruckId, DocType, ExpiryDate, reload, hideFileNames, setHeight, shortNames} : DocListProps) => {


    const {t} = useTranslation();


    const [isModalOpen, setisModalOpen] = useState(false);
    const  showPreview=(id : string, origin: number)=>{

        set_documentId(id);
        console.log("origin: ", origin)
        if(origin===1) {
            fetch(AuthService.DATA_URL + `Docs/get-doc?id=` + id, {headers: authHeader()})
                .then((response) => response.blob())
                .then((blob) => {
                    console.log(blob);
                    const imageUrl = URL.createObjectURL(blob);
                    const imageElement = document.createElement("img");
                    imageElement.src = imageUrl;
                    const container = document.getElementById("imgContainer");
                    // @ts-ignore
                    container.innerHTML = '';
                    // @ts-ignore
                    container.appendChild(imageElement);
                });
            setisModalOpen(true)
        }
        if(origin===0){
            axios.get(AuthService.DATA_URL+'docs/get-doc-dto?id='+id, {headers: authHeader()})
                .then((response)=>{
                    
                    set_documentType(response.data.documentType);
                    set_requestId(response.data.requestId);
                    setisPreviewOpen(true);
                    setrerender(!rerender);

                })
        }
    }

    const handleModalCancel=()=>{
        setisModalOpen(false)
    }

    const [APIdata, setAPIData] = useState<DataType[]>([]);
    
    const[anyRecs, setAnyRecs] = useState(-1);
    
    const [isLoading, setisLoading] = useState(true);
    const [initLoading, setInitLoading] = useState(true);


    

    useEffect(() => {
        if(anyRecs<0){
            setAnyRecs(0);
            axios.get(AuthService.DATA_URL+`Docs/get-docsfor?`+
                "ContractorId="+(ContractorId ? ContractorId : "")+
                "&DriverId="+(DriverId ? DriverId : "")+
                "&RequestId="+(RequestId ? RequestId : "")+
                "&TruckId="+(TruckId ? TruckId : "")+
                "&DocType="+(DocType? DocType.toString() : "")+
                "&ExpiryDate="+(ExpiryDate ? ExpiryDate : "")
                ,
                {headers: authHeader()})
                .then((response) => {
                    //console.log("doclist response.data: ", response.data)

                    //console.log("get-doc-dto response.data: ",response.data);
                    setAPIData(response.data);
                    setisLoading(false);

                    setInitLoading(false);

                })
                .catch((r)=>{
                    console.log(r);
                    console.log(r.response);
                    if(r.response.status===401){
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }

                })
        }

        
    }, /*[reload]*/);







    const history= useHistory();
    const user = AuthService.getCurrentUser();
    //console.log("user: ", user)
    if(!user){
        history.push("/login");
    }
    
    const deleteDoc=(id : string)=>{
        axios.delete(AuthService.DATA_URL+`Docs/`+id,
            {headers: authHeader()})
            .then((response) => {
                const indexOfObject = APIdata.findIndex((object) => {
                    return object.id === id;
                });
                if (indexOfObject > -1) {
                    APIdata.splice(indexOfObject, 1);
                }
                console.log("response.data: ", response.data);
                setAPIData(APIdata);

                axios.get(AuthService.DATA_URL+`Docs/get-docsfor?`+
                    "ContractorId="+(ContractorId ? ContractorId : "")+
                    "&DriverId="+(DriverId ? DriverId : "")+
                    "&RequestId="+(RequestId ? RequestId : "")+
                    "&TruckId="+(TruckId ? TruckId : "")+
                    "&DocType="+(DocType? DocType.toString() : "")+
                    "&ExpiryDate="+(ExpiryDate ? ExpiryDate : "")
                    ,
                    {headers: authHeader()})
                    .then((response) => {
                        console.log("doclist response.data: ", response.data)

                        setAPIData(response.data);
                        setisLoading(false);

                        setInitLoading(false);

                    })
                    .catch((r)=>{
                        console.log(r);
                        console.log(r.response);
                    })

            })
            .catch((r)=>{
                console.log(r);
                console.log(r.response);
            })
    
    }



    const [isPreviewOpen, setisPreviewOpen] = useState(false);

    const[_requestId, set_requestId]=useState();
    const[_documentType, set_documentType]=useState();
    const[_documentId, set_documentId]=useState("");

    const [rerender, setrerender] = useState(true);




    return (
        <div style={{height: setHeight?  setHeight.toString()  :  "300" ,  overflow: "scroll"}}>

            <List
                itemLayout="horizontal"
                loading={initLoading}
                locale={{ emptyText: <span> </span> }}
                ///scroll={setHeight? { y: setHeight } : { y: 'calc(65vh - 4em)' }}

                dataSource={APIdata}
                renderItem={(item) => (
                    <List.Item key={item.id} 
                               style={{
                                   //border: "1px solid #BBBCC0", 
                                   border: "none",
                                   borderRadius: "5px", 
                                   padding: "5px 0px"}}
                               
                               
                       // actions={[<a key="list-loadmore-edit"><DeleteOutlined /></a>, <a key="list-loadmore-more">more</a>]}
                    >
                        <Skeleton avatar title={false} loading={item.loading} active>
                            <List.Item.Meta
                                description={
                                <div>
                                    <Space.Compact block style={{ width: "100%", backgroundColor:"#fff"}}>
                                        <div onClick={()=>showPreview(item.id, item.documentOrigin)} 
                                            style={{
                                                cursor: "pointer",
                                            border: "1px solid #d9d9d9",
                                            borderTopLeftRadius: "5px",
                                            borderBottomLeftRadius: "5px",
                                            borderRight: "0px",
                                            height: "40px",
                                            width: "100%",
                                            fontSize: "14px",
                                            padding: "8px 5px"

                                        }}>
                                            {item.documentOrigin===0 ?
                                                <span style={{color: "#000"}}>
                                                <FileTextOutlined style={{color: "#1449AE", fontSize: "16px", padding: "0px 5px"}}/>
                                                    {t(mapEnumToString(item.documentType))+" "+item.docNumber}
                                                    {shortNames? ""
                                                        :
                                                        <span style={{color: "#999"}}> 
                                                            {
                                                               " "+ ServerdateToPoints(item.documentDate)+" "+(item.request!==null?
                                                                    item.request.createdByContractorId===user.contractorId?
                                                                        item.request.requestNumber
                                                                        : item.request.externalRequestNumber
                                                                    : "")
                                                            }
                                                        </span>                                                    }
                                                </span>
                                                :
                                                <span>
                                                <FileImageOutlined style={{color: "#1449AE", fontSize: "16px", padding: "0px 5px"}} />
                                                    {
                                                        hideFileNames?
                                                            (takeFileName(item.fileName)).substring(0,3)+".."+(takeFileName(item.fileName)).substring(((takeFileName(item.fileName)).length-4),((takeFileName(item.fileName)).length))
                                                            : takeFileName(item.fileName)
                                                    }
                                                    <span style={{float: "right", color: "BBBCC0"}}>{t("Vrsta")}:
                                                        <span style={{color: "#000", marginLeft: "4px"}}>
                                                        {t(mapEnumToString(item.documentType))}
                                                        </span>
                                                    </span>
                                                </span>
                                            }
                                        </div>

                                        <Tooltip title="Brisanje dokumenta">
                                            <Button icon={<DeleteOutlined />}  onClick={()=>deleteDoc(item.id)}  style={{height : "40px"}} />
                                        </Tooltip>
                                    </Space.Compact>
                                </div>
                                


                                }
                            />
                        </Skeleton>
                    </List.Item>
                )}
            />


            <Modal open={isModalOpen}
                //onOk={handleModalOk}
                   title={t("Predogled")}
                   centered
                   footer={(_, { CancelBtn }) => (
                       <>
                           <CancelBtn />
                       </>
                   )}
                   onCancel={handleModalCancel}
            > <div id={"imgContainer"}>
                <img alt={""} id={"photo"} src={""} />
            </div>
            </Modal>

            <Modal open={isPreviewOpen}

                   title={t("Predogled")}
                   centered
                   footer={(_, { CancelBtn }) => (
                       <>
                           <CancelBtn />
                       </>
                   )}
                   onCancel={()=>setisPreviewOpen(false)}>
                <ShowDoc  ratio={0.48}
                          showmode="preview"
                          docId={_documentId}
                          RequestId={_requestId ? _requestId : undefined}
                          forceReload={rerender}
                          documentType={_documentType ? _documentType : undefined} />

            </Modal>



        </div>
    );
};
export default DocList;