import React, {useEffect, useState} from 'react';
import {Table} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
import * as AuthService from "../../../Services/auth-service";
import authHeader from "../../../Services/auth-header";
import CompanySettingsPage from "../../settings/Components/CompanySettings";
import {useTranslation} from "react-i18next";

//import * as XLSX from 'xlsx/xlsx.mjs';


interface RouteListProps{
    ContractorId?: string;
    DriverId? : string;
    RequestId?: string;
    TruckId? : string;
    ExpiryDate? : string;
    reload? : boolean;
    setHeight? : number|null;

}
const RouteList= ({ ContractorId, DriverId, RequestId, TruckId,  ExpiryDate, reload, setHeight} : RouteListProps) => {

    const {t} = useTranslation();

    const history= useHistory();
    const user = AuthService.getCurrentUser();
    if(!user){
        AuthService.logout();
        history.push("/login");
        window.location.reload();

    }

    //const { TabPane } = Tabs;
    interface DataType {
        key: string;
        id : string;
        routeNumber : string;
        from : string;
        to : string;
        fromCountry : string;
        toCountry : string;
        fromto : string;
        fromdate: string;
        fromtime : string;
        todate: string;
        totime : string;
        price: number;
        client: string;
        receiver: string;
        payer : string;
        requestStatus : number;
        traktor : string;
        priklopnik : string;
        driver : string;
    }


    type RequestDTO={
        key: React.Key;
        id: string;
        requestNumber: string;
        price : number;
        distance : number;
        loadingType: number;
        rollingStockType: number;
        requestStatus: number;
        cargoDescription : string;
        weight : number,
        volume: number,
        clientName : string,
        receiverName: string;
        payerName : string;
        track : any;
        priklopnik: any;
        client:any;
        receiver :any;
        payer : any;
        driver : any;
        routePoints: any[];
    }



    const columns: ColumnsType<DataType> = [
        {
            title: t("Številka"),
            key: 1,
            dataIndex: 'routeNumber',
            width: 100,
            render: (text, record) => {
                //var statusspan="<span color: green>&bull;</span>";
                if(record.requestStatus === 0) {
                    return (
                        <div>
                            {text}
                            <br/>
                            <span className={"dot"}  style={{backgroundColor: "#E3601D", verticalAlign: "middle"}}> </span><span className={"greyLabel"}>{t("Novo")}</span>
                        </div>
                    )
                }
                if(record.requestStatus === 1) {
                    return (
                        <div>
                            {text}
                            <br/>
                            <span className={"dot"}  style={{backgroundColor: "#1FAA73", verticalAlign: "middle"}}> </span><span className={"greyLabel"}>{t("Načrtovano")}</span>
                        </div>
                    )
                }
                if(record.requestStatus === 20) {
                    return (
                        <div>
                            {text}
                            <br/>
                            <span className={"dot"} style={{backgroundColor: "#faad14", verticalAlign: "middle"}}> </span><span  className={"greyLabel"}>{t('Načrtovana')}</span>
                        </div>
                    )
                }
                if(record.requestStatus === 30) {
                    return (
                        <div>
                            {text}
                            <br/>
                            <span className={"dot"} style={{backgroundColor: "#1FAA73", verticalAlign: "middle"}}> </span><span  className={"greyLabel"}>{t('Confirmed')}</span>
                        </div>
                    )
                }
                if(record.requestStatus === 40) {
                    return (
                        <div>
                            {text}
                            <br/>
                            <span className={"dot"} style={{backgroundColor: "#1890FF", verticalAlign: "middle"}}> </span><span  className={"greyLabel"}>{t('On_route')}</span>
                        </div>
                    )
                }
                if(record.requestStatus === 50) {
                    return (
                        <div>
                            {text}
                            <br/>
                            <span className={"dot"}  style={{backgroundColor: "#A4A6AC", verticalAlign: "middle"}}> </span><span  className={"greyLabel"}>{t('Zaključena')}</span>
                        </div>
                    )
                }

                return (
                    <div>
                        {text}
                        <br/>
                    </div>
                )


            }},
        {
            title: t('Itinerarij'),
            key: 2,
            dataIndex: 'fromto',
            width: 350,
            ellipsis: true,
            render: (text, record) =>
                <div className="RoutePoints">
                    <div className="point start" title="">
                        <div className={"timeStart"}> 
                            <span>{record.fromdate}<br/>
                                <span className={"routeTime"}>{record.fromtime}</span>
                            </span>
                        </div>
                        <span className="name_sCDti">{record.from} <br/> 
                            <span className={"greyLabel"}>{record.fromCountry}</span> </span>
                        <div className="truncate description_liDDR"></div>
                    </div>
                    <div className="between"> </div>
                    <div className="point end"
                         title="">
                        <span className="name_eCDti">{record.to} <br/> 
                            <span className={"greyLabel"}>{record.toCountry}</span></span>
                        <div className={"timeEnd"}>
                            <span>{record.todate}<br/>
                                <span className={"routeTime"}>{record.totime}</span>
                            </span>
                        </div>
                        <div className="truncate description_liDDR"></div>
                    </div>

                </div>
        },
        {
            title: t('Izvajalec'),
            key: 3,
            dataIndex: "traktor",
            width: 165,
            render: (text, record) => {
                if(record.traktor.length===0){
                    return(
                        <div>
                            <a className={"orangeA"} href={"routes/view/:?id="+record.id }>{t("Izberite")}</a>

                        </div>
                    )
                }
                if(record.priklopnik.length===0){
                    return(
                        <div>
                            <div className={"tovornjak_div"}>
                                <img src={"/img/tovornjak.png"} alt={" "} />
                                {record.traktor}
                            </div>
                            <div style={{fontSize: "12px"}}>{record.driver}</div>
                        </div>
                    )
                }
                else{
                    return(
                        <div>
                            <div className={"traktor_div"}>
                                <img src={"/img/traktor.png"} alt={" "} />
                                {record.traktor}
                            </div>
                            <div  className={"priklopnik_div"}>
                                <img src={"/img/priklopnik.png"} alt={" "} />
                                <div style={{clear: "right"}}>{record.priklopnik}</div>
                            </div>
                            <div style={{fontSize: "12px"}}>{record.driver}</div>

                        </div>

                    )
                }

            }

        },
        {
            title: t('Cena'),
            key: 4,
            dataIndex: 'price',
            width: 75,
            align: 'right',
            render:(text, record) => <div>{(text? text: "").toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")} €<br/><span className={"routeTime"}> {record.client.substring(0,30)}</span>

            </div>
        },
        {
            title: ' ',
            key: 'operation',
            fixed: 'right',
            width: 50,
            render: (text, record) =>
                <Link className={"actionLink"} to={`/routes/view/${record.id}`}>{t("Več")} &rarr;</Link>

       // <a className={"actionLink"} href={"routes/view/:?id="+record.id }>{t("Več")} &rarr;</a>,
        },
    ];


    const [APIData, setAPIData] = useState<RequestDTO[]>([]);
    const [isLoading, setisLoading] = useState(true);


    useEffect(() => {
        axios.get(AuthService.DATA_URL+`Request/get-dtoforobject?`+
            "ContractorId="+(ContractorId ? ContractorId : "")+
            "&DriverId="+(DriverId ? DriverId : "")+
            "&TruckId="+(TruckId ? TruckId : ""),
            {headers: authHeader()})
            .then((response) => {
                //console.log("response.data: ", response.data)
                setAPIData(response.data);
                setisLoading(false);
            })
            .catch((r)=>{
                console.log(r);
                console.log(r.response);
                if(r.response.status ===401){
                    AuthService.logout();
                    history.push("/login");
                    window.location.reload();
                }
                // history.push()
            })

    }, []);



    ///console.log("APIData: ", APIData)
    const TableData = APIData.map((tr, index) => ({
        key: tr.id,
        id: tr.id,
        routeNumber:tr.requestNumber, //"TN-12.2023",
        from : tr.routePoints[0] ? tr.routePoints[0].streetName :"", //"Франкфурт-на-Майне",
        to : tr.routePoints[1] ? tr.routePoints[1].streetName : "",//"Крань",
        fromCountry: tr.routePoints[0] ? tr.routePoints[0].cityName+", "+tr.routePoints[0].countryName : "",//"Германия",
        toCountry :  tr.routePoints[1] ? tr.routePoints[1].cityName+", "+tr.routePoints[1].countryName :"",//"Словения",
        fromto : "genm",
        //fromdate: tr.routePoints[0].dateStart,// "01.10.2023 15:30",
        fromdate: tr.routePoints[0] ? tr.routePoints[0].dateStart ? tr.routePoints[0].dateStart.substring(8,10)+"."+tr.routePoints[0].dateStart.substring(5,7)+"."+tr.routePoints[0].dateStart.substring(0,4) : "" : "",
        fromtime:tr.routePoints[0] ? tr.routePoints[0].dateStart ? tr.routePoints[0].dateStart.substring(11,16) : "" : "",
        todate:tr.routePoints[1] ? tr.routePoints[1].dateStart ? tr.routePoints[1].dateStart.substring(8,10)+"."+tr.routePoints[1].dateStart.substring(5,7)+"."+tr.routePoints[1].dateStart.substring(0,4) : "" : "",
        totime:tr.routePoints[1] ? tr.routePoints[1].dateStart ? tr.routePoints[1].dateStart.substring(11,16) : "" : "",
        price: tr.price,//parseFloat(tr.price),//parseFloat(tr.price),//"1 200",
        client: tr.client?  tr.client.title : "-",
        requestStatus : tr.requestStatus, //1,
        traktor : tr.track ? tr.track.regNumber : "",
        priklopnik: tr.priklopnik ? tr.priklopnik.regNumber : "",//tr.priklopnik.RegNumber,//"LJ NB-064",
        driver: tr.driver ? tr.driver.name+" "+tr.driver.surname : "",//tr.driver.name
        receiver: tr.receiver ? tr.receiver.title : "-",
        payer : tr.payer ? tr.payer.title : "-",

    }) )



    return (
        <div>
                <Table
                    locale={{
                        triggerDesc: 'Kliknite za razvrščanje padajoče',
                        triggerAsc: 'Kliknite za razvrščanje naraščajoče',
                        cancelSort: 'Prekliči razvrščanje'
                    }}
                    columns={columns}
                    dataSource={TableData}
                    loading={isLoading}
                    pagination={false}
                    scroll={setHeight? { y: setHeight } : { y: 'calc(65vh - 4em)' }}
//                    pagination={{ pageSize: 6 }} size="small"  scroll={{ y: 'calc(60vh - 4em)' }}
                />
            </div>

    );
};
export default RouteList;