import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import "./Services/multiLang";


import {BarChartOutlined, ShopOutlined, GoldOutlined, TeamOutlined,SafetyCertificateOutlined, EuroOutlined, HomeOutlined, FileTextOutlined, IdcardOutlined, SettingOutlined, LogoutOutlined} from '@ant-design/icons';
import {Button, ConfigProvider, Layout, Menu, message, theme} from 'antd';
import ApiHeader from "./Components/AppHeader"
import './App.css';

import {Routing} from "./Pages";


import * as AuthService from "./Services/auth-service";
//import {joinChatRoom, NOTIFICATION_URL} from "./Services/auth-service";
import Login from "./Pages/Login";
import RacunPage from "./Pages/Racun";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import RegisterPage from "./Pages/Register";
import CheckOutPage from "./Pages/Checkout";
import SauftragPage2 from "./Pages/docs/DocComponents/Sauftrag2";
import DocList from "./Pages/docs/DocComponents/docList";
import {HubConnection, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import {NOTIFICATION_URL} from "./Services/auth-service";
import authHeader from "./Services/auth-header";
import SuggestedContractor from "./Pages/myroutes/RouteComponents/SuggestedContractor";
import {NotificationContext, subscribe, unsubscribe} from './Components/Notifications/NotificationContext';
import LP from "./Pages/LP/LP";
import {useLocation} from "react-router";


const {  Sider, Content } = Layout;


const App = () => {
    
    const {t} = useTranslation();


    const locationString = window.location;

    const user = AuthService.getCurrentUser();

    const [current, setCurrent] = useState(locationString.pathname);

    function handleClick(e: any) {
        setCurrent(e.key);
    }


    const [reloadSuggested, setreloadSuggested] = useState('');
    const value = useMemo(
        () => ({ reloadSuggested, setreloadSuggested }),
        [reloadSuggested]
    );
    


    function ContentDiv() {
        return <div>
            <Routing/>
   </div>;
    }


    const [show, setShow] = useState(false);
    
    const history= useHistory();
    

    const logOut = () => {
        AuthService.logout();
        history.push("/login");
        window.location.reload();
    };

    const [isFreeUser, setisFreeUser] = useState<boolean>(user ? new Date(user.paidTill)< new Date() ? true:  false: false)
    const [initialMenu, setinitialMenu] = useState(user ? new Date(user.paidTill)< new Date() ? "/docs":  "/": "/docs")

    const [disabledMenu, setdisabledMenu]=useState<boolean|null>(null)

    useEffect(()=>{
        subscribe("subscriptionPaid", ()=>{refresh()})
        if(user){
            //console.log("App tsx: ", user)
            if(disabledMenu===null){
                if(isFreeUser){
                    setdisabledMenu(true)
                }
                else{
                    setdisabledMenu(false)
                }

            }
        }
        return () => {
            unsubscribe("subscriptionPaid", () => refresh());
        }
        
    });
    const refresh=()=>{
        setdisabledMenu(false)
    }



    /*
    if (!user) {
        if (window.location.toString().indexOf("register") > 0) {
            //console.log("Redirect to Register")
            history.push("/register")
            //return (<RegisterPage/>);
        } else {
            if(window.location.toString().indexOf("checkout")>0){
                //history.push("/checkout");
                //return(<CheckOutPage/>);
            }
            else{
                if(window.location.toString().indexOf("login")>0){
                    history.push("/login");
                    //window.location.reload();
                }
                else{
                    history.push("/");
                }
            }
        }
    }
    
     */



        //console.log("useruseruser", user.language);
        if(user) {
            if (user.language) {
                if (i18n.language !== user.language) {
                    i18n.changeLanguage(user.language);
                }
            }

        }

    

    const queryString = window.location.search;
    if(!user){
        if(window.location.toString().indexOf("register")>0){
            //console.log("Redirect to Register")
            //history.push("/register")
            return(<RegisterPage/>);
        }else{
            if(window.location.toString().indexOf("checkout")>0){
                return(<CheckOutPage/>);
            }
            else{
                if(window.location.toString().indexOf("login")>0){
                    return(<Login/>);
                }
                else {
                    return (<LP/>)
                }
            }
        }
        
    }


    const urlParams = new URLSearchParams(queryString);
    const paramValue = urlParams.get('racun');
    const regValue = urlParams.get('register');

    const requestValue = urlParams.get('request');

    const saufValue = urlParams.get('sauf');


    if(paramValue){
        return(<RacunPage/>);
    }
    if(regValue){
        return(<RegisterPage/>);
    }

    if(requestValue){
        return(<DocList RequestId={requestValue}/>);
    }

    if(saufValue){
        return(<SauftragPage2 RequestId={saufValue}/>);
    }


   
    
    
    
    
    

    return (

           
                    <div className="App">
                        <ConfigProvider
                            theme={{
                                token: {
                                    // Seed Token
                                    colorPrimary: '#1c7884',
                                    colorLink: '#1c7884',
                                    
                                    //borderRadius: 2,

                                    // Alias Token
                                    //colorBgContainer: '#f6ffed',
                                },
                            }}>
                            
                       <NotificationContext.Provider value={{reloadSuggested: ""}}>
                           
                      
                        <Layout style={{height: "100vh"}}>
                            {/*
                            */}
                            
                            <Sider trigger={null}  width={250}>
                               
                                <Menu
                                    theme="light"
                                    mode="vertical"
                                    //defaultSelectedKeys={ [initialMenu]}
                                    selectedKeys={[current]}
                                    onClick={(key) => {
                                        handleClick(key);
                                        history.push(key.key);
                                        //console.log(key);
                                    }}
                                    items={
                   
                                    
                                    
                                    
                                    [
                                        {
                                            key: '0',
                                            icon: <img src={"/img/logo_white.png"} alt={" "} style={{
                                                height: '25px', margin: '10px 40px 0px'}}/>,
                                            //icon: <UserOutlined />,
                                            //label: label,
                                            disabled: true,
                                        },
                                        {
                                            key: '1',
                                            type: 'divider',
                                        },

                                        {
                                            key: '/',
                                            icon: <HomeOutlined  style={{fontSize: '18px'}} />, //<img src="/img/home_icon.png" alt={" "}
                                                       //style={{float: "left", height: '20px', margin: '10px 0px'}}/>,
                                            label: t("Domov"),
                                           // disabled: disabledMenu===null ? false : disabledMenu,
                                        },
                                        {
                                            key: '/deals',
                                            icon: <SafetyCertificateOutlined  style={{fontSize: '18px'}} />,//<img src="/img/routes_icon.png" alt={" "}
                                                  //     style={{float: "left", height: '16px', margin: '10px 0px'}}/>,
                                            label: t('Narocila'),
                                            //disabled: disabledMenu===null ? false : disabledMenu,
                                   
                                        },
                                        /*
                                        {
                                            key: '/trucks',
                                            icon: <img src="/img/truck_icon.png" alt={" "}
                                                       style={{float: "left", height: '20px', margin: '10px 0px'}}/>,
                                            label: t('Vozila'),
                                        },
                                        
                                         */
                                        {
                                            key: '/docs',
                                            icon: <FileTextOutlined  style={{fontSize: '18px'}}/>, //<img src="/img/docs_icon.png" alt={" "}
                                                       //style={{float: "left", height: '20px', margin: '10px 0px'}}/>,
                                            label: t('Dokumenti'),
                                        },
                                        {
                                            key: '/products',
                                            icon:  <GoldOutlined style={{fontSize: '18px'}}/>,//<img src="/img/steering.png" alt={" "}
                                            //style={{float: "left", height: '18px', margin: '10px 1px'}}/>, //<TeamOutlined style={{fontSize: '18px'}}/>,
                                            label: t('Products'),
                                           // disabled: disabledMenu===null ? false : disabledMenu,

                                        },

                                        /*

                                        {
                                            key: '/transactions',
                                            icon: <EuroOutlined  style={{fontSize: '18px'}}/>,// <img src="/img/transactions_icon.png" alt={" "}
                                                       //style={{float: "left", height: '20px', margin: '10px 0px'}}/>,
                                            label: t('Finances'),
                                        },
                                        
                                         */
                                        {
                                            key: '/contractors',
                                            icon: <IdcardOutlined  style={{fontSize: '18px'}}/>,//<TeamOutlined style={{fontSize: '18px'}}/>,
                                            label: t('Stranke'),
                                            //disabled: disabledMenu===null ? false : disabledMenu,
                                    
                                        },

                                        {
                                            key: '/drivers',
                                            icon:  <TeamOutlined style={{fontSize: '18px'}}/>,//<img src="/img/steering.png" alt={" "}
                                                       //style={{float: "left", height: '18px', margin: '10px 1px'}}/>, //<TeamOutlined style={{fontSize: '18px'}}/>,
                                            label: t('Employees'),
                                           // disabled: disabledMenu===null ? false : disabledMenu,
                                   
                                        },
                                        /*
                                        {
                                            key: '/cards',
                                            icon: <img src="/img/cards_icon.png" alt={" "}
                                                       style={{float: "left", height: '20px', margin: '10px 0px'}}/>,
                                            label: 'Карты',
                                        },
                                        
                                         */
                                        
                                        {
                                            key: '/settings',
                                            icon: <SettingOutlined  style={{fontSize: '18px'}} />,// <img src="/img/settings_icon.png" alt={" "}
                                                      // style={{float: "left", height: '20px', margin: '10px 0px'}}/>,
                                            label: t('Nastavitve'),
                                        },
                                        {
                                            key: '/logout',
                                            onClick :(logOut),
                                            icon: <LogoutOutlined  style={{fontSize: '18px'}}/>, //<img src="/img/logout_icon.png" alt={" "}
                                                      // style={{float: "left", height: '15px', margin: '10px 0px 10px 6px'}}/>,
                                            label: t('Odjava'),
                                            style : {
                                            position: 'absolute',
                                            bottom: 20,
                                            zIndex: 1,
                                            transition: 'all 0.2s',
                                        }
                                        },

                                    ]}
                                />

                            </Sider>
                            {/* 
                            <Button id={'colbtn'}
                                    type="text"
                                    icon={<img src={show ? imgsrc2 : imgsrc} alt={" "}
                                               style={{float: "left", height: '29px'}}/>}
                                    onClick={() => {
                                        setCollapsed(!collapsed);
                                        setShow(!show)
                                    }}
                                    style={{
                                        top: '35px',
                                        left: '-18px',
                                    }}
                            />
                            */}
                            
                            <Layout>
                               
                                <ApiHeader/>

                                <Content
                                    style={{
                                       // margin: '0px 8px 6px 0px',
                                        minHeight: 280,
                                        //background: colorBgContainer,
                                    }}

                                >
                                    <ContentDiv/>
                                </Content>
                      
                             
                            </Layout>
                        </Layout>

                       </NotificationContext.Provider>
                    </ConfigProvider>
                    </div>
                )
}



export default App;
