import axios from "axios";
import * as AuthService from "../Services/auth-service";
import authHeader from "../Services/auth-header";


interface RouteListProps{
    ContractorId?: string;
    DriverId? : string;
    RequestId?: string;
    TruckId? : string;
    ExpiryDate? : string;
    reload? : boolean;

}

export const dtoforobject = ({ ContractorId, DriverId, RequestId, TruckId,  ExpiryDate, reload} : RouteListProps) => {
    const resultPromise : any  = new Promise((resolve, reject) => {resolve(fetch(AuthService.DATA_URL+`Request/get-dtoforobject?`+
        "ContractorId="+(ContractorId ? ContractorId : "")+
        "&DriverId="+(DriverId ? DriverId : "")+
        "&TruckId="+(TruckId ? TruckId : ""),
        {headers: authHeader()})
        .then(response => response.json())
        .then((response) => {
            return(response.data);
        }))});
    return resultPromise;
}


export const routeList = async () => {
    const  data  = await axios.get(AuthService.DATA_URL+`Request/get-dto`, {headers: authHeader()})
        .then((response) => {
            //console.log("Request Service response.data: ", response.data)
            return(response.data);
        })
    //console.log("data: ", data );
    return data;
};

export const singleRoute = async (RequestId : string) => {

    const  data  = await axios.get(AuthService.DATA_URL+`Request/`+RequestId, {headers: authHeader()})
        .then((response) => {
            //console.log("Request Service response.data: ", response.data)
            return(response.data);
        })
    //console.log("data: ", data );
    return data;
};
