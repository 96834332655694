import React from 'react';
import { useLocation } from 'react-router';
import {Breadcrumb} from "antd";
import {useTranslation} from "react-i18next";
import { Link } from 'react-router-dom';




const BreadCrumbsComp = () => {
    const {t} = useTranslation();

    const location = useLocation();
    const {pathname} = location;
    const pathnames= pathname.split("/").filter((item)=> item);



    function itemRender(currentRoute:any, params:any, items:any, paths:any) {
        const isLast = currentRoute?.path === items[items.length - 1]?.path;

        return isLast ? (
            <span>{currentRoute.title}</span>
        ) : (
            <Link to={`${paths.join("/")}`}>{currentRoute.title}</Link>
        );
    }
    
    
    
    

    type citem = {
        key: string;
        title: string;
        href?: string;
    }
    
    type mroute ={
        path: string;
        breadcrumbName : string;
    }
//    var citems: Array<citem> = Array({ "title": "Главная" });
    var citems: any[] = [];
    
    var croutes: mroute[]=[];
    citems.push( {"title": t("Domov"),  "href": "/", key: "pn"} )
    croutes.push({path: "/", breadcrumbName: t("Domov")});
    pathnames.forEach((pn, index)=>{

        var npn={"title": "Автопарк", "href": "/", key: pn};
       
       
        if(pn==="trucks")
        { npn={"title": t("Vozila"), "href": "/trucks/", key: pn}; 
            //console.log("pathnames: ", pathnames)
            //console.log("pathnames2222: ", npn, index)
            citems.push( npn )
            croutes.push({path: "trucks/", breadcrumbName: t("Vozila")});
            if(pathnames[index+1]){
                croutes.push({path: "trucks/"+pathnames[index+1], breadcrumbName: t("Podatki_o_vozilu")});
            }


        }
        
        
        if(pn==="drivers"){ 
            npn={"title": t("Employees"), "href": "drivers/", key: pn}; citems.push( npn )
            croutes.push({path: "drivers/", breadcrumbName: t("Employees")});
            if(pathnames[index+1]){
                croutes.push({path: ""+pathnames[index+1], breadcrumbName: t("Urejanje_voznika")});
            }
            //Urejanje_voznika
            

        }
        if(pn==="editdriver"){  
            citems.push( {"title": t("Urejanje_voznika"), key: pn} )
            croutes.push({path: "", breadcrumbName: t("Urejanje_voznika")});

        }
        if(pn==="newdriver"){
            citems.push( {"title": t("Employees"), "href": "drivers/", key: pn} )
            citems.push( {"title": t("Urejanje_voznika"), key: pn} )
            croutes.push({path: "drivers/", breadcrumbName: t("Employees")});
            croutes.push({path: "", breadcrumbName: t("Urejanje_voznika")});

        }

        if(pn==="newtruck"){
            croutes.push({path: "trucks/", breadcrumbName: t("Vozila")});
            //croutes.push({path: "", breadcrumbName: t("Urejanje_voznika")});

        }

        
        
        
        
        if(pn==="routes"){ 
            npn={"title": t("Naročila_in_poti"), "href": "/routes", key: pn}; 
            citems.push( npn )
            croutes.push({path: "routes/", breadcrumbName: t("Naročila_in_poti")});
        }
        
        if(pn==="newroute") {
            npn={"title": t("Naročila_in_poti"), "href": "/routes", key: pn}; citems.push( npn )
            croutes.push({path: "routes/", breadcrumbName: t("Naročila_in_poti")});

            citems.push({"title": t("Dodajanje_naročila"), key: pn+"_"});
            croutes.push({path: "", breadcrumbName: t("Dodajanje_naročila")});
        }
        if(pn==="view") {
            citems.push({"title": t("Načrtovanje_poti"), key: pn});
            croutes.push({path: "", breadcrumbName: t("Načrtovanje_poti")});
        }
        if(pn==="edit") {
            citems.push({"title": t("Urejanje_naročila"), key: pn});
            croutes.push({path: "", breadcrumbName: t("Urejanje_naročila")});

        }
        if(pn==="docs") {
            citems.push({"title": t( "Dokumenti"), "href": "/docs", key: pn})
            croutes.push({path: "/docs", breadcrumbName: t("Dokumenti")});
            if(pathnames[index+1]){
                croutes.push({path: ""+pathnames[index+1], breadcrumbName: t("Podatki_dokumenta")});
            }
        }
        
        if(pn==="settings"){
            croutes.push({path: "", breadcrumbName: t("Nastavitve")});
        }

        if(pn==="showdoc") {
            npn={"title": t("Dokumenti"), "href": "/docs", key: pn}; citems.push( npn )
            citems.push({"title": "Podatki_dokumenta", key: pn});
            croutes.push({path: "/docs", breadcrumbName: t("Dokumenti")});
            croutes.push({path: "", breadcrumbName: t("Podatki_dokumenta")});
        }
        
        
        
        
        if(pn==="contractors"){ 
            npn={"title": t("Stranke"), "href": "/contractors", key: pn}; citems.push( npn )

            croutes.push({path: "/contractors", breadcrumbName: t("Stranke")});
        }
        if(pn==="new")
        {
            citems.push( {"title": t("Nov_partner"), key: pn} );
            croutes.push({path: "", breadcrumbName: t("Nov_partner")});

        }
        if(pn==="editcontractor")
        {
            citems.push( {"title": t("Urejanje_podatkov_o_partnerju"), key: pn} );
            croutes.push({path: "", breadcrumbName: t("Urejanje_podatkov_o_partnerju")});
        }


        if(pn==="transactions"){
            croutes.push({path: "/", breadcrumbName: t("Finances")});
        }
        if(pn==="dds"){
            croutes.push({path: "", breadcrumbName: t("DDS_Rep")});
        }

        if(pn==="deals"){
            npn={"title": t("Narocila"), "href": "deals/", key: pn}; citems.push( npn )
            croutes.push({path: "deals", breadcrumbName: t("Narocila")});
            if(pathnames[index+1]){
                croutes.push({path: ""+pathnames[index+1], breadcrumbName: t("Urejanje_naročila")});
            }
        }

        if(pn==="products"){
            npn={"title": t("Products"), "href": "products/", key: pn}; citems.push( npn )
            croutes.push({path: "products", breadcrumbName: t("Products")});
            if(pathnames[index+1]){
                croutes.push({path: ""+pathnames[index+1], breadcrumbName: t("Urejanje_naročila")});
            }
        }

        
        
        
        
        
        
        //if(pn==="cards"){ npn={"title": "Карты", "href": "trucks", key: pn}; citems.push( npn )}
        //if(pn==="transactions"){ npn={"title": "Транзакции", "href": "trucks", key: pn}; citems.push( npn )}
        //if(pn==="settings"){ npn={"title": t("Nastavitve"), "href": "trucks", key: pn}; citems.push( npn )}
        //if(pn.length===51){ npn={"title": "Редактирование", "href": "trucks",}; citems.push( npn )}
        
    });


    //const items=pathnames.length > 0 ? 
    //    ({ title : 'Главная'}) : 
    //    ({pathnames.map((name, index) => {title: name} });
    return (
        <div>
            {/* 
            <Breadcrumb 
            items ={citems}
            >
            </Breadcrumb>
*/}
            <Breadcrumb itemRender={itemRender}
                routes={croutes}
            >
            </Breadcrumb>
            
            
        </div>
    )
    
}

export default BreadCrumbsComp
