import React, {useRef, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";

import {Form, Input, Row, Col, Select, InputNumber, Spin, Space, DatePicker, TimePicker, Button} from "antd";
import {Simulate} from "react-dom/test-utils";
import PlacesAutocomplete from "react-places-autocomplete";
import axios from "axios/index";
import dayjs from "dayjs";
import {DeleteOutlined} from "@ant-design/icons";

import RoutePoint from "../../../types/transporter-types";
import {useTranslation} from "react-i18next";



const dateToPoints =(today : Date) =>{
    const yyyy = today.getFullYear();
    let m = today.getMonth() + 1; // Months start at 0!
    let d = today.getDate();

    let mm = m.toString();
    let dd = d.toString();
    if (d < 10) dd = '0' + dd;
    if (m < 10) mm = '0' + mm;

    return dd + '.' + mm + '.' + yyyy;
}

const timeToPoints =(today : Date) =>{
//    const yyyy = today.getFullYear();
    let m = today.getMinutes() ; // Months start at 0!
    let d = today.getHours();

    let mm = m.toString();
    let dd = d.toString();
    if (d < 10) dd = '0' + dd;
    if (m < 10) mm = '0' + mm;

    if (isNaN(m)) return '';
    return dd + ':' + mm ;
}



interface PointProps {
    keyStr?: string;
    city? : string;
    country? : string;
    street? : string;
    lat? : number;
    lng? : number;
    PointDate? : string;
    PointTime? : string;
    PointType? : number;

    forceReload?: boolean;
    isLoaded?: () => void;
    deleteFunc?: (key: string) => void;
    addFunc?: (values: any) => void;

}

const RoutePointForm = ({keyStr, forceReload, city, country, street, lat, lng, PointDate, PointTime, isLoaded, PointType, deleteFunc, addFunc}: PointProps) => {

    //console.log("Start RoutePointForm: ", city, country, street, lat, lng, PointDate, PointTime, PointType)
    const {t} = useTranslation();

    const history = useHistory();
    const user = AuthService.getCurrentUser();
    if (!user) {
        history.push("/login");
    }

    const [formPos] = Form.useForm();
    const [_routePoint, set_RoutePoint] = useState<RoutePoint>();

    const [address, setAddress] = useState("");

    const [listLoading, setslistLoading] = useState(false);




    useEffect(()=>{
        setslistLoading(true);
        set_RoutePoint({
            keyStr : keyStr? keyStr : "",
            city: city ? city : "",
            country: country ? country : "",
            street: street ? street : "",
            lat: lat ? lat :  undefined,
            lng: lng ? lng : undefined,
            PointDate: PointDate,
            PointTime: PointTime,
            PointType : PointType ? PointType : 0
        });
        if(city){
            setAddress(street+" "+city+" "+country);
            formPos.setFieldsValue({
                ["from"]: street+" "+city+" "+country
            });
        }
 
        if(PointDate){
           // console.log("PointDate: ", PointDate)
        }
        setslistLoading(false)
        
    }, [])
    
    
    
    const deletePos=(key:string)=>{
        if(deleteFunc){
            deleteFunc(key);
        }
    }

    const handleDateChange = (value: any) => {

        //console.log("before date update _routePoint: ", _routePoint);
        //console.log("value: ", value)
        //console.log("dateToPoints(new Date(value)): ", dateToPoints(new Date(value)))
        set_RoutePoint((prev: any) => ({
            ...prev,
            PointDate : dateToPoints(new Date(value)),
        }));
        if(addFunc && _routePoint){
            addFunc({
                keyStr : keyStr,
                city: _routePoint.city,
                country: _routePoint.country,
                street: _routePoint.street,
                lat: _routePoint.lat,
                lng: _routePoint.lng,
                PointDate: dateToPoints(new Date(value)),
                PointTime: _routePoint.PointTime,
                PointType : _routePoint.PointType
           
            });
        }
    }
    
    
    const check_routePoint=()=>{
        console.log(_routePoint);
    }

    const handleTimeChange = (value: any) => {
        set_RoutePoint((prev: any) => ({
            ...prev,
            PointTime : timeToPoints(new Date(value)),
        }));
        if(addFunc  && _routePoint){
            addFunc({
                keyStr : keyStr,
                city: _routePoint.city,
                country: _routePoint.country,
                street: _routePoint.street,
                lat: _routePoint.lat,
                lng: _routePoint.lng,
                PointDate: _routePoint.PointDate, //? _routePoint.PointDate : dateToPoints(new Date((formPos.getFieldValue(["theDate"]), "DD.MM.YYYY"))),
                PointTime: timeToPoints(new Date(value)), // ? _routePoint.PointTime : timeToPoints(new Date((formPos.getFieldValue(["theTime"]), "HH:mm")))
                PointType : _routePoint.PointType
            });
        }
        //console.log("_routePoint check:", keyStr, _routePoint);

    }


    const handlePointTypeChange = (value : any) =>{
        set_RoutePoint((prev: any) => ({
            ...prev,
            PointType : value,
        }));
        if(addFunc  && _routePoint){
            addFunc({
                keyStr : keyStr,
                city: _routePoint.city,
                country: _routePoint.country,
                street: _routePoint.street,
                lat: _routePoint.lat,
                lng: _routePoint.lng,
                PointDate: _routePoint.PointDate, //? _routePoint.PointDate : dateToPoints(new Date((formPos.getFieldValue(["theDate"]), "DD.MM.YYYY"))),
                PointTime: _routePoint.PointTime, //timeToPoints(new Date(value)), // ? _routePoint.PointTime : timeToPoints(new Date((formPos.getFieldValue(["theTime"]), "HH:mm")))
                PointType : value
            });
    }
    }


    const handleSelect = async(address: string, placeId?: string, suggestion?: any)=> {

        //console.log("handleSelect", address);
        if(suggestion){
            formPos.setFieldsValue({
                from: suggestion.description
            });
            axios.get("https://maps.googleapis.com/maps/api/geocode/json?address="+suggestion.description+"&key=AIzaSyAJR6qBtKHozWEDAHHl1pPQS6S3dUxgik8")
                .then((response) => {
                    var country ="";
                    var city="";
                    var street="";
                    var strret_num="";
                    var lat=0.0;
                    var lng = 0.0;
                    const geo = response.data.results[0];
                    
                    //console.log("geo: ", geo);
                    lat=geo.geometry.location.lat;
                    lng=geo.geometry.location.lng;
                    geo.address_components.forEach((obj : any)=>{
                        obj.types.forEach((typ: any)=>{
                            //console.log(typ, ":", obj);
                            if(typ==="locality" ||typ==="administrative_area_level_3" ||typ=== "administrative_area_level_1" ||typ=== "locality")
                            {
                                city=obj.long_name;
                            }
                            if(typ==="country" )
                            {
                                country=obj.long_name;
                            }
                            if(typ==="route" ||typ==="street" )
                            {
                                street=obj.long_name;
                            }
                            if(typ==="route" ||typ==="street" )
                            {
                                street=obj.long_name;
                            }
                            if(typ==="street_number")
                            {
                                console.log("street_numberstreet_numberstreet_numberstreet_numberstreet_number:",+obj.long_name )
                                strret_num=obj.long_name;
                            }

                        })
                    })
                    street=street+" "+strret_num;
                    console.log("city, country, street, lat, lng", city, country, street, lat, lng);
                    
                    
                    /*




                    var locality = geo.address_components.find((obj: any) => {
                        return obj.types.find((typ: any) => {return typ === "locality"})
                    });
                    if(locality===undefined){
                        locality = geo.address_components.find((obj: any) => {
                            return obj.types.find((typ: any) => {return typ === "street_address"})
                        });
                    }
                    const fadres=geo.formatted_address.split(", ");
                    console.log(geo.address_components, locality);
                    console.log("locality: ", locality);
                    
                    console.log("----:",  geo.address_components.find((obj: any) => {
                        return obj.types.find((typ: any) => {return typ === "administrative_area_level_1" || typ ==="administrative_area_level_2"})
                    }))
                    if(locality!== undefined){
                        console.log("not undefined locality: ", locality)
                        city=locality.long_name;
                        //set_RoutePoint((prev: any) => ({
                        //    ...prev,
                        //    city : locality.long_name,
                        //}))
                    }else{
                        city= geo.address_components.find((obj: any) => {
                            return obj.types.find((typ: any) => {return typ === "administrative_area_level_1" || typ ==="administrative_area_level_2"})
                        }).long_name;
                        country= geo.address_components.find((obj: any) => {
                            return obj.types.find((typ: any) => {return typ === "country"})
                        }).long_name;
                        street=fadres[0];
                        lat=geo.geometry.location.lat;
                        lng=geo.geometry.location.lng;
                        
                        console.log("city, country, street, lat, lng", city, country, street, lat, lng)
                        
                        //set_RoutePoint((prev: any) => ({
                        //    ...prev,
                        //    city : geo.address_components.find((obj: any) => {
                        //        return obj.types.find((typ: any) => {return typ === "administrative_area_level_1" || typ ==="administrative_area_level_2"})
                        //    }).long_name,
                        //    country: geo.address_components.find((obj: any) => {
                        //        return obj.types.find((typ: any) => {return typ === "country"})
                        //    }).long_name,
                        //    street:fadres[0],
                        //    lat:geo.geometry.location.lat,
                        //    lng:geo.geometry.location.lng,
                        //}));
                        
                         
                    }
                    
                     */
                    set_RoutePoint((prev: any) => ({
                        ...prev,
                        city : city,
                        country: country,
                        street:street,
                        lat:lng,
                        lng:lat,
                    }));
                    if(addFunc){
                        addFunc(
                            {
                                keyStr: keyStr,
                                city : city,
                                country: country,
                                street:street,
                                lat:lat,
                                lng:lng,
                                PointDate : _routePoint?.PointDate, //dtstring, //dateToPoints(new Date((formPos.getFieldValue(["theDate"]), "DD.MM.YYYY"))),
                                PointTime:  _routePoint?.PointTime, //tmstring,//dayjs(formPos.getFieldValue(["theTime"]), "HH:mm")
                                PointType : _routePoint?.PointType
                            }
                        );
                    }

                })

        }
    }



if(listLoading){
    return (<div>...</div>)
}

//console.log("PointDate: ", PointDate)
//console.log("PointDate: ", PointDate, PointTime)
    return (


        
        <div>

      <Form form={formPos}
                  name={keyStr? keyStr.toString() : "009"}
                  id={keyStr? keyStr.toString() : "009"}
                  layout={"inline"}
            initialValues={
                {
                    ["thedate"]: PointDate ? dayjs(PointDate,  "DD.MM.YYYY") : undefined, //dayjs(dateToPoints(new Date(PointDate)), "DD.MM.YYYY") : undefined,
                    ["thetime"]: PointTime ? dayjs(PointTime,  "HH:mm") : undefined, //dayjs(timeToPoints(new Date(PointTime)), "HH:mm") : undefined,
                    ["pointType"]: PointType ? PointType : 0,
                    ["from"] : street+" "+city+" "+country
                }
            }
 
                  //labelAlign="right"
                  //labelCol={{flex: '50px'}}
                  //onChange={SavePosition}

            >
                <Form.Item
                    name="pointType">
                    <Select
                        style={{ width: 120, marginBottom: "10px"}}
                        onChange={handlePointTypeChange}
                        //defaultValue={PointType}
                        options={[{ value: 0, label: t('Nakladanje') }, { value: 1, label: t('Razkladanje') }]}
                    />
                </Form.Item>

                <Row style={{minWidth: "630px"}}>
                    <Col span={11}>
                        <Form.Item  name={"from"}>
                            
                            <PlacesAutocomplete
                                value={street+" "+city+" "+country}
                                
                                onChange={setAddress}
                                onSelect={handleSelect}

                            >
                                {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                                    <div>
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Kje ...',
                                                className: 'location-search-input',
                                            })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                                                    : {backgroundColor: '#ffffff', cursor: 'pointer'};
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>


                        </Form.Item>
                    </Col>
                    <Col span={7}>
                            <Form.Item>
                                <Space.Compact>
                                    <Form.Item
                                        name="thedate"
                                        //rules={[{ required: true }]}
                                        style={{ marginInlineEnd:"0px"}}
                                    >
                                        <DatePicker style={{width: "115px"}} format={"DD.MM.YYYY"} placeholder={""}
                                        onChange={handleDateChange}/>
                                    </Form.Item>
                                    <Form.Item
                                        name="thetime"
                                        rules={[{required: false}]}
                                        style={{/*width: "350px"*/}}
                                        help={""}
                                    >
                                        <TimePicker format={"HH:mm"} style={{width: "85px"}} placeholder={""}
                                        onChange={handleTimeChange}/>
                                    </Form.Item>
                        </Space.Compact>
                            </Form.Item>
                    </Col>
                    <Col span={1} style={{paddingTop: "4px", marginLeft:"30px"}}>
                        <a onClick={()=>deletePos(keyStr ? keyStr : "0")} ><DeleteOutlined /></a>
                        {/*
                        <Button onClick={()=>{check_routePoint()}}>crp</Button>
                        */}

                    </Col>

                </Row>
                

            </Form>

        </div>


    )
}
export default RoutePointForm;