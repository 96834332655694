import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, Input, List, message, Modal, Row, Space, Tabs, Switch, Tooltip, Dropdown} from "antd";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import {Link, useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import DocList from "../../docs/DocComponents/docList";
import UploadDoc from "../../docs/DocComponents/UploadDoc";
import RouteList from "../../myroutes/RouteComponents/list";
import {useTranslation} from "react-i18next";

import { ExclamationCircleFilled } from '@ant-design/icons';
import DealsList from "../../Deals/Components/DealsList";
import DealsFilter from "../../../types/DealsListFilter";
import TransactionsList from "../../transactions/TransactionsComponents/TransactionsList";
import TransactionsFilter from "../../../types/TransactionsListFilter";
import EditContractorComp from "./EditComponent";


interface EditContractorProps{
    paramValue?: string;
}
export default function EditContractor({ paramValue} : EditContractorProps) {

    const history= useHistory();

    const {t} = useTranslation();



    const user = AuthService.getCurrentUser();
    if(!user){
        history.push("/login");
    }


    interface DataType {
        id : React.Key;
        title: string;
        davcnaStevilka: string;
        maticnaStevilka: string;
        adress1: string;
        adress2: string;
        iban : string;
        country: string;
        logoId : string;
        logo: any;
        zigId : string;
        zig: any;
        podpisId: string;
        podpis: any;
        swift:string;
    }
    
    

    const [form] = Form.useForm();

    const [company, setCompany] = useState<DataType>();
    const  [ownCompanyId, setownCompanyId] = useState<any>();

    const  [logoId, setlogoId] = useState<any>();
    const  [zigId, setzigId] = useState<any>();
    const  [podpisId, setpodpisId] = useState<any>();

    const[isClientVal, setisClientVal]=useState(false);
    const[isClientValDisabled, setisClientValDisabled]=useState(false);
    
    const[componentDisabled, setComponentDisabled]=useState(false);

    const [dealsFilter, setdealsFilter]=useState<DealsFilter>({
        searchText: "",
        dateFrom: "",
        dateTo: "",
        hidefinished:true,
        requestStatus: 100,
        selectedDates: "",
        counterPartId : paramValue
    })


    const [transactionFilter, settransactionFilter]=useState<TransactionsFilter>({
        searchText: "",
        dateFrom: "",
        dateTo: "",
        hidefinished:true,
        selectedDates: "",
        counterPartId : paramValue
    })


     
        //settest((paramValue ? paramValue : "-"));
    
    

    const BackToList = () =>{
        history.push("/contractors");
    }
    
    const onFinish =(values : any)=>{
        console.log("values: ", values)
        
        axios.put(AuthService.DATA_URL+'Contractor/'+paramValue,
            {

                "id": ownCompanyId,
                "title": values.title,
                "davcnaStevilka": values.davcnaStevilka,
                "maticnaStevilka": values.maticnaStevilka,
                "adress1": values.adress1,
                "adress2": values.adress2,
                "iban": values.iban,
                "swift": values.swift,
                "userid":"",
                "owneruserid":"",
                "logoid" : logoId,
                "podpisId": podpisId,
                "zigId":zigId,
                "tel":values.tel,
                "fax": values.fax,
                "contactPerson": values.contactPerson,
                "isClient": isClientVal//values.isClient//===true? "checked" : ""
                

            },{headers: authHeader()})
            .then((res) =>{
                message.success('Submit success!');
                history.push("/contractors");
                }
            );
            
         

    }

    const { TabPane } = Tabs;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleModalCancel = () => {
        setreload(!reload);
        setIsModalOpen(false);
    };
    const showModal1 = () => {
        setIsModalOpen(true);

    };

    const [reload, setreload] = useState(false);

const handleModalOk=()=>{
    setreload(!reload);
    setIsModalOpen(false);
}    




const showval=(v: any)=>{
    console.log("v: ",v);
    console.log("FieldValue: ",form.getFieldValue("isClient"))
    setisClientVal(v);
    
}


 

    return (
        <div>
            <h1>{t("Podatki_o_partnerju")}{company? ": "+company.title : ""}</h1>
            <Tabs defaultActiveKey="0" //activeKey={tabActiveKey}
                //onTabClick={theTabClick}
            >
                <TabPane tab={t("Osnovni_podatki")} key="0">

                    <EditContractorComp paramValue={paramValue}/>
                    
                    
                    
                </TabPane>


                <TabPane tab={t("Zgodovina_opravljenih_letov")} key="1">
                        <DealsList  filter={ dealsFilter} />

                </TabPane>
                
                <TabPane tab={t("Dokumenti")} key="2"
                style={{
                    height:  "calc(75vh - 4em)",//"300px",
                    overflow: "scroll"
                }}
                
                >
                    <Card>
                        <Button onClick={showModal1} style={{
                            border: "1px dashed #d9d9d9",
                            borderTopLeftRadius: "5px",
                            borderBottomLeftRadius: "5px",
                            //borderRight: "0px",
                            height: "40px",
                            //width: "100%",
                            fontSize: "14px",
                            color: "#1449AE",
                            marginBottom: "8px",
                            padding: "8px 5px"}}
                        >
                            + {t("Prenesite_dokument")}
                        </Button>
                        <DocList ContractorId={  paramValue ? paramValue.toString() : undefined} reload={reload} />

                    </Card>
                </TabPane>

                <TabPane tab={t("Finances")} key="33"
                         style={{
                             height:  "calc(75vh - 4em)",//"300px",
                             overflow: "scroll"
                         }}

                >


                        <TransactionsList filter={transactionFilter} commonFilter={{counterPartId: paramValue}}/>

                </TabPane>


            </Tabs>


            
            <Modal open={isModalOpen}
                onOk={handleModalOk}
                   title={t("Prenesite_dokument")}
                   centered
                   width={580}
                   onCancel={handleModalCancel}
                //footer={[]}
            >

                <UploadDoc objectId={paramValue ? paramValue.toString() : null} objectMode={4}/>
            </Modal>
        </div>



    )}