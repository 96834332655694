import React, {useEffect, useState} from 'react';
import {Table} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
import * as AuthService from "../../../Services/auth-service";
import authHeader from "../../../Services/auth-header";
import CompanySettingsPage from "../../settings/Components/CompanySettings";
import {useTranslation} from "react-i18next";

//import * as XLSX from 'xlsx/xlsx.mjs';


interface RouteListProps{
    ContractorId?: string;
    DriverId? : string;
    RequestId?: string;
    TruckId? : string;
    ExpiryDate? : string;
    reload? : boolean;

}
const ExchangeList= ({ ContractorId, DriverId, RequestId, TruckId,  ExpiryDate, reload} : RouteListProps) => {

    const {t} = useTranslation();

    const history= useHistory();
    const user = AuthService.getCurrentUser();
    if(!user){
        AuthService.logout();
        history.push("/login");
        window.location.reload();

    }

    //const { TabPane } = Tabs;
    interface DataType {
        key: string;
        id : string;
        routeNumber : string;
        externalRequestNumber: string;
        from : string;
        to : string;
        fromCountry : string;
        toCountry : string;
        fromto : string;
        fromdate: string;
        fromtime : string;
        todate: string;
        totime : string;
        price: number;
        client: string;
        receiver: string;
        payer : string;
        requestStatus : number;
        traktor : string;
        priklopnik : string;
        driver : string;
        reps: number;
        isMyOffer : boolean;
        priceForClient : number | null;
        priceToShowToSubcontractors : number | null;

    }


    type RequestDTO={
        key: React.Key;
        id: string;
        requestNumber: string;
        externalRequestNumber: string;
        price : number;
        distance : number;
        loadingType: number;
        rollingStockType: number;
        requestStatus: number;
        cargoDescription : string;
        weight : number,
        volume: number,
        clientName : string,
        receiverName: string;
        payerName : string;
        track : any;
        priklopnik: any;
        client:any;
        receiver :any;
        payer : any;
        driver : any;
        priceForClient : number | null;
        priceToShowToSubcontractors : number | null;

        routePoints: any[];
    }
    
    type exchangeDTO={
        request : RequestDTO,
        replies : any[],
        replyCount : number,
        isMyOffer : boolean
        priceForClient? : number | null;
        priceToShowToSubcontractors? : number | null;

    }



    const columns: ColumnsType<DataType> = [
        {
            title: t("Številka"),
            key: 1,
            dataIndex: 'routeNumber',
            width: 100,
            render: (text, record) => {
                //var statusspan="<span color: green>&bull;</span>";
                if(record.requestStatus === 0) {
                    return (
                        <div>
                            {text}
                            <br/>
                            <span className={"dot"}  style={{backgroundColor: "#E3601D", verticalAlign: "middle"}}> </span><span className={"greyLabel"}>{t("Novo")}</span>
                        </div>
                    )
                }
                if(record.requestStatus === 1) {
                    return (
                        <div>
                            {text}
                            <br/>
                            <span className={"dot"}  style={{backgroundColor: "#1FAA73", verticalAlign: "middle"}}> </span><span className={"greyLabel"}>{t("Načrtovano")}</span>
                        </div>
                    )
                }
                if(record.requestStatus === 20) {
                    return (
                        <div>
                            {text}
                            <br/>
                            <span className={"dot"} style={{backgroundColor: "#faad14", verticalAlign: "middle"}}> </span><span  className={"greyLabel"}>{t('Načrtovana')}</span>
                        </div>
                    )
                }
                if(record.requestStatus === 30) {
                    return (
                        <div>
                            {text}
                            <br/>
                            <span className={"dot"} style={{backgroundColor: "#1FAA73", verticalAlign: "middle"}}> </span><span  className={"greyLabel"}>{t('Confirmed')}</span>
                        </div>
                    )
                }
                if(record.requestStatus === 40) {
                    return (
                        <div>
                            {text}
                            <br/>
                            <span className={"dot"} style={{backgroundColor: "#1890FF", verticalAlign: "middle"}}> </span><span  className={"greyLabel"}>{t('On_route')}</span>
                        </div>
                    )
                }
                if(record.requestStatus === 50) {
                    return (
                        <div>
                            {text}
                            <br/>
                            <span className={"dot"}  style={{backgroundColor: "#A4A6AC", verticalAlign: "middle"}}> </span><span  className={"greyLabel"}>{t('Zaključena')}</span>
                        </div>
                    )
                }

                return (
                    <div>
                        {text}
                        <br/>
                    </div>
                )


            }},
        {
            title: t('Itinerarij'),
            key: 2,
            dataIndex: 'fromto',
            width: 350,
            ellipsis: true,
            render: (text, record) =>
                <div className="RoutePoints">
                    <div className="point start" title="">
                        <div className={"timeStart"}> 
                            <span>{record.fromdate}<br/>
                                <span className={"routeTime"}>{record.fromtime}</span>
                            </span>
                        </div>
                        <span className="name_sCDti">{record.from} <br/> 
                            <span className={"greyLabel"}>{record.fromCountry}</span> </span>
                        <div className="truncate description_liDDR"></div>
                    </div>
                    <div className="between"> </div>
                    <div className="point end"
                         title="">
                        <span className="name_eCDti">{record.to} <br/> 
                            <span className={"greyLabel"}>{record.toCountry}</span></span>
                        <div className={"timeEnd"}>
                            <span>{record.todate}<br/>
                                <span className={"routeTime"}>{record.totime}</span>
                            </span>
                        </div>
                        <div className="truncate description_liDDR"></div>
                    </div>

                </div>
        },
        {
            title: t('Izvajalec'),
            key: 3,
            dataIndex: "traktor",
            width: 165,
            render: (text, record) => {
                if(record.traktor.length===0){
                    return(
                        <div>

                            <Link className={"orangeA"} to={`/routes/view/${record.id}`}>
                                {record.reps > 0
                                    ?
                                    record.isMyOffer ? t("Offers_received") +": "+ record.reps :
                                        t("Offer_is_sent")
                                    : t("Izberite")}
                        </Link>
                            
                            
                        </div>
                    )
                }
                if(record.priklopnik.length===0){
                    return(
                        <div>
                            <div className={"tovornjak_div"}>
                                <img src={"/img/tovornjak.png"} alt={" "} />
                                {record.traktor}
                            </div>
                            <div style={{fontSize: "12px"}}>{record.driver}</div>
                        </div>
                    )
                }
                else{
                    return(
                        <div>
                            <div className={"traktor_div"}>
                                <img src={"/img/traktor.png"} alt={" "} />
                                {record.traktor}
                            </div>
                            <div  className={"priklopnik_div"}>
                                <img src={"/img/priklopnik.png"} alt={" "} />
                                <div style={{clear: "right"}}>{record.priklopnik}</div>
                            </div>
                            <div style={{fontSize: "12px"}}>{record.driver}</div>

                        </div>

                    )
                }

            }

        },
        {
            title: t('Cena'),
            key: 4,
            dataIndex: 'price',
            width: 75,
            align: 'right',
            render:(text, record) => <div>
                {
                    user.isTransporter ?
                        record.priceToShowToSubcontractors
                            ? record.priceToShowToSubcontractors.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")
                            : text.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")
                        :
                        text.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")} €
                <br/><span className={"routeTime"}> {record.client.substring(0,30)}</span>

            </div>
        },
           // render:(text, record) => <div>{text.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")} €<br/><span className={"routeTime"}> {record.client.substring(0,30)}</span>
      //      </div>
      //  },
        {
            title: ' ',
            key: 'operation',
            fixed: 'right',
            width: 50,
            render: (text, record) =>
                <Link to={`/routes/view/${record.id}`}>{t("Več")} &rarr;</Link>
        },
    ];


    const [APIData, setAPIData] = useState<exchangeDTO[]>([]);
    const [isLoading, setisLoading] = useState(true);


    useEffect(() => {
        axios.get(AuthService.DATA_URL+`Request/get-exchange-dto`
            //+ "ContractorId="+(ContractorId ? ContractorId : "")
            //+ "&DriverId="+(DriverId ? DriverId : "")
            //+ "&TruckId="+(TruckId ? TruckId : "")
            ,
            {headers: authHeader()})
            .then((response) => {
                console.log("response.data: ", response.data)
                setAPIData(response.data);
                setisLoading(false);
            })
            .catch((r)=>{
                console.log(r);
                console.log(r.response);
                if(r.response.status ===401){
                    AuthService.logout();
                    history.push("/login");
                    window.location.reload();
                }
                // history.push()
            })

    }, []);



    ///console.log("APIData: ", APIData)
    const TableData = APIData.map((tr, index) => ({
        key: tr.request.id,
        id: tr.request.id,
        routeNumber:tr.request.externalRequestNumber ? tr.request.externalRequestNumber : "",
        externalRequestNumber:tr.request.externalRequestNumber ? tr.request.externalRequestNumber : "",
        from : tr.request.routePoints[0] ? tr.request.routePoints[0].streetName :"", //"Франкфурт-на-Майне",
        to : tr.request.routePoints[1] ? tr.request.routePoints[1].streetName : "",//"Крань",
        fromCountry: tr.request.routePoints[0] ? tr.request.routePoints[0].cityName+", "+tr.request.routePoints[0].countryName : "",//"Германия",
        toCountry :  tr.request.routePoints[1] ? tr.request.routePoints[1].cityName+", "+tr.request.routePoints[1].countryName :"",//"Словения",
        fromto : "genm",
        //fromdate: tr.routePoints[0].dateStart,// "01.10.2023 15:30",
        fromdate: tr.request.routePoints[0] ? tr.request.routePoints[0].dateStart ? tr.request.routePoints[0].dateStart.substring(8,10)+"."+tr.request.routePoints[0].dateStart.substring(5,7)+"."+tr.request.routePoints[0].dateStart.substring(0,4) : "" : "",
        fromtime:tr.request.routePoints[0] ? tr.request.routePoints[0].dateStart ? tr.request.routePoints[0].dateStart.substring(11,16) : "" : "",
        todate:tr.request.routePoints[1] ? tr.request.routePoints[1].dateStart ? tr.request.routePoints[1].dateStart.substring(8,10)+"."+tr.request.routePoints[1].dateStart.substring(5,7)+"."+tr.request.routePoints[1].dateStart.substring(0,4) : "" : "",
        totime:tr.request.routePoints[1] ? tr.request.routePoints[1].dateStart ? tr.request.routePoints[1].dateStart.substring(11,16) : "" : "",
        price: tr.request.price,
        priceForClient: tr.request.priceForClient,
        priceToShowToSubcontractors: tr.request.priceToShowToSubcontractors,
        client: tr.request.client?  tr.request.client.title : "-",
        requestStatus : tr.request.requestStatus, //1,
        traktor : tr.request.track ? tr.request.track.regNumber : "",
        priklopnik: tr.request.priklopnik ? tr.request.priklopnik.regNumber : "",//tr.priklopnik.RegNumber,//"LJ NB-064",
        driver: tr.request.driver ? tr.request.driver.name+" "+tr.request.driver.surname : "",//tr.driver.name
        receiver: tr.request.receiver ? tr.request.receiver.title : "-",
        payer : tr.request.payer ? tr.request.payer.title : "-",
        reps : tr.replyCount,
        isMyOffer : tr.isMyOffer

    }) )



    return (
        <div>
            <Table
                locale={{
                    triggerDesc: 'Kliknite za razvrščanje padajoče',
                    triggerAsc: 'Kliknite za razvrščanje naraščajoče',
                    cancelSort: 'Prekliči razvrščanje'
                }}
                columns={columns}
                dataSource={TableData}
                loading={isLoading}
                pagination={false}
                scroll={{ y: 'calc(53vh)' }}
                //                    pagination={{ pageSize: 6 }} size="small"  scroll={{ y: 'calc(60vh - 4em)' }}
            />
        </div>

    );
};
export default ExchangeList;