import React from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";
import ListContractors from "./ContractorsComponents/list";
import {Button, Space} from "antd";
import {useTranslation} from "react-i18next";



const ContractorsPage = () => {
    const {t} = useTranslation();


    const history= useHistory();
    const user = AuthService.getCurrentUser();
    if(!user){
        history.push("/login");
    }

    const clearAll = () => {
        history.push("/contractors/new");
    };
    return (
        
        <div>
            <Space>
                <h1>{t("Stranke_in_partnerji")}</h1>
            </Space>
            
            
            <ListContractors />
        </div>
    );
};
export default ContractorsPage;