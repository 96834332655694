import React, {useState, useCallback, useEffect} from "react";
import {Button, Divider, Form, Input, Steps, InputNumber, Row, Col, message, Select, Checkbox} from 'antd'
//import axios from "axios";
import {useHistory} from "react-router-dom";


import RoutePointStepForm from "./RoutePointStepForm";
import axios from "axios/index";
import authHeader from "../../../Services/auth-header";
import * as AuthService from "../../../Services/auth-service";
import PointsList from "./pointsList";
import {useTranslation} from "react-i18next";

interface request {
    id: string;
    requestNumber: string;
    price : number;
    distance : number;
    loadingType: number;
    rollingStockType: number;
    requestStatus: number;
    RoutePoints: any[];
    clientId : string;
    receiverId : string;
    payerId : string;
    contractorId? : string | null;

    CargoDescription : string;
    weight : number;
    volume: number;
    requestType : number;
    //ClientName : string;
}

const routePoints :any[] =[];
type GUID = string & { isGuid: true};






export default function EditRequest() {
    const {t} = useTranslation();

    const [step, setStep] = useState(0);
    const [stepItems, setstepItems] = useState<any[]>([]);

    const[newReq, setNewReq]=useState<request>({
        id:"",
        requestNumber : "TN-01.2023",
        price: 1200,
        distance: 0,
        loadingType: 0,
        rollingStockType: 0,
        requestStatus: 0,
        clientId: "",
        receiverId: "",
        contractorId :null,
        payerId : "",
        CargoDescription:"",
        weight:0,
        volume: 0,
        requestType : 0,
        RoutePoints: []});


    const history= useHistory();


    const [formH] = Form.useForm();
    const [form] = Form.useForm();
    
    const[ContractorOptions, setContractorOptions] = useState<any[]>([]);
    const[contractors, setcontractors] = useState<any[]>([]);

    const[TransporterOptions, setTransporterOptions] = useState<any[]>([]);

    useEffect(() => {
        if (TransporterOptions.length === 0) {
            axios.get(AuthService.DATA_URL+'Contractor/get-transporters', {headers: authHeader()})
                .then((response) => {
                    if (TransporterOptions.length === 0) {
                        JSON.parse(JSON.stringify(response.data, null, 4)).forEach((row: any, i: number) => {
                            TransporterOptions.push({
                                value: row.id,
                                label: row.title
                            });
                        });
                    }

                });
        }
    }, []);



    //заполним списки опций селектов
    useEffect(() => {
        if(contractors.length===0){
            axios.get(AuthService.DATA_URL+'Contractor/', {headers: authHeader()})
                .then((response) => {
                    JSON.parse(JSON.stringify(response.data, null, 4)).forEach((d:any,c:any) => {
                        ContractorOptions.push({
                            value: d.id,
                            label: d.title,
                        });
                    });
                });
        }
    });

    //переменные для первоначальных данных для редактирования

    const[initClient, setinitClient] = useState<string>("");
    const[initReceiver, setinitReceiver] = useState<string>("");
    const[initPayer, setinitPayer] = useState<string>("");
    const[initPrice, setinitPrice] = useState<number>(0);
    const[initNumber, setinitNumber] = useState<string>("");
    const[initVolume, setinitVolume] = useState<number>(0);
    const[initweight, setinitweight] = useState<number>(0);
    const[initdescription, setinitdescription] = useState<string>("");

    const[isPublicRequest, setisPublicRequest] = useState<boolean>(true);
    const[showPerformerSelect, setshowPerformerSelect] = useState("none");

    const[initTransporter, setinitTransporter] = useState<string>("");


    const changeRequestType=(e:any)=>{
        setisPublicRequest(e.target.checked)
        if(!e.target.checked){
            setshowPerformerSelect("block")

            setNewReq(prev => ({
                ...prev,
                requestType: 1
            }));
            
            //newReq.requestType=2;
        }
        else{
            setshowPerformerSelect("none")
            //newReq.requestType=0;
            setNewReq(prev => ({
                ...prev,
                requestType: 0,
                contractorId:null
            }));

            
        }
        console.log(e.target.checked)
    }



    //заполним значения из get
    //взяв параметры из адресной строки
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramValue = urlParams.get('id');

    
    
    
    useEffect(() => {
        if(newReq.id===""){
            axios.get(AuthService.DATA_URL+'Request/'+paramValue, {headers: authHeader()})
                .then((response) => {
                    seteditRequestObj(response.data);
                    seteditPointsObj(response.data.routePoints);
                    
                    console.log("edit request get response: ",response);
                    
                    setNewReq({
                        id:response.data.id,
                        requestNumber : response.data.requestNumber,
                        price: response.data.price,
                        distance: response.data.distance,
                        loadingType: response.data.loadingType,
                        rollingStockType: response.data.rollingStockType,
                        requestStatus: response.data.requestStatus,
                        clientId: response.data.clientId,
                        receiverId :response.data.receiverId,
                        payerId :response.data.payerId,
                        weight : response.data.weight,
                        volume: response.data.volume,
                        requestType : response.data.requestType? response.data.requestType : 1,
                        contractorId : response.data.contractorId,
                        CargoDescription:response.data.cargoDescription===undefined? "" : response.data.cargoDescription,
                        RoutePoints: []});


                     
                    // сразу заполним данные для первоначальных значений формы
                    setinitClient(response.data.clientId);
                    setinitPayer(response.data.payerId)
                    setinitReceiver(response.data.receiverId);

                    //setinitClient(response.data.receiverId);
                    setinitPrice(response.data.price);
                    setinitNumber(response.data.requestNumber);
                    setinitVolume(response.data.volume);
                    setinitweight(response.data.weight);
                    setinitdescription(response.data.cargoDescription);
                    setContractorOptions(ContractorOptions);

                    if(response.data.contractorId!==null){
                        setinitTransporter(response.data.contractorId)
                        setTransporterOptions(TransporterOptions);
                    }
                   
                    if(response.data.requestType===0){
                        setisPublicRequest(true);
                        setshowPerformerSelect("none")
                    }

                    if(response.data.requestType!==0){
                        setisPublicRequest(false);
                        setshowPerformerSelect("block")
                    }



                })

        }

    }, );











    //колбэки для контролов формы - заполняем изменяемые поля
    
    //номер заказа
    
    const blurNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNewReq(prev => ({
            ...prev,
            requestNumber: e.target.value ===undefined ? "" : e.target.value
        }));
    }

    //цена заказа
    const onChangePrice=(e: (number| null)) =>{ 
        setNewReq(prev => ({
            ...prev,
            price: e===null? 0 : e
        }));
    }


    //клиент
    const ChangeClient = (value: string) => {
        setNewReq(prev => ({
            ...prev,
            clientId: value
        }));
        return value;
    }
    
    const ChangeReceiver = (value: string) => {
        setNewReq(prev => ({
            ...prev,
            receiverId: value
        }));
        return value;
    }

    const ChangePayer = (value: string) => {
        setNewReq(prev => ({
            ...prev,
            contractorId: value
        }));
        return value;
    }


    //вес
    const ChangeWeight = (e: (number| null)) => {
        setNewReq(prev => ({
            ...prev,
            weight: e===null? 0 : e
        }));
        //return value;
    }
    
    //объем 
    const ChangeVolume = (e: (number| null)) => {
        setNewReq(prev => ({
            ...prev,
            volume: e===null? 0 : e
        }));
        //return value;
    }
    
    //описание груза
    const blurDescription = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNewReq(prev => ({
            ...prev,
            cargoDescription: e.target.value ===undefined ? "" : e.target.value
        }));
    }

    const CreateRequestHeader=(data: any) =>{
        //console.log("data: ", data);
        console.log("newReq: ", newReq);
        axios.put(AuthService.DATA_URL+'Request/'+paramValue,
            newReq, {headers: authHeader()}
            )
            .then((response) =>{
                    //console.log("put response.data: ",response.data);
                    //console.log("routePoints: ", routePoints);
                axios.delete(AuthService.DATA_URL+'RoutePoint/delete-for-request?id='+paramValue,
                    {headers: authHeader()})
                    .then((r)=>{
                        let idx: number =0;
                        PointsOfRoute.forEach(point => {
                                //console.log("Saving points.. - point ",point.id);
                                //console.log("point.country: ", point.country);
                                axios.post(AuthService.DATA_URL+'RoutePoint/',
//                                    axios.put(AuthService.DATA_URL+'RoutePoint/'+point.id,
                                    {
                                        //id:point.id,
                                        address: point.street,
                                        streetName: point.street,
                                        cityName: point.city,
                                        countryName: point.country,
                                        lat: point.lat,
                                        lng: point.lng,
                                        cityId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                                        countryId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                                        dateStart: point.PointDate ? (point.PointDate.substring(6,10)+"-"+point.PointDate.substring(3,5)+"-"+point.PointDate.substring(0,2)+"T"
                                            +(point.PointTime ? (point.PointTime.length>0 ?  point.PointTime : "00:00"): "00:00")+":00.000Z") : null,
                                        dateEnd: point.PointDate ? (point.PointDate.substring(6,10)+"-"+point.PointDate.substring(3,5)+"-"+point.PointDate.substring(0,2)+"T"
                                            +(point.PointTime ? (point.PointTime.length>0 ?  point.PointTime : "00:00") : "00:00")+":00.000Z") : null,
                                        orderInRoute: idx,
                                        routePointType: point.PointType,
                                        requestId: paramValue as GUID,
                                    }, {headers: authHeader()}
                                )
                                    .then((itog) =>{
                                        //console.log("itog",itog);

                                    });
                            idx=idx+1;
                        })
                        
                    });

                    //message.success('Naročilo je bilo shranjeno!'); 
                    history.push("/routes");
                    
                     
                }
            );
        //console.log(routePoints.length );
        //console.log(newReq);
    }

    

    const BackToList = () =>{
        history.push("/myroutes");
    }

    
 const[editRequestObj, seteditRequestObj] = useState<any>({});
 const[PointsObj, seteditPointsObj] = useState<any[]>([]);


 useEffect(() => {
     axios.get(AuthService.DATA_URL+'Request/'+paramValue, {headers: authHeader()})
         .then((response) => {
            // console.log("response.data: ", response.data);
             seteditRequestObj(response.data);
             seteditPointsObj(response.data.routePoints);

             form.setFieldsValue({
                 requesrNumber: response.data.requestNumber
             })



         })
         .catch(function (error) {
             if(error.response.status===401){
                 AuthService.logout();
                 history.push("/login");
                 window.location.reload();

             }
         });
 }, []);

 
    




    const [PointsOfRoute, setPointsOfRoutes] = useState<any[]>([]);

    const handleListChange=(posiitions? : any[])=>{
        if(posiitions){
            setPointsOfRoutes(
                posiitions ? posiitions.map((tr, index) => ({
                    key: Math.random().toString(),
                    id: tr.id,
                    city: tr.city,
                    country: tr.country,
                    street: tr.street,
                    lat: tr.lat,
                    lng: tr.lng,
                    PointDate: tr.PointDate,
                    PointTime: tr.PointTime,
                    PointType : tr.PointType
                }) ) : [])
        }
        console.log("posiitions: ", posiitions);
        console.log("PointsOfRoute: ", PointsOfRoute);

    }


    const checkRP=()=>{
        console.log("PointsOfRoute: ", PointsOfRoute);
    }


    if (ContractorOptions.length===0) return <div>Waiting for ContractorOptions data...</div>;
    if (initClient==="") return <div>Waiting for initClient data...</div>;


    return (
        <div>

            <Row>
                <Col span={24}>
                    <Form
                        form={formH}
                        name={"RequestHeader"}
                        autoComplete="off"
                        labelAlign="right"
                        labelWrap
                        wrapperCol={{ flex: 1 }}
                        colon={false}
                    >
                        <h1>{t("Urejanje_naročila")} №
                            <Form.Item style={{marginBottom: "5px", marginLeft: "10px", display: "inline-block", verticalAlign: "middle" }}
                                       name="requesrNumber"
                                       label=""

                            >
                                <Input placeholder=""
                                       defaultValue={newReq.requestNumber}
                                       onChange={blurNumber}   />
                            </Form.Item>
                        </h1>
                    </Form>
                </Col>
            </Row>
            
            <Row>
                <Col span={8}>
                    <Divider orientation="left" orientationMargin="0">{t("Osnovni_podatki")}</Divider>
                    <Form
                        form={form}
                        layout="horizontal"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        labelAlign="right"
                        name="nest-messages"
                        initialValues={{
                            ["Client"]:initClient,
                            ["Price"]: initPrice,
                        ["weight"]: initweight,
                            ["volume"]:initVolume,
                            ["description"]:initdescription,
                            ["Receiver"]: initReceiver,
                            ["Payer"]: initPayer,
                            ["Performer"] : initTransporter
                        }}
                    >
                                <Form.Item name="Client" label={t("Pošiljatelj")} >
                                    <Select options ={ContractorOptions}  onChange={ChangeClient} style={{maxWidth: "220px"}}/>
                                </Form.Item>
                        <Form.Item name="Receiver" label={t("Prejemnik")} >
                            <Select options ={ContractorOptions}  onChange={ChangeReceiver} style={{maxWidth: "220px"}}/>
                        </Form.Item>


                        <Form.Item name="RequestType" style={{width: "100%", marginLeft:"125px"}}>
                            <Checkbox checked={isPublicRequest} onClick={(e)=>{changeRequestType(e)}}>Открытый заказ</Checkbox>
                        </Form.Item>
                        
                        
                        
                        <Form.Item name="Performer" label={t("Izvajalec")} style={{display: showPerformerSelect}}>
                            <Select options ={TransporterOptions}  
                                    defaultValue={initTransporter}
                                    onChange={ChangePayer} style={{maxWidth: "220px"}}/>
                        </Form.Item>

                        <Form.Item name="Price" label={t("Cena")} >
                                    <InputNumber type="text"  suffix="€" onChange={(v: (number| null)) => onChangePrice(v)}/>
                                </Form.Item>

                        <Divider orientation="left" orientationMargin="0">{t("Opis_tovora")}</Divider>
                                <Form.Item name="weight" label={t("Teža")} rules={[{ type: 'number', min: 0 }]}>
                                    <InputNumber type="text" onChange={(v: (number| null)) => ChangeWeight(v)}   />
                                </Form.Item>
                                <Form.Item name="volume" label={t("Volumen")} rules={[{ type: 'number', min: 0 }]}>
                                    <InputNumber type="text" onChange={(v: (number| null)) => ChangeVolume(v)} />
                                </Form.Item>
                                <Form.Item name="description" label={t("Opis")}>
                                    <Input.TextArea rows={3} autoSize={{ minRows: 3, maxRows: 3 }} onChange={blurDescription}  />
                                </Form.Item>
                    </Form>

                </Col>

                <Col span={14} offset={2}>
                    <Divider orientation="left" orientationMargin="0">{t("Itinerarij")}</Divider>


                    <div>
                        <PointsList informParent={handleListChange} ReqId={paramValue ? paramValue : ""} />

                    </div>

                </Col>


            </Row>
            <Button className={"orangeButton"} onClick={CreateRequestHeader}
                    style={{ marginTop:"25px", /*marginRight:"50px",*/ display: "block"}}>
                {t("Shranjevanje_naročila")}
            </Button>

        </div>
    )
}