import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import axios from "axios/index";
import authHeader from "../../../Services/auth-header";
import request from "../../../types/request";
import {Spin} from "antd";
import {useTranslation} from "react-i18next";
import contractor from "../../../types/contractor";



interface RacunProps{

    RequestId?: string;
    forceReload? : boolean;
    isLoaded? :  (data: any) => void;
    positions? : any[]


}



export default function SauftragPage2  ({  RequestId, forceReload, isLoaded, positions} : RacunProps) {

    const history = useHistory();

    const user = AuthService.getCurrentUser();
    if (!user) {
        history.push("/login");
    }

    const {t} = useTranslation();

    const [request, setrequest] = useState<request>({
        id: "",
        requestNumber: "",
        price: 1200,
        distance: 0,
        loadingType: 0,
        rollingStockType: 0,
        requestStatus: 0,
        cargoDescription: "",
        clientId: "",
        receiverId: "",
        payerId: "",
        truckId: null,
        truck: null,
        priklopnik: null,
        requestType: null,
        weight: 0,
        volume: 0,
        priklopnikId: null,
        contractorId: "",
        driverId: null,
        driver: null,
        createdByContractorId: "",
        RoutePoints: []
    });


    const[myCompany, setmyCompany]= useState<contractor>({
        id:""
    });


    useEffect(() => {
        if(myCompany.id===""){
            axios.get(AuthService.DATA_URL+'Contractor/get-ownCompany', {headers: authHeader()})
                .then((response) => {
                    //                 console.log("response.data12121212121212: ", response.data)
                    setmyCompany(response.data);
                })
        }
        setrequest({
            id: "",
            requestNumber: "",
            price: 0,
            distance: 0,
            loadingType: 0,
            rollingStockType: 0,
            requestStatus: 0,
            clientId: "",
            receiverId: "",
            payerId: "",
            truckId: "",
            truck: null,
            priklopnik: null,
            requestType: null,
            priklopnikId: "",
            contractorId: "",
            weight: 0,
            volume: 0,
            driverId: "",
            driver: null,
            createdByContractorId: "",
            cargoDescription: "",
            RoutePoints: [],
            client : null,
            payer : null,
            receiver: null,
            subContractor : null
        });
        if(RequestId){
            if(request.id===""){
                axios.get(AuthService.DATA_URL + 'Request/' + RequestId, {headers: authHeader()})
                    .then((response) => {
                            //console.log("response.data: ", response.data)
                            setrequest({
                                id: response.data.id,
                                requestNumber: response.data.requestNumber,
                                price: response.data.price,
                                distance: response.data.distance,
                                loadingType: response.data.loadingType,
                                rollingStockType: response.data.rollingStockType,
                                requestStatus: response.data.requestStatus,
                                clientId: response.data.clientId,
                                receiverId: response.data.receiverId,
                                payerId: response.data.payerId,
                                truckId: response.data.truckId,
                                truck: response.data.track,
                                priklopnik: response.data.priklopnik,
                                requestType: response.data.requestType,
                                priklopnikId: response.data.priklopnikId,
                                contractorId: response.data.contractorId,
                                weight: response.data.weight,
                                volume: response.data.volume,
                                driverId: response.data.driverId,
                                driver: response.data.driver,
                                createdByContractorId: response.data.createdByContractorId,
                                cargoDescription: response.data.cargoDescription === undefined ? "" : response.data.cargoDescription,
                                RoutePoints: response.data.routePoints,
                                client : response.data.client,
                                payer : response.data.payer,
                                receiver: response.data.receiver,
                                subContractor : response.data.subContractor
                            });
                        }
                    )
                
            }

        }
    }, [forceReload, RequestId])



    console.log("sauftrag :", RequestId)
    if(myCompany.id==="" /*request.id===""*/){
        return <Spin/>
    }
    else{
        //console.log("request: ", request)
        if(isLoaded){
            isLoaded(true);
            //console.log("callback");
        }
    }



    return (
        <div>
            <div className="Sauftrag_main" id={"sauftragMain"} style={{width: "900px", /*height: "1685px",*/  margin: "0 auto", paddingTop: "32px",
               // backgroundImage: "url(/img/sauftrag_bg2.jpg)",
               // backgroundSize: "contain",
                backgroundColor:"#fff"
                //position: "absolute"

            }}>
                <table width="100%" className="sauftragTable" style={{fontFamily: "Arial", borderCollapse: "collapse", border: "1px solid #000", verticalAlign: "top" }}>
                    <tbody>
                    <tr>
                        <td style={{width: "450px", height: "75px", borderBottom: "1px solid #FFF", borderRight: "1px solid #000"}} rowSpan={2}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>1) Versender/Lieferant</span>
                            <span   style={{marginLeft: "150px", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>2) Lieferanten-Nr.</span>
                            <div className="sauftragText" style={{}}>
                                {request.client!==null ?  request.client.title ? request.client.title : request.client : ""}<br/>
                                {request.client!==null? request.client.adress1 ? request.client.adress1 : "" : ""}
                                {request.client!==null?  request.client.adress2 ? request.client.adress2 : "" : ""}<br/>
                            </div>
                        </td>
                        <td style={{height: "75px", borderBottom: "1px solid #000"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>3) Speditionsauftrags-Nr.</span>
                            <div   style={{marginTop: "30px", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>4) Nr. Versender beim Versanspediteur</div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{height: "35px", borderBottom: "1px solid #FFF"}}>
                            <span className="sauftragText" style={{fontWeight: "bold", fontSize: "22px", borderBottom: "1px solid #fff" }}>SPEDITIONSAUFTRAG</span>
                        </td>
                       
                    </tr>

                    <tr>
                        <td style={{borderTop: "1px solid #FFF", borderBottom: "1px solid #FFF", height: "40px", borderRight: "1px solid #000"}}>
                            <div   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>5) Beladestelle</div>
                            <div className="sauftragText">
                                {request.RoutePoints.length> 0? request.RoutePoints[0].streetName ? request.RoutePoints[0].streetName : "" : ""}
                                {request.RoutePoints.length> 0? request.RoutePoints[0].cityName ? " "+request.RoutePoints[0].cityName : "": ""}
                                {request.RoutePoints.length> 0? request.RoutePoints[0].countryName ? " "+request.RoutePoints[0].countryName : "" :""}
                            </div>
                        </td>
                        <td style={{borderTop: "1px solid #FFF", borderBottom: "1px solid #FFF"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>6) Datum</span> 
                            <span   style={{marginLeft: "180px", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>7) Relations-Nr.</span>
                            <div className="sauftragText">
                                {request.RoutePoints.length> 0? request.RoutePoints[0].dateStart ?
                                    request.RoutePoints[0].dateStart.substring(8,10)+"."+request.RoutePoints[0].dateStart.substring(5,7)+"."+request.RoutePoints[0].dateStart.substring(0,4)

                                    : t("nedatirano") : ""}
                            </div>

                        </td>
                    </tr>
                    <tr>
                        <td style={{borderTop: "1px solid #FFF", borderBottom: "1px solid #FFF", height:"40px", borderRight: "1px solid #000"}}>
                            <div   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>8) Sendungsnummer</div>
                            <div className="sauftragText">
                            </div>
                        </td>
                        <td style={{borderTop: "1px solid #FFF", borderBottom: "1px solid #FFF",}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>9) Versandspediteur </span>
                            <span   style={{marginLeft: "120px", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>10) Spediteur-Nr.</span>
                            <div className="sauftragText">
                                {request.subContractor!==null? request.subContractor.title ? request.subContractor.title : "" : ""}<br/>
                                {request.subContractor!==null?  request.subContractor.adress1 ? request.subContractor.adress1 : "" : ""}
                                {request.subContractor!==null? request.subContractor.adress2 ? request.subContractor.adress2 : "" : ""}<br/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{borderTop: "1px solid #FFF", borderBottom: "1px solid #FFF", borderRight: "1px solid #000"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>11) Empfânger</span>
                            <span   style={{marginLeft: "150px", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>12) Empfânger-Nr.</span>

                            <div className="sauftragText">
                                {request.receiver!==null?  request.receiver.title ? request.receiver.title : "" : ""}<br/>
                                {request.receiver!==null? request.receiver.adress1 ? request.receiver.adress1 : "": ""} 
                                {request.receiver!==null? request.receiver.adress2 ? " "+request.receiver.adress2 : "" : ""}<br/>
                            </div>

                        </td>
                        <td style={{height: "40px", borderTop: "1px solid #FFF", borderBottom: "1px solid #FFF"}}>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ borderTop: "1px solid #FFF", borderBottom: "1px solid #FFF", borderRight: "1px solid #000"}}>
                        </td>
                        <td style={{ borderTop: "1px solid #FFF", borderBottom: "1px solid #000"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>Telefon</span>
                            <span   style={{marginLeft: "180px", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>Fax</span>

                            <div   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>13) Bordero-/Ladeliste-Nr</div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{height: "75px", borderBottom: "1px solid #FFF", borderRight: "1px solid #000"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>14) Anliefer-/Abladestelle</span>
                            <div className="sauftragText">
                                {request.RoutePoints.length> 0? request.RoutePoints[1]? request.RoutePoints[1].streetName ? request.RoutePoints[1].streetName : "" : "" : ""}
                                {request.RoutePoints.length> 0? request.RoutePoints[1]? request.RoutePoints[1].cityName ? " "+request.RoutePoints[1].cityName : "": "" : ""}
                                {request.RoutePoints.length> 0? request.RoutePoints[1]? request.RoutePoints[1].countryName ? " "+request.RoutePoints[1].countryName : "": "" : ""}
                            </div>

                        </td>
                        <td style={{ borderBottom: "1px solid #FFF"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>15) Versandvermerk fur den Versandspediteur</span>
                        </td>
                    </tr>
                    <tr>
                        <td style={{borderTop: "1px solid #FFF", borderRight: "1px solid #000"}}>
                        </td>
                        <td style={{borderTop: "1px solid #FFF"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>16) Eintrefftermin</span>
                            <span   style={{marginLeft: "140px", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>17) Eintreffzeit</span>
                        </td>
                    </tr>
              
                    </tbody>
                </table>
                <div style={{border: "1px solid #000", marginTop: "-1px", height: "220px", padding: "0px 0px"}}>
                    <table width="100%" className="sauftragTable" style={{fontFamily: "Arial", borderCollapse: "collapse", marginTop: "-1px", borderRight: "1px solid #FFF", marginLeft: "-1px", verticalAlign: "top"}}>
                        <tbody>
                        <tr>
                            <td   style={{width: "212px", borderBottom: "1px solid #000", borderLeft: "none", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>18) Zeichen und Nr <br/>Packstük-Idenitikations-Nr</td>
                            <td   style={{width: "70px", borderBottom: "1px solid #000",fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>19) Anzahl</td>
                            <td   style={{width: "130px", borderBottom: "1px solid #000",fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>20) Packstük</td>
                            <td   style={{width: "28px", borderBottom: "1px solid #000",fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>21) SF</td>
                            <td   style={{fontFamily: "Arial", borderBottom: "1px solid #000",fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>22) Inhalt</td>
                            <td   style={{width: "100px", borderBottom: "1px solid #000",fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>23) Lademittel-Gewicht kg</td>
                            <td   style={{width: "100px",borderBottom: "1px solid #000", borderRight: "none", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>24) Brutto-Gewicht kg</td>
                        </tr>
                        <tr className="sauftragText">
                            <td></td>
                            <td>
                                <div style={{float: "right", marginRight: "10px"}}>1</div>
                            </td>
                            <td></td>
                            <td></td>
                            <td>{request?  request.cargoDescription ? request.cargoDescription : "" : ""}</td>
                            <td>
                                <span style={{float: "right", marginRight: "10px"}}>
                                    {request? request.weight ? request.weight : "": ""}
                                </span>
                            </td>
                            <td>
                                <span style={{float: "right", marginRight: "10px"}}>
                                    {request? request.weight ? request.weight : "": ""}
                                </span>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </div>
                <table width="100%" className="sauftragTable" style={{fontFamily: "Arial", borderCollapse: "collapse", marginTop: "-1px", border: "1px solid #000", verticalAlign: "top"}}>
                    <tbody>
                    <tr>
                        <td   style={{width: "212px",  textAlign:"right", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>Summe</td>
                        <td style={{width: "70px"}}>
                            <span   style={{width: "100px", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>25)</span>
                            <span className="sauftragText" style={{float: "right", marginRight: "10px"}}>1</span>

                        </td>
                        <td   style={{width: "158px", borderRight: "none", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>26) Raumenhalt cdm / Lademeter</td>

                        <td   style={{textAlign:"right", borderLeft: "none", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>Summent</td>
                        <td style={{width: "100px"}}>
                            <span   style={{width: "100px", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>27)</span>
                            <span className="sauftragText" style={{float: "right", marginRight: "10px"}}>{request? request.weight ? request.weight : "": ""}</span>
                        </td>
                        <td style={{width: "100px"}}>
                            <span   style={{width: "100px", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>28)</span>
                            <span className="sauftragText" style={{float: "right", marginRight: "10px"}}>{request? request.weight ? request.weight : "": ""}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>





                <div style={{border: "1px solid #000", marginTop: "-1px", height: "220px", padding: "0px 1px"}}>
                <table width="100%" className="sauftragTable" style={{fontFamily: "Arial", borderCollapse: "collapse", marginTop: "-1px", border: "1px solid #000", verticalAlign: "top"}}>
                    <tbody>
                    <tr>
                        <td style={{width: "326px", border: "none", height: "80px"}}>
                            <span   style={{width: "100px", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>29) Gefahrgut UN-Nr.:</span>
                            <span   style={{marginLeft: "80px", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>Gefahrgut Bezeichnung</span>
                        </td>
                        <td style={{width: "326px", border: "none"}}>
                            <span   style={{width: "100px", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}></span>
                        </td>
                        <td style={{width: "326px", border: "none"}}>
                            <span   style={{width: "100px", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}></span>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "326px", border: "none"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>Gefahrzettellmuster-Nr.
                            <br/><br/>
                                Hinweise auf Sondervorschriften
                            
                            </span>
                        </td>
                        <td style={{width: "326px", border: "none", height: "140px"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>Verpackungsgruppe</span>
                        </td>
                        <td style={{width: "326px", border: "none"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>Nettomasse kg/l</span>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "326px", height: "40px"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>30) Frankatur
                            
                            </span>
                        </td>
                        <td style={{width: "326px"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>31) Warenwert für Transportversicherung</span>
                        </td>
                        <td style={{width: "326px"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>32) Versender-Nachnahme</span>
                        </td>
                    </tr>

                    </tbody>
                </table>
                </div>
               
               
               
                <table width="100%" className="sauftragTable" style={{fontFamily: "Arial", borderCollapse: "collapse", marginTop: "-1px", border: "1px solid #000", verticalAlign: "top"}}>
                    <tbody>
                    <tr>
                        <td style={{ width: "450px", borderTop: "1px solid #000", borderBottom: "1px solid #FFF"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>33) Anlagen</span></td>
                        <td style={{ borderTop: "1px solid #000", borderBottom: "1px solid #FFF"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>34) Auftragsnummerkunde</span>
                            <span   style={{marginLeft: "150px", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>35)Kontierung</span>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "450px", borderTop: "1px solid #FFF", borderBottom: "1px solid #FFF"}}>
                            </td>
                        <td style={{ borderTop: "1px solid #FFF", borderBottom: "1px solid #FFF"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>36) Transportmittel-Nr.</span>
                            <div className="sauftragText" style={{ marginLeft: "10px", display: "inline-block"}}>{request.truck!==null? request.truck.regNumber ? request.truck.regNumber : "": ""}</div>
                            <div className="sauftragText" style={{marginLeft: "20px", display: "inline-block"}}> {request.priklopnik!==null? request.priklopnik ? request.priklopnik.regNumber : "": ""}</div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "450px", borderTop: "1px solid #FFF", borderBottom: "1px solid #FFF"}}>
                        </td>
                        <td style={{ borderTop: "1px solid #FFF", borderBottom: "1px solid #FFF"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>37) LKW-Code</span>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "450px", borderTop: "1px solid #FFF", borderBottom: "1px solid #FFF"}}>
                        </td>
                        <td style={{ borderTop: "1px solid #000", borderBottom: "1px solid #000"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>38) Versndart</span>
                            <span   style={{marginLeft: "150px", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>39)Abrechnungs-Schl.</span>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "450px", height: "40px", borderTop: "none", borderBottom: "1px solid #000"}}>
                        </td>
                        <td style={{ borderTop: "none", borderBottom: "none"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>40) Empfangsbestätigung des Warenempfängers:</span><br/>
                            <i style={{fontSize: "14px"}}>Obige Sendung vollständig und in ordnungsgemäßem Zustand erhalten</i>
                            
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "450px", height: "50px", borderTop: "none", borderBottom: "none"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>41) Empfangsbestätigung des Fahrers:</span><br/>
                            <i style={{fontSize: "14px"}}>Obige Sendung vollständig und in ordnungsgemäßem Zustand übernommen</i>
                        </td>
                        <td style={{ borderTop: "none", borderBottom: "none"}}>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "450px", borderTop: "1px solid #FFF", borderBottom: "1px solid #FFF"}}>
                        </td>
                        <td style={{ borderTop: "none", borderBottom: "1px solid #000"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>Firmenstempel/Unterschrift</span>
                        </td>
                    </tr>

                    </tbody>
                </table>
                <table width="100%" className="sauftragTable" style={{fontFamily: "Arial", borderCollapse: "collapse", marginTop: "-1px", border: "1px solid #000", verticalAlign: "top"}}>
                    <tbody>
                    <tr>
                        <td style={{ width: "450px", borderTop: "1px solid #FFF", borderBottom: "1px solid #FFF"}}>
                            <div   style={{display: "inline-block", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>Datum</div>
                            <div   style={{display: "inline-block",marginLeft:"160px", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>Uhrzeit</div>
                            <div   style={{display: "inline-block", marginLeft:"50px", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>Unterschrift</div>

                        </td>
                        <td style={{ borderTop: "none", borderBottom: "1px solid #FFF"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>42)Die sendung enthält</span>
                            <span   style={{marginLeft:"50px", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>davon getauscht</span>
                        </td>
                        <td style={{ borderTop: "none", width: "160px", borderBottom: "1px solid #FFF"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>46) Für</span>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "450px", borderTop: "1px solid #FFF", borderBottom: "1px solid #000"}}>
                        </td>
                        <td style={{ borderTop: "none", borderBottom: "1px solid #FFF"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>Euro-Flach-Pal. (FP)</span>
                            <span   style={{float: "right", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>Euro-Flach-Pal. (FP)</span>

                        </td>
                        <td style={{ borderTop: "none", width: "160px", borderBottom: "1px solid #FFF"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}></span>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "450px", borderTop: "1px solid #FFF", borderBottom: "1px solid #000"}}>
                            <div   style={{display: "inline-block", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>43) Es gelten die Algemeinen Deutschen 
                            Spediteurbedingungen (ADSp) in ihrer jeweils neuesten Fassung</div>
                        </td>
                        <td style={{ borderTop: "none", borderBottom: "1px solid #000"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>Euro-Flach-Pal. (GP)</span>
                            <span   style={{float: "right", fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>Euro-Flach-Pal. (GP)</span>

                        </td>
                        <td style={{ borderTop: "none", width: "160px", borderBottom: "1px solid #000"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}></span>
                        </td>
                    </tr>

                    </tbody>
                </table>
                <table width="100%" className="sauftragTable" style={{fontFamily: "Arial", borderCollapse: "collapse", marginTop: "-1px", border: "1px solid #000", verticalAlign: "top"}}>
                    <tbody>
                    <tr>
                        <td style={{textAlign: "center", height: "80px", borderBottom: "none"}}>
                            <span   style={{fontFamily: "Arial", fontWeight: "normal", fontSize: "12px", color: "#6B6E74"}}>Platz für Barcodierung</span>
                        </td>
                    </tr>
                    </tbody>
                </table>




            </div>
            <footer/>
        </div>)
}
