import React, { useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {Button,  Steps} from "antd";
import RoutePointForm from "./RoutePointForm";
import axios from "axios";
import authHeader from "../../../Services/auth-header";

import {dateToPoints, timeToPoints} from "../../../types/transporter-types";
import {useTranslation} from "react-i18next";


type RP ={
    city? : string;
    country? : string;
    street? : string;
    lat? : number;
    lng? : number;
    PointDate? : any;
    PointTime? : any;
    step? : any;
    dateText? : any;
    theDate? : any;
    theTime? : any;
    hourText? : any;
    isChanged? : any;
}

interface PointsListProps {
    ReqId?: string;
    forceReload?: boolean;
    isLoaded?: () => void;
    deleteFunc?: () => void;
    informParent? : (postions?: {
        keyStr? : string;
        city? : string;
        country? : string;
        street? : string;
        lat? : number;
        lng? : number;
        PointDate? : any;
        PointTime? : any;
        step? : any;
        PointType? : any;
    }[]) =>void;

}

const PointsList = ({ ReqId, forceReload, deleteFunc, informParent}: PointsListProps) => {

    const {t} = useTranslation();

    const history = useHistory();
    const user = AuthService.getCurrentUser();
    if (!user) {
        history.push("/login");
    }

    
    


    const [posNo, setposNo] = useState(1);
    const [pointsList, setPointsList] = useState<any[]>([]);
    const [listLoading, setslistLoading] = useState(false);

    const handleAddedPosition = (values: any)=>{
        console.log("handleAddedPosition :",pointsList )
        const index = pointsList.indexOf(pointsList.find(p=>p.keyStr===values.keyStr), 0);
        if( pointsList[index]){
            pointsList[index].city=values.city;
            pointsList[index].country=values.country;
            pointsList[index].street=values.street;
            pointsList[index].lat=values.lat;
            pointsList[index].lng=values.lng;
            pointsList[index].PointDate=values.PointDate;
            pointsList[index].PointTime=values.PointTime;
            pointsList[index].PointType=values.PointType;
            //pointsList[index].id=values.id;
            
        }
        if(informParent){
            informParent(pointsList.map((p)=>({
                city:p.city,
                country:p.country,
                street:p.street,
                lat:p.lat,
                lng:p.lng,
                PointDate:p.PointDate,
                PointTime:p.PointTime,
                PointType: p.PointType,
                id:p.id
            })));
        }
        
         
    }
    
    const deletePos =(poskey : string) =>{

        var d=new Date();
        var secondsStart = d.getTime();
        setposNo(secondsStart);
        //  console.log("delete poskey:",poskey,  " posId: ", posNo);

        setslistLoading(true);

        const index = pointsList.indexOf(pointsList.find(p=>p.keyStr===poskey), 0);
        //console.log("position to delete: ", positionsList[index]);

        if (index > -1) {
            pointsList.splice(index, 1);
        }
        //console.log("delete: ", poskey, "posId", posNo);
        setPointsList(pointsList);
        //console.log(positionsList);
        var d=new Date();
        var secondsStart2 = d.getTime();
        setposNo(secondsStart2);

        if(informParent){
            informParent(pointsList.map((p)=>({
                city:p.city,
                country:p.country,
                street:p.street,
                lat:p.lat,
                lng:p.lng,
                PointDate:p.PointDate,
                PointTime:p.PointTime,
                PointType: p.PointType,
                id:p.id
            })));
        }

        setslistLoading(false);
    }
    
    const addPosition = (defaults? : any)=>{
        console.log("adding defaults:", defaults);

        setslistLoading(true);

        var d=new Date();
        var secondsStart = d.getTime();
        setposNo(secondsStart);
        console.log("defaults:", defaults)
        pointsList.push({
            keyStr: defaults? defaults.keyStr : posNo.toString(),
            city:defaults? defaults.city : "",
            country:defaults? defaults.country: "",
            street:defaults? defaults.street :"",
            lat:defaults? defaults.lat : 0,
            lng:defaults? defaults.lng: 0,
            PointDate:defaults? defaults.PointDate : null,
            PointTime:defaults? defaults.PointTime: null,
            PointType: defaults? defaults.PointType: null,
            id: defaults? defaults.id : undefined,


            status: process,
            title :     "",
            description:
                <div>
                    <RoutePointForm keyStr={defaults? defaults.keyStr? defaults.keyStr: posNo.toString() :posNo.toString()}
                                    addFunc={handleAddedPosition}
                                    deleteFunc={deletePos}
                                    city ={defaults? defaults.city : ""}
                                    country={defaults? defaults.country: ""}
                                    street={defaults? defaults.street :""}
                                    lat={defaults? defaults.lat : 0}
                                    lng={defaults? defaults.lng: 0}
                                    PointDate={defaults? defaults.PointDate? defaults.PointDate : undefined : undefined}  //dateToPoints(new Date(defaults.PointDate)) : undefined : undefined}
                                    PointTime={defaults? defaults.PointTime? defaults.PointTime :undefined : undefined} //timeToPoints(new Date(defaults.PointTime)) : undefined : undefined}
                                    PointType={defaults? defaults.PointType: null}
                                    
                                    />
                </div>
        });
        setPointsList(pointsList);
        setslistLoading(false);
    }








    
    useEffect(()=>{
       // console.log("pointsList:", pointsList)
        if(pointsList.length===0){
            setslistLoading(true);

            if(ReqId ){
                axios.get(AuthService.DATA_URL+'Request/'+ReqId, {headers: authHeader()})
                    .then((response) => {
                        console.log("response.data.routePoints: ", response.data.routePoints);

                        if (pointsList.length === 0 && response.data.routePoints.length>0){
                            response.data.routePoints.forEach((point: any) => {
                                addPosition({
                                    keyStr: point.id,
                                    id:point.id,
                                    city: point.cityName,
                                    country: point.countryName,
                                    street: point.streetName,
                                    lat: point.lat,
                                    lng: point.lng,
                                    PointDate:  point.dateStart ? dateToPoints(new Date(point.dateStart)) : undefined, //point.dateStart,
                                    PointTime: point.PointTime ? timeToPoints(new Date(point.PointTime)) : undefined, //point.PointTime
                                    PointType: point.routePointType

                                })
                            });
                            if(informParent){
                                informParent(pointsList.map((p)=>({
                                    id:p.id,
                                    city:p.city,
                                    country:p.country,
                                    street:p.street,
                                    lat:p.lat,
                                    lng:p.lng,
                                    PointDate:p.PointDate,
                                    PointTime:p.PointTime,
                                    PointType: p.PointType
                                })));
                            }
                    }

                    });

            }else{
                addPosition();
            }
            
        }
        setslistLoading(false);


    }, )




    

    const checkRP=()=>{
        console.log("pointsList: ", pointsList);
    }

    if(listLoading)
        return(<div>...</div>)
    else
    return(
    <div style={{overflow: "scroll", maxHeight: "500px"}}>
        <Steps
            //progressDot
            current={posNo}
            direction="vertical"
            
            items={pointsList}
        />
        <Button style={{float: "right", marginRight: "10px"}}  onClick={()=>addPosition(undefined)}>{t("Dodajanje_točke_poti")}</Button>

        {/*
        <Button onClick={checkRP}>pointsList</Button>
        */}
        
    </div>
       

)
}
export default PointsList;