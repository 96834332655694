import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";
import axios from "axios/index";
import authHeader from "../../Services/auth-header";
import {Spin} from "antd/lib";
import contractor from "../../types/contractor";
import {useTranslation} from "react-i18next";
import Transaction from "../../types/Transaction";
import {ColumnsType} from "antd/es/table";
import {
    getArticleITypeClass,
    mapDocTypeEnumToString,
    MoneyToString,
    ServerdateToPoints,
    translateFA
} from "../../utils/helpers";
import {Button, Checkbox, Col, Input, Row, Space, Table, TableProps} from "antd";
import DDSLine from "../../types/Transaction";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
//import {useTranslation} from "react-i18next";





interface ddsTreeLine {
    financeArticle? : any|null
    //amount? : number | null
    jan? : number|null
    feb? : number|null
    mar? : number|null
    apr? : number|null
    May? : number|null
    jun? : number|null
    jul? : number|null
    aug? : number|null
    sep? : number|null
    oct? : number|null
    nov? : number|null
    dec? : number|null
    yearSum? : number|null
    children? : DDSLine[]
}

export const DDSPage = ()=>{

    const {t} = useTranslation();

    const history= useHistory();
    const user = AuthService.getCurrentUser();
    if(!user) {
        history.push("/login");
        window.location.reload();

    }
    
    
    const [lines, setLines] = useState<DDSLine[]|undefined>(undefined);

    const [treelines, setTreeLines] = useState<ddsTreeLine[]|undefined>(undefined);
   
    const[year, setYear]=useState(new Date().getFullYear())
    const[considerPlan, setConsiderPlan] = useState(false)
    
    
    useEffect(()=>{

        if(lines===undefined){
            axios.get(AuthService.DATA_URL + `Transaction/get-dds?year=`+year+"&considerPlan="+considerPlan
                , {headers: authHeader()})
                .then((response) => {
                    setLines(response.data);
                    
                    
                    var startBalance=0;
                    axios.get(AuthService.DATA_URL+`Transaction/get-current-balance`
                        ,{headers: authHeader()})
                        .then((b)=>{
                            startBalance=b.data
                        })

                    console.log("dds response.data:", response.data);
                    var mmline = [];
                    mmline?.push(
                        {financeArticle:{
                            title:t("Incomes"), 
                                incomeExpense: 1, id: "11111"
                            },
                            jan: response.data.filter((d:any)=>d.financeArticle.incomeExpense>0).reduce((n:any, {jan}:any) => n + jan, 0),
                            feb: response.data.filter((d:any)=>d.financeArticle.incomeExpense>0).reduce((n:any, {feb}:any) => n + feb, 0),
                            mar: response.data.filter((d:any)=>d.financeArticle.incomeExpense>0).reduce((n:any, {mar}:any) => n + mar, 0),
                            apr: response.data.filter((d:any)=>d.financeArticle.incomeExpense>0).reduce((n:any, {apr}:any) => n + apr, 0),
                            may: response.data.filter((d:any)=>d.financeArticle.incomeExpense>0).reduce((n:any, {may}:any) => n + may, 0),
                            jun: response.data.filter((d:any)=>d.financeArticle.incomeExpense>0).reduce((n:any, {jun}:any) => n + jun, 0),
                            jul: response.data.filter((d:any)=>d.financeArticle.incomeExpense>0).reduce((n:any, {jul}:any) => n + jul, 0),
                            aug: response.data.filter((d:any)=>d.financeArticle.incomeExpense>0).reduce((n:any, {aug}:any) => n + aug, 0),
                            sep: response.data.filter((d:any)=>d.financeArticle.incomeExpense>0).reduce((n:any, {sep}:any) => n + sep, 0),
                            oct: response.data.filter((d:any)=>d.financeArticle.incomeExpense>0).reduce((n:any, {oct}:any) => n + oct, 0),
                            nov: response.data.filter((d:any)=>d.financeArticle.incomeExpense>0).reduce((n:any, {nov}:any) => n + nov, 0),
                            dec: response.data.filter((d:any)=>d.financeArticle.incomeExpense>0).reduce((n:any, {dec}:any) => n + dec, 0),
                            yearSum: 0,

                            //jan:response.data.filter((d:any)=>d.financeArticle.incomeExpense>0)
                        children:response.data.filter((d:any)=>d.financeArticle.incomeExpense>0)
                    });
                    mmline[0].yearSum=mmline[0].jan+mmline[0].feb+mmline[0].mar+mmline[0].apr+mmline[0].may+mmline[0].jun+mmline[0].jul+mmline[0].aug+mmline[0].sep+mmline[0].oct+mmline[0].nov+mmline[0].dec

                    mmline?.push({
                        financeArticle:{title:t("Expenses"), incomeExpense: -1, id: "22222"},
                        jan: response.data.filter((d:any)=>d.financeArticle.incomeExpense<0).reduce((n:any, {jan}:any) => n + jan, 0),
                        feb: response.data.filter((d:any)=>d.financeArticle.incomeExpense<0).reduce((n:any, {feb}:any) => n + feb, 0),
                        mar: response.data.filter((d:any)=>d.financeArticle.incomeExpense<0).reduce((n:any, {mar}:any) => n + mar, 0),
                        apr: response.data.filter((d:any)=>d.financeArticle.incomeExpense<0).reduce((n:any, {apr}:any) => n + apr, 0),
                        may: response.data.filter((d:any)=>d.financeArticle.incomeExpense<0).reduce((n:any, {may}:any) => n + may, 0),
                        jun: response.data.filter((d:any)=>d.financeArticle.incomeExpense<0).reduce((n:any, {jun}:any) => n + jun, 0),
                        jul: response.data.filter((d:any)=>d.financeArticle.incomeExpense<0).reduce((n:any, {jul}:any) => n + jul, 0),
                        aug: response.data.filter((d:any)=>d.financeArticle.incomeExpense<0).reduce((n:any, {aug}:any) => n + aug, 0),
                        sep: response.data.filter((d:any)=>d.financeArticle.incomeExpense<0).reduce((n:any, {sep}:any) => n + sep, 0),
                        oct: response.data.filter((d:any)=>d.financeArticle.incomeExpense<0).reduce((n:any, {oct}:any) => n + oct, 0),
                        nov: response.data.filter((d:any)=>d.financeArticle.incomeExpense<0).reduce((n:any, {nov}:any) => n + nov, 0),
                        dec: response.data.filter((d:any)=>d.financeArticle.incomeExpense<0).reduce((n:any, {dec}:any) => n + dec, 0),

                        children:response.data.filter((d:any)=>d.financeArticle.incomeExpense<0)
                    })
                    mmline[1].yearSum=mmline[1].jan+mmline[1].feb+mmline[1].mar+mmline[1].apr+mmline[1].may+mmline[1].jun+mmline[1].jul+mmline[1].aug+mmline[1].sep+mmline[1].oct+mmline[1].nov+mmline[1].dec

                    mmline?.push({
                        financeArticle:{title:t("Balance"), incomeExpense: 0, id: "33333"},
                        jan: mmline[0].jan-mmline[1].jan,//response.data.reduce((n:any, {jan}:any) => n + jan, 0),
                        feb: mmline[0].feb-mmline[1].feb,//response.data.reduce((n:any, {feb}:any) => n + feb, 0),
                        mar: mmline[0].mar-mmline[1].mar,//response.data.reduce((n:any, {mar}:any) => n + mar, 0),
                        apr: mmline[0].apr-mmline[1].apr,//response.data.reduce((n:any, {apr}:any) => n + apr, 0),
                        may: mmline[0].may-mmline[1].may,//response.data.reduce((n:any, {may}:any) => n + may, 0),
                        jun: mmline[0].jun-mmline[1].jun,//response.data.reduce((n:any, {jun}:any) => n + jun, 0),
                        jul: mmline[0].jul-mmline[1].jul,//response.data.reduce((n:any, {jul}:any) => n + jul, 0),
                        aug: mmline[0].aug-mmline[1].aug,//response.data.reduce((n:any, {aug}:any) => n + aug, 0),
                        sep: mmline[0].sep-mmline[1].sep,//response.data.reduce((n:any, {sep}:any) => n + sep, 0),
                        oct: mmline[0].oct-mmline[1].oct,//response.data.reduce((n:any, {oct}:any) => n + oct, 0),
                        nov: mmline[0].nov-mmline[1].nov,//response.data.reduce((n:any, {nov}:any) => n + nov, 0),
                        dec: mmline[0].dec-mmline[1].dec,//response.data.reduce((n:any, {dec}:any) => n + dec, 0),

                       // children:response.data.filter((d:any)=>d.financeArticle.incomeExpense<0)
                    });
                    mmline[2].yearSum=mmline[2].jan+mmline[2].feb+mmline[2].mar+mmline[2].apr+mmline[2].may+mmline[2].jun+mmline[2].jul+mmline[2].aug+mmline[2].sep+mmline[2].oct+mmline[2].nov+mmline[2].dec

                    mmline?.push({
                        financeArticle:{title:t("Account"), incomeExpense: 0, id: "44444"},
                        jan: startBalance=startBalance+mmline[0].jan-mmline[1].jan,//response.data.reduce((n:any, {jan}:any) => n + jan, 0),
                        feb: startBalance=startBalance+mmline[0].feb-mmline[1].feb,//response.data.reduce((n:any, {feb}:any) => n + feb, 0),
                        mar: startBalance=startBalance+mmline[0].mar-mmline[1].mar,//response.data.reduce((n:any, {mar}:any) => n + mar, 0),
                        apr: startBalance=startBalance+mmline[0].apr-mmline[1].apr,//response.data.reduce((n:any, {apr}:any) => n + apr, 0),
                        may: startBalance=startBalance+mmline[0].may-mmline[1].may,//response.data.reduce((n:any, {may}:any) => n + may, 0),
                        jun: startBalance=startBalance+mmline[0].jun-mmline[1].jun,//response.data.reduce((n:any, {jun}:any) => n + jun, 0),
                        jul: startBalance=startBalance+mmline[0].jul-mmline[1].jul,//response.data.reduce((n:any, {jul}:any) => n + jul, 0),
                        aug: startBalance=startBalance+mmline[0].aug-mmline[1].aug,//response.data.reduce((n:any, {aug}:any) => n + aug, 0),
                        sep: startBalance=startBalance+mmline[0].sep-mmline[1].sep,//response.data.reduce((n:any, {sep}:any) => n + sep, 0),
                        oct: startBalance=startBalance+mmline[0].oct-mmline[1].oct,//response.data.reduce((n:any, {oct}:any) => n + oct, 0),
                        nov: startBalance=startBalance+mmline[0].nov-mmline[1].nov,//response.data.reduce((n:any, {nov}:any) => n + nov, 0),
                        dec: startBalance=startBalance+mmline[0].dec-mmline[1].dec,//response.data.reduce((n:any, {dec}:any) => n + dec, 0),

                        // children:response.data.filter((d:any)=>d.financeArticle.incomeExpense<0)
                    })
                    
                    setTreeLines(mmline)//tLines(response.data)
                    
                    console.log("mmline: ", mmline)

                })
                .catch(function (error) {
                    console.log("error: ", error)
                    if (error.response.status === 401) {
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                });
        }
    }, [year, considerPlan])
    
    
    
    const renderNumber = (text: any, record: any)=>{
     
        if(text===undefined){
            return(
                <span style={{color:"#999999", backgroundColor:"#999999", float: "right"}}> </span>
            )
        }
        
        if(Number(text)===0){
            return(
                 <span style={{color:"#999999", float: "right"}}>0€</span>
            )
        }
        else{
            return <span style={{float: "right"}}>{
                record.financeArticle.incomeExpense? record.financeArticle.incomeExpense>0?

                    <span style={{color: "green"}}>+ {text?.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}€</span>
                    :
                    record.financeArticle.incomeExpense<0?
                    <span style={{color: "#7918e6"}}>- {text?.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}€</span>  
                        :
                        "":
                        <span>{text?.toFixed(2)>0?
                            <span style={{color: "green"}}>+ {text?.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}€</span>
                            :
                            <span style={{color: "#7918e6"}}>{text?.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}€</span>
                        }</span>
            
            }</span>
            
        }
    }
    

    const columns: ColumnsType<ddsTreeLine> = [
        {
            title: " ",//t('Številka'),
            dataIndex: 'transactionDate',
            width: 210,
            fixed: "left",
            render: (text, record) => { 
                return (
                    <div>
                        {record.financeArticle.id.length===5 ? 
                            <span style={{/*color:"green",*/ fontWeight:"600"}}>{record.financeArticle.title}</span> :
                              <span>
                                    <span className="graydot" style={{backgroundColor: record.financeArticle.color}}/>
                                    {t(translateFA(record.financeArticle.title))}
                                </span>
                        }
                        

                    </div>
                )
            }
        },
        {
            title: t("January")+" "+year,
            dataIndex: 'jan',
            align: "right",
            width: 160,
            render: (text, record) => { return (record.financeArticle.id==="11111"||record.financeArticle.id==="22222") ? 
                <span style={{fontWeight: "600"}}> {renderNumber(text, record)} </span> : <span> {renderNumber(text, record)} </span>
            }
        },
        {
            title: t("February")+" "+year,
            dataIndex: 'feb',
            align: "right",
            width: 160,

            render: (text, record) => { return (record.financeArticle.id==="11111"||record.financeArticle.id==="22222") ?
                <span style={{fontWeight: "600"}}> {renderNumber(text, record)} </span> : <span> {renderNumber(text, record)} </span>
            }
        },
        {
            title: t("March")+" "+year,
            dataIndex: 'mar',
            align: "right",
            width: 160,
            render: (text, record) => { return (record.financeArticle.id==="11111"||record.financeArticle.id==="22222") ?
                <span style={{fontWeight: "600"}}> {renderNumber(text, record)} </span> : <span> {renderNumber(text, record)} </span>
            }
        },
        {
            title: t("April")+" "+year,
            dataIndex: 'apr',
            align: "right",
            width: 160,
            render: (text, record) => { return (record.financeArticle.id==="11111"||record.financeArticle.id==="22222") ?
                <span style={{fontWeight: "600"}}> {renderNumber(text, record)} </span> : <span> {renderNumber(text, record)} </span>
            }
        },
        {
            title: t("May"),
            dataIndex: 'may',
            align: "right",
            width: 160,
            render: (text, record) => { return (record.financeArticle.id==="11111"||record.financeArticle.id==="22222") ?
                <span style={{fontWeight: "600"}}> {renderNumber(text, record)} </span> : <span> {renderNumber(text, record)} </span>
            }
        },  
        {
            title: t("June")+" "+year,
            dataIndex: 'jun',
            align: "right",
            width: 160,
            render: (text, record) => { return (record.financeArticle.id==="11111"||record.financeArticle.id==="22222") ?
                <span style={{fontWeight: "600"}}> {renderNumber(text, record)} </span> : <span> {renderNumber(text, record)} </span>
            }
        },
        {
            title: t("July")+" "+year,
            dataIndex: 'jul',
            align: "right",
            width: 160,
            render: (text, record) => { return (record.financeArticle.id==="11111"||record.financeArticle.id==="22222") ?
                <span style={{fontWeight: "600"}}> {renderNumber(text, record)} </span> : <span> {renderNumber(text, record)} </span>
            }
        },
        {
            title: t("August")+" "+year,
            dataIndex: 'aug',
            align: "right",
            width: 160,
            render: (text, record) => { return (record.financeArticle.id==="11111"||record.financeArticle.id==="22222") ?
                <span style={{fontWeight: "600"}}> {renderNumber(text, record)} </span> : <span> {renderNumber(text, record)} </span>
            }
        },
        {
            title: t("September")+" "+year,
            dataIndex: 'sep',
            align: "right",
            width: 160,
            render: (text, record) => { return (record.financeArticle.id==="11111"||record.financeArticle.id==="22222") ?
                <span style={{fontWeight: "600"}}> {renderNumber(text, record)} </span> : <span> {renderNumber(text, record)} </span>
            }
        },
        {
            title: t("October")+" "+year,
            dataIndex: 'oct',
            align: "right",
            width: 160,
            render: (text, record) => { return (record.financeArticle.id==="11111"||record.financeArticle.id==="22222") ?
                <span style={{fontWeight: "600"}}> {renderNumber(text, record)} </span> : <span> {renderNumber(text, record)} </span>
            }
        },
        {
            title: t("November")+" "+year,
            dataIndex: 'nov',
            align: "right",
            width: 160,
            render: (text, record) => { return (record.financeArticle.id==="11111"||record.financeArticle.id==="22222") ?
                <span style={{fontWeight: "600"}}> {renderNumber(text, record)} </span> : <span> {renderNumber(text, record)} </span>
            }
        },
        {
            title: t("December")+" "+year,
            dataIndex: 'dec',
            align: "right",
            width: 160,
            render: (text, record) => { return (record.financeArticle.id==="11111"||record.financeArticle.id==="22222") ?
                <span style={{fontWeight: "600"}}> {renderNumber(text, record)} </span> : <span> {renderNumber(text, record)} </span>
            }
        },
        
        {
            title: t("Balance"),
            dataIndex: 'yearSum',
            align: "right",
            fixed: "right",
            width: 160,
            render: (text, record) => { return (record.financeArticle.id==="11111"||record.financeArticle.id==="22222") ?
                <span style={{fontWeight: "600"}}> {renderNumber(text, record)} </span> : <span> {renderNumber(text, record)} </span>
            }
        },
    ];

    /*
    // rowSelection objects indicates the need for row selection
    type TableRowSelection<T> = TableProps<T>['rowSelection'];
    const rowSelection: TableRowSelection<ddsTreeLine> = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            console.log(selected, selectedRows, changeRows);
        },
    };

    const [checkStrictly, setCheckStrictly] = useState(false);


     */
    return <>

        <h1 style={{marginBottom: "20px"}}>{t("DDS_report")}</h1>
        <Row gutter={30}>
            <Col span={4}>
                <div style={{

                    marginBottom: "10px"}}>

                    <Space.Compact block>
                        <Button icon={<LeftOutlined />} onClick={()=> {
                            setLines(undefined);
                            setYear(year - 1)
                        }} />
                        <Input disabled={true}
                               style={{ /*width: "100px",*/ backgroundColor:"#fff", color: "#000", textAlign:"center" }}
                               value={year}
                        />
                        <Button icon={<RightOutlined/>} onClick={()=> {
                            setLines(undefined);
                            setYear(year + 1)
                        }} />

                    </Space.Compact>


                </div>
            </Col>
            <Col style={{paddingTop:"5px"}}>
                <Checkbox 
                    value={considerPlan}
                    onChange={(e)=> {
                        setLines(undefined)
                        setConsiderPlan(e.target.checked)
                    //console.log("clicked")
                }}>{t("Consider_planned")}</Checkbox>

            </Col>
        </Row>
        {treelines?
        <Table className={"ddstable"} 
               locale={{
                    triggerDesc: t('Kliknite_za_razvrščanje_padajoče'),
                    triggerAsc: t('Kliknite_za_razvrščanje_naraščajoče'),
                    cancelSort: t('Prekliči_razvrščanje')         
        }} 
               columns={columns} 
               rowClassName={(record, index)=>record.financeArticle.id.length===5 ? "subheader":  "ddstable"} 
               expandIcon={()=><span> </span>} 
               dataSource={treelines} 
               defaultExpandAllRows={true} 
               size={"middle"}
               pagination={false} 
               scroll={{ y: 'calc(72vh)' }}
        />
        :
        <Spin/>}
    </>
}