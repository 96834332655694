import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";
import {useTranslation} from "react-i18next";
import {
    Row, Col, Card, Table, Space, Button, Modal, Divider, Affix,
    Badge, Form, Input, Tooltip, Checkbox, Select, Switch, message, Dropdown, Spin,
} from "antd";
import type {MenuProps} from "antd";
import {ColumnsType} from "antd/es/table";
import Transaction from "../../types/Transaction";
import axios from "axios";
import authHeader from "../../Services/auth-header";
import {
    PlusOutlined,
    MinusOutlined,
    LeftCircleOutlined,
    LeftOutlined,
    RightOutlined, ExclamationCircleFilled, DownOutlined,
    SafetyCertificateOutlined,
    FileTextOutlined
} from '@ant-design/icons';
import IncomeForm from "./TransactionsComponents/IncomeForm";
import {dateToPoints} from "../../types/transporter-types";
import {
    dateForServerFromDate,
    getArticleITypeClass,
    mapDocTypeEnumToString,
    //mapMonthNumberToString,
    MoneyToString,
    ServerdateToPoints, translateFA
} from "../../utils/helpers";

//import { Doughnut, Bar, Chart } from 'react-chartjs-2';
//import ChartDataLabels from 'chartjs-plugin-datalabels';
//Chart.register(CategoryScale);

import ReactECharts from 'echarts-for-react';
import {TransactionsContext} from "./TransactionsComponents/TransactionsContext";
import {publish, subscribe, unsubscribe} from "../../Components/Notifications/NotificationContext";
import UploadDoc from "../docs/DocComponents/UploadDoc";
import CommonListFilter from "../../types/CommonListFilter";
import dayjs from "dayjs";
import PlanningForm from "./TransactionsComponents/PlanningForm";







const TransactionsPage = () => {




    const {t} = useTranslation();



    const mapMonthNumberToString=(i: number)=>{
       

        switch ( i ) {
            //case 0: return t("January");
            case 1: return t("January");
            case 2:return t("February")
            case 3:return t("March")
            case 4: return t("April");
            case 5:return t("May")
            case 6:return t("June")
            case 7: return t("July");
            case 8:return t("August")
            case 9:return t("September")
            case 10: return t("October");
            case 11:return t("November")
            case 12:return t("December")
            default:return "Месяц"

        }
    }
    
    

    const history= useHistory();
    const user = AuthService.getCurrentUser();
    if(!user) {
        history.push("/login");
        window.location.reload();

    }
    const [searchText, setSearchText] = useState("")
    
    
    const [isIncomeOpen, setisIncomeOpen] = useState(false)
    const [incExp, setIncExp] = useState(1);
    //const [isExpenseOpen, setisExpenseOpen] = useState(false)

    
    const accept=(transactionid:string | null | undefined)=>{

        axios.put(AuthService.DATA_URL+'Transaction/accept-planned?id='+transactionid
            ,"", {headers: authHeader()}
        ).then((result=>{
          //  console.log(result)
            if(result.data==="Saved"){
                getTransactions(listFilter);
            }

        }))

        //console.log(transactionid);
    }



    const columns: ColumnsType<Transaction> = [
            {
                title: t("Date"),
                dataIndex: 'transactionDate',
                width: 110,
                filteredValue :[searchText],
                onCell: (record, rowIndex) => {
                    return {
                        onClick: (event) => {onRowClick(record)}, // click row
                    };
                },
                onFilter: (value, record) => {
                    return (
                        //record.financeArticle!==null ? String(t(translateFA(record.financeArticle.title))).toLowerCase().includes(value.toString().toLowerCase()):[1].includes(1)||
                        String(t(mapDocTypeEnumToString(record.doc?.documentType))).toLowerCase().includes(value.toString().toLowerCase())||
                        String(record.doc?.docNumber).toLowerCase().includes(value.toString().toLowerCase())||
                        String(record.request?.requestNumber).toLowerCase().includes(value.toString().toLowerCase())||
                        String(record.description).toLowerCase().includes(value.toString().toLowerCase())||
                        String(record.request?.externalRequestNumber).toLowerCase().includes(value.toString().toLowerCase())||
                        String(record.counterPart?.title).toLowerCase().includes(value.toString().toLowerCase())
                )
                },
                render: (text, record) => {
                    return (
                        <div className={record.isPlan 
                        || (record.transactionDate&&new Date(record.transactionDate.valueOf())>new Date())? "isPlan": ""}>
                            {ServerdateToPoints(text)}
                            
                        </div>
                    )
                }
            },
            {
                title: t("Amount"),
                dataIndex: 'amount',
                width: 160,
                onCell: (record, rowIndex) => {
                    return {
                        onClick: (event) => {onRowClick(record)}, // click row
                    };
                },
                render: (text, record) =>
                    <span style={{float: "right"}} className={record.isPlan || (record.transactionDate&&new Date(record.transactionDate.valueOf())>new Date())? "isPlan": ""}>{
                        record.incomeExpense? record.incomeExpense>0?
                            <span style={{color: "green"}}>+ {text.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}€</span>
                            :
                            <span style={{color: "#7918e6"}}>- {text.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}€</span>  :""     }</span>
                
            },
            {
                title: t("Opis"),//t('Itinerarij'),
                dataIndex: 'description',
                width: 530,
                onCell: (record, rowIndex) => {
                    return {
                        onClick: (event) => {onRowClick(record)}, // click row
                    };
                },
                render: (text, record)=>
                    <span className={record.isPlan || (record.transactionDate&&new Date(record.transactionDate.valueOf())>new Date())? "isPlan": ""}>
                        {text}
                        <br/>
                        <div style={{color:"#666", fontSize: "13px", verticalAlign: "top"}}>
                            {record.financeArticle? 
                                <div style={{width: "190px", display: "inline-block", verticalAlign: "top"}}><span className="graydot" style={{backgroundColor: record.financeArticle.color}}/>{t(translateFA(record.financeArticle.title))}</div>
                                    :
                                <div style={{width: "190px", display: "inline-block"}} ><span className={getArticleITypeClass(0)}/>{t("Article_not_specified")}</div>
                            }
                            <div style={{ display: "inline-block",  verticalAlign: "top"}}>
                            {record.request?
                                <div style={{marginLeft:"10px", /*width: "120px"*/}}>
                                    <SafetyCertificateOutlined/>
                                    <span style={{marginLeft:"18px"}}>{t("Naročilo")+" "}                                 
                                        {record.request.createdByContractorId===user.contractorId? record.request.requestNumber : record.request.externalRequestNumber}
                                    </span>
                                </div>
                                : <span></span>}
                            {record.doc?
                                <span  style={{marginLeft:"10px"}}>   
                                    <FileTextOutlined/>
                                    <span style={{marginLeft:"18px"}}>
                                        {t(mapDocTypeEnumToString(record.doc.documentType))+" "+record.doc.docNumber+" "+t("from")+" "+ServerdateToPoints(record.doc.documentDate) }
                                    </span>
                                 </span>
                                : <span></span>
                                
                            }
                            </div>
                        </div>
                    
                    </span>,
            },
            {
                title: t("Nasprotna_stranka"),//t('Itinerarij'),
                dataIndex: 'counterpart.title',
                width: 190,
                onCell: (record, rowIndex) => {
                    return {
                        onClick: (event) => {onRowClick(record)}, // click row
                    };
                },

                render: (text, record) =>
                    <span className={record.isPlan || (record.transactionDate&&new Date(record.transactionDate.valueOf())>new Date())? "isPlan": ""}>{record.counterPart?  record.counterPart.title : ""}</span>,

            },
            {
                title: ' ',
                key: 'operation',
                fixed: 'right',
                //width: 70,

                render: (text, record) =>
                    <span>
                        {record.isPlan &&record.transactionDate&&new Date(record.transactionDate.valueOf())<new Date() ? 
                            <Button style={{zIndex:5000}} onClick={()=>accept(record.id)}>{t("Accept")}</Button>
                            : ""
                        }
                    </span>,
            }
        ];

    const [transactions, setTransactions]=useState<Transaction[]>([]);
    const [anyTransactions, setanyTransactions]=useState("");
    const [refreshTable, setrefreshTable]=useState(true);
    
    const[totalIncome, settotalIncome]=useState(0);
    const[totalExpense, settotalExpense]=useState(0);
    const[totalSaldo, settotalSaldo]=useState(0);


    const[donutLabels, setdonutLabels]=useState<string[]>([]);
    const[donatData, setdonatData]=useState<{value: number, name: string, itemStyle :{color: string}}[]>([]);
    const[donatColors, setdonatColors]=useState<string[]>([]);


    const[barData, setbarData]=useState<{value: number, name: string, value1: number, value2: number, itemStyle :{color: string, borderColor : string}}[]>([]);

    const[s1Data, sets1Data]=useState<{value: number, name: string, value1: number, value2: number, itemStyle :{color: string, borderColor : string}}[]>([]);
    const[s2Data, sets2Data]=useState<{value: number, name: string, value1: number, value2: number, itemStyle :{color: string, borderColor : string}}[]>([]);


    const[s3Data, sets3Data]=useState<{value: number, name: string, value1: number, value2: number, itemStyle :{color: string, borderColor : string}}[]>([]);



    const[borderData, setborderData]=useState<{value: number, name: string, itemStyle :{color: string, borderColor : string}}[]>([]);


    const[markDay, setMarkDay]=useState<number>(0);
    const[BalanceHeader, setBalanceHeader]=useState<string>("");


    const[barLabels, setbarLabels]=useState<string[]>([]);

    
   // const[choosedMonth, setChoosedMonth] = useState<number>(localStorage.getItem("selectedMonth") ? Number(localStorage.getItem("selectedMonth")) : (new Date().getMonth() + 1))//((new Date().getMonth() + 1))
    const[choosedYear, setchoosedYear] = useState<number>(localStorage.getItem("selectedYear") ? Number(localStorage.getItem("selectedYear")) : new Date().getFullYear())//((new Date().getFullYear() ));
    const[choosedMonthName, setMonthName]=useState<string>(
        mapMonthNumberToString((localStorage.getItem("selectedFromDate") ? 
            Number((dayjs(localStorage.getItem("selectedFromDate")).month()))
            : (new Date().getMonth() + 1))) + " "+(localStorage.getItem("selectedFromDate") ? "--" :  new Date().getFullYear().toString()))

    
    
    
    const[choosedFromDate, setChoosedFromDate] = useState(
        localStorage.getItem("selectedFromDate") ? dayjs(localStorage.getItem("selectedFromDate")) :
        dayjs(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1)))
    const[choosedToDate, setChoosedToDate] = useState(
        localStorage.getItem("selectedToDate") ? dayjs(localStorage.getItem("selectedToDate")) :
        dayjs(new Date((new Date()).getFullYear(), (new Date()).getMonth()+1, 0)))
    
    
  //  console.log("choosedFromDate, choosedToDate: ", choosedFromDate, choosedToDate)
    
    const [rowTransctionId, setRowTransctionId]=useState<string>("00000000-0000-0000-0000-000000000000");


    const[lateConfirm, setLateConfirm]=useState(0);
    const[noFA, setNoFA]=useState(0);
    
    const[listFilter, setListFilter] = useState<CommonListFilter | undefined>(
        {
            dateFrom:dateForServerFromDate(choosedFromDate),
                //dateForServerFromDate(dayjs(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1))),
            dateTo:dateForServerFromDate(choosedToDate),
                //dateForServerFromDate(dayjs(new Date((new Date()).getFullYear(), (new Date()).getMonth()+1, 0)))
        
        })

    const getTransactions=( tfilter?: CommonListFilter)=>{

        setisLoading(true);
        console.log("tfilter: ", tfilter)
        if(tfilter){
            console.log("filter")
            axios.get(AuthService.DATA_URL + `Transaction?filter.dateFrom=`+tfilter.dateFrom+'&filter.dateTo='+tfilter.dateTo
                , {headers: authHeader(),
                })
                .then((response) => {
                    setTransactions(response.data)
                    setanyTransactions("1");
                    let prihod = 0;
                    let rashod = 0;
                    let saldo = 0;
                    response.data.forEach((pos: Transaction) => {
                        if (pos !== null) {
                            if (pos.incomeExpense !== null && pos.incomeExpense !== undefined) {
                                if (pos.incomeExpense > 0) {
                                    prihod = prihod + (pos.amount ? pos.amount : 0)
                                } else {
                                    rashod = rashod + (pos.amount ? pos.amount : 0)
                                }
                            }
                        }
                    })
                    saldo = prihod - rashod;
                    settotalIncome(prihod);
                    settotalExpense(rashod);
                    settotalSaldo(saldo)
                    setisLoading(false)

                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                });
            
        }
        /*
        else{
            if(month&&year){
               // console.log("month&&year")
                axios.get(AuthService.DATA_URL + `Transaction?month=` + choosedMonth + '&year=' + choosedYear
                    , {headers: authHeader()})
                    .then((response) => {

                       // console.log("list response.data: ->'", anyTransactions,"'<-",  response.data)
                        setTransactions(response.data)
                        setanyTransactions("1");
                        let prihod = 0;
                        let rashod = 0;
                        let saldo = 0;
                        response.data.forEach((pos: Transaction) => {
                            if (pos !== null) {
                                if (pos.incomeExpense !== null && pos.incomeExpense !== undefined) {
                                    if (pos.incomeExpense > 0) {
                                        prihod = prihod + (pos.amount ? pos.amount : 0)
                                    } else {
                                        rashod = rashod + (pos.amount ? pos.amount : 0)
                                    }
                                }
                            }
                        })
                        saldo = prihod - rashod;
                        settotalIncome(prihod);
                        settotalExpense(rashod);
                        settotalSaldo(saldo)
                        setisLoading(false)

                    })
                    .catch(function (error) {
                        if (error.response.status === 401) {
                            AuthService.logout();
                            history.push("/login");
                            window.location.reload();

                        }
                    });
            }
        }
        */
        
       
    }
    
    
    

    const [isLoading, setisLoading]=useState(false)
    
    useEffect(()=>{
      //  console.log("choosedMonth: ", choosedMonth)
        if(anyTransactions===""){
            setanyTransactions("--");
            
            if(!listFilter){
                var date = new Date();
                var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                
            //    console.log(firstDay, lastDay)
               //setMonthName()
                /*
                if(localStorage.getItem("selectedMonth")){
                    setListFilter(prev => ({
                        ...prev,
                        dateFrom: dateForServerFromDate(dayjs(new Date(date.getFullYear(), choosedMonth-1 , 1))),
                        dateTo: dateForServerFromDate(dayjs(new Date(date.getFullYear(), choosedMonth, 0))),
                        selectedDates: ""
                    }));                    
                }
                
                 */

                
                
            }
            
            
            getTransactions(listFilter)


            subscribe("onCloseTransactionModal", (data: any) => IndexOnCloseModal());
            setisLoading(true);
            setTransactions([]);
            axios.get(AuthService.DATA_URL+`Transaction/get-current-balance`
                ,{headers: authHeader()})
                .then((b)=>{
                setBalanceHeader(MoneyToString(b.data))

            })
                .catch(function (error) {
                    console.log("error: ", error);
                    if(error.response.status===401){
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                });
            
            


            axios.get(AuthService.DATA_URL
                +"Transaction/get-dougnut?filter.dateFrom="+listFilter?.dateFrom+"&filter.dateTo="+listFilter?.dateTo 
                , {headers: authHeader()})
                .then((result: any)=>{
                    //console.log("donut result: ",result)
                    setdonutLabels(result.data.map((lab: any)=>(lab.label)))
                    setdonatData(result.data.map((lab: any)=>({
                        value : lab.data, 
                        name : t(translateFA(lab.label)),
                        itemStyle: {color: lab.color}
                    })))
                    setdonatColors(result.data.map((lab: any)=>(lab.color)))
                    
                    
                });
            axios.get(AuthService.DATA_URL+"Transaction/get-scenario2?filter.dateFrom="+listFilter?.dateFrom+"&filter.dateTo="+listFilter?.dateTo
                , {headers: authHeader()})
                .then((res: any)=>{
                    console.log(res.data)
                    var today=new Date();
                    var dd=today.getDate();
                    var mm=today.getMonth()+1
                    sets2Data(res.data.map((lab: any,idx :number)=>({
                        value :  choosedFromDate.month()+1< mm?
                            "-" :
                            idx<dd-1? "-" : lab.value,
                        value1 : lab.incomeValue,
                        value2 : lab.expenseValue,
                    })))
                });

            axios.get(AuthService.DATA_URL+"Transaction/get-scenario3?filter.dateFrom="+listFilter?.dateFrom+"&filter.dateTo="+listFilter?.dateTo
                , {headers: authHeader()})
                .then((res: any)=>{
                    console.log(res.data)
                    var today=new Date();
                    var dd=today.getDate();
                    var mm=today.getMonth()+1
                    sets3Data(res.data.map((lab: any,idx :number)=>({
                        value :  choosedFromDate.month()+1< mm?
                            "-" :
                            idx<dd-1? "-" : lab.value,
                        value1 : lab.incomeValue,
                        value2 : lab.expenseValue,
                    })))

                })
            




            axios.get(AuthService.DATA_URL+"Transaction/get-bar?filter.dateFrom="+listFilter?.dateFrom+"&filter.dateTo="+listFilter?.dateTo
                , {headers: authHeader()})
                .then((result: any)=>{
                    setbarLabels(result.data.map((lab: any, idx : number)=>(idx+1)));
                    var today=new Date();
                    var dd=today.getDate();
                    var mm=today.getMonth()+1
                    if(choosedFromDate.month()+1 ===mm){
                        setMarkDay(dd-1);
                    }
                    else{
                        setMarkDay(100);
                    }

                    sets1Data(result.data.map((lab: any,idx :number)=>({
                        value :  choosedFromDate.month()+1< mm?
                            "-" :
                            
                            idx<dd-1? "-" : lab.value,
                        value1 : lab.incomeValue,
                        value2 : lab.expenseValue,
                    })))
                    
                    setbarData(result.data.map((lab: any,idx :number)=>({
                        value : lab.value,
                        value1 : lab.incomeValue,
                        value2 : lab.expenseValue,
                        itemStyle: {color: lab.value>0?                     //выше нуля или ниже отметки 0

                                choosedFromDate.month()+1< mm? 
                                    "#1c78846e"
                                    :
                                    choosedFromDate.month()+1===mm? 
                                        idx<dd? "#1c78846e"  //d3f3ff
                                            : "rgba(189,189,189,0.2)"//"transparent" //rgba(189,189,189,0.2)
                                        : "rgba(189,189,189,0.2)"
                                            
                                    : "#ffebeb" //#ffebeb
                                        ,
  
                        
                            borderColor: lab.value>0? "#fff" : "transparent"} //#2a7ebb #d3f3ff 1990ff
                    })))
                    setborderData(result.data.map((lab: any)=>({
                        value : 0.5,
                        itemStyle: {color: lab.value>0? "#fff" : "transparent", borderColor: lab.value>0?  "#fff" : "transparent"} //#2a7ebb
                    })))


                    

                })


        }

        return () => {
            unsubscribe("onCloseTransactionModal", (data: any) => IndexOnCloseModal());
        }

        
        },[anyTransactions] )




    const IndexOnCloseModal=()=>{
        setRowTransctionId("00000000-0000-0000-0000-000000000000");
        //getTransactions(choosedMonth, choosedYear);
        setanyTransactions("")
        
    }



    const baroptions = {
        
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            position: function (point:any) {
                // fixed at top
                return [point[0], '10%'];
            },
            formatter: function (params: any) {
                //console.log(params);
                return `<div style="text-align: right"><b>${params[0].name} ${choosedMonthName} <br />           
              ${MoneyToString(params[0].data.value)}€ </b><br />
              <div style="display: inline-block">
              <span style="font-size: 12px; color: #a3c5ca">${t("Incomes")}: +${MoneyToString(params[0].data.value1)}€<br />
              ${t("Expenses")}: -${MoneyToString(params[0].data.value2)}€<br /></span>
              </div>

              
              </div>`
                {
                    //<div style="display: inline-block; margin-left: 7px; color: #02BBD5">
                    //<span style="font-size: 12px;"> +${MoneyToString(params[2].data.value1)}€<br />
                    // -${MoneyToString(params[2].data.value2)}€<br /></span>
                    //</div>
                    //<div style="display: inline-block; margin-left: 7px">
                    //<span style="font-size: 12px; color: #BC82B7"> +${MoneyToString(params[1].data.value1)}€<br />
                    // -${MoneyToString(params[1].data.value2)}€<br /></span>
                    //</div>
                }
            }
        },
        grid: {
            left: '0%',
            right: '0%',
            bottom: '0%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                distance:0,
                data: barLabels,// ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                axisTick: {
                    alignWithLabel: true
                }
            }
        ],
        yAxis: [
            {
                show: false
            }
        ],
        
        series: [
            {
                name: 'Баланс',
                type: 'bar',
                stack: 'yes',
                barWidth: '95%',
                barCategoryGap: '1%',
                data: barData, 
                markLine : {
                    silent: true, // ignore mouse events
                    symbol: 'circle',
                    data : [
                        {
                            xAxis: markDay,
                            itemStyle:{normal:{color:'#a52190'}},
                            label: {
                                color: 'transparent'
                            }
                        },
                    ]
                }
            },
            /*
            {
                name: t("Scenario")+ ' 3',
                type: 'line',
                color: "#BC82B7",
                data: s3Data,
            },
            {
                name: t("Scenario")+ ' 2',
                type: 'line',
                color: "#02BBD5",
                data: s2Data,
            },
            {
                name: t("Scenario")+ ' 1',
                type: 'line',
                color: "#a3c5ca",
                data: s1Data,
            },
            
             */
            {
                name: 'topBorder',
                stack: 'yes',
                type: 'bar',
                color: '#00acff',
                data: borderData,
                tooltip: { show: false }
            },
        ]
        
        
    };
    
    const donutOptions ={
        tooltip: {
            trigger: 'item'
        },
        /*
        legend: {
            top: '5%',
            left: 'center'
        },
        
         */
        series: [
            {
               // name: 'Access From',
                type: 'pie',
                radius: ['50%', '90%'],
                avoidLabelOverlap: false,
                padAngle: 5,
                itemStyle: {
                    borderRadius: 0
                },
                tooltip:{
                    show:false,
                },
                label: {
                    show: false,
                    position: 'center',

                },
                emphasis: {
                    label: {
                        show: true,
                        formatter: function (params: any) {
                            
                            return `${params.data.name}: ${params.data.value}€`
                        } //${MoneyToString(params[0].data.value)}
                        //fontSize: 40,
                        //fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data:  donatData
            }
        ]
    };
    
    

    const [container, setContainer] = React.useState<HTMLDivElement | null>(null);
    

    const [rowTransction, setRowTransction]=useState<Transaction|null>(null);


    const[reloadForm, setReloadForm]=useState(true)
    
    const onRowClick=(row: any)=>{
        //console.log(row.incomeExpense)
        setRowTransctionId(row.id);
        setreload(!reload);
        setIncExp(row.incomeExpense)
        setisIncomeOpen(true);
        

        
    }
    
    const CloseModals=()=>{
        setisIncomeOpen(false);
    }
    
    
    
    const changeMonnth=(i:number)=>{

        var date = new Date();
        var month:number=date.getMonth();
        var year:number=date.getFullYear();
       // console.log("choosedFromDate: ", choosedFromDate.year(), choosedFromDate.month(), 1)
        console.log("startDate: ", new Date(choosedFromDate.year(), choosedFromDate.month()+i, 1))
        console.log("endDate: ", new Date(choosedFromDate.year(), choosedFromDate.month()+1+i, 0))

        
        var start =dayjs(new Date(choosedFromDate.year(), choosedFromDate.month()+i, 1));
        var end = dayjs(new Date(choosedFromDate.year(), choosedFromDate.month()+1+i, 0));
        setChoosedFromDate(start)
        setChoosedToDate(end)

        setListFilter(prev => ({
            ...prev,
            dateFrom: dateForServerFromDate(start),
            dateTo: dateForServerFromDate(end),
            selectedDates: ""
        }));
        //setChoosedMonth(12)
        
        console.log(choosedFromDate.month()+i+1===0? 12 : choosedFromDate.month()+i+1);
        setMonthName((mapMonthNumberToString(choosedFromDate.month()+i+1===0? 12 :

            choosedFromDate.month()+i+1===13? 1 :
            choosedFromDate.month()+i+1))+" "+start.year())
        
        
        
        /*
        if(choosedMonth+i<0){
            year=choosedYear-1;
            month=12;
            setchoosedYear(choosedYear-1)
            localStorage.setItem("selectedYear", (choosedYear-1).toString());
            setChoosedMonth(12)
            mapMonthNumberToString(12)
            localStorage.setItem("selectedMonth", "12");
        }
        else{
            if(choosedMonth+i>12){
                year=choosedYear+1;
                month=1;
                setchoosedYear(choosedYear+1)
                localStorage.setItem("selectedYear", (choosedYear+1).toString());

                setChoosedMonth(1)
                mapMonthNumberToString(1)
                localStorage.setItem("selectedMonth", "1");

            }
            else{
                year=choosedYear;
                month=choosedMonth+i;
                setMonthName(mapMonthNumberToString(choosedMonth+i));
                setChoosedMonth(choosedMonth+i);
                localStorage.setItem("selectedMonth", (choosedMonth+i).toString());
            }
            
        }
        //getTransactions(choosedMonth, choosedYear);
       // console.log("localStorage: ",localStorage.getItem("selectedMonth"));
        //console.log(choosedMonth+i)
        var firstDay = new Date(year, month, 1);
        var lastDay = new Date(year, month + 1, 0);

      //  console.log(firstDay, lastDay)

        setListFilter(prev => ({
            ...prev,
            dateFrom: dateForServerFromDate(dayjs(firstDay)),
            dateTo: dateForServerFromDate(dayjs(lastDay)),
            selectedDates: ""
        }));
        
         */
        
 
        setanyTransactions("");
    }



   const handleDelete=()=>{
       console.log(rowTransctionId,": ", rowTransction)
       const { confirm } = Modal;
       confirm({
           title: t('Delete_warning'),
           icon: <ExclamationCircleFilled />,
           content: t("Delete_text"),
           okText: t('Yes'),
           okType: 'danger',
           cancelText: t('No'),
           onOk() {
               axios.delete(AuthService.DATA_URL+'Transaction/'+rowTransctionId
                   ,{headers: authHeader()})
                   .then((res:any) =>{
                       console.log(res);
                       getTransactions(listFilter);
                       setisIncomeOpen(false);
                     

                       }
                   );


           },
           onCancel() {
               console.log('Cancel');
           },
       });
   }
    
   const [showHideGrafics, steshowHideGrafics]=useState(localStorage.getItem("show_charts")==="true"? "flex": "none")
    const [showHideCheck, steshowHideCheck]=useState(localStorage.getItem("show_charts")==="true"? true: false)

    const showHideGrafs=(value: any)=>{
        //console.log(value.target.checked);
     if(value.target.checked){
         steshowHideGrafics("flex")
         steshowHideCheck(true)
         localStorage.setItem("show_charts", "true");

     }
     else{
         steshowHideGrafics("none")
         steshowHideCheck(false)
         localStorage.setItem("show_charts", "false");

     }
    }
    
    
    
    const onSearchText=(value:any)=>{

        setSearchText(value)
        if(value.length===0){
            steshowHideGrafics("flex")
            steshowHideCheck(true)
        }
        else{
            steshowHideGrafics("none")
            steshowHideCheck(false)
        }
    }



    const showReport=()=>{}

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <span onClick={()=>{history.push("transactions/dds")}}>
                    {t("DDS_Rep")}
                </span>
            ),
        },
        /*
        {
            key: '2',
            label: (
                <span onClick={()=>{history.push("/dds")}}>
                    Отчет по контрагентам
                </span>
            ),
        },
        
         */
    ];






/////Загрузка выписки
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleModalCancel = () => {
        setreload(!reload);
        setIsModalOpen(false);
        getTransactions(listFilter);
    };
    const showModal1 = () => {
        setIsModalOpen(true);

    };

    const [reload, setreload] = useState(false);

    const handleModalOk=()=>{
        setreload(!reload);
        setIsModalOpen(false);
        getTransactions(listFilter);
    }



    // rowSelection object indicates the need for row selection
    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: Transaction[]) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record: Transaction) => ({
            //disabled: record.name === 'Disabled User', // Column configuration not to be checked
           // name: record.name,
        }),
    };

    if(isLoading){
        return(
            <Spin style={{verticalAlign: "middle"}}>
                <div style={{height: "100vh"}}> </div>
            </Spin>


        )
    }


    return (
       
        <div style={{overflowY: "auto", overflowX: "hidden", height: "calc(90vh)"}} ref={setContainer}>
            
            <Affix offsetTop={0} target={() => container}>
                <div id="affixheader">
                    <Row gutter={20}  style={{marginBottom: "10px"}}>
                        <Col span={6}>
                        <Row style={{marginBottom: "15px"}}>
                            <Col span={24}>
                                <h1 style={{display: "inline-block", marginBottom: "10px", width: "100%"}}>{t("Finances")}
                                    <span style={{color: "#1c7884", fontWeight: "600",  float: "right"}}>
                                {BalanceHeader}€
                            </span>
                                </h1>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: "15px"}}>
                            <Col span={24}>
                                <div style={{
                                    //position: "absolute",
                                    //width: "300px", 
                                    marginBottom: "10px"}}>

                                    <Space.Compact block>
                                        <Button icon={<LeftOutlined />} onClick={()=>changeMonnth(-1)} />
                                        <Input disabled={true}
                                               style={{ /*width: 'calc(100% - 150px)',*/ backgroundColor:"#fff", color: "#000", textAlign:"center" }}
                                               value={choosedMonthName}
                                        />
                                        <Button icon={<RightOutlined/>} onClick={()=>changeMonnth(1)} />

                                    </Space.Compact>


                                </div>    
                            </Col>
                        </Row>


                    </Col>
                        <Col span={7} offset={1}>
                            <Row style={{marginBottom:"20px", paddingTop:"10px"}}>
                                <Col span={24}>
                                    <Button style={{marginLeft: "0px"}}
                                            onClick={()=> {
                                                setIncExp(1);
                                                setRowTransctionId("00000000-0000-0000-0000-000000000000")
                                                setisIncomeOpen(true)
                                            }}
                                            icon={<PlusOutlined/>}>
                                        {t("Income")}</Button>

                                    <Button style={{marginLeft: "10px", float: "right"}}
                                            onClick={()=> {
                                                setIncExp(0-1);
                                                setRowTransctionId("00000000-0000-0000-0000-000000000000")
                                                setisIncomeOpen(true)
                                            }}
                                            icon={<MinusOutlined/>}>{t("Expense")}</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Input.Search className={"emptyB"}
                                                  //style={{width: "100%"}}
                                                  placeholder={t("Išči")}
                                                  onSearch={(value)=>{
                                                      onSearchText(value)
                                                  }}
                                                  onChange={(e)=>{
                                                      onSearchText(e.target.value)
                                                  }}
                                    />
                                </Col>
                            </Row>
                        
                        
                        
                        



                    </Col>
                        <Col span={4} offset={0} style={{paddingLeft: "20px"}}>
                            <Row style={{marginBottom:"15px", paddingTop:"20px"}}>
                                <Col>
                                    {/*Просрочено: 2*/}
                                    {/*  <Checkbox onChange={(e)=>console.log(e)}>{t("Incomes")}</Checkbox>*/}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {/*}Не распределено: 1 */}
                                    {/* <Checkbox onChange={(e)=>console.log(e)}>{t("Expenses")}</Checkbox>*/}
                                </Col>
                            </Row>
                        </Col>

                        <Col span={6} >
                            <Row style={{marginBottom:"20px", marginTop:"10px"}}>
                                <Col>
                                    <Checkbox
                                        checked={showHideCheck}
                                        onChange={(e)=> {
                                            showHideGrafs(e)
                                        }}>{t("Show_charts")}</Checkbox>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Dropdown menu={{items}}>
                                        
                                        <Button><Space>
                                            {t("Reports")}
                                            <DownOutlined />
                                        </Space>
                                        </Button>
                                    </Dropdown>
                                </Col>
                                <Col  span={12}>

                                    <Dropdown 
                                        //menu={{items}}
                                        menu={{'items': 
                                                [
                                                    { key: '1',  label: <div onClick={showModal1} >{t("Upload")}</div> },
                                                    { key: '2',  label: <div  >{t("Download")}</div> }
                                            ], onClick: (e)=>console.log("-") }}
                                    >

                                        <Button style={{
                                            float: "right"
                                            //border: "1px dashed #d9d9d9",
                                            //borderTopLeftRadius: "5px",
                                            //borderBottomLeftRadius: "5px",
                                            //borderRight: "0px",
                                            //height: "40px",
                                            //width: "100%",
                                            //fontSize: "14px",
                                            //color: "#1449AE",
                                            //marginBottom: "8px",
                                        //    padding: "8px 5px"
                                        }}
                                        
                                        ><Space>
                                            {t("Statement")}
                                            <DownOutlined />
                                        </Space>
                                        </Button>
                                    </Dropdown>


                                    {/* 
                                    <Button onClick={showModal1} style={{
                                        border: "1px dashed #d9d9d9",
                                        borderTopLeftRadius: "5px",
                                        borderBottomLeftRadius: "5px",
                                        //borderRight: "0px",
                                        height: "40px",
                                        //width: "100%",
                                        fontSize: "14px",
                                        color: "#1449AE",
                                        marginBottom: "8px",
                                        padding: "8px 5px"}}
                                    >
                                        + {t("Prenesite_dokument")}
                                    </Button> */}
                                    <Modal open={isModalOpen}
                                           onOk={handleModalOk}
                                           title={t("Prenesite_dokument")}
                                           centered
                                           width={580}
                                           onCancel={handleModalCancel}
                                        //footer={[]}
                                    >

                                        <UploadDoc objectId={null} objectMode={10} vrstaDoka={100}/>
                                    </Modal>
                                </Col>
                            </Row>
                        </Col>

                    </Row>

                </div>
            </Affix>
            <Row style={{display: showHideGrafics }}>
                <Col span={18} style={{paddingRight: "5px", paddingBottom: "10px"}}>
                    <Card style={{width: "100%", height: "200px", paddingLeft: "0px", paddingRight: "0px"}}>
                        {/*Февраль 2024*/}
                    <div style={{height: "100px", marginTop: "-50px", marginLeft: "-50px"}}>
                        <ReactECharts option={baroptions} style={{height: "210px"}} />
                    </div>
                    </Card>
                </Col>
                <Col span={6} style={{paddingLeft: "5px", paddingBottom: "10px"}}>
                    <Card style={{width: "100%", height: "200px"}} 
                          className={"dougnat_chart"}
                          title={t("Expenses_by_item")}>{/*t("Expenses_by_item")*/}
                        <div style={{width: "170px", margin: "auto", marginTop: "-40px"}}>
                            <ReactECharts option={donutOptions} style={{height: "210px"}} />

                        </div>
                       
                    </Card>
                </Col>


            </Row>

            <Row>
             <Col span={24}>
                
                <Table
                    locale={{
                        triggerDesc: t('Kliknite_za_razvrščanje_padajoče'),
                        triggerAsc: t('Kliknite_za_razvrščanje_naraščajoče'),
                        cancelSort: t('Prekliči_razvrščanje')
                    }}
                    rowKey={( record: any ) => record.id}
                    rowSelection={{
                        type: "checkbox",
                        ...rowSelection,
                    }}
                    columns={columns}
                    dataSource={transactions}
                    loading={isLoading}
                    pagination={false}
                    scroll={{ y: 'calc(69vh)' }}
                    summary={(pageData) => {
                        let totalIncome = 0;
                        let totalExpense = 0;
                        pageData.forEach(({ amount, incomeExpense }) => {
                            if((incomeExpense? incomeExpense:0)>0){totalIncome = totalIncome+(amount? amount : 0);}
                            if((incomeExpense?incomeExpense:0)<0){totalExpense = totalExpense+(amount? amount : 0);}
                            
                    });
                        settotalIncome(totalIncome);
                        settotalExpense(totalExpense);
                        settotalSaldo(totalIncome-totalExpense)
                        return (
                        <Table.Summary>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>
                                    <div style={{width: "100px"}}>
                                        {t("Incomes")}: <br/>
                                        {t("Expenses")}: <br/>
                                        {t("Balance")}:
                                    </div>

                                
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>
                                    <div style={{width: "170px"}}>
                                    
                                    <span style={{color: "green", float: "right"}}>{totalIncome.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}€</span> <br/>
                                    <span style={{color: "#7918e6", float: "right"}}>- {totalExpense.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}€</span> <br/>
                                        {totalSaldo>0?
                                            <span style={{color: "green",float: "right"}}>{totalSaldo.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}€</span>
                                            :
                                            <span style={{color: "#7918e6",float: "right"}}>{totalSaldo.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}€</span>
                                        }
                                    </div>

                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )}}
                    
                    
                />
             </Col>
            </Row>




            <Modal open={isIncomeOpen}

                   title={incExp>0? <span><PlusOutlined/> {t("Income")}</span>  : <span><MinusOutlined/> {t("Expense")}</span> }
                   centered
                   onCancel={()=> CloseModals()}
                   footer={[
                       <Button key="back"
                               className={"emptyButton"}
                               onClick={()=> CloseModals()}>
                           {t("Ne_shranite_sprememb")}
                       </Button>,
                       <Button form="IncomeForm"
                               className={"orangeButton"}
                               key="submit" htmlType="submit" onClick={()=> CloseModals()}>
                           {t("Shraniti")}
                       </Button>,
                       <span style={{display: rowTransctionId==="00000000-0000-0000-0000-000000000000" ? "none": "inline-block"}}>
                           <Dropdown
                               menu={{'items': [{ key: '1',  label: <Button type="primary" danger>{t("Delete")}</Button> }], onClick: handleDelete }} >
                               <Button type="text">{t("Več")}..</Button>
                           </Dropdown>
                       </span>
                       
    
    
                   ]}>
                <Divider style={{marginBottom: 0}}/>
                <IncomeForm id={rowTransctionId}  incomeExpence={incExp} reload={reloadForm}/>
            </Modal>
            {/*
            <Modal open={isExpenseOpen}

                   title={"- Списание"}
                   centered
                   onCancel={()=> CloseModals()}
                   footer={[
                       <Button key="back"
                               className={"emptyButton"}
                               onClick={()=> CloseModals()}>
                           {t("Ne_shranite_sprememb")}
                       </Button>,
                       <Button className={"orangeButton"}
                           form="IncomeForm" key="submit" htmlType="submit" onClick={()=> CloseModals()}>
                           {t("Shraniti")}
                       </Button>
                   ]}>
                <Divider/>
                <IncomeForm id={rowTransctionId} incomeExpence={-1} reload={reloadForm} />
            </Modal>
            */}
        </div>
      
    );
}
export default TransactionsPage;