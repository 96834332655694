import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import type { RadioChangeEvent } from 'antd';

//import axios from 'axios';

import {Button, Card, Checkbox, Col, Row, Space, Spin, message, Upload, Progress, Radio} from "antd";
import authHeader from "../../../Services/auth-header";
import {useTranslation} from "react-i18next";

type DocProps = {
    objectId: string | null;
    objectMode: number;
    vrstaDoka? : number
    afterCreate?: (data: any) => string;
};

const UploadDoc = ({ objectId, objectMode, vrstaDoka }: DocProps) => {
    const {t} = useTranslation();


    const history = useHistory();
    const user = AuthService.getCurrentUser();
    if (!user) {
        history.push("/login");
    }
    const { Dragger } = Upload;



    
    const truckId='5d0bf8f2-394b-4063-a022-89272ba44d4a';
    const [vrstaDocumenta, setvrstaDocumenta] = useState(vrstaDoka===undefined? 0 : vrstaDoka);
    const [draggerDisabled, setdraggerDisabled] = useState(vrstaDoka===undefined? true : false);
    
    
    const onChangevrstaDocumenta = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setvrstaDocumenta(e.target.value);
        setdraggerDisabled(false);
    };


    //http://localhost:5000/api/Docs/CreateFromUpload?requestId=5d0bf8f2-394b-4063-a022-89272ba44d4a&objectMode=2
    const uploadAction =  AuthService.DATA_URL+'Docs/CreateFromUpload?requestId='+objectId+'&objectMode='+objectMode.toString()+'&DocType='+vrstaDocumenta.toString();


    
    
    const onChange =(info : any) =>{
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            console.log("info: ",info);
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }
    
    

    // @ts-ignore
    return (
        <div> 
        <Row>
            <Col>
                <Dragger 
                    disabled={draggerDisabled}
                    onChange={onChange}
                    action={uploadAction} //+'http://localhost:5000/api/Docs/CreateFromUpload?requestId=5d0bf8f2-394b-4063-a022-89272ba44d4a&objectMode=2'
                    
                    headers={authHeader()}
                    style={{minWidth: "300px"}}>  
                    <Button className={"orangeButton"} htmlType="submit">
                        + {t("Prenesite_dokument")}
                    </Button>
                    <p className="ant-upload-text" ><br/>{t("Prenesite_datoteko_ali_povlecite")}<br/> {t("in_spusti_na_označeno_območje")}<br/></p>
                    <p className="ant-upload-hint">
                        JPG, JPEG, PNG, PDF {t("ne_več_kot")} 24 MB
                    </p>
                </Dragger>
            </Col>
            <Col>
                <div className="container" style={{marginLeft: "20px", overflow: "scroll", height: "250px", width: "200px"}}>
                    <Radio.Group onChange={onChangevrstaDocumenta} value={vrstaDocumenta}>
                        {
                            objectMode==2 ? 
                                <Space direction="vertical">
                                    <Radio value={21}>{t("Tehnični_potni_list")}</Radio>
                                    <Radio value={22}>{t("Zavarovanje")}</Radio>
                                    <Radio value={0}>{t("Drug_dokument")}</Radio>
                                </Space> : 
                            objectMode==3 ?
                            <Space direction="vertical">
                            <Radio value={31}>{t("Vozniško_dovoljenje")}</Radio>
                            <Radio value={32}>{t("Zavarovanje")}</Radio>
                            <Radio value={0}>{t("Drug_dokument")}</Radio>
                            </Space> :
                            objectMode==4 ?
                            <Space direction="vertical">
                                <Radio value={10}>{t("Račun")}</Radio>
                                <Radio value={11}>CMR</Radio>
                                <Radio value={12}>{t("Pogodba")}</Radio>
                            </Space> :
                                objectMode==1 ?
                                    <Space direction="vertical">
                                        <Radio value={10}>{t("Račun")}</Radio>
                                        <Radio value={11}>CMR</Radio>
                                        <Radio value={12}>{t("Pogodba")}</Radio>
                                    </Space> :
                                    null
                            
                            }
                            )
                    </Radio.Group>
                </div>
                
            </Col>
        </Row>
        </div>
        
    )
};
export default UploadDoc;