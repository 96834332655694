import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {Avatar, Button, Card, Checkbox, Col, Divider, Form, Input, message, Row} from "antd";
import {CrownOutlined} from "@ant-design/icons";
import ShowDocPage from "../../docs/DocComponents/ShowDoc";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import {useTranslation} from "react-i18next";




const CompanySettingsPage = () => {

    const {t} = useTranslation();


    const history= useHistory();
    const user = AuthService.getCurrentUser();
    if(!user) {
        history.push("/login");
    }


    const [form] = Form.useForm();
    const onFinishFailed = () => {
        message.error('Submit failed!');
    };


    const  [ownCompany, setownCompany] = useState<any>();
    const  [ownCompanyId, setownCompanyId] = useState<any>();

    const  [logoId, setlogoId] = useState<any>();
    
    
    const  [zigId, setzigId] = useState<any>();
    const[zigPic, setzigPic] = useState<string>();



    const  [podpisId, setpodpisId] = useState<any>();
    const[podpisPic, setpodpisPic] = useState<string>();

    
    
    const  [forceReload, setforceReload] = useState(true);

    

    
    const onFinish = (values: any) => {

        axios.put(AuthService.DATA_URL+'Contractor/'+ownCompanyId,
            {

                "id": ownCompanyId,
                "title": values.title,
                "davcnaStevilka": values.davcnaStevilka,
                "maticnaStevilka": values.maticnaStevilka,
                "adress1": values.adress1,
                "adress2": values.adress2,
                "iban": values.iban,
                "swift": values.swift,
                "userid":"",
                "owneruserid":"",
                "logoid" : logoId,
                "podpisId": podpisId,
                "zigId":zigId,
                "isTransporter" : isTransporter,
                "isOperator" : isOperator,
                "AcceptOpenOrders" : AcceptOpenOrders,
                "AcceptOpenReplies" : AcceptOpenReplies


            },{headers: authHeader()})
            .then((res) =>{
                    message.success('Podatki so bili uspešno shranjeni');
                   // history.push("/settings");
                
                
                }
            )
            .finally(()=>{
                
                var tt=!forceReload;
                    setforceReload(tt);
                
            });


        console.log(values, ownCompany);
        setownCompany(values);
        console.log(ownCompany);

        const element = document.getElementById("obrazec");
    }
    const handleIsLoad =(isLoaded : boolean) =>{
        if(isLoaded) {
        }
    }


    const[isTransporter, setisTransporter] = useState<boolean>(false);
    const[isOperator, setisOperator] = useState<boolean>(false);
    const[AcceptOpenOrders, setAcceptOpenOrders] = useState<boolean>(false);
    const[AcceptOpenReplies, setAcceptOpenReplies] = useState<boolean>(false);


    useEffect(()=>{
        if(!ownCompany)
        axios.get(AuthService.DATA_URL+'Contractor/get-ownCompany', {headers: authHeader()})
            .then((response) => {
                //console.log("Request response.data: ", response.data)
                setownCompany(response.data);
                //console.log("ownCompany: ", ownCompany)

                setownCompanyId(response.data.id);
                setlogoId(response.data.logoId);
                setzigId(response.data.zigId);
                setpodpisId(response.data.podpisId)
                form.setFieldsValue({
                    title: response.data ? response.data.title : "",
                    davcnaStevilka: response.data ? response.data.davcnaStevilka : "",
                    maticnaStevilka: response.data ? response.data.maticnaStevilka : "",
                    adress1: response.data ? response.data.adress1 : "",
                    adress2: response.data ? response.data.adress2 : "",
                    iban: response.data ? response.data.iban : "",
                    swift: response.data ? response.data.swift : "",
                    
                });
                if(response.data.isOperator){
                    setisOperator(true);
                }
                if(response.data.isTransporter){
                    setisTransporter(true);
                }
                if(response.data.acceptOpenOrders){
                    setAcceptOpenOrders(true);
                }
                if(response.data.acceptOpenReplies){
                    setAcceptOpenReplies(true);
                }


                //подставим логотип
                if(response.data.logoId){
                    fetch(AuthService.DATA_URL+`Docs/get-doc?id=`+ response.data.logoId, {headers: authHeader()})
                        .then((response) => response.blob())
                        .then((blob) => {
                            const imageUrl = URL.createObjectURL(blob);
                            const imageElement = document.createElement("img");
                            imageElement.src = imageUrl;
                            const container = document.getElementById("logoContainer");
                            // @ts-ignore
                            container.innerHTML='';
                            // @ts-ignore
                            container.appendChild(imageElement);
                        });
                }
                
                //подставим печать
                if(response.data.zigId){
                    fetch(AuthService.DATA_URL+`Docs/get-doc?id=`+ response.data.zigId, {headers: authHeader()})
                        .then((response) => response.blob())
                        .then((blob) => {
                            const imageUrl = URL.createObjectURL(blob);
                            setzigPic(imageUrl);
                        });
                }
                //подставим подпись
                if(response.data.podpisId){
                    fetch(AuthService.DATA_URL+`Docs/get-doc?id=`+ response.data.podpisId, {headers: authHeader()})
                        .then((response) => response.blob())
                        .then((blob) => {
                            const imageUrl = URL.createObjectURL(blob);
                            setpodpisPic(imageUrl);
                        });
                }


                //setforceUpdate("-");

                
            })
    })







    return(
        <div>
            <Row style={{paddingTop: "10px"}}>
                <Col span={24}>
                    <Row>
                        <Col span={5}  style={{textAlign: "center"}}>
                            <div id="logoContainer">
                                <Avatar size={96} icon={<CrownOutlined />} />
                            </div>
                            
                        </Col>
                        <Col >

                            <Button className={"emptyButton"} htmlType="submit" style={{marginTop: "10px"}}>
                                + {t("Prenesite_sliko")}
                            </Button>
                            <p style={{color: "#6B6E74"}}>{t("Prenesete_lahko_slike_velikosti_do")} <br/> 256x256.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Card style={{marginTop: "20px"}}>
                                <Form
                                    form={form}
                                    name="CompanySettings"
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    layout="horizontal"
                                    labelCol={{ flex: '120px' }}
                                    labelAlign="right"
                                    labelWrap
                                    wrapperCol={{ flex: "190px" }}
                                    colon={false}
                                    //style={{ maxWidth: 600 }}
                                >

                                    <Row>
                                        <Col span={8} style={{borderRight: "1px solid #f0f0f0"}}>
                                            <Form.Item
                                                name="title"
                                                label={t("Naziv")}
                                                rules={[{ required: true }, { type: 'string', warningOnly: true }]}
                                            >
                                                <Input placeholder="Naziv Podetja d.o.o." />
                                            </Form.Item>
                                            <Form.Item
                                                name="davcnaStevilka"
                                                label={t("Davčna_Številka")}
                                                rules={[{ type: 'string', warningOnly: true }]}
                                            >
                                                <Input placeholder="SI12345678" />
                                            </Form.Item>

                                            <Form.Item
                                                name="maticnaStevilka"
                                                label={t("Matična_Številka")}
                                                rules={[{ type: 'string', warningOnly: true }]}
                                            >
                                                <Input placeholder="0123456789" />
                                            </Form.Item>
                                            <Form.Item
                                                name="adress1"
                                                label={t("Naslov") + " (1)"}
                                                rules={[{ type: 'string', warningOnly: true }]}
                                            >
                                                <Input placeholder="Ulica, 11" />
                                            </Form.Item>
                                            <Form.Item
                                                name="adress2"
                                                label={t("Naslov") + " (2)"}
                                                rules={[{ type: 'string', warningOnly: true }]}
                                            >
                                                <Input placeholder="1234 Mesto" />
                                            </Form.Item>

                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name="iban"
                                                label="IBAN"
                                            >
                                                <Input placeholder="SI56123412341234123"  />
                                            </Form.Item>
                                            <Form.Item
                                                name="swift"
                                                label="SWIFT"
                                            >
                                                <Input placeholder="LJAASI1A"  />
                                            </Form.Item>
                                            <Row>
                                              <Col span={12}>
                                                  <p style={{textAlign: "center"}}>{t("Žig")}:</p>
                                                  <div style={{
                                                      //background: "url('http://localhost:3000/img/zig.png') no-repeat",
                                                      backgroundImage: "url('" + zigPic + "')",
                                                      backgroundRepeat: "no-repeat", 
                                                      padding: "20px 10px", 
                                                      //width: "200px",
                                                      backgroundSize : "contain",
                                                      backgroundPosition: "center",
                                                      width: "100%", height: "80px"
                                                  }}>

                                                  </div>
                                              </Col>
                                                <Col span={12}>
                                                    <p style={{textAlign: "center"}}>{t("Podpis")}:</p>
                                                    <div style={{
                                                        //background: "url('http://localhost:3000/img/signature.png') no-repeat",
                                                        backgroundImage: "url('" + podpisPic + "')",
                                                        backgroundRepeat:"no-repeat",
                                                        backgroundSize : "contain", 
                                                        backgroundPosition: "center",
                                                        width: "100%", height: "80px"
                                                    }}>

                                                    </div>
                                                </Col>

                                            </Row>

                                        </Col>
                                        {/* 
                                        <Col span={8}>
                                            <Form.Item wrapperCol={{flex: "100%"}} name="isTransporter">
                                                <Checkbox checked={isTransporter}
                                                    onChange={(e)=>setisTransporter(e.target.checked)}
                                                >{t("Predstavljam_prevozno_prevozniško_podjetje")}</Checkbox>
                                            </Form.Item>
                                            <Form.Item wrapperCol={{flex: "100%"}} name="AcceptOpenOrders">
                                                <Checkbox  checked={AcceptOpenOrders}
                                                    onChange={(e)=>setAcceptOpenOrders(e.target.checked)}
                                                >{t("Želim_prejemati_javne_ponudbe_za_prevoz")}</Checkbox>
                                            </Form.Item>
                                            <Form.Item wrapperCol={{flex: "100%"}} name="isOperator">
                                                <Checkbox checked={isOperator}
                                                    onChange={(e)=>setisOperator(e.target.checked)}
                                                >{t("Predstavljam_pošiljatelja_špediterja")}</Checkbox>
                                            </Form.Item>
                                            <Form.Item wrapperCol={{flex: "100%"}} name="AcceptOpenReplies">
                                                <Checkbox checked={AcceptOpenReplies}
                                                    onChange={(e)=>setAcceptOpenReplies(e.target.checked)}
                                                >{t("Želim_imeti_dostop_do_razpoložljivih_prevoznikov")}</Checkbox>
                                            </Form.Item>

                                        </Col>
                                    */}
                                    </Row>
                                    <Divider/>
                                    <Button className={"orangeButton"} htmlType="submit" style={{marginLeft:"120px"}}>
                                        {t("Shraniti")}
                                    </Button>



                                </Form>
                            </Card>
                        </Col>
                        
                    </Row>
                    
                </Col>

                {/*

            <Col span={8} style={{paddingLeft: "40px"}} id={"obrazec"}>
                <div className="shadow"
                     style={{backgroundColor: "#fff", padding: "20px",
                         boxShadow: "0 4px 16px 0 rgba(0, 0, 0, 0.08)",
                         border: "1px solid #e6e6e6",
                         height: "500px"
                     }}
                >
                <ShowDocPage forceReload={forceReload} isLoaded={handleIsLoad} />
                </div>
               
            </Col>
                    */}
                
            </Row>

           










        </div>
    )};
export default CompanySettingsPage;