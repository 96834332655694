import React, {useState, useEffect} from "react";
import { List, Skeleton } from 'antd';

import authHeader from "../../../Services/auth-header";
import * as AuthService from "../../../Services/auth-service";

interface DataType {

        birthdate: string;
        contractor? : any;
        contractorId? : string;
        id : string;
        licenseNumber : string;
        name : string;
        surname : string;
        userId : string;
        loading: boolean;
}


interface RequestProps {
    requestId: string | null,
    contractorId? : string,
    onVlacilecChoice: (data: any, type: number) => void;
}


export default function SuggestedDrivers({requestId,contractorId, onVlacilecChoice} : RequestProps) {
    const DataUrl = AuthService.DATA_URL+'driver/get-for-request?requestid='+requestId+(contractorId ? "&ContractorId="+contractorId : "");
    //console.log("SuggestedTransport contractorId:",contractorId);

    const [initLoading, setInitLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<DataType[]>([]);
    const [list, setList] = useState<DataType[]>([]);

    useEffect(() => {
        fetch(DataUrl, {headers: authHeader()})
            .then((res) => res.json())
            .then((res) => {
                setInitLoading(false);
                setData(res);
                setList(res);
                //setData(res.map((driver: any) => ({ name: driver.name, surname: driver.surname, id : driver.id })));
                //setList(res.map((driver: any) => ({ name: driver.name, surname: driver.surname, id : driver.id })));
            });
    }, [contractorId]);


    const ChooseVlacilec=(rn : string, tp: number)=>{
        console.log("choose driver", rn, tp);
        onVlacilecChoice(rn, tp);
    }

    return(
        <div style={{maxWidth: "330px", minWidth: "330px", height:"500px", overflow: "auto"}}>



            <List
                className=""
                loading={initLoading}
                size="small"
                itemLayout="horizontal"
                //loadMore={loadMore}
                dataSource={list}
                renderItem={(item) =>
                {
                    
                        return(

                            <List.Item
                                actions={[ ]}>
                                <Skeleton avatar title={false} loading={item.loading} active>
                                    <div>
                                        <div>
                                            <div  style={{cursor: "pointer"}} onClick={()=>ChooseVlacilec(item.id, 2)}>
                                                
                                                {item.name+" "+item.surname}
                                            </div>
                                        </div>
                                    </div>
                                </Skeleton>
                            </List.Item>
                        )
                }}
            />

        </div>
    );
}