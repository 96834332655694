export default interface  RoutePoint {
    city? : string;
    country? : string;
    street? : string;
    lat? : number;
    lng? : number;
    PointDate? : any;
    PointTime? : any;
    step? : any;
    dateText? : any;
    theDate? : any;
    theTime? : any;
    hourText? : any;
    isChanged? : any;
    PointType : number;
    keyStr : string;
}



export const dateToPoints =(today : Date) =>{
    const yyyy = today.getFullYear();
    let m = today.getMonth() + 1; // Months start at 0!
    let d = today.getDate();

    let mm = m.toString();
    let dd = d.toString();
    if (d < 10) dd = '0' + dd;
    if (m < 10) mm = '0' + mm;

    return dd + '.' + mm + '.' + yyyy;
}

export const timeToPoints =(today : Date) =>{
//    const yyyy = today.getFullYear();
    let m = today.getMinutes() ; // Months start at 0!
    let d = today.getHours();

    let mm = m.toString();
    let dd = d.toString();
    if (d < 10) dd = '0' + dd;
    if (m < 10) mm = '0' + mm;

    if (isNaN(m)) return '';
    return dd + ':' + mm ;
}
