import React, {  useState }  from 'react';
import {Button, Form, Input, message, Space, Col, Row, Tabs, List, Spin,  Modal, Avatar, DatePicker} from 'antd';
import {UserOutlined} from "@ant-design/icons";

//import { useNavigate } from 'react-router-dom';

import {Card} from "antd";



import axios from 'axios';
import {useHistory, useParams} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import authHeader from "../../../Services/auth-header";
import UploadDoc from "../../docs/DocComponents/UploadDoc";
import DocList from "../../docs/DocComponents/docList";
import RouteList from "../../myroutes/RouteComponents/list";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import DopustList from "./DopustList";
import DealsList from "../../Deals/Components/DealsList";
import DealsFilter from "../../../types/DealsListFilter";
import {dateForServerFromDate} from "../../../utils/helpers";
//const history= useHistory();
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
//const paramValue = urlParams.get('id');
//console.log(paramValue);

type driver ={
    id: string;
    name: string;
    surname: string;
    birthdate: string;
    licenseNumber: string;
};

type GetDriverResponse = {
    data: driver;
};

interface Params {
    id: string;
}



const DriverDetailsPage = () => {
    const {id} = useParams<Params>();
    async function getDriver() {
      


        try {
            // 👇️ const data: GetUsersResponse
            const { data } = await axios.get<GetDriverResponse>(
                AuthService.DATA_URL+'Driver/'+id,
                {headers: authHeader()}
            );
            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log('error message: ', error.message);
                return error.message;
            } else {
                console.log('unexpected error: ', error);
                return 'An unexpected error occurred';
            }
        }
    }

    const {t} = useTranslation();

    //const [APIData, setAPIData] = useState([]);
    const history= useHistory();

    const [form] = Form.useForm();

    
    const[isLoading, setisLoading] = useState(false)

    const[initHead, setiniHead ]= useState<string>("Podatki o vozniku");
    const [Dopust, setDopust] = useState<any[]>([]);


    let mydriver;
    getDriver().then( time=> {
        setisLoading(false)
        mydriver = JSON.parse(JSON.stringify(time, null, 4));
        form.setFieldsValue({
            name: mydriver.name,
            surname: mydriver.surname,
            birthdate : mydriver.birthdate ? dayjs(mydriver.birthdate.substring(8,10)+"."+mydriver.birthdate.substring(5,7)+"."+mydriver.birthdate.substring(0,4), "DD.MM.YYYY") : undefined,//mydriver.birthdate,
            licenseNumber : mydriver.licenseNumber
        });

        setiniHead(mydriver.name +" "+ mydriver.surname);
       
        /*
        axios.get(AuthService.DATA_URL+'RestRepair/get-fordriver?id='+paramValue, {headers: authHeader()})
            .then((response) => {
                setDopust(response.data);
            })
            
         */

            })
        .catch(function (error) {
            if(error.response.status===401){
                AuthService.logout();
                history.push("/login");
                window.location.reload();

            }
        })
    ;

    const onFinish = (values: any) => {
        /*
        var month = '' + (values.birthdate.get('month')+1),
            day = '' + values.birthdate.get('date'),
            year = values.birthdate.get('year');

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
            
         */

        setisLoading(true)
        
        axios.put(AuthService.DATA_URL+'Driver/'+id,
            {
                "id": id,
                "name": values.name,
                "surname": values.surname,
                "birthdate": values.birthdate? dateForServerFromDate(values.birthdate) : null,//year+"-"+month+"-"+day+"T00:00:00Z",
                "licenseNumber": "",

            },
            {headers: authHeader()})
            .then(() =>{
                //window.location.href="/drivers";
                axios.delete(AuthService.DATA_URL+'RestRepair/delete-for-driver?id='+id,
                    {headers: authHeader()})
                    .then((r)=> {

                        console.log("Dopust:", Dopust);
                        Dopust.forEach(point => {
                            //console.log("Saving points.. - point ",point.id);
                            //console.log("point.country: ", point.country);
                            axios.post(AuthService.DATA_URL + 'RestRepair/',
//                                    axios.put(AuthService.DATA_URL+'RoutePoint/'+point.id,
                                {
                                    //id:point.id,
                                    DriverId: id,
                                    DateStart: point.fromDate ? (point.fromDate.substring(6, 10) + "-" + point.fromDate.substring(3, 5) + "-" + point.fromDate.substring(0, 2) + "T"
                                        + "00:00" + ":00.000Z") : null,
                                    DateEnd: point.toDate ? (point.toDate.substring(6, 10) + "-" + point.toDate.substring(3, 5) + "-" + point.toDate.substring(0, 2) + "T"
                                        + "00:00" + ":00.000Z") : null,
                                    reasonType: point.reason==='' ? 0 : point.reason,
                                    comment: point.comment ? point.comment : ""
                                }, {headers: authHeader()}
                            )
                                .then((itog) => {
                                    console.log("itog",itog);
                                    setisLoading(false)
                                });
                        })

                    })

                history.push("/drivers");
                //window.location.reload();
                //window.location.href="/drivers";


                }
            );
    };

    const onFinishFailed = () => {
        message.error('Submit failed!');
    };


    const { TabPane } = Tabs;



    const BackToList = () =>{
        history.push("/drivers");
    }

    const handleListChange=(posiitions? : any[])=>{
        if(posiitions){
            setDopust(
                posiitions ? posiitions.map((tr, index) => ({
                    key: Math.random().toString(),
                    id: tr.id,
                    fromDate: tr.fromDate,
                    toDate: tr.toDate,
                    reason: tr.reason,
                    comment: tr.comment,
                }) ) : [])
        }
        console.log("posiitions: ", posiitions);
        console.log("Dopust: ", Dopust);

    }



    //// Documents Modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleModalCancel = () => {
        setIsModalOpen(false);
    };
    const showModal1 = () => {
        setIsModalOpen(true);

    };

    const ListData = [
        'Racing car sprays burning fuel into crowd.',
        'Japanese princess to wed commoner.',
        'Australian walks 100km after outback crash.',
        'Man charged over missing wedding girl.',

    ];


    const [dealsFilter, setdealsFilter]=useState<DealsFilter>({
        searchText: "",
        dateFrom: "",
        dateTo: "",
        hidefinished:true,
        requestStatus: 100,
        selectedDates: "",
        driverId : id
    })



    return (

        <Spin spinning={isLoading}>
            
        <div>
            <h1>{initHead}</h1>



            <Tabs defaultActiveKey="0" //activeKey={tabActiveKey}
                //onTabClick={theTabClick}
            >
                <TabPane tab={t("Osnovni_podatki")} key="0">

                    <Form
                        form={form}
                        name="wrap"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        layout="horizontal"
                        labelCol={{ flex: '120px' }}
                        labelAlign="right"
                        labelWrap
                        wrapperCol={{ flex: 1 }}
                        colon={false}
                        //style={{ maxWidth: 600 }}
                    >


                        <Row style={{marginTop: "20px"}}>
                            <Col span={8}>

                                <Form.Item
                                    name="name"
                                    label={t("Ime")}
                                    rules={[{ required: true }, { type: 'string', warningOnly: true }]}
                                >
                                    <Input placeholder="Ime" />
                                </Form.Item>
                                <Form.Item
                                    name="surname"
                                    label={t("Priimek")}
                                    rules={[ { type: 'string', warningOnly: true }]}
                                >
                                    <Input placeholder="Priimek" />
                                </Form.Item>

                                <Form.Item
                                    name="birthdate"
                                    label={t("Datum_rojstva")}
                                    //rules={[ { warningOnly: true }]}
                                >
                                    <DatePicker format={"DD.MM.YYYY"} placeholder="Datum rojstva" />

                                    
                                </Form.Item>


                            </Col>
                            <Col span={4} offset={1} className={"text-center"}>

                                <Avatar icon={<UserOutlined/>} size={170}> </Avatar>


                            </Col>
                            
                            <Col span={10} offset={1}>
                                <div>
                                    <DopustList driverId={id ? id : ""} informParent={handleListChange} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item>
                                    <Space>
                                        <Button className={"orangeButton"} htmlType="submit">
                                            {t("Shraniti")}
                                        </Button>
                                        <Button className={"emptyButton"} onClick={BackToList}>
                                            {t("Ne_shranite_sprememb")}
                                        </Button>

                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>








                    </Form>


                </TabPane>


                <TabPane tab={t("Zgodovina_opravljenih_letov")} key="1">
                    <Card>


                        <DealsList  filter={ dealsFilter} />

                        {/* 
                        <RouteList DriverId={id ? id : undefined} />
                        */}

                    </Card>
                </TabPane>
                <TabPane tab={t("Ključni_kazalniki")} key="2">
                    <Card>
                        <List
                            size="small"
                            header={<div>Header</div>}
                            footer={<div>Footer</div>}
                            bordered
                            dataSource={ListData}
                            renderItem={(item) => <List.Item>{item}</List.Item>}
                        />
                    </Card>
                </TabPane>
                <TabPane tab={t("Dokumenti")} key="3">
                    <Card>
                        <Button onClick={showModal1} style={{
                            border: "1px dashed #d9d9d9",
                            borderTopLeftRadius: "5px",
                            borderBottomLeftRadius: "5px",
                            //borderRight: "0px",
                            height: "40px",
                            //width: "100%",
                            fontSize: "14px",
                            color: "#1449AE",
                            marginBottom: "8px",
                            padding: "8px 5px"}}>
                            + {t("Prenesite_dokument")}
                        </Button>
                        <DocList DriverId={  id ? id.toString() : undefined} />
                    </Card>
                </TabPane>
            </Tabs>


            <Modal open={isModalOpen}
                //onOk={handleModalOk}
                   title={t("Prenesite_dokument")}
                   centered
                   width={650}
                   onCancel={handleModalCancel}
                //footer={[]}
            >

                <UploadDoc objectId={id} objectMode={3}/>
            </Modal>

        </div>
        </Spin>
    );
};
export default DriverDetailsPage;