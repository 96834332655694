import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {Avatar, Button, Card, Col, Divider, Form, Input, message, Row, Dropdown} from "antd";
import type {  MenuProps } from 'antd';

import type { SelectProps } from 'antd';
import { Select, Space } from 'antd';

import {UserOutlined, DownOutlined} from "@ant-design/icons";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import {useTranslation} from "react-i18next";
import i18n from "i18next";



interface DataType {
    "id": string,
    "userName": string,
    "email": string,
    "phoneNumber": string,
    "firstNme": string,
    "lastName": string
}


const UserSettingsPage = () => {
    const {t} = useTranslation();

    const history= useHistory();
    const user = AuthService.getCurrentUser();
    const [userslang, setuserslang] = useState("en");
    const [langIcon, setlangIcon] = useState("🇬🇧");
   
    //console.log(user);
    if(!user) {
        history.push("/login");
    }

    const [form] = Form.useForm();
    const [Langform] = Form.useForm()
    
    const onFinishFailed = () => {
        message.error('Submit failed!');
    };


   // const  [ownInfo, setownInfo] = useState<any>();
    const  [ownId, setownId] = useState<any>();

    const onFinish = (values: any) => {

        axios.put(AuthService.DATA_URL + 'Contractor/updateOwnUser?id=' + ownId,
            {
                "email": values.email,
                "firstName": values.firstName,
                "lastName": values.lastName
            }, {headers: authHeader()})
            .then((res) => {
                    message.success('Podatki so bili uspešno shranjeni');
                }
            )
            .finally(() => {

            });
    }

    const [APIData, setAPIData] = useState<DataType>();

        useEffect(()=>{
           // setuserslang(user.language);
            if(!APIData)
                axios.get(AuthService.DATA_URL+'Contractor/get-ownInfo', {headers: authHeader()})
                    .then()
                    .then((response) => {
                        form.setFieldsValue({
                            firstName: response.data ? response.data.firstName : "rr",
                            lastName: response.data ? response.data.lastName : "",
                            email: response.data ? response.data.email : "",
                            login: response.data ? response.data.userName : "",
                        });
                        Langform.setFieldsValue({
                            lang:response.data ? response.data.language : "en"
                        });

//                        console.log("Request response.data: ", response.data)
                        setAPIData(response.data);

                        switch(response.data.language) {
                            case "en": {
                                setuserslang("English"); 
                                setlangIcon("en")
                                break;
                            }
                            case "si": {
                                setuserslang("Slovenščina");
                                setlangIcon("si")
                                break;
                            }
                            case "fr": {
                                setuserslang("Français");
                                setlangIcon("fr")
                                break;
                            }
                            case "de": {
                                setuserslang("Deutsch");
                                setlangIcon("de")
                                break;
                            }
                            case "ru": {
                                setuserslang("Русский");
                                setlangIcon("ru")
                                break;
                            }
                            case "it": {
                                setuserslang("Italiano");
                                setlangIcon("it")
                                break;
                            }
                            case "uk": {
                                setuserslang("Українська");
                                setlangIcon("uk")
                                break;
                            }


                            default: {
                                setuserslang("English");
                                setlangIcon("en")
                                break;
                            }
                        }

                        //setuserslang(response.data.language);
                        
                        
//                        console.log("setownInfo: ", response.data)


                        setownId(response.data.id);
//                        setlogoId(response.data.logoId);
                    })
        });


    
    const ChangeLanguage = (value: string)=>{
        console.log("value: ", value);
        var muser : any = {};
        const tt: any = localStorage.getItem("user")===null ? "" : localStorage.getItem("user");
        muser = JSON.parse(tt===null ? "-" : tt);
        muser.language = value;
        localStorage.setItem("user", JSON.stringify(muser));
        i18n.changeLanguage(value);

        axios.put(AuthService.DATA_URL+'Settings/change-language?lng='+value,
            {
                "lng": value
            },{headers: authHeader()})
            .then((response) => {
                i18n.changeLanguage(value);
                console.log(value)
            })


    }

    /*
    const options: SelectProps['options'] = [
        { value: 'en', emoji: '🇬🇧', label: 'English' },
        { value: 'si', emoji: '🇸🇮', label: 'Slovenščina' },
        { value: 'fr', emoji: '🇫🇷', label: 'Français' },
        { value: 'de', emoji: '🇩🇪', label: 'Deutsch' },
        { value: 'ru', emoji: '🇷🇺', label: 'Русский' },
        { value: 'it', emoji: '🇮🇹', label: 'Italiano' },
        { value: 'ukr', emoji: '🇺🇦', label: 'Українська' },
        ]
        
     */
    
    const items: MenuProps['items'] = [
        { key: 'en', icon: <img src="/img/lang/en.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; English</span> },
        { key: 'si', icon: <img src="/img/lang/si.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Slovenščina</span> },
        { key: 'fr', icon: <img src="/img/lang/fr.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Français</span> },
        { key: 'de', icon: <img src="/img/lang/de.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Deutsch</span>},
        { key: 'ru', icon: <img src="/img/lang/ru.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Русский</span> },
        { key: 'it', icon: <img src="/img/lang/it.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Italiano</span> },
        { key: 'uk', icon: <img src="/img/lang/uk.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Українська</span> },
    ]

    const handleLanguageClick: MenuProps['onClick'] = (e) => {
        var muser : any = {};
        const tt: any = localStorage.getItem("user")===null ? "" : localStorage.getItem("user");
        muser = JSON.parse(tt===null ? "-" : tt);
        muser.language = e.key;
        localStorage.setItem("user", JSON.stringify(muser));
        i18n.changeLanguage(e.key);

        axios.put(AuthService.DATA_URL+'Settings/change-language?lng='+e.key,
            {
                "lng": e.key
            },{headers: authHeader()})
            .then((response) => {
                i18n.changeLanguage(e.key);
//                console.log(e.key)
            })

    };

    const menuProps = {
        items,
        onClick: handleLanguageClick,
    };
    
    
    return(
        <div>
            <Row style={{paddingTop: "10px"}}>
                <Col span={3} style={{textAlign: "center"}}>
                    <Avatar size={96} icon={<UserOutlined />} />
                </Col>
                <Col span={6}>
                    
                        <Button className={"emptyButton"} htmlType="submit" style={{marginTop: "10px"}}>
                            + {t("Prenesite_sliko")}
                        </Button>
                    <p style={{color: "#6B6E74"}}>{t("Prenesete_lahko_slike_velikosti_do")} <br/> 256x256.</p>

                   
                </Col>
                <Col>
                    <Form
                        form={Langform}
                        name="Langform"
                    >

                        <Form.Item
                            name="lang2"
                            label={""}
                        >
                            <Space wrap>
                        <Dropdown menu={menuProps}>
                            <Button>
                                <Space>
                                    <img src={"/img/lang/"+langIcon+".svg"}  style={{width:"12px", height: "12px"}}/>
                                    {userslang}
                                    <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                            </Space>
                        </Form.Item>
                        
                    </Form>
                    
                </Col>
            </Row>

                <Card style={{marginTop: "20px"}}>
                    <Form
                        form={form}
                        name="wrap"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        layout="horizontal"
                        labelCol={{ flex: '120px' }}
                        labelAlign="right"
                        labelWrap
                        wrapperCol={{ flex: 1 }}
                        colon={false}
                        //style={{ maxWidth: 600 }}
                    >
                        
                        <Row>
                            <Col span={8}>
                                <Form.Item
                                    name="firstName"
                                    label={t("Ime")}
                                    rules={[{ required: true }, { type: 'string', warningOnly: true }]}
                                >
                                    <Input placeholder="Ime" />
                                </Form.Item>
                                <Form.Item
                                    name="lastName"
                                    label={t("Priimek")}
                                    rules={[{ required: true }, { type: 'string', warningOnly: true }]}
                                >
                                    <Input placeholder="Ime" />
                                </Form.Item>
                                
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="email"
                                    label={t("E_pošta")}
                                >
                                    <Input placeholder="Ime"  />
                                </Form.Item>
                                <Form.Item
                                    name="login"
                                    label={t("Login")}
                                >
                                    <Input placeholder="Ime" bordered={false} disabled={true} />
                                </Form.Item>

                            </Col>
                        </Row>
                            <Divider/>
                        <Row>
                            <Col span={8}>
                                <Form.Item
                                    name="oldPassword"
                                    label={t("Staro_geslo")}
                                    rules={[ { type: 'string', warningOnly: true }]}
                                >
                                    <Input placeholder="Ime" />
                                </Form.Item>
                                <Form.Item
                                    name="newPassword"
                                    label={t("Novo_geslo")}
                                    rules={[ { type: 'string', warningOnly: true }]}
                                >
                                    <Input placeholder="Ime" />
                                </Form.Item>
                                <Form.Item
                                    name="newPasswordRepeat"
                                    label={t("Novo_geslo")}
                                    rules={[ { type: 'string', warningOnly: true }]}
                                >
                                    <Input placeholder="Ime" />
                                </Form.Item>
                                <Button className={"orangeButton"} htmlType="submit" style={{marginLeft:"120px"}}>
                                    {t("Shraniti")}
                                </Button>


                            </Col>
                        </Row>
                        

                    </Form>
                </Card>
                









        </div>
    )};
export default UserSettingsPage;