import React, {useEffect, useState} from 'react';
import {Button, Space, Table, Input, Collapse, Select, DatePicker, Badge, Tabs, Checkbox} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {DownOutlined } from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import axios from "axios";
import * as AuthService from "../../Services/auth-service";
import authHeader from "../../Services/auth-header";
import {CollapsibleType} from "antd/es/collapse/CollapsePanel";
import {useTranslation} from "react-i18next";
import TabPane from "antd/es/tabs/TabPane";
import ExchangeList from "./RouteComponents/eschangeList";
import dayjs from "dayjs";
import { Link } from 'react-router-dom';

//import * as XLSX from 'xlsx/xlsx.mjs';




const MyRoutesPage = () => {
    const XLSX = require('xlsx');
    const {t} = useTranslation();
    const history= useHistory();

    ///

    const user = AuthService.getCurrentUser();
    if(!user){
        AuthService.logout();
        history.push("/login");
        window.location.reload();

    }

    //const { TabPane } = Tabs;
    interface DataType {
        key: string;
        id : string;
        routeNumber : string;
        externalRequestNumber : string;
        from : string;
        to : string;
        fromCountry : string;
        toCountry : string;
        fromto : string;
        fromdate: string;
        fromtime : string;
        todate: string;
        totime : string;
        price: number;
        client: string;
        receiver: string;
        payer : string;
        requestStatus : number;
        traktor : string;
        priklopnik : string;
        driver : string;
        rpcount : number;
        priceForClient? : number | null;
        priceToShowToSubcontractors? : number | null;

    }
    
    
    type RequestDTO={
        id: string;
        requestNumber: string;
        externalRequestNumber:string;
        createdByContractorId : string;
        price : number;
        distance : number;
        loadingType: number;
        rollingStockType: number;
        requestStatus: number;
        cargoDescription : string;
        weight : number,
        volume: number,
        clientName : string,
        receiverName: string;
        payerName : string;
        track : any;
        priklopnik: any;
        client:any;
        receiver :any;
        payer : any;
        driver : any;
        routePoints: any[];
    }

    
    const [searchText, setSearchText] = useState("")

    const [selectedStatus, setselectedStatus] = useState(100)
    const [selectedDates, setsselectedDates] = useState<string>("")
    
    const[filterStartDate, setfilterStartDate]=useState<string|null>(null)
    const[filterEndDate, setfilterEndDate]=useState<string|null>(null)

    
    const setSelectedStatusF = (v : number)=> {

        localStorage.setItem("selectedStatus", v.toString());
        setselectedStatus(v)

}
    
    const setselectedDatesF =(v:any)=>{
        
        if(v!=="" && v!==null){
            localStorage.setItem("selectedDates", JSON.stringify(v))//Date.parse(v[0]).toString()+"-"+Date.parse(v[1]).toString());
            setsselectedDates(Date.parse(v[0]).toString()+"-"+Date.parse(v[1]).toString())

            setfilterStartDate(dayjs(Date.parse(v[0])).format("DD.MM.YYYY"))
            setfilterEndDate(dayjs(Date.parse(v[1])).format("DD.MM.YYYY"))
        }
        if(v==="" || v===null){
            localStorage.removeItem("selectedDates");
        }

    }
    
    
    // @ts-ignore
    const columns: ColumnsType<DataType> = [
        {
            title: t('Številka'),
            dataIndex: 'routeNumber',
            width: 110,
            filteredValue :[searchText],
            onFilter: (value, record) => {
                return (
                    String(record.routeNumber).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.fromdate).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.fromtime).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.traktor ).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.priklopnik).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.driver).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.fromCountry).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.toCountry).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.from).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.to).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.client).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.price).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.todate).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.externalRequestNumber).toLowerCase().includes(value.toString().toLowerCase())||
                   String(record.totime).toLowerCase().includes(value.toString().toLowerCase())
                    

                )
            },
            render: (text, record) => {
                //var statusspan="<span color: green>&bull;</span>";
                
                if(record.requestStatus === 0) {
                    return (
                        <div>
                            {text}{text!==record.externalRequestNumber ? record.externalRequestNumber.length>0 ? <span style={{fontSize: "10px"}}><br/>{record.externalRequestNumber}</span>: "" :""}
                            <br/>
                            <span className={"dot"}  style={{backgroundColor: "#E3601D", verticalAlign: "middle"}}> </span><span className={"greyLabel"}>{t("Novo")}</span>
                        </div>
                    )
                }
                if(record.requestStatus === 1) {
                    return (
                        <div>
                            {text}{text!==record.externalRequestNumber ? record.externalRequestNumber.length>0 ? <span style={{fontSize: "10px"}}><br/>{record.externalRequestNumber}</span>: "" :""}
                            <br/>
                            <span className={"dot"}  style={{backgroundColor: "#1FAA73", verticalAlign: "middle"}}> </span><span className={"greyLabel"}>{t("Načrtovano")}</span>
                        </div>
                    )
                }
                if(record.requestStatus === 20) {
                    return (
                        <div>
                            {text}{text!==record.externalRequestNumber ? record.externalRequestNumber.length>0 ? <span style={{fontSize: "10px"}}><br/>{record.externalRequestNumber}</span>: "" :""}
                            <br/>
                            <span className={"dot"} style={{backgroundColor: "#faad14", verticalAlign: "middle"}}> </span><span  className={"greyLabel"}>{t('Načrtovana')}</span>
                        </div>
                    )
                }
                if(record.requestStatus === 30) {
                    return (
                        <div>
                            {text}{text!==record.externalRequestNumber ? record.externalRequestNumber.length>0 ? <span style={{fontSize: "10px"}}><br/>{record.externalRequestNumber}</span>: "" :""}
                            <br/>
                            <span className={"dot"} style={{backgroundColor: "#1FAA73", verticalAlign: "middle"}}> </span><span  className={"greyLabel"}>{t('Confirmed')}</span>
                        </div>
                    )
                }
                if(record.requestStatus === 40) {
                    return (
                        <div>
                            {text}{text!==record.externalRequestNumber ? record.externalRequestNumber.length>0 ? <span style={{fontSize: "10px"}}><br/>{record.externalRequestNumber}</span>: "" :""}
                            <br/>
                            <span className={"dot"} style={{backgroundColor: "#1890FF", verticalAlign: "middle"}}> </span><span  className={"greyLabel"}>{t('On_route')}</span>
                        </div>
                    )
                }
                if(record.requestStatus === 50) {
                    return (
                        <div>
                            {text}{text!==record.externalRequestNumber ? record.externalRequestNumber.length>0 ? <span style={{fontSize: "10px"}}><br/>{record.externalRequestNumber}</span>: "" :""}
                            <br/>
                            <span className={"dot"}  style={{backgroundColor: "#A4A6AC", verticalAlign: "middle"}}> </span><span  className={"greyLabel"}>{t('Zaključena')}</span>
                        </div>
                    )
                }
                if(record.requestStatus === 52) {
                    return (
                        <div>
                            {text}{text!==record.externalRequestNumber ? record.externalRequestNumber.length>0 ? <span style={{fontSize: "10px"}}><br/>{record.externalRequestNumber}</span>: "" :""}
                            <br/>
                            <span className={"dot"}  style={{backgroundColor: "red", verticalAlign: "middle"}}> </span><span  className={"greyLabel"} style={{color: "red"}}>{t('Cancel_offer')}</span>
                        </div>
                    )
                }

                    return (
                        <div>
                            {text}
                            <br/>
                        </div>
                    )

                
        }},
        {
            title: t('Itinerarij'),
            dataIndex: 'fromto',
            width: 350,
            filteredValue: [selectedStatus],
            onFilter: (value, record) => {
                // console.log("record: ", record);

                //console.log("hideFinished", hideFinished)
                //console.log("record.requestStatus.toString(): ",record.requestStatus.toString())
                return (
                    (record.requestStatus.toString() === value.toString() || value.toString() === "100")
                   &&
                    ((!hideFinished && record.requestStatus.toString()!=="50") || (hideFinished ))  
                    ) 
            },
            //sorter: (a, b) => a.regNumber.length - b.regNumber.length,
            //sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
            ellipsis: true,
            defaultSortOrder: 'ascend',
            sorter: (a: any, b: any) => {
                a = a.fromdate.toLowerCase()
                b = b.fromdate.toLowerCase()
                return a > b ? -1 : b > a ? 1 : 0
                //return new Date(a.fromdate).valueOf() - new Date(b.fromdate).valueOf()
            },
            render: (text, record) => {
                if (record.rpcount === 1) {
                    return (
                        <div className="RoutePoints">
                            <div className="point start" title="">
                                <div className={"timeStart"}> 
                            <span>{record.fromdate}<br/>
                                <span className={"routeTime"}>{record.fromtime}</span>
                            </span>
                                </div>
                                <span className="name_sCDti">{record.from} <br/> 
                            <span className={"greyLabel"}>{record.fromCountry}</span> </span>
                                <div className="truncate description_liDDR"></div>
                            </div>
                        </div>)
                }
                if (record.rpcount > 1) {
                    return (
                        <div className="RoutePoints">

                            <div className="point start" title="">
                                <div className={"timeStart"}> 
                            <span>{record.fromdate}<br/>
                                <span className={"routeTime"}>{record.fromtime}</span>
                            </span>
                                </div>
                                <span className="name_sCDti">{record.from} <br/> 
                            <span className={"greyLabel"}>{record.fromCountry}</span> </span>
                                <div className="truncate description_liDDR"></div>
                            </div>
                            <div className="between"></div>
                            <div className="point end" title="">
                                <span className="name_eCDti">{record.to} <br/>
                                <span className={"greyLabel"}>{record.toCountry}</span></span>
                                <div className={"timeEnd"}>
                                <span>
                            {record.todate}<br/>
                                <span className={"routeTime"}>{record.totime}</span>
                                </span>
                                </div>
                                <div className="truncate description_liDDR"></div>
                            </div>
                        </div>)
                }
            }
        },
        {
            title: t('Izvajalec'),
            dataIndex: "traktor",
            width: 160,
            filteredValue: [selectedDates ? selectedDates : "" ],
            onFilter: (value, record) => {
                //console.log("filter value:", value);
                //console.log("filter selectedDates", selectedDates)
                var fs=value.toString().split("-");
                var f=parseInt(fs[0]);
                var t=parseInt(fs[1]);
                var nd= record.fromdate.substring(3,5)+"/"+record.fromdate.substring(0,2)+"/"+record.fromdate.substring(6,10)
                var check = Date.parse(nd);
                //console.log("f: ", f, "check: ", check, " t: ", t );

                return ((f<=check && check<=t )|| selectedDates==="" || selectedDates===null)
            },
            
            //onFilter: (value, record)=>{return (value<Date.parse(record.fromdate)) },
            render: (text, record) => {
                if(record.traktor.length===0){
                    return(
                        <div>
                            <Link className={"orangeA"} to={`/routes/view/${record.id}`}>
                                {t("Izberite")}
                            </Link>
                            

                            
                        </div>
                    )
                }
                if(record.priklopnik.length===0){
                    return(
                        <div>
                            <div className={"tovornjak_div"}>
                                <img src={"/img/tovornjak.png"} alt={" "} />
                                {record.traktor}
                            </div>
                            <div style={{fontSize: "12px"}}>{record.driver}</div>
                        </div>
                    )
                }
                else{
                    return(
                        <div>
                            <div className={"traktor_div"}>
                                <img src={"/img/traktor.png"} alt={" "} />
                                {record.traktor}
                            </div>
                            <div  className={"priklopnik_div"}>
                                <img src={"/img/priklopnik.png"} alt={" "} />
                                <div style={{clear: "right"}}>{record.priklopnik}</div>
                            </div>
                            <div style={{fontSize: "12px"}}>{record.driver}</div>

                        </div>

                    )
                }
                
            }
        
        },
        {
            title: t('Cena'),
            dataIndex: 'price',
            width: 150,
            align: 'right',
            sorter: (a:any, b:any) => a.price - b.price,
            render:(text, record) => <div>
                {
                    user.isTransporter ?
                        record.priceToShowToSubcontractors 
                            ? record.priceToShowToSubcontractors.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")
                            : text.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")
                        :
                    text.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")} €
                <br/><span className={"routeTime"}> {record.client.substring(0,30)}</span>
                
            </div>
        },
        {
            title: ' ',
            key: 'operation',
            fixed: 'right',
            width: 70,
            render: (text, record) =><span> 
                <Link to={`/routes/view/${record.id}`}>{t("Več")} &rarr;</Link>
                {/*<a className={"actionLink"} href={"routes/view/:?id="+record.id }>{t("Več")} &rarr;</a>  */} 
                  

            </span>,
        },
    ];
    

    const [APIData, setAPIData] = useState<RequestDTO[]>([]);
    const [isLoading, setisLoading] = useState(true);


    

    useEffect(() => {
            axios.get(AuthService.DATA_URL+`Request/get-dto`, {headers: authHeader()})
                .then((response) => {

                    //console.log("user", user)
                    //console.log("DTO response", response)
                    setAPIData(response.data);

                    setisLoading(false);
                })
                .catch(function (error) {
                    if(error.response.status===401){
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                })
    }, []);
    
    useEffect(()=>{
        if(localStorage.getItem("selectedStatus")!==null){
            const filter_status: number=Number(localStorage.getItem("selectedStatus"));
            if(filter_status!==100){
                //console.log("filter_status: ", filter_status)
                setSelectedStatusF(filter_status);
                setshowFilterBadge(true);
            }

            
            const dates_status: any=localStorage.getItem("selectedDates");
            if(dates_status!=="" && dates_status!==null){
                //console.log("dates_status: ", dates_status)
                setselectedDatesF(JSON.parse(dates_status));
                setshowDatesBadge(true);

            }
            if(dates_status==="" && dates_status===null){
                setsselectedDates("");
                setshowDatesBadge(false);
            }




        }
    },[APIData])

    
    
    const TableData = APIData.map((tr, index) => ({
        key: tr.id,
        id: tr.id,
        routeNumber: tr.createdByContractorId===user.contractorId ?  tr.requestNumber : tr.externalRequestNumber, //"TN-12.2023",
        externalRequestNumber : tr.externalRequestNumber ? tr.externalRequestNumber : "",
        from : tr.routePoints[0] ? tr.routePoints[0].streetName :"", //"Франкфурт-на-Майне",
        to : tr.routePoints[1] ? tr.routePoints[1].streetName : "",//"Крань",
        fromCountry: tr.routePoints[0] ? tr.routePoints[0].cityName+", "+tr.routePoints[0].countryName : "",//"Германия",
        toCountry :  tr.routePoints[1] ? tr.routePoints[1].cityName+", "+tr.routePoints[1].countryName :"",//"Словения",
        fromto : "genm",
        //fromdate: tr.routePoints[0].dateStart,// "01.10.2023 15:30",
        fromdate: tr.routePoints[0] ? ( tr.routePoints[0].dateStart ? tr.routePoints[0].dateStart.substring(8,10)+"."+tr.routePoints[0].dateStart.substring(5,7)+"."+tr.routePoints[0].dateStart.substring(0,4) : t("nedatirano")) : "",
        fromtime:tr.routePoints[0] ? ( tr.routePoints[0].dateStart ? tr.routePoints[0].dateStart.substring(11,16): "" ): "-",
        todate:tr.routePoints[1] ? (tr.routePoints[1].dateStart ? tr.routePoints[1].dateStart.substring(8,10)+"."+tr.routePoints[1].dateStart.substring(5,7)+"."+tr.routePoints[1].dateStart.substring(0,4) : t("nedatirano") ): "",
        totime:tr.routePoints[1] ? (tr.routePoints[1].dateStart ? tr.routePoints[1].dateStart.substring(11,16) : "" ): "-",
        price: tr.price,//parseFloat(tr.price),//parseFloat(tr.price),//"1 200",
        client: tr.client?  tr.client.title : "-",
        requestStatus : tr.requestStatus, //1,
        traktor : tr.track ? tr.track.regNumber : "",
        priklopnik: tr.priklopnik ? tr.priklopnik.regNumber : "",//tr.priklopnik.RegNumber,//"LJ NB-064",
        driver: tr.driver ? tr.driver.name+" "+tr.driver.surname : "",//tr.driver.name
        receiver: tr.receiver ? tr.receiver.title : "-",
        payer : tr.payer ? tr.payer.title : "-",
        rpcount:tr.routePoints.length

    }) )
    

    const GoToDetails = () => {
        history.push("/newroute");
    };
    
    
    
    const DowmloadExcel =() =>{
        var _table = document.createElement("table");
        const tblBody = document.createElement("tbody");
        TableData.forEach((item)=>{
            const row = document.createElement("tr");
            
            
            //id : string;
            //routeNumber : string;
            var cellText = document.createTextNode(item.routeNumber);
            cellText = document.createTextNode(item.routeNumber)
            const cell = document.createElement("td");
            cell.appendChild(cellText);  row.appendChild(cell);
            tblBody.appendChild(row);
            
            //client: string;
            cellText = document.createTextNode(item.client)
            const cell1 = document.createElement("td");
            cell1.appendChild(cellText);  row.appendChild(cell1);
            
            //fromdate: string;
            cellText = document.createTextNode(item.fromdate)
            const cell2 = document.createElement("td");
            cell2.appendChild(cellText);  row.appendChild(cell2);
            //fromtime : string;
            cellText = document.createTextNode(item.fromtime)
            const cell21 = document.createElement("td");
            cell21.appendChild(cellText);  row.appendChild(cell21);

            //fromCountry : string;
            cellText = document.createTextNode(item.fromCountry)
            const cell3 = document.createElement("td");
            cell3.appendChild(cellText);  row.appendChild(cell3);
            //from : string;
            cellText = document.createTextNode(item.from)
            const cell4 = document.createElement("td");
            cell4.appendChild(cellText);  row.appendChild(cell4);
            //todate: string;
            cellText = document.createTextNode(item.todate)
            const cell5 = document.createElement("td");
            cell5.appendChild(cellText);  row.appendChild(cell5);
            //totime : string;
            cellText = document.createTextNode(item.totime)
            const cell6 = document.createElement("td");
            cell6.appendChild(cellText);  row.appendChild(cell6);

            //toCountry : string;
            cellText = document.createTextNode(item.toCountry)
            const cell7 = document.createElement("td");
            cell7.appendChild(cellText);  row.appendChild(cell7);
            //to : string;
            cellText = document.createTextNode(item.to)
            const cell8 = document.createElement("td");
            cell8.appendChild(cellText);  row.appendChild(cell8);
            //fromto : string;


            //price: number;
            cellText = document.createTextNode(item.price.toString())
            const cell9 = document.createElement("td");
            cell9.appendChild(cellText);  row.appendChild(cell9);

            //requestStatus : number;
            
            //traktor : string;
            cellText = document.createTextNode(item.traktor)
            const cell10 = document.createElement("td");
            cell10.appendChild(cellText);  row.appendChild(cell10);
            //priklopnik : string;
            cellText = document.createTextNode(item.priklopnik)
            const cell11 = document.createElement("td");
            cell11.appendChild(cellText);  row.appendChild(cell11);

            //driver : string;
            cellText = document.createTextNode(item.driver)
            const cell12 = document.createElement("td");
            cell12.appendChild(cellText);  row.appendChild(cell12);
            tblBody.appendChild(row);




        });
        _table.appendChild(tblBody);
        const workbook = XLSX.utils.table_to_book(_table, { sheet: '_table' })

        XLSX.writeFile(workbook, 'table.xls')


    }



    const [filterShow, setfilterShow] = useState('disabled' as CollapsibleType)
    const [filterKey, setfilterKey] = useState(["0"])

    
    

    
    const ShowFilters=()=>{
       // console.log("filterShow: ", filterShow);
        if(filterShow.toString()==="disabled"){
            setfilterShow("head" as CollapsibleType);
            setfilterKey(["1"]);
            //setfilterShow("disabled" as CollapsibleType);
        }
        else{
            setfilterKey(["0"]);
            setfilterShow("disabled" as CollapsibleType);
        }
    }

    const { RangePicker } = DatePicker;



    const [showFilterBadge, setshowFilterBadge] = useState(false)
    const [showDatesBadge, setshowDatesBadge] = useState(false)


    
    const[hideFinished, sethideFinished]=useState(false)
    const showhideFinished=(e :any)=>{
        if(e.target.checked ) {
            sethideFinished(true)
        }
        else{
            sethideFinished(false)
        }
    }
    
    //console.log("selectedStatus:", selectedStatus)

    
    return (
        <div>
            <h1>{t("Naročila_in_poti")}</h1>



            <Tabs defaultActiveKey="1" >
                <TabPane tab={t("Seznam_narocil")} key="1">
            
            
            
            
            <div style={{marginBottom: "20px"}}>
                {/* 
            <Space style={{ marginBottom: 0 }}>
                
                <Badge color={"orange"} dot={showFilterBadge}>
                <Button className={"emptyB"} onClick={ShowFilters}>
                    <Space>
                        Filtri
                        <DownOutlined />
                    </Space>
                </Button>
                </Badge>

            </Space>
                */}
                <Space>
                    <div>
                        <Badge color={"orange"} dot={showFilterBadge}>
                        <Select
                            defaultValue={selectedStatus}
                            value={selectedStatus}
                            labelInValue={false}
                            //                                  style={{ width: 120 }}
                            bordered={false}
                            popupMatchSelectWidth={false}
                            options={[
                                { value: 100, label: t('Vsa') },
                                { value: 0, label: t('Nova') },
                                { value: 20, label: t('Načrtovana') },
                                { value: 1, label: t('Načrtovano') },
                                { value: 30, label: t('Confirmed') },
                                { value: 40, label: t('On_route') },
                                { value: 50, label: t('Zaključena') },
                            ]}
                            onChange={(v:number)=>{
                                setshowFilterBadge(true);
                                if(v===100){
                                        setshowFilterBadge(false);
                                }
                                setSelectedStatusF(v)}}
                        />
                        </Badge>
                        <Badge color={"orange"} dot={showDatesBadge}>
                        <RangePicker bordered={false}
                                     style={{width: "220px", borderBottom: "1px solid #999"}}
                                     placeholder={[t("Od_datum"),t("Do_datum")]}
                                     format={'DD.MM.YYYY'}
                                     //defaultValue={[dayjs(filterStartDate,'DD.MM.YYYY'), dayjs(filterEndDate,'DD.MM.YYYY')]}
                                     
                                     value={[filterStartDate? dayjs(filterStartDate, 'DD.MM.YYYY') : null, filterEndDate? dayjs(filterEndDate, 'DD.MM.YYYY'):null]}
                                     
                                     onChange={(v:any)=>{
                                        // console.log("v: ", v)
                                         //console.log("selectedDates Date.parse: ", Date.parse(v[0]).toString()+"-"+Date.parse(v[1]).toString())
                                         if(v){
                                             setsselectedDates(Date.parse(v[0]).toString()+"-"+Date.parse(v[1]).toString())
                                             setshowDatesBadge(true)
                                         }else{
                                             setsselectedDates("");
                                             setshowDatesBadge(false)
                                             setfilterStartDate(null);
                                             setfilterEndDate(null);
                                             //if(selectedStatus===100){
                                             //    setshowFilterBadge(false)
                                             //}
                                         }
                                         setselectedDatesF(v)

                                     }} />
                        </Badge>
                        
                        <Checkbox style={{marginLeft: "40px"}} onClick={(e)=>{showhideFinished(e) }}>{t('Show_finished')}</Checkbox>


                    </div>
                </Space>
            <Space style={{float:'right'}}>
                <Input.Search className={"emptyB"}
                       placeholder={t("Išči")}
                       onSearch={(value)=>{
                           setSearchText(value)
                       }}
                      onChange={(e)=>{
                          setSearchText(e.target.value)  
                      }}

                       //suffix={
                       //    <Tooltip title="Extra information">
                       //        <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                       //    </Tooltip>
                       //}
                />

                <Button onClick={GoToDetails}  className={"orangeButton"}>+ {t("Novo_naročilo")}</Button>
                <Button  className={"emptyButton"}
                         onClick={DowmloadExcel}
                
                >{t("Prenesite_v_Excel")}</Button>

            </Space>
            </div>


            {/* 
            <Collapse 
                style={{paddingTop:"3px", marginTop: "-15px"}}
                ghost
                collapsible={filterShow}
                      activeKey={filterKey}
                  items={
                      [{
                          key: "1",
                          label: "",
                          children: <div>
                              <Select
                                  defaultValue={selectedStatus}
//                                  style={{ width: 120 }}
                                  bordered={false}
                                  dropdownMatchSelectWidth={false}
                                  options={[
                                      { value: 100, label: 'Vsa' },
                                      { value: 0, label: 'Nova' },
                                      { value: 1, label: 'Načrtovana' },
                                      { value: 2, label: 'Zaključena' },
                                  ]}
                                  onChange={(v:number)=>{
                                      setshowFilterBadge(true); 
                                      if(v===100){
                                          if(selectedDates==="")
                                          setshowFilterBadge(false);
                                      }
                                      setselectedStatus(v)}}
                              />
                              <RangePicker bordered={false}
                                           style={{width: "220px", borderBottom: "1px solid #999"}}
                                           placeholder={["Od","Do"]}
                                           format={'DD.MM.YYYY'} 
                                           onChange={(v:any)=>{ 
                                               if(v){
                                                   setsselectedDates(Date.parse(v[0]).toString()+"-"+Date.parse(v[1]).toString())
                                                   setshowFilterBadge(true)
                                               }else{
                                                setsselectedDates("")
                                                   if(selectedStatus===100){
                                                       setshowFilterBadge(false)
                                                   }
                                               }
                                               
                              }} />
                              
                              
                          </div>,
                          showArrow: false,
                      }]
                  }
            />
*/}

            <div style={{background: '#fff', paddingTop: "0px"}} id={"driverstab"}>




            <Table
                locale={{
                    triggerDesc: t('Kliknite_za_razvrščanje_padajoče'),
                    triggerAsc: t('Kliknite_za_razvrščanje_naraščajoče'),
                    cancelSort: t('Prekliči_razvrščanje')
                }}
                columns={columns}
                dataSource={TableData}
                loading={isLoading}
                pagination={false}
                scroll={{ y: 'calc(53vh)' }}
                //pagination={{ pageSize: 6 }} size="small"  scroll={{ y: 'calc(60vh - 4em)' }}
            />
            </div>
        
                </TabPane>
                <TabPane tab={t("Odprte_narocila")} key="2">
                    <ExchangeList />
                </TabPane>
            </Tabs>
                    
        </div>
    );
};
export default MyRoutesPage;