import React, {useEffect, useState} from 'react';
import * as AuthService from "../../../Services/auth-service";
import {useHistory} from "react-router-dom";
import {Button, Card, Col, Form, Input, message, Row, Space, Switch, Tabs} from "antd";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import {useTranslation} from "react-i18next";
import RouteList from "../../myroutes/RouteComponents/list";
import DocList from "../../docs/DocComponents/docList";


interface CreateContractorProps {
    afterCreate?: (data: any) => string;
    isClient? : boolean;
}

export default function CreateContractor({ afterCreate, isClient} : CreateContractorProps) {

    const history= useHistory();

    const {t} = useTranslation();


    const user = AuthService.getCurrentUser();
    if(!user){
        history.push("/login");
    }

    const [form] = Form.useForm();
    const [formV] = Form.useForm();


    const { TabPane } = Tabs;

    const[componentDisabled, setComponentDisabled]=useState(false);

    const[isClientVal, setisClientVal]=useState(isClient? isClient : false);

    useEffect(()=>{
        if(user.isTransporter){
            setisClientVal(true);
            setComponentDisabled(true);
        }
    })


    const showval=(v: any)=>{
        console.log("v: ",v);
        console.log("FieldValue: ",form.getFieldValue("isClient"))
        setisClientVal(v);

    }

    const onFinish=(values: any)=>{
        axios.post(AuthService.DATA_URL+'Contractor/post-response/',
            {

                // "id": "",
                "title": values.title,
                "davcnaStevilka": values.davcnaStevilka,
                "maticnaStevilka": values.maticnaStevilka,
                "adress1": values.adress1,
                "adress2": values.adress2,
                "iban": values.iban,
                "swift": values.swift,
                "userid":"",
                "OwnerUserId":"",
                "logoid" : "",
                "podpisId": "",
                "zigId":"",
                "tel":values.tel,
                "fax": values.fax,
                "contactPerson": values.contactPerson,
                "isClient": isClientVal//values.isClient//===true? "checked" : ""

                //"isCLient": isClient ? true : false
            },{headers: authHeader()})
            .then((res) =>{
                    message.success('Submit success!');
                    if(afterCreate){
                        console.log(res);
                        afterCreate(res);

                    }
                    else{
                        history.push("/contractors");
                    }

                }
            );

    }

    const BackToList = () =>{
        if(afterCreate){
            afterCreate("");
        }
        else{
            history.push("/contractors");
        }
    }







    if(afterCreate){
        return(
            <div>
                <Form
                    form={formV}
                    name="formV"
                    onFinish={onFinish}
                    autoComplete="off"
                    //layout="horizontal"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 12 }}
                    style={{ maxWidth: "600" }}

                >
                    <Switch
                        style={{float:"right", display: "none"}}
                        checkedChildren={t("Stranka")}
                        unCheckedChildren={t("Izvajalec")}
                        onChange={(e)=>showval(e)}
                        checked={true}
                        disabled={componentDisabled}

                        //defaultChecked
                    />

                            <Form.Item
                                name="title"
                                label={t("Naziv")}
                                rules={[{ required: true }, { type: 'string', warningOnly: true }]}
                            >
                                <Input placeholder="input placeholder" />
                            </Form.Item>
                            <Form.Item
                                name="davcnaStevilka"
                                label={t("Davčna_Številka")}
                                rules={[{ type: 'string', warningOnly: true }]}
                            >
                                <Input placeholder="input placeholder" />
                            </Form.Item>

                            <Form.Item
                                name="maticnaStevilka"
                                label={t("Matična_Številka")}
                                rules={[{ type: 'string', warningOnly: true }]}
                            >
                                <Input placeholder="input placeholder" />
                            </Form.Item>
                            <Form.Item
                                name="adress1"
                                label={t("Naslov")+" (1)"}
                                rules={[{ type: 'string', warningOnly: true }]}
                            >
                                <Input placeholder="input placeholder" />
                            </Form.Item>
                            <Form.Item
                                name="adress2"
                                label={t("Naslov")+" (2)"}
                                rules={[{ type: 'string', warningOnly: true }]}
                            >
                                <Input placeholder="input placeholder" />
                            </Form.Item>
                            <Form.Item>
                                <Space>
                                    <Button className={"orangeButton"} htmlType="submit">
                                        {t("Shraniti")}
                                    </Button>
                                    <Button className={"emptyButton"} onClick={BackToList} >
                                        {t("Ne_shranite_sprememb")}
                                    </Button>

                                </Space>
                            </Form.Item>
                </Form>

            </div>
        );
        
    }


    

    return(
      <div>

          <h1>{t("Nov_partner")}</h1>
          <Tabs defaultActiveKey="0" //activeKey={tabActiveKey}
              //onTabClick={theTabClick}
          >
              <TabPane tab={t("Osnovni_podatki")} key="0">


              <Form
              form={form}
              name="wrap"
              onFinish={onFinish}
              autoComplete="off"
              layout="horizontal"
              labelCol={{ flex: '120px' }}
              labelAlign="right"
              labelWrap
              wrapperCol={{ flex: 1 }}
              colon={false}
          >
                  <Row>
                      <Col span={8}>
                          <Form.Item
                              name="isClient"
                              label={""}
                              valuePropName="checked"

                          >
                              <Switch
                                  style={{float:"right"}}
                                  checkedChildren={t("Stranka")}
                                  unCheckedChildren={t("Izvajalec")}
                                  onChange={(e)=>showval(e)}
                                  checked={isClientVal}
                                  //defaultChecked
                              />
                              <span  style={{float:"right", marginRight: "10px"}}>{t("Stranka_ali_izvajalec")}</span>
                          </Form.Item>

                      </Col>
                  </Row>


              <Row>
                  <Col span={8}>

                      <Form.Item
                          name="title"
                          label={t("Naziv")}
                          rules={[{ required: true }, { type: 'string', warningOnly: true }]}
                      >
                          <Input placeholder="input placeholder" />
                      </Form.Item>
                      <Form.Item
                          name="davcnaStevilka"
                          label={t("Davčna_Številka")}
                          rules={[{ type: 'string', warningOnly: true }]}
                      >
                          <Input placeholder="input placeholder" />
                      </Form.Item>

                      <Form.Item
                          name="maticnaStevilka"
                          label={t("Matična_Številka")}
                          rules={[{ type: 'string', warningOnly: true }]}
                      >
                          <Input placeholder="input placeholder" />
                      </Form.Item>


                      <Form.Item
                          name="contactPerson"
                          label={t("Kontaktna_oseba")}
                          rules={[{ type: 'string', warningOnly: true }]}
                      >
                          <Input  placeholder="input placeholder" />
                      </Form.Item>



                  </Col>



                  <Col span={8}>
                      <Form.Item
                          name="adress1"
                          label={t("Naslov")+" (1)"}
                          rules={[{ type: 'string', warningOnly: true }]}
                      >
                          <Input  placeholder="input placeholder" />
                      </Form.Item>
                      <Form.Item
                          name="adress2"
                          label={t("Naslov")+" (2)"}
                          rules={[{ type: 'string', warningOnly: true }]}
                      >
                          <Input   placeholder="input placeholder" />
                      </Form.Item>
                      <Form.Item
                          name="tel"
                          label={t("Telefon")}
                          rules={[{ type: 'string', warningOnly: true }]}
                      >
                          <Input   placeholder="input placeholder" />
                      </Form.Item>
                      <Form.Item
                          name="fax"
                          label={t("Faks")}
                          rules={[{ type: 'string', warningOnly: true }]}
                      >
                          <Input   placeholder="input placeholder" />
                      </Form.Item>

                  </Col>
                  <Col span={8}>
                      <Form.Item
                          name="iban"
                          label="IBAN"
                      >
                          <Input  placeholder="SI56123412341234123"  />
                      </Form.Item>
                      <Form.Item
                          name="swift"
                          label="SWIFT"
                      >
                          <Input  placeholder="LJAASI1A"  />
                      </Form.Item>

                  </Col>
                  
                  
                  
              </Row>
              <Row>
                  <Col span={24}>
                      <Form.Item>
                          <Space>
                              <Button className={"orangeButton"} htmlType="submit">
                                  {t("Shraniti")}
                              </Button>
                              <Button className={"emptyButton"} onClick={BackToList} >
                                  {t("Ne_shranite_sprememb")}
                              </Button>

                          </Space>
                      </Form.Item>
                  </Col>
              </Row>
          </Form>
              </TabPane>


              <TabPane tab={t("Zgodovina_opravljenih_letov")} disabled={true} key="1">

              </TabPane>

              <TabPane tab={t("Dokumenti")} disabled={true}  key="2"
                       style={{
                           height:  "calc(75vh - 4em)",//"300px",
                           overflow: "scroll"
                       }}

              >
              </TabPane>
          </Tabs>


      </div>  
    );
}