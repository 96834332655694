import React from 'react';
import * as AuthService from "../../../Services/auth-service";
import {useHistory} from "react-router-dom";
import {Button, Col, Form, Input, message, Row, Space, DatePicker, Tabs, Card, List, Avatar, Divider} from "antd";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import {useTranslation} from "react-i18next";
import RouteList from "../../myroutes/RouteComponents/list";
import DocList from "../../docs/DocComponents/docList";
import {UserOutlined} from "@ant-design/icons";


interface CreateDocProps {
    afterCreate?: (data: any) => string;
}

export default function CreateDriver({ afterCreate} : CreateDocProps) {

    const {t} = useTranslation();
    const { TabPane } = Tabs;


    const history= useHistory();

    const user = AuthService.getCurrentUser();
    if(!user){
        history.push("/login");
    }

    const [form] = Form.useForm();
    const [formV] = Form.useForm();




    const onFinish=(values: any)=>{
        axios.post(AuthService.DATA_URL + 'Driver/post-response/',
            {

                // "id": "",
                "name": values.name,
                "surname": values.surname,
                "birthdate": values.birthdate,
                "licenseNumber": ""
            }, {headers: authHeader()})
            .then((res) => {
                    //message.success('Submit success!');
                    if (afterCreate) {
                        console.log(res);
                        afterCreate(res);

                    } else {
                        history.push("/drivers");
                    }

                }
            );

    }

    const BackToList = () =>{
        if(afterCreate){
            afterCreate("");
        }
        else{
            history.push("/drivers");
        }
    }

    

    return(
        <div>

            <h1>{t("Urejanje_voznika")}</h1>



            <Tabs defaultActiveKey="0" //activeKey={tabActiveKey}
                //onTabClick={theTabClick}
            >
                <TabPane tab={t("Osnovni_podatki")} key="0">
            <Form
                form={form}
                name="wrap"
                onFinish={onFinish}
                autoComplete="off"
                layout="horizontal"
                labelCol={{ flex: '120px' }}
                labelAlign="right"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
            >


                <Row  style={{marginTop: "20px"}}>
                    <Col span={8}>
                        <Form.Item
                            name="name"
                            label={t("Ime")}
                            rules={[{ required: true }, { type: 'string', warningOnly: true }]}
                        >
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item
                            name="surname"
                            label={t("Priimek")}
                            rules={[{ type: 'string', warningOnly: true }]}
                        >
                            <Input placeholder="input placeholder" />
                        </Form.Item>

                        <Form.Item
                            name="birthdate"
                            label={t("Datum_rojstva")}
                            //rules={[{  warningOnly: true }]}
                        >
                            <DatePicker format={"DD.MM.YYYY"} style={{width: "165px"}} placeholder="input placeholder" />
                        </Form.Item>
                        <Divider/>
                    </Col>
                    <Col span={4} offset={1} className={"text-center"}>

                        <Avatar icon={<UserOutlined/>} size={170}> </Avatar>


                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                       
                        

                        <Form.Item  label=" " >
                           
                            <Space>
                                
                                <Button className={"orangeButton"} htmlType="submit">
                                    {t("Shraniti")}
                                </Button>
                                <Button className={"emptyButton"} onClick={BackToList} >
                                    {t("Ne_shranite_sprememb")}
                                </Button>

                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
                </TabPane>

                <TabPane tab={t("Zgodovina_opravljenih_letov")} key="1" disabled={true}>
                </TabPane>
                <TabPane tab={t("Ključni_kazalniki")} key="2" disabled={true}>

                </TabPane>
                <TabPane tab={t("Dokumenti")} key="3" disabled={true}>

                </TabPane>




            </Tabs>


        </div>
    );
}