import React, {useRef, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import Product from "../../../types/Product";

import ProductSelect from "./ProductSelect";

import {Form, Input, Row, Col, Select, InputNumber, Spin} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {Simulate} from "react-dom/test-utils";
import submit = Simulate.submit;
interface DocPositionProps {
    keyStr?: number;
    productId? : string;
    naziv? : string;
    kolicina? : number;
    cena? : number;
    davek? : number;
    forceReload?: boolean;
    isLoaded?: () => void;
    deleteFunc?: (key: number) => void;
    addFunc?: (values: any) => void;

}

const DocPositionForm = ({keyStr,productId, forceReload, naziv, kolicina, cena, davek, isLoaded, deleteFunc, addFunc}: DocPositionProps) => {

    const history = useHistory();
    const user = AuthService.getCurrentUser();
    if (!user) {
        history.push("/login");
    }


    const [formPos] = Form.useForm();

    const [_productId, set_productId] = useState("");
    const [_naziv, set_naziv] = useState();
    const [_kolicina, set_kolicina] = useState();
    const [_cena, set_cena] = useState();
    const [_davek, set_davek] = useState();


    
    
    useEffect(()=>{
            formPos.setFieldsValue(
                {
                    ["naziv"] : naziv ? naziv : _naziv? _naziv : "",
                    ["kolicina"] : kolicina ? kolicina : _kolicina? _kolicina : 0,
                    ["davek"] : davek ? davek : _davek? _davek : 0,
                    ["cena"] : cena ? cena : _cena? _cena : 0
                }
            );
    },[naziv, kolicina, davek, cena])
    

    const deletePos=(key:number)=>{
        if(deleteFunc){
            deleteFunc(key);
        }
    }
    //console.log(keyStr)

    const SavePosition = (prodId: any)=>{
        
        console.log("PositionForm _productId to send: ", _productId)
        
        const sendValues = {
            id : keyStr,
            naziv : formPos.getFieldValue(["naziv"]),
            cena: formPos.getFieldValue(["cena"]),
            davek: formPos.getFieldValue(["davek"]),
            kolicina: formPos.getFieldValue(["kolicina"]),
            productId: prodId,
        }
        
        console.log("PositionForm values to send: ", sendValues)
        set_naziv(formPos.getFieldValue(["naziv"]));
        set_kolicina(formPos.getFieldValue(["kolicina"]))
        set_cena(formPos.getFieldValue(["cena"]));
        set_davek(formPos.getFieldValue(["davek"]))
        
        if(addFunc){
            addFunc(sendValues);
        }
    }
    
    if(!keyStr)return(
        <Spin> </Spin>
    )

    const setNewProduct=(prod?: Product)=>{
        console.log("Product received in PositionForm", prod)
        set_productId(prod?.id ? prod.id : "")
        formPos.setFieldsValue(
            {
                ["naziv"] : prod?.title,
                ["kolicina"] : 1,
                ["davek"] : prod?.vatRate,
                ["cena"] : prod?.priceForClient
            }
        );
        SavePosition(prod?.id);
    }
    
    return(
        <div style={{width: "100%"}}>
            
            
            <Form
                form={formPos}
                name={keyStr.toString()}
                layout="vertical"
                //labelCol={{ flex: '100px' }}
                labelAlign="right"
                labelWrap
                //wrapperCol={{ flex: 1 }}
                //onFinish={SavePosition}
                onChange={SavePosition}
                initialValues={
                    {
                        ["vrsta"]:0
                    }
                }
            >
                
                <Row  gutter={8}>
                    <Col span={11}>
                        
                        <Form.Item name="naziv" label="">
                            <ProductSelect afterCreate={setNewProduct} defaultSelected={productId? productId : undefined}  />
                            {/* 

                            <Input placeholder={"Naziv storitva/blaga"} 
                            />*/}
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item name="kolicina" label="">
                            <Input placeholder=""
                            />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item name="davek" label="">
                            <Select
                                options={
                                    [
                                        { label: "22%", value: 2200 },
                                        { label: "9.5%", value: 950 },
                                        { label: "8%", value: 800 },
                                        { label: "5%", value: 500 },
                                        { label: "Exempted 0%", value: 0 },
                                        { label: "Non-taxable 0%", value: 1 },
                                    ]}
                                onChange={SavePosition}
                                //onChange={(e) => ShowContractorDoc(e)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="cena" label="">
                            <InputNumber 
                            />
                        </Form.Item>
                    </Col>
                    <Col span={1} style={{paddingTop: "4px"}}>
                        <a onClick={()=>deletePos(keyStr ? keyStr : 0)} ><DeleteOutlined /></a>

                    </Col>


                </Row>
                {_naziv ? "" :
                    "" //<Button htmlType="submit">Shraniti</Button>
                }
            </Form>

        </div>
    )}
export default DocPositionForm;