import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import type { RadioChangeEvent } from 'antd';
import {useTranslation} from "react-i18next";
import UploadDoc from "./UploadDoc";
import {Button, Drawer, Select, Spin} from "antd";
import EditContractorComp from "../../contractors/ContractorsComponents/EditComponent";
import ProductDetailPage from "../../Products/Components/ProductDetails";
import Product from "../../../types/Product";
import axios from "axios/index";
import authHeader from "../../../Services/auth-header";
import {PlusOutlined, PrinterOutlined} from '@ant-design/icons';



type DocProps = {
    defaultSelected? : string;
    afterCreate?: (data: any) => void;
};

const ProductSelect = ({defaultSelected, afterCreate }: DocProps) => {
    const {t} = useTranslation();


    const history = useHistory();
    const user = AuthService.getCurrentUser();

    const handleChange = (value: string) => {
        console.log(`selected ${value}`);
        setSelectedProduct(value);
        var prod=productList?.find(f=>f.id===value);
        if(afterCreate) {
            afterCreate(prod);
        }
    };
    
    const [openDrawer, setopenDrawer] = useState(false)
    
    const[productList, setProductList]=useState<Product[]|null>(null)
    const[selectedProduct, setSelectedProduct] = useState<string|null|undefined>("");
    const[pageloading, setpageloading] = useState<boolean>(false)



    useEffect(()=>{
        if(productList===null){
            setpageloading(true)
            axios.get(AuthService.DATA_URL+'Products/get-products', {headers: authHeader()})
                .then((response)=>{
                    setProductList(response.data);
                    if(defaultSelected){
                        setSelectedProduct(defaultSelected);
                    }
                        setpageloading(false)
                });
        }
    })


    const onSearch = (value: string) => {
        //console.log('search:', value);
    };
    
    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const createNew=()=>{
        setopenDrawer(true)
    }
    
    const setnewId=(newProd: Product)=>{
       if(afterCreate){
           productList?.push(newProd);
           setSelectedProduct(newProd.id)
           afterCreate(newProd);

       }
       else {
           console.log("newProd: ", newProd)
       }
        setopenDrawer(false)
    }



    return <Spin spinning={pageloading} >
        <Select
            showSearch
            //style={{ width: 120 }}
            optionFilterProp="children"
            onChange={handleChange}
            onSearch={onSearch}
            filterOption={filterOption}
            value={selectedProduct}
            notFoundContent={<span><PlusOutlined/> <a onClick={()=>{createNew()}}>Add new good or service</a> </span>}
            
            options={productList? productList.map((tr, index) => ({
                label : tr.title ? tr.title : "",
                value : tr.id? tr.id : ""
            }) ): [{value: "", label: ""}]}
        />

        <Drawer title={t("Ustvarite_novo_podjetje")} placement="right"
                onClose={()=>setopenDrawer(false)} 
                open={openDrawer}>
            <ProductDetailPage key={0} createId={"0"} aftercreate={setnewId}  />
        </Drawer>      
    </Spin>

};
export default ProductSelect;