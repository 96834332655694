import React, {ReactNode, useEffect, useState} from 'react';
import {Table, Tooltip, Timeline} from 'antd';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import DealsFilter from "../../../types/DealsListFilter";
import DealsList from "./DealsList";
import DealLog from "../../../types/DealLog";
import axios from "axios/index";
import authHeader from "../../../Services/auth-header";
import {mapDocTypeEnumToString, MapLogEvent, ServerdateToPoints} from "../../../utils/helpers";


interface dealLogProps{
    id : string
}

const DealLogsTimeLine= ({ id} : dealLogProps) => {

    const {t} = useTranslation();

    const history = useHistory();
    const user = AuthService.getCurrentUser();
    if (!user) {
        AuthService.logout();
        history.push("/login");
        window.location.reload();

    }
    
    const [logs, setLogs] = useState<DealLog[]|null>(null);
    const [titems, setTitems] = useState<{ color: string, children: ReactNode}[] >([])
    
    
    useEffect(()=>{
        if(logs==null){
            if(id!==""){
                axios.get(AuthService.DATA_URL+'Deals/get-log?id='+id, {headers: authHeader()})
                    .then((response)=>{
                        setLogs(response.data);
                        console.log("logs: " ,response.data)

                        setTitems(response.data.map((log: DealLog)=>({
                            //label : ServerdateToPoints(log.eventDateTime),
                            
                            color: log.doc!=null? "#1677ff" : 
                                    log.transaction!=null? 
                                        log.transaction.incomeExpense !=null ?
                                            log.transaction.incomeExpense >0? "#008000" : "#7918e6" : "#1449AE" : "#1c7884"
                            ,
                            children: <span>{ServerdateToPoints(log.eventDateTime)} <br/>
                                {t(MapLogEvent(log.dealLogEvenet == null ? 0 : log.dealLogEvenet))+" "}
                                {log.doc===null? "" : <span>
                                    {t(mapDocTypeEnumToString(log.doc?.documentType ? log.doc?.documentType : 0)) + " " + log.doc?.docNumber}</span>}
                                
                                
                            </span>
                        })))
                    })
                    .catch(function (error) {
                        if(error.response.status===401){
                            AuthService.logout();
                            history.push("/login");
                            window.location.reload();

                        }
                    })

            }
        }
    })
    
    

    return <div style={{height: "calc(65vh)", overflow: "scroll", paddingTop: "10px"}}>
        <Timeline
            mode={"alternate"}
            items={titems}/>
    </div>
}




export default DealLogsTimeLine;
    
