import React, {useState, useEffect, useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {Button, Card, Col, Divider, Dropdown, Form, InputNumber, Modal, Row, Select, Spin, Tabs} from "antd";
import FloatLabel from "../../../utils/FloatLabel/FloatLabel";
import {CloseCircleOutlined, DownOutlined} from "@ant-design/icons";
import SuggestedTransport from "./SuggestedTransport";
import SuggestedDrivers from "./SuggestedDrivers";
import DocList from "../../docs/DocComponents/docList";
import UploadDoc from "../../docs/DocComponents/UploadDoc";
import TabPane from "antd/es/tabs/TabPane";
import axios from "axios";
import authHeader from "../../../Services/auth-header";



interface reply {
    id: string | null;
    requestNumber: string;
    requestId? : string;
    price : number;
    truckId? : string | null;
    truck: any,
    priklopnik : any,
    priklopnikId? : string | null;
    contractorId? : string;
    driverId? :  string | null;
    driver : any;
}


interface EditReqwestProps {
    RequestId?: string;
}
export default function CreateReply({RequestId} :  EditReqwestProps) {

    const {t} = useTranslation();

    const history = useHistory();
    const user = AuthService.getCurrentUser();
    if (!user) {
        AuthService.logout();
        history.push("/login");
        window.location.reload();

    }
    
    const [form] = Form.useForm();


    const[reply, setreply] = useState<reply>({
        id:"-",
        requestNumber : "",
        requestId : "",
        price: 0,
        truckId :null,
        truck: null,
        priklopnik : null,
        priklopnikId :null,
        contractorId : "",
        driverId : null,
        driver : null,
    });


    const[trucks, settrucks] = useState<any[]>([]);

    const[pageloading, setPageloading] = useState<boolean>(true);
    const[initContractor, setinitContractor] = useState<string>("");
    const[initContractorName, setinitContractorName] = useState<string>("");

    const[initTruck, setinitTruck] = useState<string | null>('');
    const[initPriklopnik, setinitPriklopnik] = useState<string | null>(null);
    const[initDriver, setinitDriver] = useState<string| null>(null);



    const[initPrice, setinitPrice] = useState(0);
    
    const[ContractorOptions, setContractorOptions] = useState<any[]>([]);



    const[_RequestId, _setRequestId] = useState<string>("");



    useEffect(() => {
        setPageloading(true);

        if (ContractorOptions.length === 0) {
            axios.get(AuthService.DATA_URL+'Contractor/get-transporters', {headers: authHeader()})
                .then((response) => {
                    if (ContractorOptions.length === 0) {
                        JSON.parse(JSON.stringify(response.data, null, 4)).forEach((row: any, i: number) => {
                            ContractorOptions.push({
                                value: row.id,
                                label: row.title
                            });
                        });
                    }

                });
            //setContractorOptions(ContractorOptions);
        }

        setPageloading(false);

    }, [RequestId]);
    
    const GetDriverOptions =(data: string) => {
        axios.get(AuthService.DATA_URL+'driver/get-for-contractor?ContractorId='+data, {headers: authHeader()})
            .then((response) => {

                response.data.forEach((row: any, i: number) => {
                    if(!DriversOptions.find(o=>o.key===row.id))
                        DriversOptions.push({
                            key : row.id,
                            value: row.name + " " + row.surname,
                            label: row.name + " " + row.surname,
                        });
                });
                setDriversOptions(DriversOptions);
            });



        fetch(AuthService.DATA_URL + 'driver/get-for-request?requestid=' + RequestId + (data ? "&ContractorId=" + data : ""),
            {headers: authHeader()})
            .then((res) => res.json())
            .then((res) => {
                setavailableDriver(res);
            });


    }

    const GetTruckOptions = (data : string) =>{
        axios.get(AuthService.DATA_URL+'truck/get-for-contractor?ContractorId='+data, {headers: authHeader()})
            .then((response) => {
                response.data.forEach((row: any, i: number) => {
                    if (row.truckType === 0 || row.truckType === 2) {
                        if(!TruckOptions.find(o=>o.key===row.id))
                            TruckOptions.push({
                                key : row.id,
                                value: row.regNumber,
                                label: row.regNumber,
                                type : row.truckType
                            });
                        trucks.push({
                            id: row.id,
                            truck_type: row.truckType

                        })
                    } else {
                        if(!PriklopnikOptions.find(o=>o.key===row.id))
                            PriklopnikOptions.push({
                                key : row.id,
                                value: row.regNumber,
                                label: row.regNumber,
                            });

                    }
                });
                setTruckOptions(TruckOptions);
                setPriklopnikOptions(PriklopnikOptions);
                //setinitTruck(null);
                //form.setFieldsValue({"mainTruck":null})
            });

        fetch(AuthService.DATA_URL+'truck/get-for-request?requestid='+RequestId+(data ? "&ContractorId="+data : ""),
            {headers: authHeader()})
            .then((res) => res.json())
            .then((res) => {
                availableTransport.length=0;
                setavailableTransport(res);





            });
    }



    useEffect(() => {
        setPageloading(true);

        GetTruckOptions(user.contractorId);
        GetDriverOptions(user.contractorId);
        setinitContractor(user.contractorId);
        setinitContractorName(user.contractorName)
        setblockedContractor(true)


        //if(_RequestId===""){
            if(reply.id==="-"){
            axios.get(AuthService.DATA_URL+'Request/' + RequestId, {headers: authHeader()})
                .then((response) => {
                    //console.log("request: ", response.data)
                    setinitPrice(response.data.priceToShowToSubcontractors? response.data.priceToShowToSubcontractors : response.data.price);
                    axios.get(AuthService.DATA_URL+'Reply/get-contractors-reply?requestId=' + RequestId, {headers: authHeader()})
                        .then((reply_rsponse) => {
                            setreply({
                                id: reply_rsponse.data.length!=0 ? reply_rsponse.data.id : "",
                                requestNumber: response.data.requestNumber,
                                requestId : RequestId,
                                price: reply_rsponse.data.length!=0 ? reply_rsponse.data.price : response.data.priceToShowToSubcontractors? response.data.priceToShowToSubcontractors : response.data.price,
                                truckId: reply_rsponse.data.length!=0 ? reply_rsponse.data.truckId : "",
                                truck : reply_rsponse.data.length!=0 ? reply_rsponse.data.track : null,
                                priklopnik: reply_rsponse.data.length!=0 ? reply_rsponse.data.priklopnik : null,
                                priklopnikId:reply_rsponse.data.length!=0 ?  reply_rsponse.data.priklopnikId : "",
                                contractorId:  user.contractorId, /////!!!!!!!
                                driverId: reply_rsponse.data.length!=0 ? reply_rsponse.data.driverId : "",
                                driver : reply_rsponse.data.length!=0 ? reply_rsponse.data.driver : null,
                            });
                            _setRequestId(RequestId ? RequestId : "");
                            
                            setinitPrice(response.data.priceToShowToSubcontractors ? response.data.priceToShowToSubcontractors : response.data.price);
                            setinitTruck(reply_rsponse.data.truckId ? reply_rsponse.data.truckId  : "");
                            setinitPriklopnik(reply_rsponse.data.priklopnikId ? reply_rsponse.data.priklopnikId  : "");
                            setinitDriver(reply_rsponse.data.driverId ? reply_rsponse.data.driverId  : "");

                        })                 
                })
                .catch(function (error) {
                    console.log("error: ", error);
                    if(error.response.status===401){
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                });
               
        }
        setPageloading(false)
    },[RequestId]);
    




    const[blockedContractor, setblockedContractor] = useState(false);

    const[TruckOptions, setTruckOptions] = useState<any[]>([]);
    const[PriklopnikOptions, setPriklopnikOptions] = useState<any[]>([]);
    const[DriversOptions, setDriversOptions] = useState<any[]>([]);

    const [availableTransport, setavailableTransport] = useState<any[]>([]);
    const [availableDriver, setavailableDriver] = useState<any[]>([]);


    const [statusPriklopnik, setstatusPriklopnik] = useState<any>("");
    const [statusVlacilec, setstatusVlacilec] = useState<any>("");
    const [statusDriver, setstatusDriver] = useState<any>("");

    const [PriklopnikHelp, setPriklopnikHelp] = useState<any>("");
    const [VlacilecHelp, setVlacilecHelp] = useState<any>("");
    const [DriverHelp, setDriverHelp] = useState<any>("");

    const[hints, sethints] = useState<string>("none")
    const[displaySuggestedTransport, setdisplaySuggestedTransport] = useState<string>("none");
    const[SuggestedTransportBtnClass, setSuggestedTransportBtnClass] = useState<string>("");

    const[displaySuggestedContractor, setdisplaySuggestedContractor] = useState<string>("none");
    const[SuggestedContractorBtnClass, setSuggestedContractorBtnClass] = useState<string>("");

    const[displaySuggestedDriver, setdisplaySuggestedDriver] = useState<string>("none");
    const[SuggestedDriverBtnClass, setSuggestedDriverBtnClass] = useState<string>("");

    const[tabTitle, settabTitle] = useState<string>("Namigi: Izvajalec");




    const setReqTruck =(data:any)=>{
        console.log("TruckOptions: ", TruckOptions);
        console.log("data: ", data);
        
        var mmm=TruckOptions.find(t=>t.key===data);

        if(mmm){
            setreply(prev => ({
                ...prev,
                truckId: mmm.key
            }));
            if(!availableTransport.find(t=>t.vlacilec!=null && t.vlacilec.id===mmm.key)){
                setstatusVlacilec("warning");
                var hr=t("Zaseden_v_teh_dneh")
                setVlacilecHelp(hr);
            }else{
                setstatusVlacilec("");
                setVlacilecHelp("");
            }
            sethints("none");
        }
        else{
            setreply(prev => ({
                ...prev,
                truckId: null
            }));
        }
    }
    
    const setReqPriklopnik =(data:any)=> {
        var mmm=PriklopnikOptions.find(t=>t.key===data);

        if(mmm){
            setreply(prev => ({
                ...prev,
                priklopnikId: mmm.key
            }));
            if(!availableTransport.find(t=>t.priklopnik!=null && t.priklopnik.id===mmm.key)){

                setstatusPriklopnik("warning");

                var hr=t("Zaseden_v_teh_dneh")
                setPriklopnikHelp(hr);
            }else{
                setstatusPriklopnik("");
                setPriklopnikHelp("");
            }
        }
        else{
            setreply(prev => ({
                ...prev,
                priklopnikId: null
            }));
        }



        sethints("none");
    }

    const setReqDriver =(data:any)=> {

        var mmm=DriversOptions.find(t=>t.key===data);

        if(mmm) {
            setreply(prev => ({
                ...prev,
                driverId: mmm.key
            }));


            if(!availableDriver.find(t=>t.id===mmm.key)){
                setstatusDriver("warning");
                var hr=t("Zaseden_v_teh_dneh")
                setDriverHelp(hr);
            }else{
                setstatusDriver("");
                setDriverHelp("");
            }



            sethints("none");

        }
        else{
            setreply(prev => ({
                ...prev,
                driverId: null
            }));
        }
        sethints("none");
    }


    const setReqPrice=(data: any) =>{

        setreply(prev => ({
            ...prev,
            price: Number(data)
        }));
    }
    
    
    const ShowTrucksHints = () =>{
        if(hints=="none"){
            sethints("block");
        }
        else{
            // sethints("none");
        }
        setdisplaySuggestedTransport("block")
        setSuggestedTransportBtnClass("orangeclicked");
        settabTitle(t("Namigi")+": "+t("Vozilo"));

        setSuggestedContractorBtnClass("");
        setSuggestedDriverBtnClass("");

        setdisplaySuggestedContractor("none");
        setdisplaySuggestedDriver("none");

    }

    const ShowDriverHints = () =>{
        if(hints=="none"){
            sethints("block");
        }
        else{
            //sethints("none");
        }
        setdisplaySuggestedDriver("block")
        setSuggestedDriverBtnClass("orangeclicked");
        settabTitle(t("Namigi")+": "+t("Voznik"));

        setSuggestedContractorBtnClass("");
        setSuggestedTransportBtnClass("");

        setdisplaySuggestedContractor("none");
        setdisplaySuggestedTransport("none");


    }


    const hideHints = () =>{
        sethints("none");
        setSuggestedContractorBtnClass("");
        setSuggestedTransportBtnClass("");
        setSuggestedDriverBtnClass("");

    }

    const { TabPane } = Tabs;


    const [isDocModalOpen, setIsDocModalOpen] = useState(false);

    const [isStopModalOpen, setIsStopModalOpen] = useState(false);

    const [reload, setreload] = useState(false);

    const handleModalCancel = () => {
        setIsDocModalOpen(false);
    };
    const handleDocModalCancel = () => {
        setreload(!reload);
        setIsDocModalOpen(false);
    };
    const showDocModal1 = () => {
        setIsDocModalOpen(true);

    };


    const handleDocModalOk=()=>{
        setreload(!reload);
        setIsDocModalOpen(false);
    }


    const handleChooseVlacilec = useCallback(
        (data: any, type:number) => {
          
            var mmm=TruckOptions.find(t=>t.key===data);
            if(type===0){
                form.setFieldsValue({
                    mainTruck: mmm.value
                })
                setreply(prev => ({
                    ...prev,
                    truckId: mmm.key
                }));
                setstatusVlacilec("");
                setVlacilecHelp("");
            }
            if(type===1){
                mmm=PriklopnikOptions.find(t=>t.key===data);
                setreply(prev => ({
                    ...prev,
                    priklopnikId: mmm.key
                }));
                form.setFieldsValue({
                    priklopnik: mmm.value
                })
                setstatusPriklopnik("");
                setPriklopnikHelp("");
            }
            if(type===2){
                form.setFieldsValue({
                    mainTruck: mmm.value
                })
                setreply(prev => ({
                    ...prev,
                    truckId: mmm.key
                }));

                setreply(prev => ({
                    ...prev,
                    priklopnikId: ""
                }));
                form.setFieldsValue({
                    priklopnik: null
                })
                setstatusVlacilec("");
                setVlacilecHelp("");
                setstatusPriklopnik("");
                setPriklopnikHelp("");
            }



        },[]);


    const handleChooseVoznik = useCallback(
        (data: any, type:number) => {

            var mmm=DriversOptions.find(t=>t.key===data);
            form.setFieldsValue({
                driver: mmm.value
            })
            setreply(prev => ({
                ...prev,
                driverId: mmm.key
            }));
            // setstatusDriver("");
            // setDriverHelp("");




        },[]);



    const UdateRequestReply=(data: any) =>{

        console.log("form-data:", data);
        console.log("usestate-data:", reply);
            //это исполнитель назначаил машину и водителя
            if(reply.truckId!=undefined && reply.contractorId!=undefined && reply.driverId!=undefined){
                //reply.requestStatus=30
            }
            else{
                //по идее нельзя пускать без указанной машины и водителя
                //хорошо бы какой-нибудь модал тут поставить и не пускать идти дальше
                setIsStopModalOpen(true)
                return
            }


            // @ts-ignore
        if(reply.id.length===0){
                reply.id="00000000-0000-0000-0000-000000000000";
                axios.post(AuthService.DATA_URL+'Reply/',
                    reply, {headers: authHeader()})
                    .then((response) =>{
                            console.log(response.data);
                        }
                    );

            }
            else{
                axios.put(AuthService.DATA_URL+'Reply/'+reply.id,
                    reply, {headers: authHeader()})
                    .then((response) =>{
                            console.log(response.data);
                        }
                    );
            }
        history.push("/routes");
            
            

    }



    const showReplyState =()=>{
        console.log(reply)
    }



    //console.log("init values:", initContractor, initPrice, initTruck, initPriklopnik)

    //useEffect(()=>{
            //console.log(reply);
            //setPageloading(true);
            //setinitContractor(reply.contractorId ? reply.contractorId : "");
            //setinitPrice(reply.price);
            //setinitTruck(reply.truckId ? reply.truckId  : "");
            //setinitPriklopnik(reply.priklopnikId ? reply.priklopnikId  : "");
            //setinitDriver(reply.driverId ? reply.driverId  : "");
            // setPageloading(false);

        
   //},[reply])


 
    //console.log("TruckOptions: ", TruckOptions);
    //if (ContractorOptions.length===0) return <div>Waiting for ContractorOptions data...</div>;
   // if (initContractor.length===0) return <div>Waiting for initContractor data...</div>;
    if(reply.id==="-") return <Spin/>
    // @ts-ignore
   // if (initTruck==='' && reply.id!='') return <div>Waiting for initTruck data... : '{initTruck}'</div>;
    //if(DriversOptions.length===0) return <Spin/>
    //if (initContractor.length===0) return <div>Waiting for initContractor data...</div>;
    
    

    
    


    return <div> 
        <div>
            <Row>
                <Col span={13}> 
                    <div style={{position: "relative", display:"flex", minHeight: "80vh", paddingTop: "10px", }}>
                        <Card style={{
                            width : "100%",
                            borderRadius: "0px 0px 8px 8px", marginTop: "-1px", borderTop: "1px solid #fff"}} >
                            
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={UdateRequestReply}
                                labelAlign="right"
                                labelWrap
                                initialValues={
                                    {
                                        ["statusAfter"]: 0,
                                        ["contractor"]: initContractor,
                                        ["mainTruck"]: reply.truckId,
                                        ["priklopnik"]: initPriklopnik,
                                        ["driver"]: initDriver,
                                        ["price"]: initPrice,
                                    }
                                }

                            >
                                <Divider orientation="left" orientationMargin="0" style={{marginTop: "0px"}}>{t("Izvajalec")}</Divider>
                               
                                <FloatLabel label={t("Izvajalec")}>
                                    <Form.Item name="contractor"
                                               rules={[{ required: true }]}
                                    >
                                        <Select
                                            style={{ display: "inline-table", height: "50px"}}

                                            disabled ={blockedContractor}
                                            options ={ContractorOptions}
                                            //defaultValue={initContractor}
                                            //style={{width:"262px"}}
                                            //onClick={()=>ShowContractorHints()}
                                            //onChange={(e)=>setReqContractor(e)}
                                        />
                                    </Form.Item> 
                                </FloatLabel>

                                <Divider orientation="left" orientationMargin="0" style={{marginTop: "0px"}}>{t("Vozilo")}</Divider>



                                <Row gutter={0} style={{ width: '100%'}}>
                                    <Col span={12}>
                                        <FloatLabel label={t("Vlačilec")} name="mainTruck">
                                            <Form.Item
                                                name="mainTruck"
                                                help={VlacilecHelp}
                                                //rules={[{ required: true }]}
                                                style={{ display: 'inline-block', width: '100%' }}
                                            >


                                                <Select className={"bordered-left"}
                                                        style={{ display: "inline-table", height: "50px"}}
                                                        options={TruckOptions.map((truck) => ({ label: truck.label, value: truck.key }))}
                                                        onClick={()=>ShowTrucksHints()}
                                                        onChange={(e)=>setReqTruck(e)}
                                                        allowClear={true}
                                                        status={statusVlacilec}
                                                        //defaultValue={initTruck}
                                                        //disabled={blockedVehicle}


                                                    //defaultValue={form.getFieldsValue().truckType}
                                                />
                                            </Form.Item>
                                        </FloatLabel>
                                    </Col>
                                    <Col span={12}>
                                        <FloatLabel label={t("Priklopnik")}>
                                            <Form.Item
                                                name="priklopnik"
                                                rules={[{ required: false }]}
                                                help={PriklopnikHelp}
                                                style={{ display: 'inline-block', borderRight: "0px",  width: '100%'/*, marginLeft: '8px'*/ }}
                                            >
                                                <Select className={"bordered-right"}
                                                    // labelInValue={true}
                                                        style={{ display: "inline-table", height: "50px", borderRadius: "0px"}}
                                                        options={PriklopnikOptions.map((truck) => ({ label: truck.label, value: truck.key }))}
                                                        onClick={()=>ShowTrucksHints()}
                                                        onChange={(e)=>setReqPriklopnik(e)}
                                                        allowClear={true}
                                                        status={statusPriklopnik}
                                                        //defaultValue={initPriklopnik}
                                                        //disabled={blockedVehicle}


                                                    //disabled={disabledPriklopnik}
                                                    //defaultValue={form.getFieldsValue().truckType}
                                                />

                                            </Form.Item>
                                        </FloatLabel>
                                    </Col>
                                    {/* 
                                            <Col span={2}>
                                            <Button className={SuggestedTransportBtnClass}   onClick={()=>ShowTrucksHints()}>?</Button>
                                            </Col>
                                            */}
                                </Row>


                                <Divider orientation="left" orientationMargin="0" style={{marginTop: "0px"}}>{t("Voznik")}</Divider>
                                <FloatLabel  label={t("Voznik")}>
                                    <Form.Item name="driver"
                                               help={DriverHelp}

                                    >
                                        <Select
                                            options={DriversOptions.map((truck) => ({ label: truck.label, value: truck.key }))}

                                            // options ={DriversOptions}
                                            //defaultValue={initDriver}
                                            status={statusDriver}
                                            style={{ display: "inline-table", height: "50px", borderRadius: "0px"}}
                                            onClick={()=>ShowDriverHints()}
                                            onChange={(e)=>setReqDriver(e)}
                                            allowClear={true}
                                            //disabled={blockedDriver}

                                        />
                                    </Form.Item>
                                </FloatLabel>

                                <Divider orientation="left" orientationMargin="0" style={{marginTop: "0px"}}>{t("Cena")}</Divider>
                                <FloatLabel  label={t("Cena")}>
                                    <Form.Item name="price">
                                        <InputNumber
                                            //defaultValue={initPrice}
                                            style={{ display: "inline-table", height: "50px", borderRadius: "5px", width: "100%", textAlign: "right"}}
                                            onChange={(e)=>setReqPrice(e)}
                                        />
                                    </Form.Item>
                                </FloatLabel>
                                






                                <div>
                                   <Form.Item>
                                                <Button className={"orangeButton"}   htmlType="submit"
                                                        style={{ marginTop:"25px", /*marginRight:"50px",*/ }}>
                                                    {t("Shraniti")}
                                                </Button>
                                               

                                            </Form.Item>

                                        </div>
                            </Form>




                        </Card>
                    </div>


                </Col>
                <Col span={10}>
                    <Card style={{backgroundColor: "#f4f6f9", border: "none", }}>
                        <div style={{width: "320px",  display: hints}}>
                            <Tabs tabBarExtraContent={
                                <a onClick={
                                    hideHints
                                    /*  ()=> {
                                      sethints("none")
                                  }*/}
                                > <CloseCircleOutlined /> {t("Skrijte")}</a>
                            }>
                                <TabPane tab={tabTitle} key="1">
                                    <div style={{display: displaySuggestedTransport}}>
                                        <SuggestedTransport requestId={RequestId ? RequestId : null} contractorId={reply.contractorId ? reply.contractorId : undefined} onVlacilecChoice={handleChooseVlacilec}  />

                                    </div>
                                    <div style={{display: displaySuggestedContractor}}>
                                    </div>
                                    <div style={{display: displaySuggestedDriver}}>

                                        <SuggestedDrivers requestId={RequestId ? RequestId : null} contractorId={reply.contractorId ? reply.contractorId : undefined} onVlacilecChoice={handleChooseVoznik}  />

                                    </div>

                                </TabPane>
                            </Tabs>


                        </div>
                        <div style={{width: "320px",  display: (hints==="none"? "block":"none")}}>

                            <Divider orientation="left" orientationMargin="10" style={{marginTop: "0px"}}>{t("Dokumenti")}</Divider>


                            <Button onClick={showDocModal1} style={{
                                border: "1px dashed #d9d9d9",
                                borderTopLeftRadius: "5px",
                                borderBottomLeftRadius: "5px",
                                //borderRight: "0px",
                                height: "40px",
                                //width: "100%",
                                fontSize: "14px",
                                color: "#1449AE",
                                marginTop: "10px",
                                marginBottom: "8px",
                                padding: "8px 5px"}}>
                                + {t("Prenesite_dokument")}
                            </Button>
                            <DocList RequestId={  RequestId ? RequestId.toString() : undefined} reload={reload} hideFileNames={true} />

                            <Modal open={isDocModalOpen}
                                   onOk={handleDocModalOk}
                                   title={t("Prenesite_dokument")}
                                   centered
                                   width={580}
                                   onCancel={handleDocModalCancel}
                                //footer={[]}
                            >

                                <UploadDoc objectId={RequestId ? RequestId.toString() : null} objectMode={1}/>
                            </Modal>


                        </div>







                    </Card>


                </Col>
            </Row>
        </div>
        <Modal title={t("Ne_morem_shraniti")} open={isStopModalOpen}
               footer={(_, { OkBtn }) => (
                   <>
                       <OkBtn />
                   </>
               )}
               onOk={()=>{setIsStopModalOpen(false)}}
               onCancel={()=>{setIsStopModalOpen(false)}}>
            <p>{t("Navesti_je_treba_vozilo_in_voznika")}</p>

        </Modal>
    </div>
    

}
