import React from 'react';
import { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import MyRoutesPage from "./myroutes";
import DocsPage from "./docs";
//import CardsPage from "./cards";
import TransactionsPage from "./transactions";
import SettingsPage from "./settings";
import TrucksPage from "./trucks";
import TrucksDetailsPage from "./trucks/details";

import newTruckPage from "./trucks/newTruck";
import RouteDetailsPage from "./myroutes/order-detail";
import RouteCreatePage from "./myroutes/order-create";
import RouteEditPage from "./myroutes/order-edit";

import CreateContractorPage from "./contractors/page-createContractor";
import EditContractorPage from "./contractors/page-editContractor";
import ContractorsPage from "./contractors";
import ShowDocPage from "./docs/DocComponents/ShowDoc";
import CreateDriver from "./drivers/DriverComponents/create";
import DriverDetailsPage from "./drivers/DriverComponents/details";
import CreateDocPage from "./docs/DocComponents/CreateDoc";
import PositionsList from "./docs/DocComponents/positionsList";
import CheckOutPage from "./Checkout";
import {DDSPage} from "./transactions/dds";
import DealsPage from "./Deals/ListPage";
import DealsDetailPage from "./Deals/Components/DealsDetailPage";
import LP from "./LP/LP";
import CheckoutForm from "./ServicePayments/CheckoutPage";
import Return from "./ServicePayments/ReturnPage";
import ProductsPage from "./Products/ProductListPage";
import ProductDetailPage from "./Products/Components/ProductDetails";



const DashboardPage = lazy(() => import("./Dashboard"));
const DriversPage = lazy(() => import("./drivers"));


interface Props {
    component?: React.ComponentType | null
    path?: string | null
}






export const Routing = () => {
    
    return (
        <Suspense fallback={<div>Loading...</div>}>
            
        <Switch>
            <Route key={1} exact path="/" component={LP} />
            <Route key={2} exact path="/Dashboard" component={TransactionsPage} />
            <Route key={3} exact path="/trucks" component={TrucksPage} />
            <Route key={4} path="/trucks/:id" component={TrucksDetailsPage} />
            <Route key={5} exact path="/newtruck" component={newTruckPage} />


            <Route key={6} exact path="/drivers" component={DriversPage} />
            <Route key={7} exact path="/newdriver" component={CreateDriver} />
            <Route key={8} path="/drivers/:id" component={DriverDetailsPage} />

            
            
            
            <Route key={9} exact path="/routes" component={MyRoutesPage} />
            <Route key={10} path="/newroute" component={RouteCreatePage} />
            <Route key={11} path="/routes/view/:id" component={RouteDetailsPage} />

            {/* <Route key={11} path="/routes/view/:id" component={RouteDetailsPage} /> */}


             <Route key={90} exact path="/deals" component={DealsPage} />
            <Route key={91} path="/deals/:id" component={DealsDetailPage} />


            <Route key={40} exact path="/products" component={ProductsPage} />
            <Route key={41} path="/products/:id" component={ProductDetailPage} />
            
            
            <Route key={12} path="/routes/edit/:id" component={RouteEditPage} />

            
            
            <Route key={13} exact path="/contractors" component={ContractorsPage} />
            <Route key={14} exact path="/contractors/new" component={CreateContractorPage} />
            <Route key={15} path="/contractors/editcontractor/:id" component={EditContractorPage} />




            <Route key={16} exact path="/docs" component={DocsPage} />
            <Route key={17} exact path="/showdoc" component={ShowDocPage} />
            <Route key={171} exact path="/docs/:id" component={CreateDocPage} />


            
            
            
            <Route key={18} exact path="/transactions" component={TransactionsPage} />
            <Route key={181} exact path="/transactions/dds" component={DDSPage} />
            <Route key={19} exact path="/settings" component={SettingsPage} />

            <Route key={1978}  path="/rev_checkout" component={CheckOutPage} />


            <Route  key={62} exact  path="/return" component={Return} />
            <Route  key={61} exact path="/checkout" component={CheckoutForm} />


        </Switch>
        </Suspense>
    );
};