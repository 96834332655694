import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../Services/auth-service";
import axios from "axios/index";
import authHeader from "../Services/auth-header";
import {Spin} from "antd/lib";
import contractor from "../types/contractor";
import {useTranslation} from "react-i18next";
import DocPosition from "../types/DocPosition";
import i18n from "i18next";
import i18next from "i18next";

interface RacunProps{
    ContractorId?: string;
    RequestId?: string;
    documentDate? : string;
    payementDate? : string;
    documentNumber? : string;
    forceReload? : boolean;
    isLoaded? :  (data: any) => void;
    positions? : DocPosition[],
    lang? : string;


}



const RacunPage = ({ ContractorId, RequestId, documentNumber, documentDate, payementDate, forceReload, isLoaded, positions, lang} : RacunProps) => {
    
    const history= useHistory();

    const user = AuthService.getCurrentUser();
    if(!user) {
        history.push("/login");
    }

    
    

    const {t} = useTranslation();
    



    const[logoPic, setlogoPic] = useState<string>();
    const[zigPic, setzigPic] = useState<string>();
    const[podpisPic, setpodpisPic] = useState<string>();
    const[myCompany, setmyCompany]= useState<contractor>({
        id:""
    });
    const[contractorTitle, setcontractorTitle]= useState<any>();

    const[contractor, setcontractor]= useState<contractor>({
        id: ""
    });
    const[request, setrequest]= useState<any>();


    const [docPositions, setDocPositions] = useState<any[]>([]);
    const[_documentNumber, set_documentNumber ]=useState("");
    
    const[davek22, setDavek22]=useState(0);
    const[davek9, setDavek9]=useState(0);
    const[davek8, setDavek8]=useState(0);
    const[davek5, setDavek5]=useState(0);
    const[davek0, setDavek0]=useState(0);
  


    const[amount22, setAmount22]=useState(0);
    const[amount9, setAmount9]=useState(0);
    const[amount8, setAmount8]=useState(0);
    const[amount5, setAmount5]=useState(0);
    const[amount0, setAmount0]=useState(0);

    
   // console.log("response.data12121212121212: ")
    useEffect(()=>{
        if(myCompany.id==="")
            axios.get(AuthService.DATA_URL+'Contractor/get-ownCompany', {headers: authHeader()})
                .then((response) => {
   //                 console.log("response.data12121212121212: ", response.data)
                    setmyCompany(response.data);
                    //подставим логотип
                    if(response.data.logoId){
                        fetch(AuthService.DATA_URL+`Docs/get-doc?id=`+ response.data.logoId, {headers: authHeader()})
                            .then((response) => response.blob())
                            .then((blob) => {
                                const imageUrl = URL.createObjectURL(blob);
                                setlogoPic(imageUrl);
                            });
                    }
                    //подставим подпись
                    if(response.data.podpisId){
                        fetch(AuthService.DATA_URL+`Docs/get-doc?id=`+ response.data.podpisId, {headers: authHeader()})
                            .then((response) => response.blob())
                            .then((blob) => {
                                const imageUrl = URL.createObjectURL(blob);
                                setpodpisPic(imageUrl);
                            });
                    }
                    //подставим печать
                    if(response.data.zigId){
                        fetch(AuthService.DATA_URL+`Docs/get-doc?id=`+ response.data.zigId, {headers: authHeader()})
                            .then((response) => response.blob())
                            .then((blob) => {
                                const imageUrl = URL.createObjectURL(blob);
                                setzigPic(imageUrl);
                            });
                    }
                })
                
                .catch(()=>{
                    setmyCompany({id:"sdfsdf"});
                    AuthService.logout();
                    history.push("/login");
                    window.location.reload();
                })
        
        if(ContractorId){
            axios.get(AuthService.DATA_URL+'Contractor/'+ContractorId, {headers: authHeader()})
                .then((response) => {
                   // console.log("getting racun contractor:",response);
                    setcontractorTitle(response.data.Title);
                    setcontractor(response.data)
                    
                })
                .catch((e)=>{
                    //setcontractorTitle("-");
                }) 
        }
        if(RequestId){
            axios.get(AuthService.DATA_URL+'Request/'+RequestId, {headers: authHeader()})
                .then((response) => {
               //     console.log("Request: ", response);
                    //setcontractorTitle(response.data.Title);
                    setrequest(response.data)


                })
                .catch((e)=>{
                    //setcontractorTitle("-");
                })
        }
        if(positions){
            setDocPositions(positions.map((pos)=>{
                return{
                    id: pos.id,
                    docId : pos.docId,
                    naziv:pos.naziv,
                    kolicina: pos.kolicina,
                    davek: pos.davek,
                    cena: pos.cena,
                    orderInDoc : pos.orderInDoc,
                    total: (pos.cena?pos.cena :0 ) *(pos.kolicina? pos.kolicina : 0)
                }
            }));
            
            var _davek22=0;
            var _davek9 =0;
            var _davek8 =0;
            var _davek5 =0;
            var _davek0 =0;
            
            var _amount22=0
            var _amount9=0;
            var _amount8=0;
            var _amount5=0;
            var _amount0=0;

            positions.forEach((pos)=>{
                if(pos.davek===2200){
                    _davek22=_davek22+((pos.kolicina?pos.kolicina:0 )*(pos.cena? pos.cena : 0))/100*22
                    _amount22=_amount22+((pos.kolicina?pos.kolicina:0 )*(pos.cena? pos.cena : 0))
                }
                if(pos.davek===900){
                    _davek9=_davek9+((pos.kolicina?pos.kolicina:0 )*(pos.cena? pos.cena : 0))/100*9
                    _amount9=_amount9+((pos.kolicina?pos.kolicina:0 )*(pos.cena? pos.cena : 0))
                }


                if(pos.davek===800){
                    _davek8=_davek8+((pos.kolicina?pos.kolicina:0 )*(pos.cena? pos.cena : 0))/100*8
                    _amount8=_amount8+((pos.kolicina?pos.kolicina:0 )*(pos.cena? pos.cena : 0))
                }
                if(pos.davek===500){
                    _davek5=_davek5+((pos.kolicina?pos.kolicina:0 )*(pos.cena? pos.cena : 0))/100*5
                    _amount5=_amount5+((pos.kolicina?pos.kolicina:0 )*(pos.cena? pos.cena : 0))
                }
                if(pos.davek===0 || pos.davek===1){
                    _davek0=_davek0;//+((pos.kolicina?pos.kolicina:0 )*(pos.cena? pos.cena : 0))/100*0
                    _amount0=_amount0+((pos.kolicina?pos.kolicina:0 )*(pos.cena? pos.cena : 0))
                }



            })
            setDavek22(_davek22)
            setDavek9(_davek9)
            setAmount22(_amount22)
            setAmount9(_amount9)
            
           // console.log("Racun positions: ", docPositions);
        }


        if(documentNumber!==undefined && documentNumber!==null){
            set_documentNumber(documentNumber);
        }

        
    }, [forceReload, ContractorId, RequestId, documentNumber, positions])


    const ctitle = contractor ? contractor.title : undefined;
//    if(ContractorId)
    const _contractor = ContractorId ? contractor ? contractor : myCompany : myCompany
        
        
   //     contractor ? contractor : ContractorId;







//Отключение warning в консоли
    console.warn  = () => {};

    //console.log("docPositions: ", docPositions)


    if(myCompany.id===""){
        return <Spin/>
    }else{
        if(isLoaded){
            isLoaded(true);
        }
    }


    return (


            
            <div className="Racun_main" id={"racunMain"} style={{width: "978px", margin: "0 auto", fontSize: "17px",
            backgroundColor: "#fff", maxWidth: "900px"}}>
                <table width="100%" style={{fontFamily: "Arial"}}>
                    <tbody>
                    <tr>
                        <td rowSpan={2} style={{ verticalAlign: "top", padding: "30px 0",}}>
                            <div style={{
                                //background: "url('http://localhost:3000/img/logo_temp.png') no-repeat",
                                backgroundImage: "url('" + logoPic + "')",
                                backgroundRepeat:"no-repeat",
                                backgroundSize : "cover",
                                width: "100px", height: "100px"}}>
                            </div>
                        </td>
                        <td style={{width: "100mm", padding: "20px 0", borderTop: "2px solid #000"}}>
                            <div style={{textAlign: "justify", fontSize: "10pt", }}>
                                <div style={{marginBottom: "5px", fontWeight: "bold"}}>{myCompany.id==="" ? "" : myCompany.title}</div>
                                <div style={{marginBottom: "5px"}}>{myCompany.id==="" ? "" : myCompany.adress1}</div>
                                <div style={{marginBottom: "5px"}}>{myCompany.id==="" ? "" : myCompany.adress2}</div>
                                
                                <div style={{marginBottom: "5px"}}>{myCompany.id==="" ? "" : myCompany.davcnaStevilka}</div>
                                <div style={{marginBottom: "20px"}}>{myCompany.id==="" ? "" : myCompany.iban ? <span>IBAN: {myCompany.iban}</span> : ""}</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "100mm", padding: "20px 0", borderTop: "2px solid #000"}}>
                            <div style={{textAlign: "justify", fontSize: "10pt", }}>
                                <div style={{marginBottom: "5px", fontWeight: "bold"}}>{contractor?.title}</div>
                                <div style={{marginBottom: "5px"}}>{contractor?.adress1}</div>
                                <div style={{marginBottom: "5px"}}>{contractor.adress2}</div>
                               
                                <div style={{marginBottom: "5px"}}>{contractor.davcnaStevilka}</div>
                                <div style={{marginBottom: "5px"}}>{contractor.iban ? <span>IBAN: {contractor.iban}</span> : ""}</div>
                            </div>
                        </td>
                        
                    </tr>
                    </tbody>
                </table>
                
                <table width="100%" style={{fontFamily: "Arial", borderTop: "2px solid #000", fontSize: "11pt",
                    borderBottom: "2px solid #000", marginBottom: "80px"}}>
                    <tbody>
                    <tr>
                        <td style={{ padding:"30px 5px", marginTop: "30px", fontFamily: "Arial", }}>
                            <div style={{fontWeight: "bold", fontSize: "25pt", display: "block"}}>
                                {/*RAČUN*/} {t('Račun', { lng: lang })}
                            </div>
                            <div style={{fontWeight: "bold", fontSize: "16pt", display: "block"}}>
                                {_documentNumber}
                            </div>                        
                        </td>
                        <td style={{width: "100mm", padding: "20px 0", fontSize: "10pt" }}>
                            <div style={{marginBottom: "5px"}}>{t("Datum_dokumenta", { lng: lang })}: <b>{documentDate}</b></div>
                            {/*  <div style={{marginBottom: "5px"}}>Datum storitve</div>*/}
                            <div style={{marginBottom: "5px"}}>{t("Datum_plačila", { lng: lang })}:  <b>{payementDate}</b></div>
    
                        </td>
                    </tr>
                    </tbody>
                </table>
    
    
                <table border={0} width="100%" cellPadding="0" cellSpacing="0"
                       style={{borderCollapse: "collapse", width: "100%", fontFamily: "Arial"}}>
                    <thead style={{borderBottom: "1px solid #ddd"}}>
                    <tr>
                        <th style={{width:"13mm", textAlign: "left", paddingBottom:"15px"}}>#</th>
    
                        <th style={{ textAlign: "left", paddingBottom:"15px"}}>{t("Naziv_storitva_blaga", { lng: lang })}</th>
                        <th style={{width:"20mm", textAlign: "left", paddingBottom:"15px"}}>{t("Količina", { lng: lang })}</th>
                        <th style={{width:"20mm", textAlign: "left", paddingBottom:"15px"}}>&nbsp;{t("Davek", { lng: lang })}</th>
                        <th style={{width:"30mm", textAlign: "left", paddingBottom:"15px"}}>{t("Cena", { lng: lang })} (€)</th>
                        <th style={{width:"30mm", textAlign: "right", paddingBottom:"15px"}}>{t("Skupaj", { lng: lang })} (€)</th>
                    </tr>
                    </thead>
                    <tbody style={{borderBottom: "1px solid #ddd"}}>
                    {
                        docPositions.length > 0 ?
                                
                                    docPositions.map((row: any, i)=>(

                                        <tr>
                                            <td style={{width:"13mm", paddingTop:"15px 0"}}>{i+1}</td>
                                            <td style={{ padding:"15px 0"}}>
                                                {row.naziv}<br/>
                                            </td>
                                            <td style={{width:"20mm", padding:"15px 0"}}>{row.kolicina}</td>
                                            <td style={{width:"20mm", padding:"15px 0"}}>{row.davek===2200? "22%" : row.davek===950? "9.5%" : row.davek===8000? "8.0%" : row.davek===500? "5.0%"  : row.davek===0? "0%" : row.davek===1? "0%" :"0%"}</td>
                                            <td style={{width:"30mm", textAlign: "left", padding:"15px 0"}}>{!row.cena ? 0: row.cena.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}</td>
                                            <td style={{width:"30mm", textAlign: "right", padding:"15px 0"}}>{!row.total ? 0: row.total.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}</td>
                                            {/*
                                                <td style={{width:"30mm", textAlign: "left", padding:"15px 0"}}>{request ? request.price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ") : ""}</td>
                                                <td style={{width:"30mm", textAlign: "right", padding:"15px 0"}}>{request ? request.price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ") : ""}</td>
                                            */}
                                            </tr>

                           
                                    ))
                            
                             : (<tr><td></td></tr>)
                    }
                    

                    
                    
                    
                    
                    </tbody>
                </table>
    
                <table style={{fontFamily: "Arial"}} border={0} width="100%" cellPadding="0" cellSpacing="0">
                   <tbody>
                    <tr>
                        <td style={{padding:"10px 0 0 0", color: "#aaa", verticalAlign: "top", fontSize: "10pt"}} rowSpan={4}>
                            {t("Znesek_prosimo_plačajte_na", { lng: lang })} {myCompany.iban} <br/>
                            BIC: {myCompany.swift}<br/><br/>
                            {t("Ob_plačilu_navedite_referenco", { lng: lang })} {_documentNumber}. <br/><br/>
                            {t("Hvala_za_zaupanje", { lng: lang })}
                            
                        </td>
                    </tr>
                    <tr>
                        <td style={{width:"70mm", fontWeight:"normal",  textAlign:"left", padding:"10px 0 0 0"}}>{t("Skupaj", { lng: lang })} (€):</td>
                        <td style={{width:"30mm", fontWeight:"normal",  textAlign: "right", padding:"10px 0 0 0"}}>{docPositions.reduce((sum, current) => sum + current.total, 0).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}</td>
                    </tr>
                    <tr>
                        <td style={{width:"70mm", fontWeight:"normal",  textAlign:"left", padding:"10px 0"}}>
                            
                            { davek22!==0?
                                <span>
                                    {t("Davek_za_DDV", { lng: lang })} 22% / {amount22.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}
                                    (€):<br/></span>
                                :""
                            } 
                            { davek9!==0?
                                <span>
                                    {t("Davek_za_DDV", { lng: lang })} 9.5% / {amount9.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}
                                    (€):</span>
                                :""
                            }
                            { davek8!==0?
                                <span>
                                    {t("Davek_za_DDV", { lng: lang })} 8.0% / {amount8.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}
                                    (€):</span>
                                :""
                            }                            
                            { davek5!==0?
                            <span>
                                    {t("Davek_za_DDV", { lng: lang })} 5.0% / {amount5.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}
                                (€):</span>
                            :""
                            }
                            { davek0!==0?
                                <span>
                                    {t("Davek_za_DDV", { lng: lang })} 0.0% / {amount0.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}
                                    (€):</span>
                                :""
                            }




                        </td>
                        
                        
                        
                        <td style={{width:"30mm", fontWeight:"normal",  textAlign: "right", padding:"10px 0"}}>
                            {
                                davek22!==0?
                                    <span>
                                        {(davek22).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ") }
                                    <br/></span> 
                                    :""
                            }
                            {
                                davek9!==0?
                                    <span>
                                        {(davek9).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ") }
                                    </span>
                                    :""
                            }
                        
                        </td>
                    </tr>
                    <tr>
                        <td style={{width:"70mm", fontWeight:"bold",  textAlign:"left", padding:"15px 0", borderTop: "2px solid #000"}}>{t("Skupaj", { lng: lang })} (€):</td>
                        <td style={{width:"30mm", fontWeight:"bold",  textAlign:"right", borderTop: "2px solid #000"}}>
                            {docPositions.reduce((sum, current) => sum + current.total, 0).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ") }
                        </td>
                    </tr>
                    <tr>
    
                        <td colSpan={5} style={{ verticalAlign: "bottom", fontWeight:"bold",  textAlign:"right", padding:"15px 0", borderTop: "2px solid #000"}}>
                            <div style={{textAlign: "right", marginTop: "20mm", marginBottom: "10px", verticalAlign: "bottom"}}>
                                {myCompany?.title}
                            </div>
    
                            <div style={{
                                //background: "url('http://localhost:3000/img/zig.png')",
                                backgroundPosition: "right",
                                backgroundImage: "url('" + zigPic + "')",
                                backgroundRepeat: "no-repeat", padding: "20px 10px", width: "200px", 
                                verticalAlign: "bottom",
                                textAlign: "center",
                                float: "right",
                                height: "160px"}}>
                                <div style={{
                                    //background: "url('http://localhost:3000/img/signature.png') no-repeat",
                                    backgroundImage: "url('" + podpisPic + "')",
                                    backgroundRepeat:"no-repeat",
                                    backgroundSize : "contain", marginLeft: "-70px",
                                    width: "160px", height: "180px"}}>
    
                                </div>
                            </div>
    
                        </td>
                    </tr>
                   </tbody>
                </table>
    
    
    
    
    
    
    
    
    
    
                <br/>
                <br/><br/><br/><br/><br/>
                <div style={{backgroundColor:"#000000", width:"100%", fontSize:"1px", height:"1px"}}>&nbsp,</div>
                <br/>
                <div style={{textAlign: "center", fontSize: "10pt", }}>
                    {myCompany.title} {myCompany.adress1} {myCompany.adress2} {t("ID_za_DDV", { lng: lang })}: {myCompany.davcnaStevilka} IBAN: {myCompany.iban}
                </div>
    
    
                <br/>
            


    
        </div>
)
}

export default RacunPage;