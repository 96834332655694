import {Button, Input, Space, Table, Tooltip, Drawer, message, Badge, Collapse, Select} from "antd";
import {DownOutlined, SearchOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {ColumnsType} from "antd/es/table";
import axios from "axios";
import * as AuthService from "../../../Services/auth-service";
import authHeader from "../../../Services/auth-header";
import CreateDoc from "./create";
import {useHistory} from "react-router-dom";
import {CollapsibleType} from "antd/es/collapse/CollapsePanel";
import {useTranslation} from "react-i18next";

export default function ListContractors() {

    const {t} = useTranslation();

    const history= useHistory();

    const user = AuthService.getCurrentUser();
    if(!user){
        history.push("/login");
    }
    
    interface DataType {
        //key: React.Key;
        id : React.Key;//string;
        title: string;
        isClient : boolean;
        davcnaStevilka: string;
        maticnaStevilka: string;
        adress1: string;
        adress2: string;
        
    }


    const [searchText, setSearchText] = useState("")
    const [showFilterBadge, setshowFilterBadge] = useState(false)

    const [selectedType, setselectedType] = useState(100)


    const columns: ColumnsType<DataType> = [
        {
        //    key: 'Naziv',
            title: t('Naziv'),
            dataIndex: 'title',
            filteredValue :[searchText],
            onFilter: (value, record) => {
                return (
                    String(record.title).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.davcnaStevilka).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.maticnaStevilka).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.adress1 ).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.adress2 ).toLowerCase().includes(value.toString().toLowerCase())
                )
            },

//            sorter: (a, b) => a.title.length - b.title.length,
            //sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
            ellipsis: true,
        },
        {
            title: t(' '),
            dataIndex: 'isClient',
            width: 110,
            //          key: 'adress',
            //          sorter: (a, b) => a.maticnaStevilka.length - b.maticnaStevilka.length,
            //sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
            ellipsis: true,
            render: (text, record) =>
                <span style={{color: "#bbb"}}>
                {record.isClient? t("Stranka"): t("Izvajalec")}                    
                </span>

        },
        {
            title: t('Davčna_Številka'),
            dataIndex: 'davcnaStevilka',
            width: 160,

            //      key: 'davcnaStevilka',
      //      sorter: (a, b) => a.davcnaStevilka.length - b.davcnaStevilka.length,
            //sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
            ellipsis: true,
        
        },
        {
            title: t('Matična_Številka'),
            dataIndex: 'maticnaStevilka',
            width: 160,

            //        key: 'maticnaStevilka',
    //        sorter: (a, b) => a.maticnaStevilka.length - b.maticnaStevilka.length,
            //sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
            ellipsis: true,

        },
        {
            title: t('Naslov'),
            dataIndex: 'adress',
  //          key: 'adress',
  //          sorter: (a, b) => a.maticnaStevilka.length - b.maticnaStevilka.length,
            //sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
            ellipsis: true,
            render: (text, record) =>
                        record.adress1+" "+record.adress2,

        },
        {
            title: ' ',
//            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (text, record) =>
                    <a className={"actionLink"} href={"/contractors/editcontractor/"+record.id}>{t("Več")} &rarr;</a>,
        }]
    
    const clearAll = () =>{}
    const newPartner = () => {
        history.push("/contractors/new");
    };

    const  getCreated = (value : any):string =>{
        setOpen(false);
        console.log("value: ", value);
        if(value!==""){
            message.success('Submit success!');
            return value.data.id
        }
        return "";
    }



    const [TableData, setTableData] = useState([]);


    useEffect(() => {
        axios.get(AuthService.DATA_URL+`Contractor`
            , { headers: authHeader() }
        )
            .then((response) => {
                console.log("Contractors response.data: ",response.data)
                setTableData(response.data);
            })
            .catch(function (error) {
                if(error.response.status===401){
                    AuthService.logout();
                    history.push("/login");
                    window.location.reload();

                }
            })

    }, []);



    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const [filterShow, setfilterShow] = useState('disabled' as CollapsibleType)
    const [filterKey, setfilterKey] = useState(["0"])



    const ShowFilters=()=>{
        console.log("filterShow: ", filterShow);
        if(filterShow.toString()==="disabled"){
            setfilterShow("head" as CollapsibleType);
            setfilterKey(["1"]);
            //setfilterShow("disabled" as CollapsibleType);
        }
        else{
            setfilterKey(["0"]);
            setfilterShow("disabled" as CollapsibleType);
        }
    }




    return (
        <div>
            <Space style={{ marginBottom: 16 }}>
                    <Badge color={"orange"} dot={showFilterBadge} style={{display: "none"}}>
                    <Button className={"emptyB"} onClick={ShowFilters} style={{display: "none"}}>
                        <Space>
                            Filtri
                            <DownOutlined />
                        </Space>
                    </Button>
                </Badge>
            </Space>
            <Space style={{float:'right'}}>
                <Input.Search className={"emptyB"}
                              placeholder={t("Išči")}
                              onSearch={(value)=>{
                                  setSearchText(value)
                              }}
                              onChange={(e)=>{
                                  setSearchText(e.target.value)
                              }}

                />
                <Button type="primary" onClick={showDrawer} style={{display: "none"}}>
                    Open
                </Button>
                <Button onClick={newPartner} className={"orangeButton"}>+ {t("Nov_partner")}</Button>

                <Button onClick={clearAll}  className={"emptyButton"}>{t("Prenesite_v_Excel")}</Button>

            </Space>
            <Collapse
                ghost
                collapsible={filterShow}
                activeKey={filterKey}
                items={
                    [{
                        key: "1",
                        label: "",
                        children: <div>
                            <Select
                                defaultValue={selectedType}
                                bordered={false}
                                dropdownMatchSelectWidth={false}
                                options={[
                                    { value: 100, label:'Vse vrste dokumentov' },
                                    { value: 0, label: 'Drugačen dokument' },
                                    { value: 10, label: 'Racun' },
                                    { value: 11, label: 'CMR' },
                                    { value: 20, label: 'Truck foto' },
                                    { value: 21, label: 'Truck license' },
                                    { value: 22, label: 'Truck insuarence' },
                                    { value: 30, label: 'Driver foto' },
                                    { value: 31, label: 'Driver license' },
                                    { value: 32, label: 'Driver insuarence' },
                                ]}
                                onChange={(v: number) => {
                                    setshowFilterBadge(true);
                                    if (v === 100) {
                                        if(selectedType===100)
                                            setshowFilterBadge(false);
                                    }
                                    setselectedType(v)
                                }}
                            />

                        </div>,
                        showArrow: false,
                    }]
                }
            />


            <div style={{background: '#fff'}} id={"driverstab"}>

                <Table
                    className="ContractorsTable"
                    locale={{
                        triggerDesc: 'Нажмите, чтобы отсортировать по убыванию',
                        triggerAsc: 'Нажмите, чтобы отсортировать по возрастанию',
                        cancelSort: 'Отменить сортировку'
                    }}
                    columns={columns}
                    dataSource={TableData}
                    pagination={{ pageSize: 50 }} size="small"  scroll={{ y: 'calc(60vh - 4em)' }}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => {history.push("/contractors/editcontractor/"+record.id)}, // click row
                        };
                    }}
                />




            </div>

            <Drawer title="Ustvarite novo podjetje" placement="right" onClose={onClose} open={open}>
                <CreateDoc afterCreate={getCreated}/>
            </Drawer>
        </div>

    );
}
