import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";
import {Tabs, TabsProps} from "antd";
import UserSettingsPage from "./Components/UserSetting";
import CompanySettingsPage from "./Components/CompanySettings";
import {useTranslation} from "react-i18next";
import BillingPage from "./Components/Billing";
import FinArticlesPage from "./Components/FinArticles";


const SettingsPage = () => {

    const {t} = useTranslation();

    const history= useHistory();
    const user = AuthService.getCurrentUser();
    if(!user) {
        history.push("/login");
    }



    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    //console.log("urlParams: ", urlParams);
    
    const paramValue = urlParams.get('tab');
    const resultValue = urlParams.get('result');

    //console.log("paramValue: ", paramValue);
    
        const onChange = (key: string) => {
            //console.log(key);
        };

        const items: TabsProps['items'] = [
            {
                key: '1',
                label: t('Nastavitve_uporabnika'),
                children: <UserSettingsPage/>
            },
            {
                key: '2',
                label: t('Nastavitve_podjetja'),
                children: <CompanySettingsPage/>
            },
            {
                key: '3',
                label: t('Obračunavanje'),
                children: <BillingPage result={resultValue}/>
            },
            
            {
                key: '4',
                label: "Finance articles",
                children: <FinArticlesPage />
            }


            
            
            ]
   

   
   
    
    
    return (
        <div>
            <h1>{t("Nastavitve")}</h1> 
            <Tabs defaultActiveKey={paramValue===null ? "1" : paramValue } items={items} onChange={onChange} />
        <br/>
            
        
        
        
        </div>
    );
};
export default SettingsPage;