
import React from 'react';
import ListTrucks from "./TruckComponents/list";
import {Button, Space, Tabs} from "antd";
import {useHistory} from "react-router-dom";
import CalendarTrucks from "./TruckComponents/CalendarTruck";
import * as AuthService from "../../Services/auth-service";
import {useTranslation} from "react-i18next";


const TrucksPage = () => {
    const { TabPane } = Tabs;
    const history= useHistory();
    const {t} = useTranslation();


    
    const clearAll = () => {
        history.push("/newtruck");
        //window.location.href="/newtruck";
    };
    return (
        <div>
            <Space>
                <h1>{t("Vozila")}</h1>
            </Space>
            <Space style={{float:'right', marginTop:"20px"}}>
                <Button onClick={clearAll} className={"orangeButton"}>+ {t("Dodajanje_vozila")}</Button>
            </Space>
            <Tabs defaultActiveKey="1" >
                <TabPane tab={t("Seznam_vozil")} key="1">
                    <ListTrucks/>
                </TabPane>
                <TabPane tab={t("Koledar")} key="2">
                    <CalendarTrucks />
                </TabPane>
            </Tabs>
           
        </div>
     
    );
};
export default TrucksPage;