import React, { useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {Button, Col, Divider, List, Row, Steps} from "antd";
import axios from "axios";
import authHeader from "../../../Services/auth-header";

import {dateToPoints, timeToPoints} from "../../../types/transporter-types";
import {useTranslation} from "react-i18next";
import DocPositionForm from "../../docs/DocComponents/PositionForm";
import PositionsList from "../../docs/DocComponents/positionsList";
import DopustPositionForm from "./DopustPositionForm";

interface DopustListProps {
    driverId?: string;
    deleteFunc?: () => void;
    informParent? : (postions?: {
        keyStr? : string;
        id? : string;
        fromDate? : string;
        toDate? : string;
        reason? : string;
        comment? : string;
    }[]) =>void;

}

const DopustList = ({ driverId,  deleteFunc, informParent}: DopustListProps) => {

    const {t} = useTranslation();

    const history = useHistory();
    const user = AuthService.getCurrentUser();
    if (!user) {
        history.push("/login");
        window.location.reload();

    }

    const [dopustList, setdopustList] = useState<any[]>([]);
    const [posNo, setposNo] = useState(1);








    const index = dopustList.indexOf(dopustList.find(p=>p.keyStr===driverId), 0);
    useEffect(()=>{
        if(driverId) {
            console.log("ReqId: ", driverId);
            dopustList.length=0;

            axios.get(AuthService.DATA_URL + `RestRepair/get-fordriver?id=` + driverId, {headers: authHeader()})
                .then((response) => {
                    console.log("RestRepair response.data: ", response.data)
                    if (dopustList.length === 0 && response.data.length>0){
                        response.data.forEach((dop : any)=>{
                            console.log(dop)
                            addPosition({
                                keyStr: dop.id,
                                id:dop.driverId,
                                fromDate: dop.dateStart ? dateToPoints(new Date(dop.dateStart)) : undefined,
                                toDate: dop.dateEnd ? dateToPoints(new Date(dop.dateEnd)) : undefined,
                                reason: dop.reasonType,
                                comment : dop.comment
                            });
                            if(informParent){
                                informParent(dopustList.map((p)=>({
                                    id:p.id,
                                    fromDate: p.dateStart,
                                    toDate: p.dateEnd,
                                    reason: p.reason,
                                    comment:p.comment
                                })));
                            }

                        })
                    }
                })
            console.log(dopustList);
        }
        else{
            if(dopustList.length===0){
                addPosition();
            }
        }

    }, [driverId])

    const addPosition = (defaults? : any)=>{
        //setslistLoading(true);
        var d=new Date();
        var secondsStart = d.getTime();
        setposNo(secondsStart);
        dopustList.push({
            keyStr: defaults? defaults.keyStr : posNo,
            id: defaults? defaults.id :"",
            fromDate :defaults? defaults.fromDate :"",
            toDate : defaults? defaults.toDate :"",
            reason : defaults? defaults.reason :"",
            comment : defaults? defaults.comment :"",
        });
        setdopustList(dopustList);
        console.log("dopustList: ", dopustList);
        //setslistLoading(false);
    }

    const deletePos =(poskey : string) =>{

        var d=new Date();
        var secondsStart = d.getTime();
        setposNo(secondsStart);
        //  console.log("delete poskey:",poskey,  " posId: ", posNo);

        //setslistLoading(true);

        const index = dopustList.indexOf(dopustList.find(p=>p.keyStr===poskey), 0);
        //console.log("position to delete: ", positionsList[index]);

        if (index > -1) {
            dopustList.splice(index, 1);
        }
        //console.log("delete: ", poskey, "posId", posNo);
        setdopustList(dopustList);
        //console.log(positionsList);
        var d=new Date();
        var secondsStart2 = d.getTime();
        setposNo(secondsStart2);

        if(informParent){
            informParent(dopustList.map((p)=>({
                key : p.key,
                fromDate: p.fromDate,
                id : p.id,
                toDate: p.dateEnd,
                reason: p.reason,
                comment:p.comment
            })));
        }

        //setslistLoading(false);
    }


    const handleAddedPosition = (values: any)=>{
        //console.log("values: ", positionsList );
        const index = dopustList.indexOf(dopustList.find(p=>p.keyStr===values.key), 0);
        if( dopustList[index]){
            dopustList[index].fromDate=values.fromDate;
            dopustList[index].id=values.id;
            dopustList[index].toDate=values.toDate;
            dopustList[index].reason=values.reason;
            dopustList[index].comment=values.comment;
        }
        if(informParent){
            informParent(dopustList.map((p)=>({
                id : p.id,
                fromDate : p.fromDate,
                toDate : p.toDate,
                reason : p.reason,
                comment : p.comment
            })));
        }
        //console.log("values: ", positionsList );
        ///addPosition();
    }

    const renderItem = (item: any) =>{

        return(
            <List.Item key={"sdfsdf" + item.keyStr.toString()}
                       style={{borderBottomWidth: 0, padding: "0px"}}
            >
                <List.Item.Meta
                    description={
                        <div>
                            <DopustPositionForm keyStr={item.keyStr} id={driverId} fromDate={item.fromDate} toDate={item.toDate} reason={item.reason} comment={item.comment}  forceReload={true} deleteFunc={deletePos} addFunc={handleAddedPosition} />
                            <Divider/>
                        </div>

                    }
                />
            </List.Item>

        )
    }


    return <div>
        <b>{t('Dopust')} / {t('Bolniški_dopust')}</b><br/><br/>

        <div style={{height: "50vh", overflow: "scroll"}}>
            <List
                style={{width: "100%", borderBottomWidth: "0"}}
                //loading={listLoading}
                bordered={false}
                locale={{ emptyText: <span> </span> }}
                dataSource={dopustList}
                renderItem={(item: any, index) => (
                    renderItem(item)
                )}
            />

        </div>
        <Button style={{float: "left", marginRight: "10px", marginTop: "10px"}}  onClick={()=>addPosition(undefined)}>{t("Dodajte_vrstico")}</Button>

    </div>
}

export default DopustList;