import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {Button, Row, Spin, Table, ColorPicker, Col} from "antd";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import ServicePayment from "../../../types/ServicePayment";
import {ServerdateToPoints, ToMoney, translateFA} from "../../../utils/helpers";
import FinanceArticle from "../../../types/FinanceArticle";
import {ColumnsType} from "antd/es/table";
import Transaction from "../../../types/Transaction";
import {
    PlusOutlined,
    MinusOutlined,
} from '@ant-design/icons';


interface billingProps{
    result? : string | null
}
const FinArticlesPage = ({result}: billingProps) => {
    const {t} = useTranslation();

    const history = useHistory();
    const user = AuthService.getCurrentUser();


    //console.log(user);
    if (!user) {
        history.push("/login");
    }

 
    const [incomes, setIncomes]=useState<FinanceArticle[] | null>(null);
    const [expenses, setexpenses]=useState<FinanceArticle[] | null>(null);

    const [isLoading, setisLoading]=useState(false)



    useEffect(()=>{
        if(incomes===null){
            setisLoading(true)
            axios.get(AuthService.DATA_URL+'FinanceArticle/get-income-articles', {headers: authHeader()})
                .then((response) => {
                    console.log("response.data: ", response.data);
                    setIncomes(response.data);
                    //setpayRevLink(response.data.pendingPayement.checkOutUrl);
                    setisLoading(false);
                })
        }
        if(expenses===null){
            setisLoading(true)
            axios.get(AuthService.DATA_URL+'FinanceArticle/get-expense-articles', {headers: authHeader()})
                .then((response) => {
                    console.log("response.data: ", response.data);
                    setexpenses(response.data);
                    //setpayRevLink(response.data.pendingPayement.checkOutUrl);
                    setisLoading(false);
                })
        }
        
        
        
    });
    
    
    const changeFAColor=(faId? : string, color? : any)=>{
        console.log(color.toHexString().substring(1))
        
        axios.put(AuthService.DATA_URL+'FinanceArticle/change-color?id='+faId+'&color='+color.toHexString().substring(1)

    ,"", {headers: authHeader()}
        ).then((result=>{
            console.log(result)
        }))
        
         
        
    }
    const columns: ColumnsType<FinanceArticle> = [

        {
            title: t('Naziv'),
            dataIndex: 'title',
            width: '25%',
            render: (value, record) => {
                return(t(translateFA(value))  )
            }
            //editable: true,
        },        
        {
            title: ' ',
            dataIndex: 'color',
            width: '25%',
            render: (value, record) => {
                return(<ColorPicker defaultValue={value}  size="small" onChange={(e)=>changeFAColor(record.id, e)}  />)
            }
            //editable: true,
        },];

    return(
        <div>

            <Row style={{paddingTop: "10px"}} gutter={20}>
                <Col span={12}>
                    <h3 style={{color: "#1c7884"}}><PlusOutlined/> {t("Incomes")}</h3>
                    <Table
                        size={"small"}
                        locale={{
                            triggerDesc: t('Kliknite_za_razvrščanje_padajoče'),
                            triggerAsc: t('Kliknite_za_razvrščanje_naraščajoče'),
                            cancelSort: t('Prekliči_razvrščanje')
                        }}
                        columns={columns}
                        dataSource={incomes? incomes : []}
                        loading={isLoading}
                        pagination={false}
                        scroll={{ y: 'calc(45vh)' }}
                    />
                </Col>
                <Col span={12}>
                    <h3 style={{color: "#1c7884"}}><MinusOutlined/> {t("Expenses")}</h3>
                    <Table
                        size={"small"}
                        locale={{
                            triggerDesc: t('Kliknite_za_razvrščanje_padajoče'),
                            triggerAsc: t('Kliknite_za_razvrščanje_naraščajoče'),
                            cancelSort: t('Prekliči_razvrščanje')
                        }}
                        columns={columns}
                        dataSource={expenses ? expenses : []}
                        loading={isLoading}
                        pagination={false}
                        scroll={{ y: 'calc(65vh)' }}
                    />
                </Col>


            </Row>
        </div>
    )
}

export default FinArticlesPage;