import {HubConnection, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import authHeader from "../../Services/auth-header";
import {NOTIFICATION_URL} from "../../Services/auth-service";
import {useEffect, useState} from "react";
import React from "react";
import * as AuthService from "../../Services/auth-service";




export function useConnection() {

    function createConnection(path: string): HubConnection {
        //console.log("authHeader: ", authHeader())
        return new HubConnectionBuilder()
            .withUrl(NOTIFICATION_URL, {
                headers: authHeader()
            })
            .configureLogging(LogLevel.None)
            //.withAutomaticReconnect()
            .build();
    }


    const [connection, setConnection] = useState<HubConnection|undefined>(undefined);
    

    if(connection===undefined){
        const nextConnection = createConnection("");
        setConnection(nextConnection);
    }
    
    return connection
    
    /*
    // @ts-ignore
    React.useEffect(() => {
        if (user) {
            const nextConnection = createConnection("");
            setConnection(nextConnection);
            return () => nextConnection//?.stop(); //: connection?.stop();
        }
    }, [user]);

    return connection;
    
     */
}

