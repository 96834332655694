import Doc from "./DocPosition";
import Transaction from "./Transaction";
import contractor from "./contractor";
import IUser from "./user-type";

export default interface Deal{
        id?: string | null;
        dealNumber?: string | null;
        dealDescription?: string | null;
        title?: string | null;
        costPrice?: number | null;
        priceForClient?: number | null;
        priceToShowToSubcontractors?: number | null;
        requestStatus: number;
        clientId?: string | null;
        client?: contractor | null;
        createdByContractorId?: string | null;
        createdByContractor?: contractor | null;
        subContractorId?: string | null;
        subContractor?: contractor | null;
        employeeId?: string | null;
        employee?: any | null;
        userId?: string | null;
        createdAt?: string | null;
        dealDate?: string | null;
        dealDateEnd?: string | null;
        requestType?: number | null;
        docs?: Doc[] | null;
        transactions?: Transaction[] | null;
        planDocNumber?: string | null;
        planDocDate?: string | null;
        planPaydate?: string | null;
        planIncomeAmount ?: number | null;
        planIncomeDate?: string | null;
        planExpenceAmount ?: number | null;
        planExpenceDate?: string | null;
        
        
}




export  const mapDealStatusName=(status: number) =>  {
        switch ( status ) {
                case 0:
                        return "Novo";
                case 1:
                        return "Načrtovano"
                case 20:
                        return "Načrtovana"
                case 30:
                        return "Confirmed";
                case 31:
                        return "Confirmed";
                case 40:
                        return "On_route"
                case 50:
                        return "Zaključena"
                case 51:
                        return "Cancel_offer"
                case 52:
                        return "Cancel_offer";
                default:
                        return ""
        }
}

export  const mapDealStatusColor=(status: number) => {
        switch (status) {
                case 0:
                        return "#E3601D";
                case 1:
                        return "#faad14"
                case 20:
                        return "#faad14"
                case 30:
                        return "#1FAA73";
                case 40:
                        return "#1890FF"
                case 50:
                        return "#A4A6AC"
                case 52:
                        return "red";
                default:
                        return "red"
        }
}